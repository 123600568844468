import React from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import DashboardOverview from "./dashboardElements/DashboardOverview";
import DashboardRegionalView from "./dashboardElements/DashboardRegionalView";
import DashboardTeamView from "./dashboardElements/DashboardTeamView";
import './OwnerDashboard.css'
import DashboardTasks from "./dashboardElements/DashboardTasks";
import ChatPage from "../../realtimeChatComponents/ChatPage";
import OwnerChatPage from "./inboxElements/OwnerChatPage";
import OwnerNotificationPage from "./inboxElements/OwnerNotificationPage";

const OwnerInboxElement: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get("view") || "chat";

  return (
    <div className="h-screen flex flex-col w-full p-4">
      {/* Navigation Tabs */}
      <div className="flex gap-5 text-xl">
        <NavLink
          to="?view=chat"
          className={`sub-nav-item ${view === "chat" ? "sub-nav-active" : ""}`}
        >
          Chat
        </NavLink>
        <NavLink
          to="?view=notifications"
          className={`sub-nav-item ${view === "notifications" ? "sub-nav-active" : ""}`}
        >
          Notifications
        </NavLink>
      </div>

      {/* Content Area */}
      <div className="flex-1 rounded-lg p-4 overflow-y-auto w-full bg-white blue-scrollbar">
        {view === "chat" && <OwnerChatPage/>}
        {view === "notifications" && <OwnerNotificationPage />}
      </div>
    </div>
  );
};

export default OwnerInboxElement;
