// components/SlideOutPanel.tsx
import React, { useEffect } from "react";
import NotificationPanel from "./NotificationPanel";
import ChatPanel from "./ChatPanel";
import TagBadge from "../../ui/Tag/TagBadge";

interface SlideOutPanelProps {
  isOpen: boolean;
  onClose: () => void;
  activeTab: string;
  onTabChange: (tab: string) => void;
  notifications: any[]; // Replace `any` with your Notification type
  conversations: any[]; // Replace `any` with your Conversation type
}

const SlideOutPanel: React.FC<SlideOutPanelProps> = ({
  isOpen,
  onClose,
  activeTab,
  onTabChange,
  notifications,
  conversations,
}) => {
  useEffect(() => {}, [conversations, notifications]);
  return (
    <div
      className={`fixed top-0 z-50 right-0 h-full flex flex-col w-96 bg-white text-text shadow-lg transform transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      {/* Panel header with two tabs and a close button */}
      <div className="flex relative justify-between items-center p-4 border-b">
        <div className="flex gap-2 w-full justify-stretch text-center">
          <div
            className={`px-4 relative w-full py-2 cursor-pointer hover:bg-gray-100 ${
              activeTab === "notifications"
                ? "font-bold border-b-2 border-blue-500"
                : ""
            }`}
            onClick={() => onTabChange("notifications")}
          >
            <div className="relative">
              Notifications
              {notifications.filter((n) => n.read === false).length > 0 && (
                <div className="absolute -left-2 -top-2 text-xs bg-red-400 h-4 w-4 text-white flex justify-center items-center align-middle rounded-full">
                  {notifications
                    .filter((n) => n.read === false)
                    .length.toString()}
                </div>
              )}
            </div>
          </div>
          <div
            className={`px-4 relative w-full py-2 cursor-pointer hover:bg-gray-100 ${
              activeTab === "chat" ? "font-bold border-b-2 border-blue-500" : ""
            }`}
            onClick={() => onTabChange("chat")}
          >
            <div className="relative">
              Chats
              {conversations.filter(
                (conv) =>
                  !conv.lastReadAt ||
                  (conv.lastMessage &&
                    new Date(conv.lastReadAt) <
                      new Date(conv.lastMessage.createDate))
              ).length > 0 && (
                <div className="absolute left-2 -top-2 text-xs bg-red-400 h-4 w-4 text-white flex justify-center items-center align-middle rounded-full">
                  {
                    conversations.filter(
                      (conv) =>
                        !conv.lastReadAt ||
                        (conv.lastMessage &&
                          new Date(conv.lastReadAt) <
                            new Date(conv.lastMessage.createDate))
                    ).length
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={onClose}
        className="absolute top-2.5 right-2.5 text-xl leading-none"
      >
        &times;
      </button>

      {/* Panel body shows either notifications or the chat panel */}
      <div className="overflow-y-auto flex-1 flex z-50">
        {activeTab === "notifications" ? (
          <NotificationPanel notifications={notifications} />
        ) : (
          <ChatPanel />
        )}
      </div>
    </div>
  );
};

export default SlideOutPanel;
