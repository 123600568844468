import React, { useEffect, useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import Waiver from "../../../interfaces/participant/Waiver";
import {
  getParticipantInfo,
  getWaivers,
  postParticipantBump,
} from "../../../services/participantServices/ParticipantService";
import Select, { SingleValue } from "react-select";
import Banner from "../../ui/Banner/Banner";
import AssignedParticipant from "../../../interfaces/participant/AssignedParticipant";
import Unauthorized from "../errorPages/Unathorized";

interface OptionType {
  value: string;
  label: string;
}

const BumpParticipantForm: React.FC = ({}) => {
  // Eventually we are going to want to set a gloabl region and get that information
  // to POST new participants to
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [waivers, setWaivers] = useState<Waiver[] | null>([]);
  const [wavierSelection, setWaiverSelection] = useState<string>();
  const [participant, setParticipant] = useState<AssignedParticipant>();
  const { participantId } = useParams<{ participantId: string }>();
  const [waiverOptions, setWaiverOptions] = useState<OptionType[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const fetchInfo = async () => {
      if (currentUser) {
        const authToken: string = await currentUser.getIdToken();
        const waiversFetched = await getWaivers(authToken);

        if (waiversFetched) {
          setWaivers(waiversFetched);
        }
      }
    };

    fetchInfo();
  }, [currentUser]); // Only re-fetch when currentUser changes

  useEffect(() => {
    if (waivers && waivers.length > 0) {
      const formattedWaivers = waivers.map((waiver) => ({
        value: waiver.id.toString(),
        label: waiver.waiverName,
      }));
      setWaiverOptions(formattedWaivers);
    }
  }, [waivers]); // React only when employees or waivers state changes

  useEffect(() => {
    const fetchInfo = async () => {
      if (currentUser && participantId) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedParticipant = await getParticipantInfo(
          participantId,
          authToken
        );

        if (fetchedParticipant === "unauthorized") {
          return <Unauthorized />
        } else if (fetchedParticipant) {
          setParticipant(fetchedParticipant);
        }
      }
    };
    fetchInfo();
  }, [participantId]); // React only when employees or waivers state changes

  const handleWaiverChange = (selectedOption: SingleValue<OptionType>) => {
    const waiverId = selectedOption ? selectedOption.value : "";
    setWaiverSelection(waiverId);
  };

  const submit = async () => {
    setIsLoading(true);
    if (wavierSelection && currentUser && participantId) {
      const authToken = await currentUser.getIdToken();
      await postParticipantBump(participantId, wavierSelection, authToken);
      setErrorMessage("");
      navigate("/edit/participants");
    } else {
      console.error("Unable to submit creation");
    }
    setIsLoading(false);
  };

  const dismissBanner = () => {
    setErrorMessage("");
  };

  return (
    <div className="bg-[#F7F9FC]">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="-mt-10 max-w-screen-sm mx-auto text-text dark:text-text-dark p-5 relative w-11/12 rounded-3xl -top-64 bg-white">
        <div>
          <div className="text-text inter-font text-4xl text-center">
            Bump Participant Form
          </div>
          <div className="text-muted text-sm mt-5 mx-auto">
            Please use this form to change the participating waiver of a
            participant.
          </div>
        </div>
        <div className="max-w-screen-sm mx-auto">
          {errorMessage !== "" && (
            <Banner
              status="error"
              message={errorMessage}
              dismissBanner={dismissBanner}
            />
          )}
        </div>
        {/* Form information here */}

        <div className="mt-2 max-w-screen-sm mx-auto bg-card dark:bg-card-dark text-text dark:text-text-dark p-5 rounded-lg shadow-lg border">
          <div className="my-5">
            <div className="text-muted">Participant</div>
            <div className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg">
              {participant?.firstName} {participant?.lastName}
            </div>
          </div>
          <div className="my-5">
            <div className="text-muted">Participant Waiver</div>
            <div className="remove-input-txt-border ms-1">
              <Select
                options={waiverOptions}
                value={
                  waiverOptions.find(
                    (option) => option.value === wavierSelection
                  ) || null
                }
                onChange={handleWaiverChange}
                className="dark:text-text"
              />
            </div>
          </div>
          <div className="flex justify-end">
            {isLoading ? (
              <button
                disabled
                className="flex gap-2 justify-center items-center align-middle bg-[#43eaa7] text-white p-2 rounded shadow hover:shadow-none"
              >
                Loading...
              </button>
            ) : (
              <button
                className="flex gap-2 justify-center items-center align-middle bg-[#3DD598] text-white p-2 rounded shadow hover:shadow-none"
                onClick={submit}
              >
                <div>Submit</div>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BumpParticipantForm;
