import React from "react";
import { TooltipProps } from "recharts";

/**
 * CustomTooltip
 * Shows:
 * - Bold label (e.g. user name)
 * - Each data series with a colored dot and name
 * - The value aligned to the right
 */
const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  // If tooltip isn't active or no payload, don't render anything
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  return (
    <div className="border bg-white p-2 rounded-md shadow-sm text-xs">
      {/* The "label" is typically the category (e.g. user's name) */}
      <div className="font-bold mb-2 underline">{label}</div>

      {payload.map((entry) => {
        const rawPayload: any = entry.payload;
        const dotColor = rawPayload.legendColor || entry.color;
        return (
        <div
          key={entry.dataKey}
          className="flex align-middle items-center justify-between gap-5"
        >
          {/* Left side: circle + series name */}
          <span className="flex items-center align-middle gap-2">
            {/* Colored circle */}
            <div
              className={`w-3 h-3 rounded-full`}
              style={{
                backgroundColor: dotColor,
              }}
            />
            {entry.name /* Typically "onTime" or "Late" (or Bar "name" prop) */}
          </span>
          {/* Right side: value */}
          <span>{entry.value}</span>
        </div>
        );
      })}
    </div>
  );
};

export default CustomTooltip;
