import React, { useState, useEffect, useMemo } from "react";
import TaskList from "../../../../../../interfaces/task/TaskList";
import TaskFilters from "../../../../../../interfaces/task/TaskFilters";
import { fetchOrganizationTasksWithCache } from "../../../../../../services/taskServices/TaskService";
import { useAuth } from "../../../../../../firebase/AuthProvider";
import AdminTaskTable from "../../../../tasks/AdminTaskTable";
import AdminTasksFilter from "./AdminTaskFilter";
import Avatar from "../../../../../../interfaces/avatar/Avatar";
import { getAvatarsFromStorage } from "../../../../../../services/fileServices/FileService";
import { getTaskLKValues } from "../../../../../../services/taskServices/TaskLKService";
import { User } from "../../../../../../interfaces/User";
import Status from "../../../../../../interfaces/task/Status";
import Participant from "../../../../../../interfaces/Participant";
import { getAllParticipants } from "../../../../../../services/participantServices/ParticipantService";

interface OptionType {
  value: string;
  label: string;
}

const AdminTasksTable: React.FC = () => {
  const { currentUser } = useAuth();
  const [tasks, setTasks] = useState<TaskList[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [avatars, setAvatars] = useState<Avatar[]>([]);
  const [assignees, setAssignees] = useState<User[]>([])
  const [statuses, setStatuses] = useState<Status[]>([])
  const [participants, setParticipants] = useState<Participant[]>([])

  // Pagination
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  // Sorting
  const [sortKey, setSortKey] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  // Filtering
  const [taskFilters, setTaskFilters] = useState<TaskFilters>({});

  // Calculate total pages
  const totalPages = Math.ceil(totalCount / pageSize);

  useEffect(() => {
    if (!currentUser) return;

    const fetchTasks = async () => {
      setLoading(true);
      try {
        const authToken = await currentUser.getIdToken();
        const mergedFilters: TaskFilters = {
          ...taskFilters,
          sortKey,
          sortDirection,
        };
        const fetchedTasks = await fetchOrganizationTasksWithCache(
          authToken,
          "organization_tasks",
          page,
          pageSize,
          mergedFilters
        );

        setTasks(fetchedTasks?.tasks || []);
        setTotalCount(fetchedTasks?.totalCount || 0);
      } catch (err) {
        console.error("Error fetching tasks:", err);
        setTasks([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [currentUser, page, pageSize, taskFilters, sortKey, sortDirection]);

  const handlePageChange = (newPage: number) => {
    if (!loading && newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  // Function to generate truncated pagination
  const generatePaginationButtons = () => {
    const pages: (number | string)[] = [];

    if (totalPages <= 7) {
      // Show all pages if there are 7 or fewer
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Always show first page
      pages.push(1);

      if (page > 4) pages.push("...");

      // Show 3 pages around the current page
      const start = Math.max(2, page - 1);
      const end = Math.min(totalPages - 1, page + 1);

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (page < totalPages - 3) pages.push("...");

      // Always show last page
      pages.push(totalPages);
    }

    return pages;
  };

  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    if (storedAvatars) setAvatars(storedAvatars);
  }, []);

  useEffect(() => {
    if (currentUser !== null) {

      const fetchLkValues = async () => {
        if (currentUser !== null) {
          const authToken: string = await currentUser.getIdToken();
          await getTaskLKValues(authToken).then((data) => {
            if (data) {
              setAssignees(data?.users);
            }
            setStatuses(data?.statuses ?? []);
          });
          await getAllParticipants(authToken).then((data) => {
            if (data) setParticipants(data);
          });
        }
      };
      fetchLkValues();
    }
  }, [currentUser]);

const assigneeOptions: OptionType[] = useMemo(
    () =>
      assignees.map((assignee) => ({
        value: assignee.id.toString(),
        label: assignee.displayName,
      })),
    [assignees]
  );

  const participantOptions: OptionType[] = useMemo(
    () => [
      { value: "none", label: "None" }, // For tasks with no participant
      ...participants.map((p) => ({
        value: p.id.toString(),
        label: `${p.firstName} ${p.lastName}`,
      })),
    ],
    [participants]
  );

  const statusOptions: OptionType[] = useMemo(
    () =>
      statuses.map((status) => ({
        value: status.name,
        label: status.name,
      })),
    [statuses]
  );

  const dateOptions: OptionType[] = useMemo(
    () => [
      { value: "overdue", label: "Overdue" },
      { value: "today", label: "Today" },
      { value: "week", label: "This Week" },
      { value: "month", label: "This Month" },
    ],
    []
  );

  const typeOptions: OptionType[] = useMemo(
    () => [
      { value: "interim", label: "Interim" },
      { value: "monthly", label: "Monthly" },
      { value: "quarterly", label: "Quarterly" },
      { value: "annual", label: "Annual" },
    ],
    []
  );
  useEffect(() => {
    if (!currentUser) return;

    const fetchTasks = async () => {
      try {
        const authToken: string = await currentUser.getIdToken();

        // Combine filters + sorting into a single object if your API expects them together
        const mergedFilters: TaskFilters = {
          ...taskFilters,
          sortKey,
          sortDirection,
        };

        const fetchedTasks = await fetchOrganizationTasksWithCache(
          authToken,
          "organization_tasks",
          page,
          pageSize,
          mergedFilters
          // cacheTTL if you like: 2 * 60 * 1000
        );

        console.log(fetchedTasks);
        setTasks(fetchedTasks?.tasks ?? []);
        setTotalCount(fetchedTasks?.totalCount ?? 0);
      } catch (err) {
        console.error("Error fetching tasks:", err);
        setTasks([]);
      }
    };

    fetchTasks();
  }, [currentUser, page, pageSize, taskFilters, sortKey, sortDirection]);

  // Sorting: When the user clicks a column in the child table
  const handleSortChange = (newSortKey: string) => {
    if (sortKey === newSortKey) {
      // Toggle asc/desc if same column
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      // Otherwise new column => ascend
      setSortKey(newSortKey);
      setSortDirection("asc");
    }

    // Typically reset to first page on new sort
    setPage(1);
  };

  // Called by the filter component
  const handleFilterChange = React.useCallback((filters: TaskFilters) => {
    // ...existing logic to set state
    setTaskFilters(filters);
    setPage(1);
  }, []);

  // Pagination
  const handleNextPage = () => setPage((p) => p + 1);
  const handlePreviousPage = () => setPage((p) => Math.max(1, p - 1));

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Tasks</h1>

      <div className="flex justify-between">
        <div style={{ margin: "1rem 0" }}>
          {/* Pagination Buttons */}
          <button
            disabled={loading || page === 1}
            onClick={() => handlePageChange(page - 1)}
            className={`font-bold py-1.5 px-3 rounded ${
              page === 1
                ? "cursor-default bg-gray-50 text-gray-400"
                : "bg-gray-100 hover:bg-gray-200"
            }`}
          >
            Previous
          </button>

          {generatePaginationButtons().map((p, index) => (
            <button
              key={index}
              onClick={() => typeof p === "number" && handlePageChange(p)}
              disabled={loading || p === "..."}
              className={`mx-1 px-3 py-1.5 rounded ${
                page === p
                  ? "bg-blue-600 text-white"
                  : "bg-gray-100 hover:bg-gray-200"
              } ${p === "..." ? "cursor-default bg-transparent" : ""}`}
            >
              {p}
            </button>
          ))}

          <button
            disabled={loading || page === totalPages}
            onClick={() => handlePageChange(page + 1)}
            className={`font-bold py-1.5 px-3 rounded ${
              page === totalPages
                ? "cursor-default bg-gray-50 text-gray-400"
                : "bg-gray-100 hover:bg-gray-200"
            }`}
          >
            Next
          </button>

          <label className="mx-4">Page Size:</label>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(parseInt(e.target.value, 10))}
            className="bg-gray-50 w-16 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
        </div>
        <AdminTasksFilter
          onFilterChange={setTaskFilters}
          assigneeOptions={assigneeOptions}
          participantOptions={participantOptions}
          typeOptions={typeOptions}
          statusOptions={statusOptions}
          dateOptions={dateOptions}
        />
      </div>

      {/* Table Content */}
      <AdminTaskTable
        avatars={avatars}
        tasks={tasks}
        sortKey={sortKey}
        pageSize={pageSize}
        loading={loading}
        sortDirection={sortDirection}
        onChangeSort={setSortKey}
      />

      <div className="w-full flex justify-center mt-5">
      <button
        disabled={loading || page === 1}
        onClick={() => handlePageChange(page - 1)}
        className={`font-bold py-1.5 px-3 rounded ${
          page === 1
            ? "cursor-default bg-gray-50 text-gray-400"
            : "bg-gray-100 hover:bg-gray-200"
        }`}
      >
        Previous
      </button>

      {generatePaginationButtons().map((p, index) => (
        <button
          key={index}
          onClick={() => typeof p === "number" && handlePageChange(p)}
          disabled={loading || p === "..."}
          className={`mx-1 px-3 py-1.5 rounded ${
            page === p
              ? "bg-blue-600 text-white"
              : "bg-gray-100 hover:bg-gray-200"
          } ${p === "..." ? "cursor-default bg-transparent" : ""}`}
        >
          {p}
        </button>
      ))}

      <button
        disabled={loading || page === totalPages}
        onClick={() => handlePageChange(page + 1)}
        className={`font-bold py-1.5 px-3 rounded ${
          page === totalPages
            ? "cursor-default bg-gray-50 text-gray-400"
            : "bg-gray-100 hover:bg-gray-200"
        }`}
      >
        Next
      </button> 
      </div>
    </div>
  );
};

export default AdminTasksTable;
