// SkeletonRow.tsx
import React from "react";

interface SkeletonRowDivProps {
  columns: number; // e.g., 8
}

const SkeletonRowDiv: React.FC<SkeletonRowDivProps> = ({ columns }) => {
  return (
    <div className="data columns-8 truncate p-3 px-5 border-t border-b animate-pulse">
      {Array.from({ length: columns }).map((_, idx) => (
        <div
          key={idx}
          className="truncate max-w-[145px] min-w-[100px] px-10 w-1/2 h-3 bg-gray-200 rounded mb-2"
        />
      ))}
    </div>
  );
};

export default SkeletonRowDiv;
