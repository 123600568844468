import { faClockRotateLeft, faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../../../ui/Banner/Banner";

const TaskCreationSelector: React.FC = () => {
  const navigate = useNavigate();

  const [paramValue, setParamValue] = useState('');
  // const [successMessage, setSuccessMessage] = useState<string>("");

  useEffect(() => {
    // Parse the URL search params
    const params = new URLSearchParams(window.location.search);
    
    // Get the value of the parameter "?="
    const value = params.get('success');

    // Set the value to state
    setParamValue(value || ''); // If value is null or undefined, set an empty string

  }, []); // Empty dependency array to run only once after component mount

  const dismissBanner = () => { 
    navigate("");
    setParamValue("");
  }

  return (
    <div className="">
        {paramValue === "true" && <div className="mt-5"><Banner dismissBanner={dismissBanner} status="success" message={""}/></div>}
      <h2 className="title important mt-10">
        Select the type of task to create!
      </h2>
      <div className="flex gap-5 text-text dark:text-text-dark justify-center flex-wrap">
        <div
          className="cursor-pointer bg-card dark:bg-card-dark p-10 w-96 my-5 rounded-lg shadow-xl hover:shadow-sm hover:bg-text-dark hover:dark:bg-text hover:text-primary"
          onClick={() => navigate("/task/new/recurring")}
        >
          <div className="text-8xl text-center">
            <FontAwesomeIcon icon={faClockRotateLeft} />
          </div>
          <div className="font-bold text-4xl text-center pt-10">Recurring Task</div>
          <div className="text-md text-center pt-5 text-text dark:text-text-dark">Great for tasks that are going to appear at a set interval</div>
          <div className="text-xs text-center text-muted">(e.g. Every month on the 1st) </div>
        </div>
        <div
          className="cursor-pointer bg-card dark:bg-card-dark p-10 w-96 my-5 rounded-lg shadow-xl hover:shadow-sm hover:bg-text-dark hover:dark:bg-text hover:text-primary"
          onClick={() => navigate("/task/new/interim")}
        >
          <div className="text-8xl text-center">
            <FontAwesomeIcon icon={faThumbTack} />
          </div>
          <div className="font-bold text-4xl text-center pt-10">Interim Task</div>
          <div className="text-md text-center pt-5 text-text dark:text-text-dark">Great for tasks that can't be predicted or set at a set interval</div>
          <div className="text-xs text-center text-muted">(e.g. Adhoc state request for resources)</div>
        </div>
      </div>
    </div>
  );
};

export default TaskCreationSelector;
