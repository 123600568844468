import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navigation/Navbar/Navbar";
import Home from "./components/components/home/Home";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./components/components/authenticationComponents/Login";
import "./App.css";
import { AuthProvider, useAuth } from "./firebase/AuthProvider";
import LandingPage from "./components/components/landing/LandingPage";
import { NotificationProvider } from "./contexts/NotificationProvider";
import { ChatConnectionProvider } from "./contexts/ChatConnectionContext";
import GuestSignPage from "./components/components/fileViewer/pdfViewer/GuestSignPage";
import Footer from "./components/components/footer/Footer";
import AdminDashboard from "./components/components/dashboard/AdminDashboard";
import OwnerDashboard from "./components/components/dashboard/OwnerDashboard/OwnerDashboard";

function App() {
  const {currentUser, currentRoles} = useAuth();
  const [darkMode, setDarkMode] = useState(() => {
    // Check for saved dark mode preference and initialize state accordingly
    const savedMode = localStorage.getItem("darkMode");
    return savedMode === "true"; // returns true if savedMode is 'true', otherwise false
  });

  useEffect(() => {
    // Check for saved dark mode preference
    const isDarkMode = localStorage.getItem("darkMode") === "true";
    setDarkMode(false);
  }, []);

  useEffect(() => {
    // Apply or remove the 'dark' class on the body
    document.body.classList.toggle("dark", darkMode);

    // Save preference
    localStorage.setItem("darkMode", darkMode.toString());
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="App bg-background text-text dark:bg-background-dark dark:text-text min-h-screen relative">
      <Router>
        <AuthProvider>
          <NotificationProvider>
            <ChatConnectionProvider>
              {currentRoles?.some(role => role.name === 'OWNER' || role.name === 'ADMIN') ? (
                <OwnerDashboard />
              ): 
                <Navbar toggleDarkMode={toggleDarkMode} isDarkMode={darkMode} />
              }
              <div className="relative flex w-full">
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/landing-page" element={<LandingPage />} />
                  <Route path="/" element={<Login />} />

                  {/* Public route for guest signing */}
                  <Route path="/sign/:token" element={<GuestSignPage />} />

                  <Route element={<ProtectedRoute />}>
                    {/* Protected routes */}
                    <Route path="/*" element={<Home />} />
                    <Route path="/logout" element={<Login />} />
                  </Route>
                </Routes>
              </div>
              <Footer/>
            </ChatConnectionProvider>
          </NotificationProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
