import React, { useState } from "react";
import { uploadAvatar } from "../../../services/fileServices/FileService";
import { useAuth } from "../../../firebase/AuthProvider";
import Banner from "../../ui/Banner/Banner";
import LoadingSpinner from "../../ui/Loading/LoadingSpinner";

interface ProfilePictureUploadProps {
  onUploadSuccess: (url: string) => void; // A callback to handle the uploaded file URL
}

const ProfilePictureUpload: React.FC<ProfilePictureUploadProps> = ({
  onUploadSuccess,
}) => {
  const { currentUser } = useAuth();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError("No file selected");
      return;
    }

    setIsUploading(true);
    setError(null);

    if (currentUser)
      try {
        const authToken = await currentUser.getIdToken();
        const fileLocation = await uploadAvatar(
          authToken,
          currentUser.uid,
          selectedFile
        );

        if (fileLocation !== "Error") {
          onUploadSuccess(fileLocation);
          setSelectedFile(null); // Reset the file input after successful upload
        } else {
          setError("Failed to upload the file. Please try again.");
        }
      } catch (error) {
        console.error("Error during file upload:", error);
        setError("An unexpected error occurred. Please try again.");
      } finally {
        setIsUploading(false);
      }
  };

  return (
    <div className="file-upload-component mt-5">
      <h3 className="inter-font text-text mb-4">Upload Profile Picture</h3>
      {error && 
      <div className="my-4">
        <Banner message={error} status={"error"} dismissBanner={() => setError(null)} />
        </div>
        }
      <div className="flex items-center justify-center w-full">
        <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span className="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              PNG or JPG (MAX. 800x400px)
            </p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            onChange={handleFileChange}
          />
        </label>
      </div>
      {selectedFile && (
        <div className="mt-4">
          <p>Selected file: {selectedFile.name}</p>
          {isUploading ? (
            <button
              disabled
              type="button"
              className="py-1 px-1 text-sm font-medium w-full text-center  text-white bg-[#3DD598] rounded border border-gray-200 hover:bg-[#3DD598] hover:text-white focus:z-10 focus:ring-2 focus:ring-white focus:text-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex justify-center items-center"
            >
            <LoadingSpinner color="white" />
            </button>
          ) : (
            <button
              onClick={handleUpload}
              className={`bg-[#3dd598] text-white p-1 rounded flex justify-center items-center align-middle cursor-pointer`}
            >
              Upload
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfilePictureUpload;
