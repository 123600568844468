import { Message } from "../../services/chatService/ChatService";

/**
 * Helper function to check if two dates are on the same day.
 */
function areSameDay(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

/**
 * Helper function to calculate the difference in hours between two dates.
 */
function getHourDifference(date1: Date, date2: Date): number {
  return Math.abs(date1.getTime() - date2.getTime()) / (1000 * 60 * 60);
}

/**
 * Helper function to format a date as "MMM d, yyyy" (e.g., "Mar 1, 2025").
 */
function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
}

/**
 * Group messages by day.
 * - If the date is "today," we further split into multiple groups whenever there’s
 *   more than 5 hours between consecutive messages.
 * - Otherwise, all messages of that day go into a single group.
 */
export function groupMessagesByDate(messages: Message[]): {
  dateLabel: string;
  messages: Message[];
}[] {
  if (!messages?.length) return [];

  // 1) Sort messages by ascending createDate
  const sorted = [...messages].sort(
    (a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
  );

  const now = new Date();
  const groups: { dateLabel: string; messages: Message[] }[] = [];
  let currentGroup: { dateLabel: string; messages: Message[] } | null = null;

  for (let i = 0; i < sorted.length; i++) {
    const msg = sorted[i];
    const msgDate = new Date(msg.createDate);

    // 2) Determine if message is "today" or in a past date
    const isMsgToday = areSameDay(msgDate, now);

    // 3) Build label: "Today" if same day as now, otherwise e.g. "Mar 1, 2025"
    const dateLabel = isMsgToday ? "Today" : formatDate(msgDate);

    // If we have no group yet or the date label changed, start a new group
    if (!currentGroup || currentGroup.dateLabel !== dateLabel) {
      // Push old group if it exists
      if (currentGroup) {
        groups.push(currentGroup);
      }
      currentGroup = {
        dateLabel,
        messages: [msg],
      };
      continue;
    }

    // 4) If this is "today," check 5-hour gap
    if (isMsgToday) {
      // Get the last message in the current group
      const lastMsgInGroup = currentGroup.messages[currentGroup.messages.length - 1];
      const lastDate = new Date(lastMsgInGroup.createDate);

      // Calculate difference in hours
      if (getHourDifference(msgDate, lastDate) >= 5) {
        // We exceed the 5-hour gap => start a new group with the same "Today" label
        groups.push(currentGroup);
        currentGroup = {
          dateLabel,
          messages: [msg],
        };
      } else {
        // Otherwise, continue in the same group
        currentGroup.messages.push(msg);
      }
    }
    // 5) If it's not "today," we just put all messages for that day in one group
    else {
      currentGroup.messages.push(msg);
    }
  }

  // Push the final group if any
  if (currentGroup) {
    groups.push(currentGroup);
  }

  return groups;
}
