// TaskService.ts

import { PagedResult } from "../../interfaces/PagedResult";
import InterimTemplateRequest from "../../interfaces/task/InterimTemplateRequest";
import InterimTemplateResponse from "../../interfaces/task/InterimTemplateResponse";
import NewInterimTemplate from "../../interfaces/task/NewInterimTemplate";
import NewTask from "../../interfaces/task/NewTask";
import Task from "../../interfaces/task/Task"; // Import your Task interface
import TaskFilters from "../../interfaces/task/TaskFilters";
import TaskList from "../../interfaces/task/TaskList";
import TaskRequest from "../../interfaces/task/TaskRequest";

// Function to fetch a task by its ID
export async function getTaskById(
  taskId: string,
  authToken: string
): Promise<Task | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/${taskId}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.status === 403) {
      // Throw an error that can be caught in the component.
      console.log("403 ERROR HERE");
      throw new Error("Unauthorized");
    }

    if (!response.ok) {
      // For other non-OK responses, return null.
      return null;
    }

    const taskData = await response.json();

    // Map the task data to your Task interface
    const task: Task = {
      id: taskData.id,
      title: taskData.title,
      assignees: taskData.assignees,
      participant: taskData.participant,
      description: taskData.description,
      isReady: taskData.isTaskReady,
      information: taskData.information ? taskData.information : undefined,
      organization: taskData.organization,
      taskType: {
        id: taskData.type.id,
        type: taskData.type.name,
      },
      taskOrganization: {
        id: taskData.taskOrg.id,
        type: taskData.taskOrg.name,
      },
      subType: {
        id: taskData.subType.id,
        type: taskData.subType.name,
      },
      startDate: new Date(taskData.startDate),
      endDate: new Date(taskData.endDate),
      completionTime: taskData.completionTime
        ? new Date(taskData.completionTime)
        : undefined,
      progress: taskData.progress,
      status: taskData.taskStatus,
      priority: taskData.priority,
      tags: taskData.tags,
      // Add other necessary properties
    };

    return task;
  } catch (error) {
    console.error("Error fetching task:", error);
    throw error; // Propagate the error so the component can catch it.
  }
}

/* Fetches *organization-wide tasks* from the API, which the server will only return
 * if the user making the request is OWNER or ADMIN. All filtering is optional and
 * done on the server side.
 */
export async function getOrganizationTasks(
  authToken: string,
  page: number = 1,
  pageSize: number = 100,
  filters?: TaskFilters, 
): Promise<{ tasks: TaskList[]; totalCount: number } | null> {
  try {
    // Build query parameters
    const queryParams = new URLSearchParams({
      page: page.toString(),
      pageSize: pageSize.toString(),
    });

    // If we have filters, append them
    if (filters) {
      if (filters.status && filters.status.length > 0) {
        filters.status.forEach((s) => queryParams.append("status", s));
      }
      if (filters.type && filters.type.length > 0) {
        filters.type.forEach((t) => queryParams.append("type", t));
      }
      if (filters.date && filters.date.length > 0) {
        filters.date.forEach((d) => queryParams.append("date", d));
      }
      if (filters.assignees && filters.assignees.length > 0) {
        filters.assignees.forEach((a) => queryParams.append("assignee", a));
      }
      if (filters.sortKey) {
        queryParams.append("sortKey", filters.sortKey);
      }
      if (filters.sortDirection) {
        queryParams.append("sortDirection", filters.sortDirection);
      }
      if (filters.participants && filters.participants.length > 0) {
        filters.participants.forEach((p) =>
          queryParams.append("participant", p)
        );
      }
    }

    // Adjust this endpoint to match your .NET Core route for "GetTasksInOrganization"
    // e.g. "/tasks/organization" or something similar
    const apiUrl = `${
      process.env.REACT_APP_API_BASE_URL
    }/tasks/all?${queryParams.toString()}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // If server returns 401, 403, or other error, handle accordingly
      return null;
    }

    const res = await response.json();

    // Map the task data to your TaskList interface
    const tasks: TaskList[] = res.tasks.map((taskData: any) => ({
      id: taskData.id,
      title: taskData.title,
      assignees: taskData.assignees,
      description: taskData.description,
      organization: taskData.organization,
      participant: taskData.participant,
      taskType: taskData.type,
      hasAttachments: taskData.hasAttachments,
      taskOrganization: taskData.taskOrg,
      subType: {
        id: taskData.subType.id,
        type: taskData.subType.name,
      },
      startDate: new Date(taskData.startDate),
      endDate: new Date(taskData.endDate),
      completionTime: taskData.completionTime
        ? new Date(taskData.completionTime)
        : undefined,
      progress: 100, // Adjust if you have a real progress field
      status: taskData.taskStatus,
      priority: "LOW", // Adjust if needed
      assigneeRoleName: taskData.assigneeRoleName,
      fromTemplate: taskData.fromTemplate,
      tags: taskData.tags,
      // Add or map any other properties you need
    }));

    return {
      tasks,
      totalCount: res.totalCount,
    };
  } catch (error) {
    console.error("Error fetching organization tasks:", error);
    return null;
  }
}

// Example only: adjust the path & parameters as needed
export const fetchOrganizationTasksWithCache = async (
  authToken: string,
  cacheKey: string = "organization_tasks",
  page = 1,
  pageSize = 25,
  filters?: TaskFilters,
  cacheTTL = 2 * 60 * 1000
): Promise<{ tasks: TaskList[]; totalCount: number } | null> => {
  // Build query params
  const queryParams = new URLSearchParams({
    page: page.toString(),
    pageSize: pageSize.toString(),
  });

  if (filters) {
    if (filters.status && filters.status.length > 0) {
      filters.status.forEach((s) => queryParams.append("status", s));
    }
    if (filters.type && filters.type.length > 0) {
      filters.type.forEach((t) => queryParams.append("type", t));
    }
    if (filters.date && filters.date.length > 0) {
      filters.date.forEach((d) => queryParams.append("date", d));
    }
    if (filters.assignees && filters.assignees.length > 0) {
      filters.assignees.forEach((a) => queryParams.append("assignee", a));
    }
    if (filters.participants && filters.participants.length > 0) {
      filters.participants.forEach((p) =>
        queryParams.append("participant", p)
      );
    }
  }

  if (filters?.sortKey) {
    queryParams.append("sortKey", filters.sortKey);
  }
  if (filters?.sortDirection) {
    queryParams.append("sortDirection", filters.sortDirection);
  }


  const cacheKeyWithParams = `${cacheKey}_${page}_${pageSize}_${JSON.stringify(
    filters
  )}`;

  // Retrieve from localStorage or fetch from server
  // const cached = localStorage.getItem(cacheKeyWithParams);
  // if (cached) {
  //   try {
  //     const parsed = JSON.parse(cached) as {
  //       tasks: TaskList[];
  //       lastRetrieved: number;
  //     };
  //     console.log('got here')
  //     const isFresh = Date.now() - parsed.lastRetrieved < cacheTTL;
  //     if (isFresh) {
  //       return parsed.tasks;
  //     }
  //   } catch (error) {
  //     console.error("Error parsing cache for organization tasks:", error);
  //   }
  // }

  // Fetch from server
  const tasks: any = await getOrganizationTasks(authToken, page=page, pageSize=pageSize, filters=filters).then((t:any) => {
    return {tasks: t?.tasks, totalCount: t?.totalCount};
  });

  // Cache
  // localStorage.setItem(
  //   cacheKeyWithParams,
  //   JSON.stringify({
  //     tasks,
  //     lastRetrieved: Date.now(),
  //   })
  // );

  if (tasks) { 
    return tasks;
  } else { 
    return null
  }
};

// Function to fetch all tasks for logged-in User
export async function getTasks(
  authToken: string,
  userId?: string,
  page: number = 1,
  pageSize: number = 1000, // Default page size
  filters?: TaskFilters | null
): Promise<{ tasks: TaskList[]; totalCount: number } | null> {
  try {
    // Build query parameters from page, pageSize, and filters
    const queryParams = new URLSearchParams({
      page: page.toString(),
      pageSize: pageSize.toString(),
    });

    // If we have filters, append them to queryParams
    // For arrays (e.g. status=Open&status=InProgress), we can do:
    if (filters) {
      if (filters.status && filters.status.length > 0) {
        filters.status.forEach((s) => queryParams.append("status", s));
      }
      if (filters.type && filters.type.length > 0) {
        filters.type.forEach((t) => queryParams.append("type", t));
      }
      if (filters.date && filters.date.length > 0) {
        filters.date.forEach((d) => queryParams.append("date", d));
      }
      if (filters.assignees && filters.assignees.length > 0) {
        filters.assignees.forEach((a) => queryParams.append("assignee", a));
      }
      if (filters.participants && filters.participants.length > 0) {
        filters.participants.forEach((p) =>
          queryParams.append("participant", p)
        );
      }
    }

    let apiUrl = `${
      process.env.REACT_APP_API_BASE_URL
    }/tasks?${queryParams.toString()}`;

    // If a userId is specified, use the user-specific endpoint
    if (userId) {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL
      }/tasks/user/${userId}?${queryParams.toString()}`;
    }

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const res = await response.json();

    // Map the task data to your Task interface
    const tasks: TaskList[] = res.tasks.map((taskData: any) => ({
      id: taskData.id,
      title: taskData.title,
      assignees: taskData.assignees,
      description: taskData.description,
      organization: taskData.organization,
      participant: taskData.participant,
      taskType: taskData.type,
      hasAttachments: taskData.hasAttachments,
      taskOrganization: taskData.taskOrg,
      subType: {
        id: taskData.subType.id,
        type: taskData.subType.name,
      },
      startDate: new Date(taskData.startDate),
      endDate: new Date(taskData.endDate),
      completionTime: taskData.completionTime
        ? new Date(taskData.completionTime)
        : undefined,
      progress: 100,
      status: taskData.taskStatus,
      priority: "LOW",
      assigneeRoleName: taskData.assigneeRoleName,
      fromTemplate: taskData.fromTemplate,
      tags: taskData.tags,
      // Add other necessary properties
    }));

    return { tasks: tasks, totalCount: res.totalCount };
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}

// utils/taskCache.ts

export const fetchTasksWithCache = async (
  authToken: string,
  userId: string,
  isCurrentlyLoggedInUser: boolean = true,
  cacheTTL: number = 2 * 60 * 1000 // e.g. 2 minutes
) => {
  const cacheKey = `tasks_${isCurrentlyLoggedInUser ? "ADMIN_" : ""}${userId}`;
  const cached = localStorage.getItem(cacheKey);

  if (cached) {
    try {
      const parsed = JSON.parse(cached) as {
        tasks: TaskList[];
        lastRetrieved: number;
      };

      const isFresh = Date.now() - parsed.lastRetrieved < cacheTTL;
      if (isFresh) {
        // Return the cached tasks if still valid
        return parsed.tasks;
      }
    } catch (error) {
      // If JSON parse fails, we'll just fetch new data
      console.error("Error parsing cache for", userId, error);
    }
  }

  let tasks;

  // If nothing is cached or it’s stale, fetch from the server
  if (isCurrentlyLoggedInUser) {
    tasks = await getTasks(authToken).then((t) => {
      return t?.tasks;
    });
  } else {
    console.log("CALLING TASK FROM SPECIFIC USER");
    tasks = await getTasks(authToken, userId).then((t) => {
      return t?.tasks;
    });
  }
  localStorage.setItem(
    cacheKey,
    JSON.stringify({
      tasks,
      lastRetrieved: Date.now(),
    })
  );
  return tasks;
};

// Function to fetch tasks for a participant
export async function getTasksForParticipant(
  authToken: string,
  participantId: string,
  page: number = 1,
  pageSize: number = 10
): Promise<PagedResult<TaskList> | null> {
  try {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/tasks/participant/${participantId}?page=${page}&pageSize=${pageSize}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const res = await response.json();

    // Map the task data to your Task interface
    const tasks: TaskList[] = res.items.map((taskData: any) => ({
      id: taskData.id,
      title: taskData.title,
      assignees: taskData.assignees,
      description: taskData.description,
      organization: taskData.organization,
      participant: taskData.participant,
      taskType: taskData.type,
      hasAttachments: taskData.hasAttachments,
      taskOrganization: taskData.taskOrg,
      subType: {
        id: taskData.subType.id,
        type: taskData.subType.name,
      },
      startDate: new Date(taskData.startDate),
      endDate: new Date(taskData.endDate),
      completionTime: taskData.completionTime
        ? new Date(taskData.completionTime)
        : undefined,
      progress: 100,
      status: taskData.taskStatus,
      priority: "LOW",
      assigneeRoleName: taskData.assigneeRoleName,
      fromTemplate: taskData.fromTemplate,
      tags: taskData.tags,
      // Add other necessary properties
    }));

    return {
      items: tasks,
      totalCount: res.totalCount,
    };
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}

export async function publishTask(
  authToken: string,
  taskRequest: NewTask
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks`;

  // Converet NewTask type to TaskRequest type
  const requestBody: TaskRequest = {
    title: taskRequest.title,
    description: taskRequest.description,
    organizationId: 1,
    workflowGroup: taskRequest.workflowGroup,
    taskOrganization: Number(taskRequest.taskOrganization),
    taskType: Number(taskRequest.taskType),
    taskSubType: Number(taskRequest.taskSubType),
    participant: Number(taskRequest.participant),
    templateTaskId: undefined,
    requiredActions: undefined,
    requiredFiles: taskRequest.requiredFiles,
    assignee: taskRequest.assignee || "",
    coAssignee: taskRequest.coAssignee,
    endDate: taskRequest?.dueDate || Date.now().toString(),
  };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.log(response.json());
      return null;
    }

    const taskId = await response.json();

    return taskId.result;
  } catch (error) {
    console.error("Error posting comment", error);
    return null;
  }
}

export async function publishTemplateInterimTask(
  authToken: string,
  taskRequest: NewTask
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/fromTemplate`;

  // Converet NewTask type to TaskRequest type
  const requestBody: TaskRequest = {
    title: taskRequest.title,
    description: taskRequest.description,
    organizationId: 1,
    workflowGroup: taskRequest.workflowGroup,
    taskOrganization: Number(taskRequest.taskOrganization),
    taskType: Number(taskRequest.taskType),
    taskSubType: Number(taskRequest.taskSubType),
    participant: Number(taskRequest.participant),
    templateTaskId: taskRequest.templateTaskId,
    requiredActions: undefined,
    requiredFiles: taskRequest.requiredFiles,
    assignee: taskRequest.assignee || "",
    coAssignee: taskRequest.coAssignee,
    endDate: taskRequest?.dueDate || Date.now().toString(),
  };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.log(response.json());
      return null;
    }

    const taskId = await response.json();

    return taskId.result;
  } catch (error) {
    console.error("Error posting comment", error);
    return null;
  }
}

export async function publishInterimTemplate(
  authToken: string,
  taskRequest: NewInterimTemplate
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/template/interim`;

  // Converet NewTask type to TaskRequest type
  const requestBody: InterimTemplateRequest = {
    title: taskRequest.title,
    description: taskRequest.description,
    information: taskRequest.information,
    organizationId: 1,
    workflowGroup: taskRequest.workflowGroup,
    taskOrganization: Number(taskRequest.taskOrganization),
    taskType: Number(taskRequest.taskType),
    taskSubType: Number(taskRequest.taskSubType),
    participant: Number(taskRequest.participant),
    daysFromCreationDueDate: Number(taskRequest.daysFromCreationDueDate),
    templateTaskId: undefined,
    requiredActions: undefined,
    requiredFiles: taskRequest.requiredFiles,
    assignee: taskRequest.assignee || "",
    coAssignee: taskRequest.coAssignee || "",
    endDate: taskRequest?.dueDate || Date.now().toString(),
  };

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.log(response.json());
      return null;
    }

    const taskId = await response.json();

    return taskId;
  } catch (error) {
    console.error("Error posting interim template task", error);
    return null;
  }
}

export async function updateInterimTemplate(
  authToken: string,
  taskRequest: NewInterimTemplate
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/template/interim`;

  // Convert NewInterimTemplate to whatever your .NET endpoint expects
  const requestBody: InterimTemplateRequest = {
    id: taskRequest?.id,
    title: taskRequest.title,
    description: taskRequest.description,
    information: taskRequest.information,
    organizationId: 1,
    workflowGroup: taskRequest.workflowGroup,
    taskOrganization: Number(taskRequest.taskOrganization),
    taskType: Number(taskRequest.taskType),
    taskSubType: Number(taskRequest.taskSubType),
    participant: Number(taskRequest.participant),
    daysFromCreationDueDate: Number(taskRequest.daysFromCreationDueDate),
    templateTaskId: undefined,
    requiredActions: undefined,
    requiredFiles: taskRequest.requiredFiles,
    assignee: taskRequest.assignee || "",
    coAssignee: taskRequest.coAssignee,
    endDate: taskRequest?.dueDate || Date.now().toString(),

    // NEW: Pass along the tags array
    tags: taskRequest.tags ?? [],
  };

  try {
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.log(await response.json());
      return null;
    }

    const json = await response.json();
    return json.result;
  } catch (error) {
    console.error("Error Updating InterimTemplate", error);
    return null;
  }
}

export async function getInterimTemplates(
  authToken: string
): Promise<InterimTemplateResponse[] | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/template/interim`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response.json());
      return null;
    }

    const templates = await response.json();
    // Converet NewTask type to TaskRequest type
    const templateInterims: InterimTemplateResponse[] = templates.map(
      (taskRequest: InterimTemplateResponse) => ({
        id: taskRequest.id,
        title: taskRequest.title,
        description: taskRequest.description,
        information: taskRequest.information,
        daysFromCreationDueDate: Number(taskRequest.daysFromCreationDueDate),
        // @TODO: Update this for post-BLOSSOM work
        organizationId: 1,
        taskOrganization: Number(taskRequest.taskOrganization),
        taskType: Number(taskRequest.taskType),
        taskSubType: Number(taskRequest.taskSubType),
        requiredFiles: taskRequest.requiredFiles,
        tags: taskRequest.tags,
      })
    );
    return templateInterims;
  } catch (error) {
    console.error("Error posting comment", error);
    return null;
  }
}

export async function deleteTaskById(
  authToken: string,
  taskId: string
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/${taskId}`;

  try {
    const response = await fetch(apiUrl, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response.json());
      return null;
    }

    return 1; // success
  } catch (error) {
    console.error("Error deleting task", error);
    return null;
  }
}

export async function fetchLatestTasksForUser(
  authToken: string,
  userId?: string
): Promise<Task[] | null> {
  let apiUrl: string;
  if (userId) {
    apiUrl = `${process.env.REACT_APP_API_BASE_URL}/tasks/latest/${userId}`;
  } else {
    apiUrl = `${process.env.REACT_APP_API_BASE_URL}/tasks/latest`;
  }

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    console.log(response);

    return response.json();
  } catch (err) {
    console.error(err);
    return null;
  }
}
