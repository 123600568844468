// SkeletonRow.tsx
import React from "react";

type SkeletonRowProps = {
  columns: number;
};

const SkeletonRow: React.FC<SkeletonRowProps> = ({ columns }) => {
  return (
    <tr>
      {Array.from({ length: columns }).map((_, colIndex) => (
        <td
          key={`col-${colIndex}`}
          className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
        >
          {/* 
            Adjust the `w-**` classes to control the width
            of the placeholder, or use w-full to fill it 
            for a more 'bar-like' look. 
          */}
          <div className="w-3/4 h-4 bg-gray-200 rounded animate-pulse"></div>
        </td>
      ))}
    </tr>
  );
};

export default SkeletonRow;
