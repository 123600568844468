// AbacPolicy.ts
export interface AbacPolicy {
    id: number;
    role: string;
    resource: string; // e.g. "task"
    action: string;   // e.g. "view:task"
    condition: string; // JSON string, e.g. '{"onlyAssigned": true}'
  }
  
  export interface ResourceContext {
    currentUserId?: string;
    assignedUserIds?: string[];
    creatorId?: string;
    // any other needed fields
  }
  
  export function doesUserHavePermission(
    policies: AbacPolicy[],
    actionToCheck: string,
    context?: ResourceContext
  ): boolean {
    // 1) Filter policies that match the action (support wildcards if needed)
    const relevantPolicies = policies.filter((p) =>
      policyActionMatches(p.action, actionToCheck)
    );
  
    if (relevantPolicies.length === 0) return false;
  
    // 2) For each policy, parse condition and evaluate
    for (const policy of relevantPolicies) {
      try {
        const conditionObj = policy.condition ? JSON.parse(policy.condition) : {};
        if (evaluateCondition(conditionObj, context)) {
          return true; // If at least one policy is satisfied, user is allowed
        }
      } catch (err) {
        console.error("Failed to parse ABAC policy condition:", err);
        // If parse fails, skip or treat as false
      }
    }
  
    // If none of the relevant policies pass their conditions, user is denied
    return false;
  }
  
  // Basic wildcard support: "view:*" matches "view:task", etc.
  function policyActionMatches(policyAction: string, actionToCheck: string): boolean {
    // If policy is "*" => match everything
    if (policyAction === "*") return true;
  
    // If policy ends with ":*", e.g. "view:*"
    if (policyAction.endsWith(":*")) {
      const prefix = policyAction.replace(":*", "");
      return actionToCheck.startsWith(prefix + ":");
    }
  
    // Otherwise do a direct comparison, ignoring case if you prefer
    return policyAction.toLowerCase() === actionToCheck.toLowerCase();
  }
  
  // Evaluate the condition object
  function evaluateCondition(
    conditionObj: any,
    context?: ResourceContext
  ): boolean {
    if (!context) {
      // If no context, pass if conditionObj is empty
      return Object.keys(conditionObj).length === 0;
    }
  
    // "onlyAssigned" => user must be in assignedUserIds
    if (conditionObj.onlyAssigned === true) {
      if (
        !context.assignedUserIds ||
        !context.currentUserId ||
        !context.assignedUserIds.includes(context.currentUserId)
      ) {
        return false;
      }
    }
  
    // "onlyCreatedBy" => user must be the resource's creator
    if (conditionObj.onlyCreatedBy === true) {
      if (!context.creatorId || context.creatorId !== context.currentUserId) {
        return false;
      }
    }
  
    // ... Add more condition checks as needed ...
    // e.g. "managedEmployeesOnly", "vipUserOnly", etc.
  
    // If we haven't returned false, we pass
    return true;
  }
  
