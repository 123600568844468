// TaskAttachmentService.ts
import TaskAttachment from "../../interfaces/task/TaskFiles/TaskAttachment";

// Function to fetch a task by its ID
export async function getAttachmentsByTaskId(
  taskId: string, 
  authToken: string
): Promise<TaskAttachment[] | null> {
  try {
    const apiUrl = (`${process.env.REACT_APP_API_BASE_URL}/tasks/attachments/${taskId}`);

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const taskDataList = await response.json();

    // Map the task data list to an array of TaskAttachment objects
    const attachments: TaskAttachment[] = taskDataList.map((taskData: any) => ({
      id: taskData.id,
      state: { 
        id: taskData.state.id, 
        state: taskData.state.state
      },
      file: { 
        id: taskData.file.id,
        filename: taskData.file.fileName, 
        location: taskData.file.location, 
        filetype: {
          id: taskData.file.type.id, 
          type: taskData.file.type.type
        }, 
        updateDate: taskData.file.updateDate, 
        createDate: taskData.file.createDate, 
        author: { 
          id: taskData.file.author.id, 
          firstName: taskData.file.author.firstName, 
          lastName: taskData.file.author.lastName, 
          email: taskData.file.author.email, 
          displayName: taskData.file.author.displayName, 
          scId: taskData.file.author.scId, 
          joinDate: taskData.file.author.joinDate
        }
      }, 
      pendingSignatureRequest: taskData.pendingSignatureRequest
    }));


    return attachments;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}
