import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  downloadFile,
  getDriveFileById,
  getFileById,
} from "../../../services/fileServices/FileService";
import { useAuth } from "../../../firebase/AuthProvider";
import DriveFile from "../../../interfaces/file/DriveFile";
import PDFViewer from "./pdfViewer/PDFViewer"; // The same viewer, but we’ll pass canEdit={false}
import FileViewer from "react-file-viewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatFileSize } from "../../../utilities/fileUtils";
import { formatDateTime } from "../../../utilities/dateUtils";
import {
  faAngleDown,
  faAngleRight,
  faArrowLeft,
  faCalendarDays,
  faChevronDown,
  faChevronRight,
  faCodeBranch,
  faDownload,
  faFileSignature,
  faFont,
  faICursor,
  faPenFancy,
  faSignature,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { getSignatureRequestsForFile } from "../../../services/fileServices/signatures/SignatureService";
import { SignatureRequest } from "../../../interfaces/file/fileSignature/SignatureRequest";
import { SignatureType } from "../../../interfaces/file/fileSignature/SignatureBox";
import { mapSignatureBoxToSignatureField } from "../../../utilities/MapFunctions";

const FileViewPage: React.FC = () => {
  const { currentUser } = useAuth();
  const { fileId } = useParams();
  const navigate = useNavigate();

  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileMetadata, setFileMetadata] = useState<DriveFile | null>(null);
  const [blobMimeType, setBlobMimeType] = useState<string>("");
  const [scale, setScale] = useState(1.0); // For PDF zoom
  const [zoom, setZoom] = useState(1.0); // For images
  const [textContent, setTextContent] = useState<string>("");
  const [showVersionList, setShowVersionList] = useState<boolean>(false);
  const [signatureRequests, setSignatureRequests] = useState<
    SignatureRequest[]
  >([]);

  const [activeNav, setActiveNav] = useState<string | null>("document");

  // Suppose you get the user’s permission from your backend or Firestore:
  const [userCanEdit, setUserCanEdit] = useState<boolean>(true);

  useEffect(() => {
    // Example check – in a real app, you’d do an API call or check role:
    // @TODO: Handle Authorization
    setUserCanEdit(true);
  }, [currentUser]);

  useEffect(() => {
    const fetchFile = async () => {
      if (!fileId || !currentUser) return;

      try {
        const authToken = await currentUser.getIdToken();
        // 1) Fetch metadata
        const activeSignatureFields = await getSignatureRequestsForFile(
          authToken,
          fileId
        );
        if (activeSignatureFields) setSignatureRequests(activeSignatureFields);
        const file = await getDriveFileById(fileId, authToken);
        if (file) {
          setFileMetadata(file);

          // 2) Fetch actual file blob URL
          const url = await getFileById(fileId, authToken);
          if (url) {
            setFileUrl(url);
            const response = await fetch(url);
            const blob = await response.blob();

            // Infer the MIME type from either the file metadata, the blob, or fallback
            let inferredMimeType = file.mimeType || blob.type;
            // You can do more advanced logic here if needed
            setBlobMimeType(inferredMimeType);

            if (inferredMimeType === "text/plain") {
              const text = await response.text();
              setTextContent(text);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };

    fetchFile();
  }, [fileId, currentUser]);

  // Fallback for render
  const renderViewer = () => {
    if (!fileUrl || !fileMetadata) return <div>Loading...</div>;

    const extension = (
      fileMetadata.fileName?.split(".").pop() || ""
    ).toLowerCase();

    // If PDF
    if (blobMimeType === "application/pdf" || extension === "pdf") {
      return (
        <div>
          {/* PDF Zoom Controls */}
          <div className="flex items-center gap-2 mt-2 justify-end">
            <label htmlFor="scale">Zoom: </label>
            <input
              type="range"
              id="scale"
              min="0.5"
              max="2.0"
              step="0.1"
              value={scale}
              onChange={(e) => setScale(parseFloat(e.target.value))}
            />
            <span>{scale.toFixed(1)}x</span>
          </div>
          <PDFViewer
            fileUrl={fileUrl}
            scale={scale}
            signatureFields={signatureRequests.flatMap(req => 
              req.signatureBoxes.map(box => mapSignatureBoxToSignatureField(box))
            )}
            canEdit={false} // READ-ONLY here
            selectedSignerEmail=""
            onSignatureFieldsChange={() => {}}
          />
        </div>
      );
    }

    // If image
    if (
      blobMimeType.startsWith("image/") ||
      ["jpg", "jpeg", "png", "gif"].includes(extension)
    ) {
      return (
        <div>
          <div
            style={{
              transform: `scale(${zoom})`,
              transformOrigin: "center",
              display: "inline-block",
            }}
          >
            <img src={fileUrl} alt={fileMetadata.fileName} />
          </div>
          <div className="flex items-center gap-2 mt-2 justify-end">
            <label>Zoom: </label>
            <input
              type="range"
              min="0.5"
              max="3.0"
              step="0.1"
              value={zoom}
              onChange={(e) => setZoom(parseFloat(e.target.value))}
            />
            <span>{zoom.toFixed(1)}x</span>
          </div>
        </div>
      );
    }

    // If text
    if (
      blobMimeType === "text/plain" ||
      ["txt", "csv", "log"].includes(extension)
    ) {
      return (
        <div className="border p-4 rounded bg-white max-w-full overflow-auto">
          <pre className="whitespace-pre-wrap break-words">{textContent}</pre>
        </div>
      );
    }

    // If video
    if (
      blobMimeType.startsWith("video/") ||
      ["mp4", "webm", "mov", "avi"].includes(extension)
    ) {
      return (
        <video
          src={fileUrl}
          controls
          style={{ maxWidth: "100%", maxHeight: "70vh" }}
        />
      );
    }

    // Otherwise, fallback to react-file-viewer
    const fileType = (() => {
      if (extension === "doc" || extension === "docx") return "docx";
      if (extension === "xls" || extension === "xlsx") return "xlsx";
      return extension || "unknown";
    })();

    return (
      <FileViewer
        fileType={fileType}
        filePath={fileUrl}
        onError={(err) => console.log("FileViewer error:", err)}
      />
    );
  };

  const handleDownload = async () => {
    if (!fileId || !fileMetadata || !currentUser) return;
    try {
      const authToken = await currentUser.getIdToken();
      const blob = await downloadFile(fileId, authToken);
      if (blob) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileMetadata.fileName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const toggleVersionList = () => {
    setShowVersionList(!showVersionList);
  };

  const toggleNav = (elementName: string) => {
    if (activeNav === elementName) {
      setActiveNav(null);
    } else {
      setActiveNav(elementName);
    }
  };

  return (
    <div
      className="relative bg-[#F7F9FC] dark:bg-background-dark sm:min-w-[640px] 
    overflow-x-auto max-w-full overflow-hidden h-[100vh]"
    >
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>

      <div
        className="-top-64 relative dark:bg-card-dark dark:text-text-dark 
                        bg-[#F7F9FF] rounded-3xl shadow-lg w-11/12 mx-auto 
                        min-h-[600px] -mt-10 overflow-auto mb-10"
      >
        <div className="bg-white border-b">
          <div className="flex justify-between p-4">
            <div className="flex gap-4 align-middle items-center">
              <div
                className="border shadow-md rounded-lg p-2 hover:shadow-none cursor-pointer text-sm"
                onClick={() => navigate(-1)}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Back
              </div>
              <h1 className="text-xl font-bold">{fileMetadata?.fileName}</h1>
            </div>
            <div className="flex gap-2 items-center align-middle">
              <button
                onClick={handleDownload}
                className="border px-3 py-1 rounded"
              >
                <FontAwesomeIcon icon={faDownload} />
              </button>
              {userCanEdit && fileMetadata?.latest && (
                <div className="text-right">
                  {/* Link to the edit route, e.g. /files/:fileId/edit */}
                  <button
                    className="bg-blue-600 text-white px-3 py-1 rounded flex items-center align-middle gap-2"
                    onClick={() => navigate(`/edit/file/${fileId}`)}
                  >
                    <FontAwesomeIcon icon={faFileSignature} />
                    <div>Sign</div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {!fileMetadata?.latest && (
          <div className="absolute border p-2 z-50 rounded-lg top-5 left-1/2 -translate-x-1/2 border-yellow-600 text-yellow-600 bg-yellow-50">
            <div className="flex gap-2 items-center align-middle">
              <FontAwesomeIcon icon={faTriangleExclamation} />
              Warning! You are not viewing the latest version of this file.
            </div>
          </div>
        )}
        <div className="flex gap-4 w-full">
          <div className="mb-4 flex-1">{renderViewer()}</div>
          <div className="w-64 border-l bg-white">
            <div
              className="flex justify-between items-center align-middle border-b p-4 cursor-pointer"
              onClick={() => toggleNav("document")}
            >
              <h2 className="font-bold">Document</h2>
              <FontAwesomeIcon
                className="text-inactive cursor-pointer"
                icon={activeNav === "document" ? faChevronDown : faChevronRight}
              />
            </div>
            {activeNav === "document" && (
              <div className="flex flex-col text-sm mt-2 gap-3 p-4">
                <div>
                  <div className="font-bold">File Type</div>
                  <div className="text-muted text-xs">
                    {fileMetadata?.mimeType || "N/A"}
                  </div>
                </div>
                <div>
                  <div className="font-bold">File Name</div>
                  <div className="text-muted text-xs">
                    {fileMetadata?.fileName}
                  </div>
                </div>
                <div>
                  <div className="font-bold">Created</div>
                  <div className="text-muted text-xs">
                    {fileMetadata?.createDate
                      ? formatDateTime(new Date(fileMetadata.createDate))
                      : "N/A"}
                  </div>
                </div>
                <div>
                  <div className="font-bold">Last Updated</div>
                  <div className="text-muted text-xs">
                    {fileMetadata?.updateDate
                      ? formatDateTime(new Date(fileMetadata.updateDate))
                      : "N/A"}
                  </div>
                </div>
                <div>
                  <div className="font-bold">File Size</div>
                  <div className="text-muted text-xs">
                    {formatFileSize(fileMetadata?.size || 0)}
                  </div>
                </div>
                <div>
                  <div className="font-bold">Version</div>
                  <div
                    className={`text-xs flex justify-between w-3/4 ${
                      fileMetadata?.versionId && fileMetadata.versionId > 0
                        ? "text-blue-500 cursor-pointer hover:bg-gray-100"
                        : "text-muted"
                    }`}
                    onClick={toggleVersionList}
                  >
                    {fileMetadata?.versionId
                      ? "v" + fileMetadata.versionId
                      : "v1"}
                    {fileMetadata?.versionId && fileMetadata.versionId > 1 && (
                      <div>
                        <FontAwesomeIcon
                          icon={showVersionList ? faAngleDown : faAngleRight}
                        />
                      </div>
                    )}
                  </div>
                  {showVersionList && fileMetadata?.versionHistory && (
                    <div
                      className={`flex flex-col text-wrap overflow-hidden bg-gray-100 text-blue-700`}
                    >
                      {fileMetadata?.versionHistory.map(
                        (fileVersion, index) => (
                          <div
                            className={`ps-4 text-xs py-1 text-ellipsis truncate ${
                              fileMetadata.versionId === fileVersion.versionId
                                ? "bg-white text-text"
                                : "hover:bg-gray-200"
                            } cursor-pointer flex gap-1 align-middle items-center`}
                            key={index}
                            onClick={() => {
                              if (
                                fileMetadata.versionId !== fileVersion.versionId
                              )
                                navigate(`/view/file/${fileVersion.fileID}`);
                            }}
                          >
                            <FontAwesomeIcon icon={faCodeBranch} />
                            {`(Version: v${fileVersion.versionId}) ${fileVersion.fileName} `}
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div>
              <div
                className="flex justify-between items-center align-middle border-b p-4 cursor-pointer"
                onClick={() => toggleNav("signature-requests")}
              >
                <h2 className="font-bold">Signature Requests</h2>
                <FontAwesomeIcon
                  className="text-inactive cursor-pointer"
                  icon={
                    activeNav === "signature-requests"
                      ? faChevronDown
                      : faChevronRight
                  }
                />
              </div>
              {activeNav === "signature-requests" && (
                <div className="mt-2">
                  {signatureRequests.map((sr, index) => (
                    <div className="mx-4" key={index}>
                      {sr.signatureBoxes.map((field) => (
                      <div
                        key={field.id}
                        className={`p-2 mb-2 cursor-pointer border rounded`}
                      >
                        <div className="flex align-middle items-center gap-2">
                          {field.boxType.name === SignatureType.DATE && (
                            <FontAwesomeIcon
                              className="w-6"
                              icon={faCalendarDays}
                            />
                          )}
                          {field.boxType.name === SignatureType.SIGNATURE && (
                            <FontAwesomeIcon
                              className="w-6"
                              icon={faSignature}
                            />
                          )}
                          {field.boxType.name === SignatureType.INITIALS && (
                            <FontAwesomeIcon className="w-6" icon={faFont} />
                          )}
                          {field.boxType.name === SignatureType.TEXT && (
                            <FontAwesomeIcon className="w-6" icon={faICursor} />
                          )}{" "}
                          <div>{field.assignedSignerEmail || "(none)"}</div>
                        </div>
                        {/* <div>Page: {field.pageNumber}</div>
                      <div>Type: {field.signatureType || "Signature"}</div>
                      <div>Assigned: {field.signerEmail || "(none)"}</div> */}
                      </div>
                      ))}
                    </div>
                  ))}
                  {signatureRequests.length === 0 && (
                    <div className="text-muted text-xs">
                      There are currently no Signature Fields created.
                      Right-click anywhere on the PDF to add a new field.
                    </div>
                  )}

                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileViewPage;
