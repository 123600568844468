// src/SproutSheets.tsx
import React, { useState } from 'react';
import { Workbook } from '../../../../interfaces/sproutSheets/Workbook';
import { createEmptySheet } from '../../../../utilities/sheetUtils/SheetUtils';
import Spreadsheet from './components/Spreadsheet';
import { FormattingToolbar } from './components/FormattingToolbar';

const SproutSheets: React.FC = () => {
    const [workbook, setWorkbook] = useState<Workbook>({
        sheets: [createEmptySheet('Sheet1')],
        activeSheetIndex: 0,
      });


  return (
    <div className='bg-white'>
      <h1>My React Spreadsheet</h1>
      <div style={{ display: 'flex', gap: '8px' }}>
        {workbook.sheets.map((sheet, index) => (
          <button
            key={index}
            onClick={() =>
              setWorkbook((prev) => ({ ...prev, activeSheetIndex: index }))
            }
            style={{
              fontWeight: index === workbook.activeSheetIndex ? 'bold' : 'normal',
            }}
          >
            {sheet.name}
          </button>
        ))}
        <button
          onClick={() => {
            // Add a new sheet
            const newSheetName = `Sheet${workbook.sheets.length + 1}`;
            setWorkbook((prev) => ({
              ...prev,
              sheets: [...prev.sheets, createEmptySheet(newSheetName)],
              activeSheetIndex: prev.sheets.length, // Make the new sheet active
            }));
          }}
        >
          + New Sheet
        </button>
      </div>
      <Spreadsheet
        sheet={workbook.sheets[workbook.activeSheetIndex]}
        workbook={workbook}
        setWorkbook={setWorkbook}
      />
    </div>
  );
};

export default SproutSheets;
