import React, { useEffect, useState } from "react";
import Select from "react-select";
import Banner from "../../../../ui/Banner/Banner";

interface RecurringTaskCreationDateStepProps {
  step: number;
  setValue: (calculationType: string, offset: string) => void;
}

// Define the option type
type OptionType = {
  value: string;
  label: string;
};

const RecurringTaskCreationDateStep: React.FC<RecurringTaskCreationDateStepProps> = ({
  step,
  setValue,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<OptionType | null>();
  const [fixedDateSelection, setFixedDateSelection] =
    useState<OptionType | null>(null);
  const [dayInput, setDayInput] = useState<string | "">("");

  // Define the options
  const options: OptionType[] = [
    { value: "daysAfterCreation", label: "Days After Creation" },
    { value: "daysAfterLinkageDate", label: "Days After Linkage Date" },
    { value: "daysAfterCpocDate", label: "Days After CPOC Date" },
    { value: "daysAfterCpocEndDate", label: "Days After CPOC End Date" },
    { value: "daysBeforeCreation", label: "Days Before Creation" },
    { value: "daysBeforeLinkageDate", label: "Days Before Linkage Date" },
    { value: "daysBeforeCpocDate", label: "Days Before CPOC Date" },
    { value: "daysBeforeCpocEndDate", label: "Days Before CPOC End Date" },
    { value: "monthsAfterCreation", label: "Months After Creation" },
    { value: "monthsAfterLinkageDate", label: "Months After Linkage Date" },
    { value: "monthsAfterCpocDate", label: "Months After CPOC Date" },
    { value: "monthsAfterCpocEndDate", label: "Months After CPOC End Date" },
    { value: "monthsBeforeCreation", label: "Months Before Creation" },
    { value: "monthsBeforeLinkageDate", label: "Months Before Linkage Date" },
    { value: "monthsBeforeCpocDate", label: "Months Before CPOC Date" },
    { value: "monthsBeforeCpocEndDate", label: "Months Before CPOC End Date" },
    { value: "fixedDate", label: "Fixed Date" },
  ];

  const fixedDateOptions: OptionType[] = [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "quarterly", label: "Quarterly" },
    { value: "yearly", label: "Yearly" },
  ];

  const handleChange = (option: OptionType | null) => {
    setSelectedOption(option);
    // Handle change here (e.g., storing the selected value)
  };

  const handleFixedDateChange = (option: OptionType | null) => {
    setFixedDateSelection(option);
    setDayInput("");
  };

  const handleDayInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDayInput(event.target.value);
  };

  const handleOffsetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDayInput(event.target.value);
  };

  const isValidDayInput = (): boolean => {
    const day = parseInt(dayInput);
    if (isNaN(day)) return false;
    if (selectedOption?.value === "fixedDate" && fixedDateSelection?.value === "weekly") {
      return day >= 1 && day <= 7;
    } else if ( selectedOption?.value === "fixedDate" ) {
      return day >= 1 && day <= 31;
    } else { // for non-fixed dates
      return day >= 0 && day <= 365;
    }
  };

  const handleSubmit = () => {
    if (!isValidDayInput()) {
      setErrorMessage("Please enter a valid day.");
      return;
    }
    if (selectedOption?.value === "fixedDate" && fixedDateSelection) {
      const day = parseInt(dayInput);
      let cronExpression = "";

      if (fixedDateSelection.value === "weekly") {
        const cronDay = day % 7; // Converts 7 to 0 (Sunday) and keeps other days as is
        cronExpression = `0 0 * * ${cronDay}`;
      } else if (fixedDateSelection.value === "monthly" && day) {
        cronExpression = `0 0 ${day} * *`;
      } else if (fixedDateSelection.value === "quarterly" && day) {
        cronExpression = `0 0 ${day} */3 *`;
      } else if (fixedDateSelection.value === "yearly" && day) {
        cronExpression = `0 0 ${day} 1 *`;
      } else {
        return;
      }

      setValue(selectedOption.value, cronExpression);

    } else if ( selectedOption?.value !== undefined ) { 
      setValue(selectedOption?.value, dayInput);
    }
  };

  const dismissBanner = () => { 
    setErrorMessage("");
  }

  return (
    <div className="mx-auto block bg-card dark:bg-card-dark mt-20 rounded-lg shadow-xl p-10 remove-input-txt-border">
      { errorMessage !== "" && <div className="mb-5"><Banner status="warning" dismissBanner={dismissBanner} message={errorMessage}/></div>}
      <div className="font-bold text-primary">
        Great, now the same thing, but for when the task should be created (visible)!{" "}
      </div>
      <div className="mt-2 text-muted">
        How often would you like this task to be created?
      </div>
      <div>
        <div className="mt-5">
          <div className="mb-1 text-sm">
            Select the calculation type for the creation date
          </div>
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      </div>
      <div>
        {selectedOption?.value === "fixedDate" ? (
          <div className="mt-5">
            <div className="text-sm mb-1">Please select a fixed date</div>
            <Select
              value={fixedDateSelection}
              onChange={handleFixedDateChange}
              options={fixedDateOptions}
              className="react-select-container"
              classNamePrefix="react-select"
            />
            {fixedDateSelection && (
              <div className="mt-5 flex-col">
                <div className="text-sm mb-1">Select an day of the week/month</div>
                <input
                  className="w-full border p-2 rounded-md"
                  type="number"
                  min={fixedDateSelection.value === "weekly" ? "1" : "1"}
                  max={fixedDateSelection.value === "weekly" ? "7" : "31"}
                  value={dayInput}
                  onChange={handleDayInputChange}
                  placeholder={
                    fixedDateSelection.value === "weekly"
                      ? "Day of the week (1=Monday, 7=Sunday)"
                      : "Day of the month"
                  }
                />
              </div>
            )}
          </div>
        ) : (
          selectedOption && (
            <div className="mt-5 flex-col">
              <div className="text-sm mb-1">Input an offset in days</div>
              <input
                className="w-full border p-2 rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                type="number"
                min="0"
                max="365"
                step="1"
                placeholder="Enter a positive integer"
                onChange={handleOffsetChange}
                // Include more input handling as needed
              />
            </div>
          )
        )}
      </div>
      <div className="mt-5">
        {selectedOption && (
          <button
            className="block ms-auto primary-button"
            onClick={handleSubmit}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default RecurringTaskCreationDateStep;
