// file: components/chat/ConversationList.tsx
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faReply,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

import * as signalR from "@microsoft/signalr";
import { useChatConnection } from "../../../contexts/ChatConnectionContext"; // <== Our new context
import {
  Conversation,
  Message,
  createConversation,
  getMyConversations,
} from "../../../services/chatService/ChatService";
import { getAllUsersInOrganization } from "../../../services/userServices/UserInfoService";
import Avatar from "../../../interfaces/avatar/Avatar";
import UserInfo from "../../../interfaces/UserInfo";

interface ConversationListProps {
  authToken: string;
  userId: string;
  avatars: Avatar[];
  selectedConversationId: number | null;
  onSelectConversation: (conversation: Conversation) => void;
  width?: string;
}

const ConversationList: React.FC<ConversationListProps> = ({
  authToken,
  userId,
  avatars,
  selectedConversationId,
  onSelectConversation,
  width
}) => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserInfo[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [addConversationView, setAddConversationView] =
    useState<boolean>(false);

  // A map: conversationId -> array of userIds who are typing
  const [typingMap, setTypingMap] = useState<Record<number, string[]>>({});

  // 1) Access the **shared** SignalR connection from context
  const connection = useChatConnection();

  // 2) Fetch conversations + org users
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getMyConversations(authToken);
        const allOrgUsers = await getAllUsersInOrganization(authToken);

        setConversations(data);
        if (allOrgUsers) setUsers(allOrgUsers);
        setError(null);
      } catch (err) {
        console.error("Failed to load conversations:", err);
        setError("Failed to load conversations");
      } finally {
        setLoading(false);
      }
    };

    if (authToken) {
      fetchData();
    }
  }, [authToken]);

  // 3) Once we have the connection, attach event handlers exactly once
  useEffect(() => {
    if (!connection) return; // Not connected yet

    // Handler: user started/stopped typing
    const handleTypingStatus = (
      convId: number,
      typingUserId: string,
      currentlyTyping: boolean
    ) => {
      // Do not track ourselves
      if (typingUserId === userId) return;

      setTypingMap((prev) => {
        const currentList = prev[convId] || [];
        if (currentlyTyping) {
          if (!currentList.includes(typingUserId)) {
            return { ...prev, [convId]: [...currentList, typingUserId] };
          }
          return prev;
        } else {
          return {
            ...prev,
            [convId]: currentList.filter((id) => id !== typingUserId),
          };
        }
      });
    };

    // Handler: new message
    const handleReceiveMessage = (message: Message) => {
      // Update conversation's last message
      setConversations((prev) =>
        prev.map((conv) => {
          if (conv.id === message.conversationId) {
            return {
              ...conv,
              lastMessage: message,
            };
          }
          return conv;
        })
      );
    };

    // Handler: read receipt
    const handleReceiveReadReceipt = (
      convId: number,
      userIdWhoRead: string,
      lastReadAt: Date
    ) => {
      // If I'm the one who read it, update lastReadAt on that conversation
      setConversations((prev) =>
        prev.map((conv) => {
          if (conv.id !== convId) return conv;
          if (userIdWhoRead === userId) {
            return {
              ...conv,
              lastReadAt,
            };
          }
          return conv;
        })
      );
    };

    // Attach event handlers
    connection.on("ReceiveTypingStatus", handleTypingStatus);
    connection.on("ReceiveMessage", handleReceiveMessage);
    connection.on("ReceiveReadReceipt", handleReceiveReadReceipt);

    return () => {
      // Clean up
      connection.off("ReceiveTypingStatus", handleTypingStatus);
      connection.off("ReceiveMessage", handleReceiveMessage);
      connection.off("ReceiveReadReceipt", handleReceiveReadReceipt);
    };
  }, [connection, userId]);

  // 4) Each time our `conversations` list changes, join those conversation groups
  useEffect(() => {
    if (!connection) return;
    conversations.forEach((conv) => {
      connection
        .invoke("JoinConversation", conv.id)
        .then(() => {
          // console.log("Joined conversation group:", conv.id);
        })
        .catch((err) => {
          console.error(`Failed to join conversation ${conv.id}`, err);
        });
    });
  }, [connection, conversations]);

  // ----- CREATE NEW CONVERSATION -----
  const handleUserSelection = (selectedOptions: any) => {
    setSelectedUsers(
      selectedOptions ? selectedOptions.map((opt: any) => opt.value) : []
    );
  };

  const handleCreateConversation = async () => {
    if (!selectedUsers.length) {
      alert("Please select at least 1 user");
      return;
    }

    try {
      const newConv = await createConversation(authToken, selectedUsers);
      // Add to local state
      setConversations((prev) => [...prev, newConv]);
      setAddConversationView(false);
      // Select it
      onSelectConversation(newConv);
    } catch (err) {
      console.error("Failed to create conversation:", err);
      alert("Error creating conversation. See console logs.");
    }
  };

  const toggleAddConversation = () => {
    setAddConversationView(!addConversationView);
  };

  // ----- HELPER: find avatar -----
  const findAvatarById = (id: string): string | null => {
    const avatarObj = avatars.find((a) => a.id === id);
    return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
  };

  // ----- RENDER -----
  if (loading) return <div>Loading conversations...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div
    className={`${width ? width : 'w-[300px]'}`}
      style={{ borderRight: "", }}
    >
      <div className="flex justify-between mb-2 p-4">
        <h3 className="inter-font-bold text-text">My Conversations</h3>
        <div className="relative flex items-center align-middle gap-2 cursor-pointer">
          <div
            className="flex items-center align-middle gap-1"
            onClick={toggleAddConversation}
          >
            <div className="h-7 w-7 bg-blue-500 rounded-full text-white flex items-center align-middle justify-center">
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
          {addConversationView && (
            <div className="absolute top-10 right-0 bg-white border shadow-lg p-2 w-[250px] remove-input-txt-border z-50">
              <Select
                isMulti
                options={users.map((user) => ({
                  value: user.id,
                  label:
                    user.displayName || `${user.firstName} ${user.lastName}`,
                }))}
                value={selectedUsers.map((uid) => ({
                  value: uid,
                  label: users.find((u) => u.id === uid)?.displayName || uid,
                }))}
                onChange={handleUserSelection}
                placeholder="Select users..."
              />
              <button
                onClick={handleCreateConversation}
                className="mt-2 bg-blue-500 text-white rounded p-2 w-full"
              >
                Create
              </button>
            </div>
          )}
        </div>
      </div>

      <ul style={{ listStyle: "none", padding: 0 }}>
        {conversations.map((conv) => {
          const isSelected = conv.id === selectedConversationId;
          const style = {
            cursor: "pointer",
            padding: "8px",
          };

          const read =
            conv.lastMessage &&
            conv.lastReadAt &&
            new Date(conv.lastReadAt) >= new Date(conv.lastMessage.createDate);

          // For group vs single participant
          const participantsExcludingMe = conv.participants.filter(
            (p) => p.id !== userId
          );
          const isGroup = participantsExcludingMe.length > 1;
          const participantsNames = participantsExcludingMe
            .map((p) => p.displayName || p.firstName + " " + p.lastName)
            .join(", ");

          // Show last message
          const lastMsg = conv.lastMessage;
          const lastMsgText = lastMsg
            ? lastMsg.messageContent.length > 100
              ? lastMsg.messageContent.substring(0, 100) + "..."
              : lastMsg.messageContent
            : "No messages yet";

          // Typing info
          const typingUsers = typingMap[conv.id] || [];
          const isSomeoneTyping = typingUsers.length > 0;

          return (
            <li
              key={conv.id}
              style={style}
              onClick={() => onSelectConversation(conv)}
              className={`flex items-center align-middle gap-2 w-full ${isSelected ? "bg-gray-200" : "bg-transparent hover:bg-gray-100"}`}
            >
              {/* Show either a single avatar or group icon */}
              {isGroup ? (
                <div className="h-10 w-10 p-4 text-white mr-2 rounded-full bg-blue-500 flex justify-center align-middle items-center">
                  <FontAwesomeIcon icon={faUserGroup} />
                </div>
              ) : (
                <div className="h-10 w-10 p-4 text-white mr-2 rounded-full bg-blue-500 flex justify-center align-middle items-center">
1                  <img
                    src={
                      participantsExcludingMe[0]
                        ? findAvatarById(participantsExcludingMe[0].id) ??
                          "/default-avatar.png"
                        : "/default-avatar.png"
                    }
                    className="w-10 h-10 absolute"
                    alt="profile_picture"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
              )}

              <div className="flex justify-stretch w-full items-center align-middle truncate">
                <div className="w-full">
                  <div style={{ fontWeight: "bold" }}>{participantsNames}</div>
                  {isSomeoneTyping ? (
                    <div style={{ fontStyle: "italic", color: "#888" }}>
                      {typingUsers.length > 1
                        ? "Several people are typing..."
                        : `${
                            conv.participants.find(
                              (p) => p.id === typingUsers[0]
                            )?.displayName || "Someone"
                          } is typing...`}
                    </div>
                  ) : (
                    <div className="flex items-center align-middle gap-1 w-full">
                      {lastMsg?.author?.id === userId && (
                        <FontAwesomeIcon
                          className="text-xs text-muted-light"
                          icon={faReply}
                        />
                      )}
                      <div className={`overflow-ellipsis ${read ? "text-muted-light" : "font-bold"}`}>
                        <div className="overflow-ellipsis line-clamp-1">{lastMsgText}</div>
                      </div>
                    </div>
                  )}
                </div>

                {!read && conv.lastMessage && (
                  <div className="h-2 w-2 bg-blue-600 rounded-full text-ellipsis line-clamp-1"></div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ConversationList;
