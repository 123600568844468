import React, { useEffect, useState } from "react";
import TemplateTask from "../../../../interfaces/templateTask/TemplateTask";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";
import { useAuth } from "../../../../firebase/AuthProvider";
import {
  addUserToTaskGroup,
  customBatchRun,
  deleteTemplateTaskRequiredFile,
  getAllTemplateTasks,
  getTemplateTask,
  getTemplateTaskRequiredFiles,
  getTemplateTasks,
  removeUserFromTaskGroup,
  submitTaskToAddToGroup,
} from "../../../../services/templateService/TemplateTaskService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faEdit,
  faFile,
  faTasks,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  deleteTemplateTaskAction,
  getTemplateTaskActions,
  publishTemplateTaskTags,
} from "../../../../services/templateService/TemplateTaskActionService";
import TemplateTaskAction from "../../../../interfaces/templateTask/TemplateTaskAction";
import TemplateRequiresFile from "../../../../interfaces/templateTask/TemplateRequiresFile";
import AddTaskAction from "./AddTaskAction";
import AddRequiredFile from "./AddRequiredFile";
import TaskGroup from "../../../../interfaces/task/workflow/TaskGroup";
import TaskCreationLKValues from "../../../../interfaces/lkValues/TaskCreationLKValues";
import { getTaskLKValues } from "../../../../services/taskServices/TaskLKService";
import TemplateTaskShortResponse from "../../../../interfaces/templateTask/TemplateTaskShortResponse";
import { useNavigate } from "react-router-dom";
import Banner from "../../../ui/Banner/Banner";
import { formatDateToDateFormFormat } from "../../../../utilities/dateUtils";
import { getAllTags } from "../../../../services/tagServices/TagService";
import Tag from "../../../../interfaces/tag/Tag";
import ConfirmDeleteModal from "../../../ui/ConfirmationModals/ConfirmDeleteModal";
import { createAdministrativeTransactionRequest } from "../../../../services/AdministrativeRequestServices/AdministrativeRequestService";
import LoadingSpinner from "../../../ui/Loading/LoadingSpinner";

interface OptionType {
  value: string;
  label: string;
}

const EditRecurringTasksPage: React.FC = () => {
  const { currentUser, currentUserInfo } = useAuth();
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean>(false);
  const [triggerTaskToggle, setTriggerTaskToggle] = useState<boolean>(false);
  const [recurringTasks, setRecurringTasks] = useState<TaskGroup>();
  const [templateTask, setTemplateTask] = useState<TemplateTask>();
  const [templateTaskId, setTemplateTaskId] = useState<number>();
  const [assignee, setAssignee] = useState<string>("");
  const [addTaskToggle, setAddTaskToggle] = useState<boolean>(false);
  const [selectedTaskToAdd, setSelectedTaskToAdd] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [selectedTaskToRun, setSelectedTaskToRun] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedEndDateToRun, setSelectedEndDateToRun] = useState<string>(
    formatDateToDateFormFormat(new Date())
  );
  const [selectedStartDateToRun, setSelectedStartDateToRun] = useState<string>(
    formatDateToDateFormFormat(new Date())
  );
  const [addTemplateTaskOptions, setAddTemplateTaskOptions] = useState<
    OptionType[]
  >([]);
  const [allTemplateTasks, setAllTemplateTasks] =
    useState<TemplateTaskShortResponse[]>();
  const [lkOptions, setLkOptions] = useState<
    TaskCreationLKValues | null | undefined
  >();
  const [assigneeOptions, setAssigneeOptions] = useState<OptionType[]>([]);
  const [templateTaskActions, setTemplateTaskActions] = useState<
    TemplateTaskAction[]
  >([]);
  const [templateFiles, setTemplateFiles] = useState<TemplateRequiresFile[]>(
    []
  );
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [tagOptions, setTagOptions] = useState<OptionType[]>([]);
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchAllTags = async () => {
      if (currentUser) {
        const authToken = await currentUser.getIdToken();
        const tags = await getAllTags(authToken);
        if (tags) {
          setAllTags(tags);
        }
      }
    };

    fetchAllTags();
  }, [currentUser]);

  useEffect(() => {
    if (allTags.length > 0) {
      const formatted = allTags.map((t) => ({
        value: t.id.toString(),
        label: t.name,
      }));
      setTagOptions(formatted);
    }
  }, [allTags]);

  useEffect(() => {
    const fetchTaskTemplates = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedTemplates = await getTemplateTasks(authToken);
        if (fetchedTemplates) setRecurringTasks(fetchedTemplates);
      }
    };
    const fetchTaskTemplate = async () => {
      if (currentUser !== null && templateTaskId !== undefined) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedTemplate = await getTemplateTask(
          templateTaskId,
          authToken
        );
        const fetchedTemplateActions = await getTemplateTaskActions(
          templateTaskId,
          authToken
        );
        const fetchedTemplateFiles = await getTemplateTaskRequiredFiles(
          templateTaskId,
          authToken
        );
        if (fetchedTemplate) setTemplateTask(fetchedTemplate);
        if (fetchedTemplateActions)
          setTemplateTaskActions(fetchedTemplateActions);
        if (fetchedTemplateFiles) setTemplateFiles(fetchedTemplateFiles);
      }
    };

    const fetchAllTaskTemplates = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedTemplates = await getAllTemplateTasks(authToken);
        if (fetchedTemplates) setAllTemplateTasks(fetchedTemplates);
      }
    };
    const fetchLkValues = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedValues: TaskCreationLKValues | null =
          await getTaskLKValues(authToken);
        setLkOptions(fetchedValues);
      }
    };

    if (lkOptions) {
      const formattedAssignees = lkOptions.users.map((user) => ({
        value: user.id.toString(),
        label: user.displayName,
      }));

      setAssigneeOptions(formattedAssignees);
    }
    if (!lkOptions) {
      fetchLkValues();
    }
    if (!allTemplateTasks) {
      fetchAllTaskTemplates();
    }
    if (!recurringTasks) {
      fetchTaskTemplates();
    }
    if (templateTaskId) {
      fetchTaskTemplate();
    }
    if (allTemplateTasks) {
      const formattedTemplateTaskOptions = allTemplateTasks.map((task) => ({
        value: task.id.toString(),
        label: task.title,
      }));

      setAddTemplateTaskOptions(formattedTemplateTaskOptions);
    }
  }, [templateTaskId, reload, allTemplateTasks]);

  useEffect(() => {
    if (templateTask) {
      // if templateTask.tags is e.g. Tag[]
      const existingTagIds = templateTask.tags?.map((tag) => tag.id) ?? [];
      setSelectedTagIds(existingTagIds);
    }
  }, [templateTask]);

  const handleTagChange = (
    selectedOptions: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    const newIds = selectedOptions.map((opt) => parseInt(opt.value));
    setSelectedTagIds(newIds);
  };

  const deleteTaskFile = async (fileId: number) => {
    if (currentUser !== null && fileId) {
      const authToken: string = await currentUser.getIdToken();
      await deleteTemplateTaskRequiredFile(fileId, authToken);
      setReload(!reload);
    }
  };

  const deleteTaskAction = async (actionId: number) => {
    if (currentUser !== null && actionId) {
      const authToken: string = await currentUser.getIdToken();
      await deleteTemplateTaskAction(actionId, authToken);
      setReload(!reload);
    }
  };

  const submitTaskTags = async () => {
    if (templateTask !== undefined && currentUser !== null) {
      try {
        const authToken: string = await currentUser.getIdToken();
        // Create an array of promises
        publishTemplateTaskTags(
          selectedTagIds,
          templateTask.id,
          authToken
        ).then((data: number | null) => {
          // reloadFn();
        });

        // Wait for all promises to resolve
        // Only executed if all promises resolve successfully
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle any errors here (e.g., show an error message to the user)
      }
    }
  };

  const reloadFn = () => {
    setReload(!reload);
  };

  const handleAddTaskChange = (selectedOption: SingleValue<OptionType>) => {
    const taskId = selectedOption ? selectedOption.value : "";
    setSelectedTaskToAdd(taskId);
  };

  const handleRunTaskChange = (selectedOption: SingleValue<OptionType>) => {
    const taskId = selectedOption ? selectedOption.value : "";
    setSelectedTaskToRun(taskId);
  };

  const toggleNewTaskAssignment = () => {
    setTriggerTaskToggle(false);
    setAddTaskToggle(!addTaskToggle);
  };

  const toggleCustomRunTrigger = () => {
    setAddTaskToggle(false);
    setTriggerTaskToggle(!triggerTaskToggle);
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedStartDateToRun(value);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedEndDateToRun(value);
  };

  const submitTaskToAdd = async () => {
    setIsLoading(true);
    if (selectedTaskToAdd && currentUser) {
      const authToken = await currentUser.getIdToken();
      // right now we can default to a single group since we don't support editing groups.
      const response = await submitTaskToAddToGroup(
        authToken,
        selectedTaskToAdd
      );
      if (response) {
        setReload(reload);
      }
    }
    setIsLoading(false);
  };

  const closeSuccessMessage = () => {
    setSuccessMessage("");
  };

  const submitTaskTrigger = async () => {
    setIsLoading(true);
    await delay(5000);

    if (selectedTaskToRun && currentUser) {
      const authToken = await currentUser.getIdToken();
      // right now we can default to a single group since we don't support editing groups.
      const response = await customBatchRun(
        selectedStartDateToRun,
        selectedEndDateToRun,
        selectedTaskToRun,
        authToken
      );
      if (response) {
        if (parseInt(response) > 0) {
          setSuccessMessage(
            `${response} tasks were either created or were already created for the selected recurring task`
          );
        }
        toggleCustomRunTrigger();
        setIsLoading(false);
        // setReload(reload);
      }
    }
    setIsLoading(false);
  };

  const delay = async (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      if (currentUser) {
        const authToken: string = await currentUser.getIdToken();
        if (templateTaskId) {
          const newRequest = {
            requesterId:
              currentUser.uid,
            transactionTypeId: "Template Task Deletion",
            statusId: "pending",
            templateTaskId: templateTaskId,
          };
    
          await createAdministrativeTransactionRequest(authToken, newRequest);
          // Assuming delete is successful
          setIsLoading(false);
          setShowModal(false);
          navigate("/home");
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error deleting the file:", error);
    }
  };

  return (
    <div className="bg-background -z-50 remove-input-txt-border">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>

      <div className="bg-white w-11/12 mx-auto rounded-3xl p-5 relative -top-64 -mt-10 max-w-[1800px]">
        {!templateTaskId && (
          <div>
            <div className="flex justify-between align-middle">
              <div className="inter-font text-text text-3xl">
                Recurring Tasks
              </div>
              <div>
                <button
                  onClick={() => navigate("/task/new/recurring")}
                  className="dark-button dark:border-text-dark m-2"
                >
                  New Recurring Task
                </button>
              </div>
            </div>
            {successMessage !== "" && (
              <div>
                <Banner
                  status="success"
                  message={successMessage}
                  dismissBanner={closeSuccessMessage}
                />
              </div>
            )}
            <div className="flex flex-col gap-4 bg-card dark:bg-card-dark p-5 rounded-lg shadow-lg">
              <div className="text-text inter-font">
                Select a Task Group to edit
              </div>
              {recurringTasks &&
                Object.entries(recurringTasks).map(([taskGroup, groupData]) => (
                  <div
                    key={taskGroup}
                    className="border border-muted-dark shadow-lg rounded-md p-2 pb-3"
                  >
                    <div className="flex justify-between">
                      <div className="relative -top-5 bg-card dark:text-text-dark dark:bg-card-dark inline text-lg font-bold">
                        {taskGroup}
                      </div>
                      <div className="flex gap-4">
                        <button
                          onClick={toggleNewTaskAssignment}
                          className="dark-button"
                        >
                          Assign New Task
                        </button>
                        <button
                          onClick={toggleCustomRunTrigger}
                          className="dark-button"
                        >
                          Custom Run
                        </button>
                      </div>
                    </div>
                    {addTaskToggle && (
                      <div className="w-1/3 bg-gray-100 flex ms-auto absolute right-10 justify-center py-5 rounded-3xl shadow-lg m-2 max-w-[350px]">
                        {allTemplateTasks && (
                          <div className="mb-4 flex flex-col w-4/5">
                            <label
                              htmlFor="requiredFiles"
                              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
                            >
                              Select task to add to group :
                            </label>
                            <div className="flex gap-3 flex-col">
                              <Select
                                options={addTemplateTaskOptions.filter(
                                  (option) =>
                                    !groupData.templateTasks.some(
                                      (task) =>
                                        task.id == parseInt(option.value)
                                    )
                                )}
                                className="w-full"
                                onChange={handleAddTaskChange}
                                value={addTemplateTaskOptions.find(
                                  (option) => option.value === selectedTaskToAdd
                                )}
                              />
                              {isLoading ? (
                                <button
                                  disabled
                                  type="button"
                                  className=" px-2.5 me-2 text-sm font-medium w-full text-center  text-white bg-[#3DD598] rounded border border-gray-200 hover:bg-[#3DD598] hover:text-white focus:z-10 focus:ring-2 focus:ring-white focus:text-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex justify-center items-center"
                                >
                                  <LoadingSpinner color="white" size="sm"/>
                                </button>
                              ) : (
                                <button
                                  onClick={submitTaskToAdd}
                                  className="bg-[#3DD598] text-white rounded w-full p-2"
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {triggerTaskToggle && (
                      <div className="w-1/3 bg-gray-100 flex ms-auto absolute right-10 justify-center py-5 rounded-3xl shadow-lg m-2 max-w-[350px]">
                        {allTemplateTasks && (
                          <div className="mb-4 w-4/5">
                            <label
                              htmlFor="requiredFiles"
                              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
                            >
                              Select task to execute :
                            </label>
                            <div className="flex gap-3 remove-input-txt-border">
                              <Select
                                options={addTemplateTaskOptions.filter(
                                  (option) => option.value != selectedTaskToRun
                                )}
                                className="w-full"
                                onChange={handleRunTaskChange}
                                value={addTemplateTaskOptions.find(
                                  (option) => option.value === selectedTaskToAdd
                                )}
                              />
                            </div>
                            <div className="my-4">
                              <label
                                htmlFor="requiredFiles"
                                className="block text-text dark:text-text-dark text-sm font-bold mb-2"
                              >
                                Select Start Date for execution :
                              </label>
                              <input
                                type="date"
                                id="startDate"
                                name="startDate"
                                value={selectedStartDateToRun}
                                onChange={handleStartDateChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
                              />
                            </div>
                            <div className="my-4">
                              <label
                                htmlFor="requiredFiles"
                                className="block text-text dark:text-text-dark text-sm font-bold mb-2"
                              >
                                Select End Date for execution :
                              </label>
                              <input
                                type="date"
                                id="endDate"
                                name="endDate"
                                value={selectedEndDateToRun}
                                onChange={handleEndDateChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
                              />
                            </div>
                            {isLoading ? (
                              <button
                                disabled
                                type="button"
                                className="px-2.5 me-2 text-sm font-medium w-full text-center  text-white bg-[#3DD598] rounded border border-gray-200 hover:bg-[#3DD598] hover:text-white focus:z-10 focus:ring-2 focus:ring-white focus:text-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex justify-center items-center"
                              >
                                <LoadingSpinner color="white" size="sm"/>
                              </button>
                            ) : (
                              <button
                                disabled={isLoading}
                                onClick={
                                  !isLoading ? submitTaskTrigger : () => {}
                                }
                                className="bg-[#3DD598] text-white rounded w-full p-2"
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    <div className="text-sm text-text inter-font mb-2">
                      Assigned Tasks
                    </div>
                    <div className="flex flex-col gap-2">
                      {groupData.templateTasks
                        .slice() // Create a shallow copy to avoid mutating the original array
                        .sort((a, b) => a.id - b.id) // Sort tasks by ID in ascending order
                        .map((task, index) => (
                          <div
                            key={index}
                            className="grid grid-cols-[auto_1fr_auto] items-center p-4 bg-background 
          rounded-lg shadow-md hover:shadow-sm gap-4 my-1"
                          >
                            {/* Compact Icon + ID */}
                            <div className="flex items-center gap-2 min-w-[60px]">
                              <FontAwesomeIcon icon={faTasks} />
                              <span
                                className="text-blue-500 cursor-pointer"
                                onClick={() => setTemplateTaskId(task.id)}
                              >
                                {currentUserInfo?.organization.name +
                                  "-" +
                                  task.id}
                              </span>
                            </div>

                            {/* Task Title (Left-Aligned, Expands) */}
                            <span className="truncate">{task.title}</span>

                            {/* Action Button (Right-Aligned) */}
                            <div
                              onClick={() => setTemplateTaskId(task.id)}
                              className="cursor-pointer justify-self-end"
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {templateTaskId && (
          <div>
            <div className="flex justify-between items-center align-middle">
              <div
                className="hover:text-muted cursor-pointer my-5 ms-10 border p-2 rounded-xl shadow-md hover:shadow-none bg-background"
                onClick={() => setTemplateTaskId(undefined)}
              >
                {"< "} Back
              </div>
              <div className="bg-danger text-white p-2 shadow-md hover:shadow-none rounded-xl cursor-pointer"
              onClick={() => setShowModal(true)}>
                Delete Template
              </div>
            </div>
            {showModal && (
              <ConfirmDeleteModal
                onConfirm={confirmDelete}
                onCancel={() => setShowModal(false)}
                isLoading={isLoading}
              >
                Are you sure you want to delete this task?
              </ConfirmDeleteModal>
            )}
            <div className="text-4xl mb-5 ms-5 inter-font text-text">Edit</div>
            {templateTask && (
              <div className="">
                <div className="flex flex-col gap-4 bg-card p-5 rounded-lg shadow-lg border">
                  <div className="important">
                    Template: {templateTask?.title}
                  </div>
                  <div>
                    <div className="flex justify-between">
                      <span className="font-bold">Title: </span>
                      {templateTask.title}
                    </div>
                    <div className="flex flex-col">
                      <span className="font-bold">Description: </span>
                      <span className="text-muted text-sm ms-5">
                        {templateTask.description}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="font-bold">Task Organization: </span>
                      {templateTask.taskOrganization.type}
                    </div>
                    <div className="flex justify-between">
                      <span className="font-bold">Task Type: </span>
                      {templateTask.taskType.type}
                    </div>
                    <div className="flex justify-between">
                      <span className="font-bold">Task Sub-Type: </span>
                      {templateTask.taskSubType.type}
                    </div>
                    <div className="flex justify-between">
                      <span className="font-bold">
                        Scheduling Definitions:{" "}
                      </span>
                      <div className="bg-background p-2">
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">
                            Distribution Pattern:
                          </span>{" "}
                          <span>{templateTask.distributionPattern}</span>
                        </div>
                        {templateTask.waiverName && (
                          <div className="flex justify-between gap-5">
                            <span className="font-bold">Waiver Type:</span>{" "}
                            <span>{templateTask.waiverName}</span>
                          </div>
                        )}
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">
                            Due Date Calculation Type:
                          </span>{" "}
                          <span>{templateTask.dueDateCalculationType}</span>
                        </div>
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">Due Date Offset:</span>{" "}
                          <span>{templateTask.dueDateOffset}</span>
                        </div>
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">Due Date Cron:</span>{" "}
                          <span>{templateTask.dueDateCron}</span>
                        </div>
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">
                            Creation Date Calculation Type:
                          </span>{" "}
                          <span>
                            {templateTask.creationDateCalculationType}
                          </span>
                        </div>
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">
                            Creation Date Offset:
                          </span>{" "}
                          <span>{templateTask.creationDateOffset}</span>
                        </div>
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">Creation Date Cron:</span>{" "}
                          <span>
                            {templateTask.creationCron
                              ? templateTask.creationCron
                              : "<undefined>"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                {templateTaskActions.length > 0 && (
                  <div className="flex flex-col gap-4 bg-card p-5 rounded-lg shadow-lg my-5">
                    <div className="important">Task Actions</div>
                    {templateTaskActions.map((action, index) => (
                      <div
                        key={index}
                        className="bg-background shadow-lg rounded-lg p-4 flex gap-2 justify-between"
                      >
                        <div className="flex justify-between gap-2">
                          <span className="font-bold text-primary">
                            <FontAwesomeIcon icon={faBolt} />{" "}
                          </span>{" "}
                          <span>{action.description}</span>
                        </div>
                        <div
                          className="text-danger cursor-pointer"
                          onClick={() => deleteTaskAction(action.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <AddTaskAction taskId={templateTask.id} reload={reloadFn} />
                {templateTask && (
                  <div className="flex flex-col gap-4 bg-card p-10 rounded-lg shadow-lg my-5 border">
                    <div className="font-bold text-primary">Task Tags</div>
                    <Select
                      options={tagOptions}
                      isMulti
                      onChange={handleTagChange}
                      value={tagOptions.filter((opt) =>
                        selectedTagIds.includes(parseInt(opt.value))
                      )}
                    />
                    <button
                      onClick={submitTaskTags}
                      className="bg-brandSecondary text-white rounded-lg ms-auto p-2 min-w-[75px]"
                    >
                      Add
                    </button>
                  </div>
                )}
                {templateFiles.length > 0 && (
                  <div className="flex flex-col gap-4 bg-card p-5 rounded-lg shadow-lg my-5 border">
                    <div className="important">Task Actions</div>
                    {templateFiles.map((file, index) => (
                      <div
                        key={index}
                        className="bg-background shadow-lg rounded-lg p-4 flex justify-between gap-2"
                      >
                        <div className="flex justify-between gap-2">
                          <span className="font-bold text-primary">
                            <FontAwesomeIcon icon={faFile} />{" "}
                          </span>{" "}
                          <span>{file.templateFile?.fileName}</span>
                        </div>
                        <div
                          className="text-danger cursor-pointer"
                          onClick={() => deleteTaskFile(file.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <AddRequiredFile taskId={templateTask.id} reload={reloadFn} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditRecurringTasksPage;
