import React, { useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import AdminRequestsTable from "./AdminRequestsTable";
import AdministrativeTransactionRequest from "../../../interfaces/adminRequests/AdministrativeTransactionRequest";
import { useAuth } from "../../../firebase/AuthProvider";

interface AdminRequestsProps { 
    transactions: AdministrativeTransactionRequest[];
    triggerTaskApproval: (requestId: number) => void;
    triggerTaskRejection: (requestId: number) => void;
}

const AdminRequests: React.FC<AdminRequestsProps> = ({ transactions, triggerTaskApproval, triggerTaskRejection }) => {
  const { currentUser, currentOrganization } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get("view") || "open";

  if (!transactions || transactions.length === 0) {
    return <div>No transactions available.</div>;
  }


  return (
    <div className="h-screen flex flex-col w-full p-4">
      {/* Navigation Tabs */}
      <div className="flex gap-5 text-xl">
        <NavLink
          to="?view=open"
          className={`sub-nav-item ${view === "open" ? "sub-nav-active" : ""}`}
        >
          Open
        </NavLink>
        <NavLink
          to="?view=all"
          className={`sub-nav-item ${view === "all" ? "sub-nav-active" : ""}`}
        >
          All
        </NavLink>
      </div>

      {/* Content Area */}
      {currentUser && currentOrganization && (
        <div className="flex-1 flex rounded-lg p-4 overflow-y-auto w-full bg-white blue-scrollbar">
          {view === "open" && (
            <AdminRequestsTable
              transactions={transactions.filter((tx) => tx.status === 'pending')}
              organizationName={currentOrganization.name}
              approveTask={triggerTaskApproval}
              rejectTask={triggerTaskRejection}
            />
          )}
          {view === "all" && (
            <AdminRequestsTable
              transactions={transactions}
              organizationName={currentOrganization.name}
              approveTask={triggerTaskApproval}
              rejectTask={triggerTaskRejection}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AdminRequests;
