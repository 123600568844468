import React from "react";
import TaskList from "../../../interfaces/task/TaskList";
import { useNavigate } from "react-router-dom";
import {
  calculateDateProximity,
  formatDateToMMDDYYYY,
} from "../../../utilities/dateUtils";
import Avatar from "../../../interfaces/avatar/Avatar";
import SkeletonRow from "../../ui/LoadingSkeleton/SkeletonRow";
import SkeletonRowDiv from "../../ui/LoadingSkeleton/SkeletonRowDiv";
import Pagination from "../../ui/Pagination/Pagination";

interface TasksPageTableProps {
  tasks: TaskList[];
  avatars: Avatar[];
  displayTaskCount: boolean;
  page: number;
  pageSize: number;
  totalCount: number;
  isLoading: boolean;
  onPageChange?: (page: number) => void;
}

const TasksPageTable: React.FC<TasksPageTableProps> = ({
  tasks,
  avatars,
  displayTaskCount = false,
  page,
  pageSize,
  totalCount,
  isLoading,
  onPageChange,
}) => {
  const navigate = useNavigate();

  const findAvatarById = (userId: string): string | null => {
    const avatarObj = avatars?.find((avatar) => avatar.id === userId);
    return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
  };

  return (
    <div>
      {onPageChange ? (
        <div className="flex justify-between align-bottom items-end mb-3">
          <div className="flex justify-center align-middle items-center ms-5">
          <Pagination
            currentPage={page}
            totalPages={Math.ceil(totalCount / pageSize) || 0}
            onPageChange={onPageChange}
          />
          </div>
          <div className="text-muted align-bottom text-end pe-8 pt-0 text-sm">
            {"Page: " + page} | {" "}
            {totalCount + " total tasks"}
          </div>
        </div>
      ) : (
        <div className="text-muted mx-auto text-end pe-8 pb-3 pt-0 text-sm w-full">
          {"Page: " + page} |{" "}
          {totalCount + " total tasks"}
        </div>
      )}

      <div className="relative bg-card dark:bg-card-dark min-w-[1000px] overflow-x-auto shadow-lg mb-5 dark:text-text-dark rounded-xl inter-font mx-5 text-[13px]">
        {/* Table Header */}
        <div className="font-bold headers columns-8 px-5 py-4 border-b">
          <div className="truncate max-w-[145px] min-w-[122px]">Title</div>
          <div className="truncate max-w-[145px] min-w-[122px]">Attachment</div>
          <div className="truncate max-w-[145px] min-w-[122px]">
            Participant
          </div>
          <div className="truncate max-w-[145px] min-w-[122px]">Type</div>
          <div className="truncate max-w-[145px] min-w-[122px]">Assignee</div>
          <div className="truncate max-w-[145px] min-w-[122px]">Due Date</div>
          <div className="truncate max-w-[145px] min-w-[122px]">Proximity</div>
          <div className="truncate max-w-[145px] min-w-[122px]">Status</div>
        </div>

        {/* If loading, show skeleton rows; otherwise, show real rows */}
        {!isLoading &&
          tasks?.map((task, index) => (
            <div
              onClick={() => navigate(`/task/BLOSSOM-${task.id}`)}
              key={index}
              className={`data columns-8 truncate p-3 px-5 hover:shadow-lg cursor-pointer 
                          hover:bg-background dark:hover:bg-background-dark 
                          border-t border-b ${
                            index % 2 === 1
                              ? "bg-gray-100 dark:bg-gray-800"
                              : ""
                          }`}
            >
              <div className="truncate max-w-[145px] min-w-[122px]">
                {task.title}
              </div>
              <div className="truncate max-w-[145px] min-w-[122px] ms-8">
                <input
                  type="checkbox"
                  disabled
                  checked={task.hasAttachments}
                  className="checked:bg-[#3DD598] checked:disabled:hover:bg-[#3DD598] 
                             checked:disabled:hover bg-gray-400 border-none rounded"
                />
              </div>
              <div className="truncate max-w-[145px] min-w-[122px] ">
                {task.participant?.firstName
                  ? task.participant.firstName[0] +
                    ". " +
                    task.participant.lastName
                  : "N/A"}
              </div>
              <div className="truncate max-w-[145px] min-w-[122px] ">
                {task.subType.type
                  ? task.subType.type
                      .toLowerCase()
                      .split(" ")
                      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(" ")
                  : "N/A"}
              </div>
              <div className="truncate max-w-[145px] min-w-[122px] flex flex-nowrap overflow-hidden">
                {task.assignees
                  .filter((a) => !a.isReporter) // Exclude reporters
                  .map((user, userIndex) => {
                    const avatarUrl = findAvatarById(user.user.id);
                    return (
                      <div
                        className="w-7 h-7 font-bold flex items-center 
                                   justify-center rounded-full bg-gray-300 
                                   dark:text-text mr-1"
                        key={userIndex}
                      >
                        {avatarUrl ? (
                          <img
                            src={avatarUrl}
                            alt={`${user.user.firstName} ${user.user.lastName}`}
                            className="w-full h-full rounded-full object-cover"
                          />
                        ) : (
                          <div className="text-sm">
                            {user.user.firstName[0] + user.user.lastName[0]}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className="truncate max-w-[145px] min-w-[122px] ">
                {formatDateToMMDDYYYY(task.endDate)}
              </div>

              {/* Proximity (Overdue, etc.) */}
              {calculateDateProximity(task.endDate) === "Overdue" &&
                task.status.name !== "COMPLETED" && (
                  <div className="truncate max-w-[145px] min-w-[122px] font-bold text-danger">
                    {calculateDateProximity(task.endDate)}
                  </div>
                )}
              {task.status.name !== "COMPLETED" &&
                calculateDateProximity(task.endDate) !== "Overdue" && (
                  <div className="truncate max-w-[145px] min-w-[122px] ">
                    {calculateDateProximity(task.endDate)}
                  </div>
                )}
              {task.status.name === "COMPLETED" && (
                <div className="truncate max-w-[145px] min-w-[122px] ps-5">
                  --
                </div>
              )}

              {/* Status */}
              <div className="truncate max-w-[145px] min-w-[122px] flex gap-1">
                <div>
                  {task.status.name
                    .toLowerCase()
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ")}
                </div>
                {task.assigneeRoleName !== undefined &&
                  task.status.name === "IN REVIEW" && (
                    <div className="font-bold">
                      {task.assigneeRoleName === "SC" && "(SC)"}
                      {task.assigneeRoleName === "SUPERVISOR" && "(SUP)"}
                      {task.assigneeRoleName === "PROGRAM MANAGER" && "(PM)"}
                    </div>
                  )}
              </div>
            </div>
          ))}

        {/* Render Skeleton Rows if loading */}
        {isLoading &&
          Array.from({ length: 25 }).map((_, i) => (
            <SkeletonRowDiv key={i} columns={8} />
          ))}
      </div>
    </div>
  );
};

export default TasksPageTable;
