import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './firebase/AuthProvider';

const ProtectedRoute = () => {
  const { currentUser, isLoading } = useAuth(); // Add isLoading to your auth context
  const location = useLocation();
  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator you prefer
  }

  if (location.pathname.startsWith("/sign/")) {
    return <Outlet />;
  }

  return currentUser ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
