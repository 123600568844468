import React, { useState, useRef, useEffect, ChangeEvent } from "react";
import { Conversation } from "../../../services/chatService/ChatService";
import "./ChatInput.css";
import { EmojiItem, emojiData } from "../emojiPicker/EmojiPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

interface ChatInputProps {
  conversation: Conversation;
  sendMessageFn: (
    input: string,
    conversationId: number,
    attachmentUrl?: File
  ) => void;
  updateText: (input: string) => void;
  text: string;
  // NEW: callback to pass selected file up to parent
  onSelectFile: (file: File | undefined) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({
  sendMessageFn,
  conversation,
  updateText,
  text,
  onSelectFile, // <--- parent's callback
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // === AUTO-COMPLETE STATE ===
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [emojiSearchTerm, setEmojiSearchTerm] = useState("");
  const [filteredEmojis, setFilteredEmojis] = useState<EmojiItem[]>([]);
  const [highlightIndex, setHighlightIndex] = useState(0);

  // Dynamically adjust text-area height (up to 4 lines).
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // reset
      const scrollHeight = textareaRef.current.scrollHeight;
      const maxHeight = 100; // ~4 lines
      if (scrollHeight <= maxHeight) {
        textareaRef.current.style.height = `${scrollHeight}px`;
        textareaRef.current.style.overflowY = "hidden";
      } else {
        textareaRef.current.style.height = `${maxHeight}px`;
        textareaRef.current.style.overflowY = "scroll";
      }
    }
  }, [text]);

  /**
   * Whenever user picks a file, pass it up to the parent.
   */
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      onSelectFile(e.target.files[0]);
    }
  };

  /**
   * Whenever the text changes, detect if there's a `:<search>` pattern
   * near the cursor. If so, filter emojis for suggestions.
   */
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    updateText(newValue);

    // We find the caret (cursor) position in the textarea
    const selectionStart = e.target.selectionStart;
    // Get the substring up to the caret
    const textUpToCursor = newValue.slice(0, selectionStart);
    // Find the last colon in that substring
    const lastColonIndex = textUpToCursor.lastIndexOf(":");

    if (lastColonIndex !== -1) {
      // The text after the last colon up to the caret
      const term = textUpToCursor.slice(lastColonIndex + 1).trim();
      // If there's whitespace or another colon, or no term, we hide
      if (!term || term.includes(" ") || term.includes(":")) {
        setShowSuggestions(false);
        return;
      }
      // We have a search term, filter the emojis
      setEmojiSearchTerm(term.toLowerCase());
      const filtered = emojiData.filter((emojiItem: any) =>
        emojiItem.keywords.some((kw: string) =>
          kw.toLowerCase().includes(term.toLowerCase())
        )
      );
      setFilteredEmojis(filtered);
      setShowSuggestions(filtered.length > 0);
      setHighlightIndex(0);
    } else {
      // No colon found
      setShowSuggestions(false);
    }
  };

  /**
   * Async function to handle sending the message (no direct file upload here).
   * The parent can handle the file upload if it wants. Or we can do it here too.
   */
  const handleSend = () => {
    const trimmed = text.trim();
    if (trimmed.length > 0) {
      sendMessageFn(trimmed, conversation.id);
      updateText("");
      // Optionally: onSelectFile(null) if you want to clear the file in the parent after sending
      onSelectFile(undefined);
    }
  };

  /**
   * Handle key presses for sending messages or navigating suggestions.
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (showSuggestions && filteredEmojis.length > 0) {
      // If suggestions open, Up/Down/Enter navigates them
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setHighlightIndex((prev) =>
          prev < filteredEmojis.length - 1 ? prev + 1 : 0
        );
        return;
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setHighlightIndex((prev) =>
          prev > 0 ? prev - 1 : filteredEmojis.length - 1
        );
        return;
      } else if (e.key === "Enter") {
        // Insert selected emoji
        if (!e.shiftKey && !e.metaKey) {
          e.preventDefault();
          handleSelectEmoji(filteredEmojis[highlightIndex]);
          return;
        }
        // If SHIFT+ENTER -> new line, do nothing special
      }
    }

    // Normal Chat Send: Enter without SHIFT -> send
    if (e.key === "Enter" && !e.shiftKey && !e.metaKey) {
      e.preventDefault();
      handleSend();
    }
  };

  /**
   * When a user selects an emoji, replace the ":term" substring with that emoji.
   */
  const handleSelectEmoji = (emojiItem: EmojiItem) => {
    if (!textareaRef.current) return;
    const caretPos = textareaRef.current.selectionStart;

    // Full text up to the caret
    const textUpToCaret = text.slice(0, caretPos);
    // The last colon index in textUpToCaret
    const lastColonIndex = textUpToCaret.lastIndexOf(":");
    const beforeColon = text.slice(0, lastColonIndex);
    const afterCaret = text.slice(caretPos);

    const newText = beforeColon + emojiItem.emoji + afterCaret;
    updateText(newText);

    // Move caret to the position right after the newly inserted emoji
    const newCaretPos = lastColonIndex + emojiItem.emoji.length;
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(newCaretPos, newCaretPos);
      }
    }, 0);

    setShowSuggestions(false);
  };

  return (
    <div className="relative w-full flex items-center gap-2">
      {/* TEXTAREA WRAPPER */}
      <div
        className={`textarea-container flex-1 ${isFocused ? "focused" : ""}`}
        style={{ position: "relative" }}
      >
        <textarea
          ref={textareaRef}
          className="rounded-3xl custom-scrollbar w-full outline-none focus:outline-none border-transparent focus:border-transparent focus:ring-0"
          style={{
            resize: "none",
            overflowY: "scroll",
          }}
          rows={1}
          placeholder="Type a message... (use ':' for emojis e.g. ':heart')"
          value={text}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        {/* Suggestions dropdown */}
        {showSuggestions && filteredEmojis.length > 0 && (
          <div className="emoji-suggestions-box absolute bottom-full bg-white rounded-xl shadow-sm max-h-80 overflow-auto text-sm">
            {filteredEmojis.map((emojiItem, idx) => (
              <div
                key={idx}
                className={`emoji-suggestion-item cursor-pointer hover:bg-gray-200 p-2 ${
                  idx === highlightIndex ? "highlighted" : ""
                }`}
                onMouseDown={(e) => {
                  // onMouseDown so we don't lose focus before click
                  e.preventDefault();
                  handleSelectEmoji(emojiItem);
                }}
              >
                <span style={{ fontSize: "1.2em", marginRight: "0.5rem" }}>
                  {emojiItem.emoji}
                </span>
                <span style={{ opacity: 0.7 }}>
                  {emojiItem.keywords.join(", ")}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="">
      <input
        id="chatFileInput"
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      {/* Attach button (label triggers hidden file input) */}
      <label
        htmlFor="chatFileInput"
        className="cursor-pointer text-base hover:text-blue-500 rounded-full px-2.5 py-2 border bg-white hover:bg-gray-100"
        title="Attach a file"
      >
        <FontAwesomeIcon icon={faPaperclip} />
      </label>
      </div>
    </div>
  );
};

export default ChatInput;
