import React, { useState, useRef, useEffect } from 'react';
import { Workbook } from '../../../../../interfaces/sproutSheets/Workbook';
import { Sheet } from 'xlsx'; // or your own custom interface for "Sheet"
import Cell from '../../../../../interfaces/sproutSheets/Cell';
import { columnIndexToLetter } from '../../../../../utilities/sheetUtils/SheetUtils';
import { FormattingToolbar } from './FormattingToolbar';

/** Helper: convert letter+number (e.g. "B3") to numeric row/col. Zero-based. */
function referenceToRowCol(ref: string) {
  // E.g. "A1", "B3", "AA10", ...
  // Separate letters from numbers
  const match = ref.match(/^([A-Z]+)([0-9]+)$/);
  if (!match) return { row: 0, col: 0 };
  const letters = match[1];
  const numbers = match[2];
  const rowIndex = parseInt(numbers, 10) - 1; // "1" => row 0
  let colIndex = 0;
  for (let i = 0; i < letters.length; i++) {
    colIndex = colIndex * 26 + (letters.charCodeAt(i) - 65 + 1);
  }
  colIndex -= 1; // "A" => col 0
  return { row: rowIndex, col: colIndex };
}

/** Helper: Convert zero-based row/col to letter+number reference. */
function rowColToReference(row: number, col: number) {
  // col to letters
  let temp = col;
  let letter = '';
  while (temp >= 0) {
    letter = String.fromCharCode((temp % 26) + 65) + letter;
    temp = Math.floor(temp / 26) - 1;
  }
  return `${letter}${row + 1}`;
}

/** Shift formula references by (rowDelta, colDelta). E.g. =A1+10 => =A2+10 if rowDelta=1. */
function shiftFormulaReferences(formula: string, rowDelta: number, colDelta: number) {
  // We'll do a naive regex for references in the form [A-Z]+[0-9]+
  return formula.replace(/([A-Z]+[0-9]+)/g, (match) => {
    const { row, col } = referenceToRowCol(match);
    const shiftedRow = row + rowDelta;
    const shiftedCol = col + colDelta;
    // clamp to some minimum if you want to avoid negative references
    if (shiftedRow < 0 || shiftedCol < 0) {
      return match; // skip shifting if negative
    }
    return rowColToReference(shiftedRow, shiftedCol);
  });
}

/** Evaluate a single cell that might be a formula. */
function evaluateCell(
  rowIndex: number,
  colIndex: number,
  sheetData: any[], // the "rows" array
): string | number {
  const cell = sheetData[rowIndex].cells[colIndex];

  // If it's not a formula, displayValue = raw value
  if (typeof cell.value === 'string' && cell.value.startsWith('=')) {
    cell.type = 'formula';
  } else if (!cell.type) {
    cell.type = 'text'; 
  }

  // If not formula => just return cell.value
  if (cell.type !== 'formula') {
    cell.displayValue = cell.value;
    return cell.value;
  }

  // If it's a formula:
  // 1) Parse out references
  const formula = cell.value.slice(1).trim(); // remove leading '='
  // 2) Replace references like A1, B2, etc. with actual numeric values
  //    We'll do a naive approach with a regex
  let replaced = formula.replace(/([A-Z]+[0-9]+)/g, (match: any) => {
    const { row, col } = referenceToRowCol(match);
    // If row/col are in range, get that cell's displayValue
    if (row >= 0 && row < sheetData.length && col >= 0 && col < sheetData[row].cells.length) {
      const refCell = sheetData[row].cells[col];
      // Make sure that referenced cell has a computed displayValue (or evaluate it if needed)
      if (refCell.type === 'formula' && refCell.displayValue == null) {
        // recursively evaluate the reference
        refCell.displayValue = evaluateCell(row, col, sheetData);
      }
      return refCell.displayValue || 0;
    }
    return '0';
  });

  // 3) Evaluate the final expression
  //    ***Caution***: using `eval` is insecure if you accept arbitrary user input.
  //    We do a minimal attempt here for demonstration. 
  let computed: number;
  try {
    computed = new Function(`return (${replaced})`)();
  } catch (err) {
    computed = NaN; // or some error handling
  }

  cell.displayValue = computed;
  return computed;
}

/** Evaluate all formulas in the sheet. Very naive approach. */
function evaluateAllCellsInSheet(sheetData: any[]) {
  for (let r = 0; r < sheetData.length; r++) {
    for (let c = 0; c < sheetData[r].cells.length; c++) {
      const cell = sheetData[r].cells[c];
      if (typeof cell.value === 'string' && cell.value.startsWith('=')) {
        evaluateCell(r, c, sheetData);
      } else {
        // Non-formula cell => displayValue = raw value
        cell.displayValue = cell.value;
      }
    }
  }
}

interface Range {
  startRow: number;
  startCol: number;
  endRow: number;
  endCol: number;
}

const Spreadsheet: React.FC<{
  sheet: Sheet;
  workbook: Workbook;
  setWorkbook: React.Dispatch<React.SetStateAction<Workbook>>;
}> = ({ sheet, workbook, setWorkbook }) => {

  // --------------------------
  // 1. Local States
  // --------------------------
  const [selectedCell, setSelectedCell] = useState<{ row: number; col: number } | null>(null);
  const [editingCell, setEditingCell] = useState<{ row: number; col: number } | null>(null);

  const [selectedRange, setSelectedRange] = useState<Range | null>(null);

  // Resizing states
  const [resizingCol, setResizingCol] = useState<number | null>(null);
  const [colResizeStartX, setColResizeStartX] = useState<number>(0);
  const [initialColWidth, setInitialColWidth] = useState<number>(0);
  
  const [resizingRow, setResizingRow] = useState<number | null>(null);
  const [rowResizeStartY, setRowResizeStartY] = useState<number>(0);
  const [initialRowHeight, setInitialRowHeight] = useState<number>(0);

  // Autofill handle
  const [isFilling, setIsFilling] = useState(false);
  const [fillRange, setFillRange] = useState<Range | null>(null);

  const tableRef = useRef<HTMLDivElement>(null);

  // --------------------------
  // 2. Helpers
  // --------------------------
  /** Normalize a range so that startRow <= endRow and startCol <= endCol. */
  const normalizeRange = (range: Range): Range => {
    const { startRow, endRow, startCol, endCol } = range;
    return {
      startRow: Math.min(startRow, endRow),
      endRow: Math.max(startRow, endRow),
      startCol: Math.min(startCol, endCol),
      endCol: Math.max(startCol, endCol),
    };
  };

  /** Check if cell is in a range */
  const isCellInRange = (row: number, col: number, range: Range) => {
    const norm = normalizeRange(range);
    return (
      row >= norm.startRow &&
      row <= norm.endRow &&
      col >= norm.startCol &&
      col <= norm.endCol
    );
  };

  // --------------------------
  // 3. Keyboard Navigation
  // --------------------------
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (!selectedCell) return;

      let { row, col } = selectedCell;
      const rowCount = sheet.rows.length;
      const colCount = sheet.rows[0]?.cells.length || 0;

      const shiftPressed = e.shiftKey;

      const moveSelection = (newRow: number, newCol: number) => {
        // clamp within bounds
        const clampedRow = Math.max(0, Math.min(newRow, rowCount - 1));
        const clampedCol = Math.max(0, Math.min(newCol, colCount - 1));
        setSelectedCell({ row: clampedRow, col: clampedCol });

        // If shift is held, update selectedRange
        if (shiftPressed && selectedRange) {
          setSelectedRange({
            startRow: selectedRange.startRow,
            startCol: selectedRange.startCol,
            endRow: clampedRow,
            endCol: clampedCol,
          });
        } else if (shiftPressed && !selectedRange) {
          // If there was no range, create one from the old cell to the new cell
          setSelectedRange({
            startRow: row,
            startCol: col,
            endRow: clampedRow,
            endCol: clampedCol,
          });
        } else {
          // No shift => selection is just the single cell
          setSelectedRange({
            startRow: clampedRow,
            startCol: clampedCol,
            endRow: clampedRow,
            endCol: clampedCol,
          });
        }
      };

      switch (e.key) {
        case 'ArrowUp':
          e.preventDefault();
          moveSelection(row - 1, col);
          break;
        case 'ArrowDown':
          e.preventDefault();
          moveSelection(row + 1, col);
          break;
        case 'ArrowLeft':
          e.preventDefault();
          moveSelection(row, col - 1);
          break;
        case 'ArrowRight':
          e.preventDefault();
          moveSelection(row, col + 1);
          break;

        case 'Enter':
          e.preventDefault();
          if (editingCell) {
            // finalize the edit
            setEditingCell(null);
          } else {
            // start editing
            if (!shiftPressed) {
              setEditingCell({ row, col });
            }
          }
          // Move down or up
          const rowDelta = shiftPressed ? -1 : 1;
          moveSelection(row + rowDelta, col);
          break;

        case 'Tab':
          e.preventDefault();
          // Move right or left
          const colDelta = shiftPressed ? -1 : 1;
          moveSelection(row, col + colDelta);
          break;

        default:
          break;
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedCell, editingCell, selectedRange, sheet.rows]);

  // --------------------------
  // 4. Mouse Events (Resizing, Fill)
  // --------------------------
  function onMouseMove(e: React.MouseEvent) {
    // Column resizing
    if (resizingCol !== null) {
      const delta = e.clientX - colResizeStartX;
      const newWidth = initialColWidth + delta;
      setWorkbook(prev => {
        const newSheets = [...prev.sheets];
        const currentSheet = { ...newSheets[prev.activeSheetIndex] };
        const newColumnWidths = [...currentSheet.columnWidths];
        newColumnWidths[resizingCol] = Math.max(newWidth, 40); // min col width
        currentSheet.columnWidths = newColumnWidths;
        newSheets[prev.activeSheetIndex] = currentSheet;
        return { ...prev, sheets: newSheets };
      });
    }

    // Row resizing
    if (resizingRow !== null) {
      const delta = e.clientY - rowResizeStartY;
      const newHeight = initialRowHeight + delta;
      setWorkbook(prev => {
        const newSheets = [...prev.sheets];
        const currentSheet = { ...newSheets[prev.activeSheetIndex] };
        const newRows = [...currentSheet.rows];
        newRows[resizingRow] = {
          ...newRows[resizingRow],
          height: Math.max(newHeight, 16) // min row height
        };
        currentSheet.rows = newRows;
        newSheets[prev.activeSheetIndex] = currentSheet;
        return { ...prev, sheets: newSheets };
      });
    }

    // Autofill dragging
    if (isFilling && selectedRange) {
      const rect = (tableRef.current as HTMLDivElement).getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;

      // Rough calculation of hovered row/col based on x/y
      let cumulativeHeight = 0;
      let hoveredRow = 0;
      for (let r = 0; r < sheet.rows.length; r++) {
        const rowHeight = sheet.rows[r].height ?? 24;
        if (offsetY < cumulativeHeight + rowHeight) {
          hoveredRow = r;
          break;
        }
        cumulativeHeight += rowHeight;
        if (r === sheet.rows.length - 1) hoveredRow = r;
      }

      let cumulativeWidth = 50; // offset for row header
      let hoveredCol = 0;
      for (let c = 0; c < sheet.columnWidths.length; c++) {
        const colWidth = sheet.columnWidths[c];
        if (offsetX < cumulativeWidth + colWidth) {
          hoveredCol = c;
          break;
        }
        cumulativeWidth += colWidth;
        if (c === sheet.columnWidths.length - 1) hoveredCol = c;
      }

      setFillRange({
        startRow: selectedRange.startRow,
        startCol: selectedRange.startCol,
        endRow: hoveredRow,
        endCol: hoveredCol,
      });
    }
  }

  function onMouseUp() {
    setResizingCol(null);
    setResizingRow(null);

    // Finalize autofill
    if (isFilling && fillRange) {
      applyAutofill(selectedRange, fillRange);
    }
    setIsFilling(false);
    setFillRange(null);
  }

  // --------------------------
  // 5. Start Resizing
  // --------------------------
  function startColResize(e: React.MouseEvent, colIndex: number) {
    e.preventDefault();
    setResizingCol(colIndex);
    setColResizeStartX(e.clientX);
    setInitialColWidth(sheet.columnWidths[colIndex]);
  }

  function startRowResize(e: React.MouseEvent, rowIndex: number) {
    e.preventDefault();
    setResizingRow(rowIndex);
    setRowResizeStartY(e.clientY);
    setInitialRowHeight(sheet.rows[rowIndex].height ?? 24);
  }

  // --------------------------
  // 6. Cell Clicks & Selection
  // --------------------------
  function handleCellMouseDown(e: React.MouseEvent, rowIndex: number, colIndex: number) {
    const shiftPressed = e.shiftKey;
    if (shiftPressed && selectedCell) {
      const { row: oldRow, col: oldCol } = selectedCell;
      setSelectedRange({
        startRow: oldRow,
        startCol: oldCol,
        endRow: rowIndex,
        endCol: colIndex,
      });
    } else {
      // single cell
      setSelectedRange({
        startRow: rowIndex,
        startCol: colIndex,
        endRow: rowIndex,
        endCol: colIndex,
      });
    }
    setSelectedCell({ row: rowIndex, col: colIndex });
    setEditingCell(null);
  }

  function handleCellDoubleClick(rowIndex: number, colIndex: number) {
    setEditingCell({ row: rowIndex, col: colIndex });
  }

  // --------------------------
  // 7. Editing
  // --------------------------
  function handleCellChange(
    rowIndex: number,
    colIndex: number,
    newValue: string
  ) {
    setWorkbook(prev => {
      const newSheets = [...prev.sheets];
      const currentSheet = { ...newSheets[prev.activeSheetIndex] };
      const newRows = [...currentSheet.rows];
      const row = { ...newRows[rowIndex] };
      const newCells = [...row.cells];
      const cell = { ...newCells[colIndex] };

      cell.value = newValue;
      // If starts with '=', treat as formula
      if (newValue.trim().startsWith('=')) {
        cell.type = 'formula';
      } else {
        cell.type = 'text';
      }

      newCells[colIndex] = cell;
      row.cells = newCells;
      newRows[rowIndex] = row;
      currentSheet.rows = newRows;
      newSheets[prev.activeSheetIndex] = currentSheet;
      
      // Evaluate the entire sheet so the new formula or text is updated
      evaluateAllCellsInSheet(newRows);

      return { ...prev, sheets: newSheets };
    });
  }

  // --------------------------
  // 8. Autofill Logic
  // --------------------------
  function applyAutofill(originalRange: Range | null, targetRange: Range | null) {
    if (!originalRange || !targetRange) return;
    const normOrig = normalizeRange(originalRange);
    const normTarget = normalizeRange(targetRange);

    // If the target is basically the same as the original, do nothing
    if (
      normOrig.startRow === normTarget.startRow &&
      normOrig.endRow === normTarget.endRow &&
      normOrig.startCol === normTarget.startCol &&
      normOrig.endCol === normTarget.endCol
    ) {
      return;
    }

    // Example approach: replicate the block from the original range across the new range
    // We'll handle formula shifting so that references move relatively.

    setWorkbook(prev => {
      const newSheets = [...prev.sheets];
      const currentSheet = { ...newSheets[prev.activeSheetIndex] };
      const newRows = [...currentSheet.rows];

      const numOrigRows = normOrig.endRow - normOrig.startRow + 1;
      const numOrigCols = normOrig.endCol - normOrig.startCol + 1;
      
      // Compute how many times we replicate the pattern
      for (let r = normTarget.startRow; r <= normTarget.endRow; r++) {
        for (let c = normTarget.startCol; c <= normTarget.endCol; c++) {
          // Identify the corresponding cell in the original range
          // We'll do a simple pattern repeat or "extension"
          const rowOffset = (r - normOrig.startRow) % numOrigRows;
          const colOffset = (c - normOrig.startCol) % numOrigCols;

          const sourceRow = normOrig.startRow + rowOffset;
          const sourceCol = normOrig.startCol + colOffset;

          const sourceCell = newRows[sourceRow].cells[sourceCol];
          const targetCell = { ...newRows[r].cells[c] };

          // If the source is a formula, shift references
          if (sourceCell.type === 'formula' && typeof sourceCell.value === 'string') {
            // how far is the target from the source
            const rowDelta = r - sourceRow;
            const colDelta = c - sourceCol;
            const newFormula = shiftFormulaReferences(sourceCell.value, rowDelta, colDelta);
            targetCell.value = newFormula;
            targetCell.type = 'formula';
          } else {
            // text or number => direct copy
            targetCell.value = sourceCell.value;
            targetCell.type = sourceCell.type;
          }
          // copy style if you want
          targetCell.style = { ...sourceCell.style };

          newRows[r].cells[c] = targetCell;
        }
      }

      currentSheet.rows = newRows;
      newSheets[prev.activeSheetIndex] = currentSheet;

      // Evaluate the entire sheet for updated formulas
      evaluateAllCellsInSheet(currentSheet.rows);

      return { ...prev, sheets: newSheets };
    });
  }

  // --------------------------
  // 9. Render & Visuals
  // --------------------------
  // We'll also highlight the fill range with a dotted border
  const getCellStyle = (rowIndex: number, colIndex: number) => {
    let backgroundColor = '#fff';
    let borderStyle: React.CSSProperties = { border: '1px solid #ccc' };

    // Are we in the main selected range?
    if (selectedRange && isCellInRange(rowIndex, colIndex, selectedRange)) {
      backgroundColor = '#d0e6ff';
    }

    // Are we in the autofill range?
    if (fillRange && isCellInRange(rowIndex, colIndex, fillRange)) {
      // Add a dotted border
      borderStyle = {
        outline: '1px dashed #0000FF',
        outlineOffset: '-1px'
      };
    }

    // If the cell is the "active" cell, we might add an outline
    const isActive = selectedCell?.row === rowIndex && selectedCell?.col === colIndex;
    if (isActive) {
      borderStyle = {
        ...borderStyle,
        outline: '1px solid blue',
        outlineOffset: '-1px'
      };
    }

    return {
      ...borderStyle,
      width: sheet.columnWidths[colIndex],
      backgroundColor,
      padding: '4px',
    };
  };

  // Position of fill handle (bottom-right of the selection)
  let fillHandleStyle: React.CSSProperties | undefined;
  if (selectedRange) {
    const norm = normalizeRange(selectedRange);

    // Calculate offset top
    let topOffset = 0;
    for (let r = 0; r < norm.endRow + 1; r++) {
      topOffset += (sheet.rows[r].height ?? 24);
    }
    // Calculate offset left
    let leftOffset = 50; // skip row header
    for (let c = 0; c < norm.endCol + 1; c++) {
      leftOffset += sheet.columnWidths[c];
    }

    fillHandleStyle = {
      position: 'absolute',
      top: topOffset + 'px',
      left: leftOffset + 'px',
      width: '6px',
      height: '6px',
      borderRadius: '1000px',
      background: 'blue',
      cursor: 'crosshair'
    };
  }

  return (
    <div
      ref={tableRef}
      style={{ position: 'relative', userSelect: 'none' }}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <FormattingToolbar
        workbook={workbook}
        setWorkbook={setWorkbook}
        selectedCell={selectedCell}
      />

      {/* Fill handle square */}
      {selectedRange && (
        <div
          style={fillHandleStyle}
          className='absolute'
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsFilling(true);
            setFillRange(selectedRange);
          }}
        />
      )}

      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ width: 50 }}></th>
            {sheet.columnWidths.map((colWidth: number, colIndex: number) => (
              <th
                key={colIndex}
                style={{
                  position: 'relative',
                  width: colWidth,
                  border: '1px solid #ccc',
                  textAlign: 'center'
                }}
              >
                {columnIndexToLetter(colIndex)}
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    width: 5,
                    cursor: 'col-resize',
                    height: '100%'
                  }}
                  onMouseDown={(e) => startColResize(e, colIndex)}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sheet.rows.map((row: any, rowIndex: number) => (
            <tr
              key={rowIndex}
              style={{ height: row.height ?? 24, border: '1px solid #ccc' }}
            >
              <th
                style={{
                  position: 'relative',
                  border: '1px solid #ccc',
                  width: 50,
                  textAlign: 'center'
                }}
              >
                {rowIndex + 1}
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: 5,
                    cursor: 'row-resize',
                  }}
                  onMouseDown={(e) => startRowResize(e, rowIndex)}
                />
              </th>
              {row.cells.map((cell: Cell, colIndex: number) => {
                const isEditing =
                  editingCell?.row === rowIndex && editingCell?.col === colIndex;

                return (
                  <td
                    key={colIndex}
                    style={{
                      ...getCellStyle(rowIndex, colIndex),
                      ...cell.style,
                    }}
                    onMouseDown={(e) => handleCellMouseDown(e, rowIndex, colIndex)}
                    onDoubleClick={() => handleCellDoubleClick(rowIndex, colIndex)}
                  >
                    {isEditing ? (
                      <input
                        type={cell.type === 'number' ? 'number' : 'text'}
                        value={cell.value}
                        autoFocus
                        onBlur={() => setEditingCell(null)}
                        onChange={(e) => handleCellChange(rowIndex, colIndex, e.target.value)}
                        style={{ width: '100%' }}
                      />
                    ) : (
                      // Display the computed value if formula, else raw value
                      cell.type === 'formula' ? (cell.displayValue ?? '') : (cell.value ?? '')
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Spreadsheet;
