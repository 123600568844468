import React from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import './OwnerDashboard.css'
import Settings from "../../settings/Settings";

const OwnerSettingsElement: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get("view") || "general";

  return (
    <div className="h-screen flex flex-col w-full p-4">
      {/* Navigation Tabs */}
      <div className="flex gap-5 text-xl">
        <NavLink
          to="?view=general"
          className={`sub-nav-item ${view === "general" ? "sub-nav-active" : ""}`}
        >
          General
        </NavLink>
      </div>

      {/* Content Area */}
      <div className="flex-1 rounded-lg p-4 overflow-y-auto w-full bg-white blue-scrollbar">
        {view === "general" && <Settings />}
      </div>
    </div>
  );
};

export default OwnerSettingsElement;
