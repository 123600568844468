import React, {
  useContext,
  useState,
  useEffect,
  createContext,
  ReactNode,
  useRef,
} from "react";
import { auth } from "./firebase-config";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  User,
  UserCredential, // Import UserCredential
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { getOrganizationByUserId } from "../services/userServices/UserOrganizationService";
import { getRoleByUserId } from "../services/userServices/UserRolesService";
import Role from "../interfaces/identity/Role";
import Organization from "../interfaces/identity/Organization";
import { getUserInfoById } from "../services/userServices/UserInfoService";
import UserInfo from "../interfaces/UserInfo";
import { fetchAndStoreAvatars } from "../services/fileServices/FileService";
import { getPermissions } from "../services/permissionServices/PermissionService";
import { AbacPolicy, ResourceContext, doesUserHavePermission } from "../utilities/permissionUtils/PermissionUtils";

// Auth context interface
interface AuthContextType {
  currentUser: User | null;
  currentRoles: Role[] | null;
  currentOrganization: Organization | null;
  currentUserInfo: UserInfo | null;
  currentPolicies: AbacPolicy[];
  hasPermission: (action: string) => boolean; 
  login: (email: string, password: string) => Promise<UserCredential>;
  register: (email: string, password: string) => Promise<UserCredential>;
  logout: () => Promise<void>;
  isLoading: boolean;
}

// Creating the context
const AuthContext = createContext<AuthContextType>({} as AuthContextType);
export default AuthContext;

// Interface for the AuthProvider props
interface AuthProviderProps {
  children: ReactNode;
}

// Provider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentRoles, setCurrentRoles] = useState<Role[] | null>(null);
  const [currentOrganization, setCurrentOrganization] = useState<Organization | null>(
    null
  );
  const [currentUserInfo, setCurrentUserInfo] = useState<UserInfo | null>(null);
  const [currentPolicies, setCurrentPolicies] = useState<AbacPolicy[]>([]);
  const sessionTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  useEffect(() => {
    // setting the persistence
    setPersistence(auth, browserLocalPersistence);
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      setIsLoading(false); // Set loading to false once the user is fetched
      if (user) {

        const accessToken = await user.getIdToken()
        // Reset the session timer on user state change
        resetSessionTimer();
        // Fetch user roles and organization from your backend
        const roles = await getRoleByUserId(accessToken); // Placeholder function
        const organization = await getOrganizationByUserId(accessToken); // Placeholder function
        const userInfo = await getUserInfoById(accessToken);
        await fetchAndStoreAvatars(accessToken)

        try {
          const abacPolicies = await getPermissions(accessToken);
          setCurrentPolicies(abacPolicies);
        } catch (err) {
          console.error("Error fetching permissions:", err);
        }

        if (roles) {
          setCurrentRoles(roles);
        }
        if (organization) {
          setCurrentOrganization(organization);
        }
        if (userInfo) {
          setCurrentUserInfo(userInfo);
        }
      }
    });
    return unsubscribe;
  }, []);

  const resetSessionTimer = () => {
    if (sessionTimeoutRef.current) {
      clearTimeout(sessionTimeoutRef.current);
    }
    // Set session expiry time (e.g., 1 hour)
    sessionTimeoutRef.current = setTimeout(() => {
      // Force user logout
      logout();
    }, 43200000); // 12 hours in milliseconds
  };

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const register = (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    // if (sessionTimeoutRef.current) {
    //   clearTimeout(sessionTimeoutRef.current);
    // }
    return signOut(auth);
  };

  // The "hasPermission" function that calls into our ABAC utility
  const hasPermission = (action: string, ctx?: ResourceContext): boolean => {
    // If user not logged in
    if (!currentUser) return false;

    // Evaluate the policies
    return doesUserHavePermission(currentPolicies, action, ctx);
  };

  const value = {
    currentUser,
    currentRoles, 
    currentOrganization,
    currentUserInfo,
    currentPolicies, 
    hasPermission,
    login,
    register,
    logout,
    isLoading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Hook for using the context
export const useAuth = () => useContext(AuthContext);
