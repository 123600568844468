import {
  CreateSignatureRequestPayload,
  SignatureRequestResponse,
} from "../../../interfaces/file/fileSignature/SignatureCreationRequests";
import { SignatureRequest } from "../../../interfaces/file/fileSignature/SignatureRequest";

// Creates a signature request and returns the created record
export async function createSignatureRequest(
  payload: CreateSignatureRequestPayload,
  authToken: string
): Promise<SignatureRequestResponse | null> {
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/sign/generate-request`;

  //   return null;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    return response.json();
  } catch (error) {
    console.error("Error creating signature request", error);
    return null;
  }
}

export async function fetchSignatureRequestByToken(
  token: string
): Promise<SignatureRequest | null> {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/sign/public/${token}`,
      {
        method: "GET",
      }
    );
    if (!res.ok) {
      console.log(res);
      return null;
    }

    console.log(`${process.env.REACT_APP_API_BASE_URL}/sign/public/${token}`)
    const response =  await res.json();
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function fillSignatureBox(
  token: string,
  boxId: string,
  signatureValue: string
): Promise<boolean> {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/sign/public/${token}/boxes/${boxId}`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ signatureValue }),
      }
    );
    return res.ok;
  } catch {
    return false;
  }
}

/**
 * Submits a completed signature request.
 * This endpoint should only be called once all signature boxes have been signed.
 * @param token The signature request token.
 * @returns True if submission was successful; false otherwise.
 */
export async function submitSignatureRequest(token: string): Promise<{ finalizedPdfUrl: string; signingLogUrl: string } | null> {
  try {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/sign/submit/${token}`;
    // We assume no additional body data is needed.
    const response = await fetch(apiUrl, {
      method: "PUT",
    });
    if (response.status === 200) { 
        return response.json(); 
    } else { 
        const responseValue = await response.text();
        console.log(responseValue)
    }
    return null;
  } catch (error) {
    console.error("Error submitting signature request", error);
    return null;
  }
}

export async function getSignatureRequestsForFile(
  authToken: string, 
  fileId: string
): Promise<SignatureRequest[] | null> { 

  try { 
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/sign/file/${fileId}`;
    const response = await fetch(apiUrl, {
      method: 'GET', 
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

    if (response.status === 200) { 
      const responseVal = await response.json();
      console.log(responseVal)
      return responseVal;
    } else { 
      const responseValue = await response.text();
      console.log(responseValue)
    }
    return null;
  } catch (error) { 
    console.error("Error fetching signature requests", error);
    return null;
  }
}
