// Navbar.tsx
import {
  faInbox,
  faMessage,
  faRightFromBracket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
// import DarkModeToggle from "../../ui/DarkModeToggle/DarkModeToggle";
import "./Navbar.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getLatestNotifications,
  getNotifications,
} from "../../../services/notificationServices/NotificationService";
import { useAuth } from "../../../firebase/AuthProvider";
import EventNotification from "../../../interfaces/eventNotification/EventNotification";
import TaskSearch from "../../components/search/TaskSearch";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import Avatar from "../../../interfaces/avatar/Avatar";
import { ToastOptions } from "react-toastify";
import { useNotifications } from "../../../contexts/NotificationProvider";
import { useChatConnection } from "../../../contexts/ChatConnectionContext";
import {
  Conversation,
  Message,
  getMyConversations,
} from "../../../services/chatService/ChatService";
import SlideOutPanel from "../Sidenav/SlideOutPanel";
import { renderMessageWithLinks } from "../../../utilities/notifications/EventNotificationUtils";

interface NavbarProps {
  toggleDarkMode: () => void;
  isDarkMode: boolean;
}

interface NavItemProps {
  name: string;
  icon: IconDefinition;
  onClick: () => void;
  path: string;
}

const NavItem: React.FC<NavItemProps & { isActive: boolean }> = ({
  name,
  icon,
  isActive,
  onClick,
  path,
}) => {
  return (
    <Link
      to={path}
      className={`flex items-center p-2 cursor-pointer`}
      onClick={onClick}
    >
      <span className="mr-2 text-xl">
        <FontAwesomeIcon icon={icon} />
      </span>
      <span>{name}</span>
    </Link>
  );
};

const Navbar: React.FC<NavbarProps> = ({ toggleDarkMode, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [addToggle, setAddToggle] = useState(false);
  const [notifications, setNotifications] = useState<EventNotification[]>([]);
  const [taskId, setTaskId] = useState<string>("");
  const [currentAvatar, setCurrentAvatar] = useState<Avatar | null>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true); // Track the initial load
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const connection = useChatConnection();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("notifications");

  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, currentRoles } = useAuth();
  const { notify } = useNotifications();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleAddMenu = () => {
    setAddToggle(!addToggle);
  };

  useEffect(() => {
    setAddToggle(false);
    setIsPanelOpen(false);

    const extractTaskId = () => {
      const match = location.pathname.match(/\/task\/([^\/]+)/);
      if (match) {
        const id = match[1];
        setTaskId(id);
        // Use taskId for any logic here
      } else {
        setTaskId("");
      }
    };

    extractTaskId();
    // Get notifications on a polling timer
  }, [location]);

  useEffect(() => {
    if (
      currentUser &&
      !currentRoles?.some(
        (role) => role.name === "OWNER" || role.name === "ADMIN" || role.name === "ADMIN"
      )
    ) {
      const getNotificationsForUser = async () => {
        const authToken = await currentUser.getIdToken();
        const response = await getNotifications(authToken);
        if (response !== undefined && response !== null) {
          setNotifications(response);
        }
      };
      getNotificationsForUser();
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      currentUser &&
      !currentRoles?.some(
        (role) => role.name === "OWNER" || role.name === "ADMIN" || role.name === "ADMIN"
      )
    ) {
      const getData = async () => {
        const authToken = await currentUser.getIdToken();
        const data = await getMyConversations(authToken);
        setConversations(data);
      };

      getData();
    }
  }, [currentUser]);

  useEffect(() => {
    if (!connection) return;
    conversations.forEach((conv) => {
      connection
        .invoke("JoinConversation", conv.id)
        .then(() => {
          // console.log("Joined conversation group:", conv.id);
        })
        .catch((err) => {
          console.error(`Failed to join conversation ${conv.id}`, err);
        });
    });
  }, [connection, conversations]);

  useEffect(() => {
    if (
      connection &&
      currentUser &&
      !currentRoles?.some(
        (role) => role.name === "OWNER" || role.name === "ADMIN" || role.name === "ADMIN"
      )
    ) {
      const handleReceiveMessage = (msg: Message) => {
        // if msg.conversationId === conversation.id, push into messages
        if (msg.author.id !== currentUser.uid) {
          playNotificationSound("message_notification.mp3");
          const messageElement = (
            <div className="inter-font bg-white">
              <div className="flex align-middle items-center text-blue-500 gap-1 inter-font text-xs mb-2">
                <FontAwesomeIcon icon={faMessage} />
                <div>Messages</div>
                <div className="text-text">
                  {conversations.filter(
                    (conv) => conv.id === msg.conversationId
                  )[0].participants.length > 2 &&
                    conversations
                      .filter((conv) => conv.id === msg.conversationId)[0]
                      .participants.filter(
                        (part) => part.id !== currentUser.uid
                      )
                      .map((part) => {
                        return part.firstName;
                      })
                      .join(", ")}
                </div>
                <div className="text-muted-light">&#183;</div>
                <div className="text-2xs text-muted-light">Now</div>
              </div>
              <div className="inter-font-bold text-sm">
                {msg.author.firstName + " " + msg.author.lastName}
              </div>
              <div className="text-xs">{msg.messageContent}</div>
              <div className="bg-inactive text-blue-500 flex text-xs gap-4 mt-2">
                <div
                  className="hover:text-blue-900"
                  onClick={() => navigate("/chat")}
                >
                  REPLY
                </div>
                <div className="hover:text-blue-900">MARK READ</div>
              </div>
            </div>
          );
          notify(messageElement);
          setConversations((prevConversations) => {
            return prevConversations.map((conv) => {
              if (conv.id === msg.conversationId) {
                return {
                  ...conv,
                  // Update the lastMessage to the newly received message
                  lastMessage: msg,
                  // If your Conversation interface has other fields like
                  // 'lastMessageDate', you might update those too. For example:
                  // lastMessageDate: msg.createDate,
                };
              }
              return conv;
            });
          });
        }
      };

      // Attach event handlers
      connection.on("ReceiveMessage", handleReceiveMessage);

      return () => {
        // Clean up
        connection.off("ReceiveMessage", handleReceiveMessage);
      };
    }
  }, [currentUser, connection]);

  useEffect(() => {
    if (
      currentUser &&
      !currentRoles?.some(
        (role) => role.name == "OWNER" || role.name !== "ADMIN"
      )
    ) {
      const pollInterval = 30000; // 30 seconds

      // Our polling function that fetches new notifications
      const pollNotifications = async () => {
        if (currentUser) {
          const authToken = await currentUser.getIdToken();

          // const newNotificationsResponse = await getLatestNotifications(authToken);
          const latest = await getLatestNotifications(authToken);

          if (latest && latest.length > 0) {
            // Add new notifications to top of existing
            setNotifications((prev) => {
              const combined = [...latest, ...prev];
              return combined.slice(0, 5); // keep only top 5 if you’d like
            });
            if (!isInitialLoad) {
              playNotificationSound(); // Play sound for new notifications
              // For each new notification, let's toast it
            }
            latest.forEach((notification) => {
              // Optionally handle different statuses
              let toastOptions: ToastOptions = {};
              // Example style changes based on status:
              switch (notification.status) {
                case "error":
                  toastOptions = { type: "error" };
                  break;
                case "success":
                  toastOptions = { type: "success" };
                  break;
                case "info":
                default:
                  toastOptions = { type: "info" };
                  break;
              }
              const content = (
                <div className="text-xs inter-font">
                  {renderMessageWithLinks(notification.message, notification)}
                </div>
              );

              notify(content, toastOptions);
            });
          }
        }

        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
      };

      pollNotifications(); // Initial fetch when the component mounts

      const interval = setInterval(() => {
        pollNotifications();
      }, pollInterval);

      return () => clearInterval(interval); // Cleanup the interval on component unmount
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && !currentRoles?.some(role => role.name === 'OWNER' || role.name === 'ADMIN')) {
      const storedAvatars = getAvatarsFromStorage();
      if (storedAvatars) {
        const avatar = storedAvatars.find(
          (avatar) => avatar.id === currentUser.uid
        );
        if (avatar) {
          setCurrentAvatar(avatar);
        }
      }
    }
  }, [currentUser]);





  const openPanel = () => {
    setIsPanelOpen(true);
  };

  const playNotificationSound = (filename: string | null = null) => {
    let soundFileName;

    if (filename) {
      soundFileName = filename;
    } else {
      // Retrieve the user's preferred sound from local storage
      soundFileName =
        localStorage.getItem("notificationSound") || "notification-4.mp3";
    }

    const audio = new Audio(`/notificationTones/${soundFileName}`); // Assuming the files are in the public folder
    audio
      .play()
      .then(() => {})
      .catch((error) => {
        console.error("Error playing sound:", error);
      });
  };

  return (
    <div>
      {currentUser &&
        currentRoles &&
        !currentRoles.some((role) => role.name === "OWNER" || role.name === "ADMIN" ) && (
          <div className="bg-navBackground dark:bg-navBackground-dark h-[127px] ">
            <nav className="bg-black p-4 dark:bg-navBackground-dark text-white dark:text-text-dark main-nav z-40 ">
              <div className="container mx-auto flex justify-between items-center">
                {/* Logo */}
                <Link
                  to={"/profile/edit"}
                  className=" text-lg font-bold outfit-font flex items-center gap-4"
                >
                  <div>
                    {currentAvatar ? (
                      <img
                        src={`data:image/png;base64,${currentAvatar.avatar}`}
                        className="h-[40px] w-[40px] rounded-full"
                      ></img>
                    ) : (
                      <div className="w-[30px] h-[30px] bg-darkTogglebg-dark rounded-full flex items-center justify-center">
                        <FontAwesomeIcon icon={faUser} className="text-base" />
                      </div>
                    )}
                  </div>
                  {
                    currentRoles?.reduce(
                      (maxIdObject, obj) =>
                        obj.id > maxIdObject.id ? obj : maxIdObject,
                      currentRoles[currentRoles.length - 1]
                    )?.name
                  }
                </Link>

                {/* Mobile Menu Toggle (Hidden by default) */}
                <div className="md:hidden">
                  <button className=" focus:outline-none" onClick={toggleMenu}>
                    <i className={`fas ${isOpen ? "fa-times" : "fa-bars"}`}></i>
                  </button>
                </div>

                {/* Navigation Links (Desktop) */}
                <ul className="hidden md:flex space-x-4">
                  <li className="d-flex items-center align-middle my-auto">
                    <TaskSearch />
                  </li>
                  {/* @TODO: Update Region to be a selectable toggle for all regions associated with a User */}
                  <li className="d-flex items-center align-middle my-auto">
                    <div className="text-xs region-bullet text-white inter-font flex justify-center align-middle items-center">
                      Region: 2
                    </div>
                  </li>
                  <li className="relative d-flex justify-center items-center align-middle my-auto cursor-pointer  w-8 h-8">
                    <div
                      className="text-2xl w-full mx-auto text-center hover:bg-navbarDark dark:hover:bg-text rounded-3xl"
                      onClick={() => toggleAddMenu()}
                    >
                      +
                    </div>
                    {addToggle && (
                      <div className="absolute bg-card dark:bg-card-dark p-2 rounded-lg shadow-lg min-w-[150px] right-0 mt-5 text-text">
                        <div
                          className="text-center hover:bg-background dark:hover:bg-background-dark"
                          onClick={() =>
                            navigate(
                              taskId === "" || taskId === "new"
                                ? `task/new/activity`
                                : `task/new/activity?taskId=${
                                    taskId.split("-")[1]
                                  }`
                            )
                          }
                        >
                          Add Activity
                        </div>
                        <div
                          className="text-center hover:bg-background dark:hover:bg-background-dark"
                          onClick={() => navigate("task/new/interim")}
                        >
                          Add Task
                        </div>
                      </div>
                    )}
                  </li>
                  {/* <li className="">
                  <DarkModeToggle
                    toggleDarkMode={toggleDarkMode}
                    isDarkMode={isDarkMode}
                  />
                </li> */}
                  <li className="flex items-center">
                    <div
                      className="relative cursor-pointer hover:text-gray-400 flex justify-center align-middle items-center rounded-full"
                      onClick={openPanel}
                    >
                      <FontAwesomeIcon
                        className="text-center flex items-center align-middle"
                        icon={faInbox}
                      />
                      {/* Optionally show an indicator for unread conversations */}
                      {(notifications.filter((n) => n.read === false).length >
                        0 ||
                        conversations.filter(
                          (conv) =>
                            !conv.lastReadAt ||
                            (conv.lastMessage &&
                              new Date(conv.lastReadAt) <
                                new Date(conv.lastMessage.createDate))
                        ).length > 0) && (
                        <div className="absolute w-2.5 h-2.5 -top-1.5 -right-1.5 bg-red-600 rounded-full "></div>
                      )}
                    </div>
                  </li>
                  <li
                    className="flex items-center justify-center align-middle cursor-pointer hover:text-brandSecondary"
                    onClick={() => navigate("/login")}
                  >
                    <div className="flex items-center justify-center align-middle gap-2 text-base inter-font">
                      <FontAwesomeIcon icon={faRightFromBracket} />
                      <div>Logout</div>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <div className="flex flex-row gap-10 w-4/5 mx-auto mt-[20px] container secondary-nav">
                  <div
                    onClick={() => navigate("/home")}
                    className={`${
                      location.pathname.startsWith("/home") ||
                      location.pathname.startsWith("/dashboard")
                        ? "text-brandSecondary"
                        : ""
                    } cursor-pointer hover:text-brandSecondary `}
                  >
                    <div className="text-sm">Dashboard</div>
                    <div>
                      {location.pathname.startsWith("/home") ||
                      location.pathname.startsWith("/dashboard") ? (
                        <div className="mt-1 h-[3px] w-full bg-brandSecondary rounded-full"></div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => navigate("/tasks")}
                    className={`${
                      location.pathname.startsWith("/tasks") ||
                      location.pathname.startsWith("/task")
                        ? "text-brandSecondary border-b-brandSecondary"
                        : ""
                    } cursor-pointer hover:text-brandSecondary `}
                  >
                    <div className="text-sm">Tasks</div>
                    <div>
                      {location.pathname.startsWith("/tasks") ||
                      location.pathname.startsWith("/task") ? (
                        <div className="mt-1 h-[3px] w-full bg-brandSecondary rounded-full"></div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div
                    onClick={() => navigate("/drive/caseFileDrive")}
                    className={`${
                      location.pathname.startsWith(`/drive`)
                        ? "text-brandSecondary border-b-brandSecondary"
                        : ""
                    } cursor-pointer hover:text-brandSecondary `}
                  >
                    <div className="text-sm">Drive</div>
                    <div>
                      {location.pathname.startsWith(`/drive`) ? (
                        <div className="mt-1 h-[3px] w-full bg-brandSecondary rounded-full"></div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Mobile Menu (Hidden by default) */}
              <div className={`md:hidden ${isOpen ? "block" : "hidden"}`}>
                <ul className="flex flex-col space-y-2">
                  <li className="d-flex align-center my-auto">
                    <div className="text-xs text-muted">Region: 2</div>
                  </li>
                </ul>
              </div>
              <SlideOutPanel
                isOpen={isPanelOpen}
                onClose={() => setIsPanelOpen(false)}
                activeTab={activeTab}
                onTabChange={setActiveTab}
                notifications={notifications}
                conversations={conversations}
              />
            </nav>
          </div>
        )}
    </div>
  );
};

export default Navbar;
