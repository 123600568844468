// components/ChatPanel.tsx
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import { Conversation, sendMessage } from "../../../services/chatService/ChatService";
import Avatar from "../../../interfaces/avatar/Avatar";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import ConversationList from "../../components/realtimeChatComponents/ConversationListComponent";
import ChatWindow from "../../components/realtimeChatComponents/ChatWindow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";

const ChatPanel: React.FC = () => {
  const { currentUser } = useAuth();
  const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
  const [avatars, setAvatars] = useState<Avatar[]>([]);
  const [authToken, setAuthToken] = useState<string>("");

  // Retrieve the authentication token when the currentUser is available
  useEffect(() => {
    let isMounted = true;
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        if (isMounted) setAuthToken(token);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [currentUser]);

  // Load any stored avatars
  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars || []);
  }, []);

  const userId = currentUser?.uid;

  // Handler for sending a new message
  const handleSendMessage = async (message: string, conversationId: number) => {
    if (!message.trim()) return;
    if (!selectedConversation && !conversationId) {
      alert("No conversation selected");
      return;
    }
    try {
      const token = await currentUser?.getIdToken();
      if (!token || !conversationId) return;
      await sendMessage(token, conversationId, message);
    } catch (err) {
      console.error("Failed to send message:", err);
    }
  };

  // Handler for selecting a conversation from the list
  const handleSelectConversation = (conversation: Conversation) => {
    setSelectedConversation(conversation);
  };

  return (
    <div className="relative flex flex-col flex-1 w-full h-full overflow-hidden">
      {/* Conversation List Panel */}
      <div
        className={`absolute top-0 left-0 w-full h-full transform transition-transform duration-300 ${
          selectedConversation ? "-translate-x-full" : "translate-x-0"
        }`}
      >
        {authToken !== "" && userId && (
          <ConversationList
            authToken={authToken}
            userId={userId}
            avatars={avatars}
            selectedConversationId={selectedConversation?.id || null}
            onSelectConversation={handleSelectConversation}
            width="full"
          />
        )}
      </div>
  
      {/* Chat Window Panel */}
      <div
        className={`absolute top-0 left-0 w-full h-full transform transition-transform duration-300 flex ${
          selectedConversation ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="relative flex flex-1 h-full">
          {/* Back Button */}
          <div
            className="absolute h-10 w-10 top-16 left-3 z-10 bg-blue-500 text-white border shadow-xl flex items-center justify-center rounded-full p-1 cursor-pointer"
            onClick={() => setSelectedConversation(null)}
          >
            <FontAwesomeIcon icon={faLeftLong} />
          </div>
  
          {selectedConversation && currentUser && userId ? (
            <ChatWindow
              avatars={avatars}
              authToken={authToken}
              currentUserId={userId}
              sendMessageFn={handleSendMessage}
              conversation={selectedConversation}
              height="h-full flex-1"
            />
          ) : (
            <div className="flex items-center justify-center h-full text-gray-500">
              Please select a conversation or create a new one to begin.
            </div>
          )}
        </div>
      </div>
    </div>
  );
  
};

export default ChatPanel;
