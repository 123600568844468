import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Unauthorized: React.FC = () => {
  return (
    <div className="relative bg-white dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full h-screen">
      <div className="bg-black w-full h-[300px] rounded-b-3xl"></div>
      <div className="overflow-x-auto flex justify-center items-center align-middle -top-64 relative dark:bg-card-dark dark:text-text-dark bg-gray-200 p-5 rounded-3xl shadow-lg w-11/12 mx-auto min-h-[600px] -mt-10">
        <div className="max-w-[300px] flex flex-col justify-center">
          <div className="text-9xl font-bold flex items-center align-middle mx-auto">
            <span>4</span>
            <span className="flex items-center align-middle"><FontAwesomeIcon className="text-8xl" icon={faLock} /></span>
            <span>3</span>
        </div>
          <div className="inter-font mt-4">
            Oops! You don't have permission to access this content.
          </div>
          <div className="mt-2 text-sm text-muted">
            If you think that you are supposed to have access to this content, please check with your administrator or try again later.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
