import React, { useEffect, useState } from "react";
import AssignedParticipant from "../../../interfaces/participant/AssignedParticipant";
import Select, { SingleValue } from "react-select";
import { useAuth } from "../../../firebase/AuthProvider";
import TaskCreationLKValues from "../../../interfaces/lkValues/TaskCreationLKValues";
import { getTaskLKValues } from "../../../services/taskServices/TaskLKService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardUser,
  faRecycle,
  faRepeat,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { reassigneParticipant } from "../../../services/participantServices/ParticipantService";
import Banner from "../../ui/Banner/Banner";
import LoadingSpinner from "../../ui/Loading/LoadingSpinner";

interface TransferParticipantComponentProps {
  participants: AssignedParticipant[] | null | undefined;
}

interface OptionType {
  value: string;
  label: string;
}

const TransferParticipantComponent: React.FC<
  TransferParticipantComponentProps
> = ({ participants }) => {
  const { currentUser } = useAuth();
  const [participantOptions, setParticipantOptions] = useState<OptionType[]>(
    []
  );
  const [selectedParticipant, setSelectedParticipant] = useState<string>();
  const [assigneeOptions, setAssigneeOptions] = useState<OptionType[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (participants !== null && participants !== undefined) {
      const options = participants.map((participant) => ({
        value: participant.id.toString(),
        label: participant.firstName + " " + participant.lastName,
      }));
      setParticipantOptions(options);
    }
  }, [participants]);

  useEffect(() => {
    const fetchLkValues = async () => {
      if (currentUser) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedValues: TaskCreationLKValues | null =
          await getTaskLKValues(authToken);
        if (fetchedValues) {
          setAssigneeOptions(
            fetchedValues.users.map((user) => ({
              value: user.id.toString(),
              label: user.firstName + " " + user.lastName,
            }))
          );
        }
      }
    };
    fetchLkValues();
  }, [currentUser]);

  const handleSelectedParticipantChange = (
    selectedOption: SingleValue<OptionType>
  ) => {
    const participant = selectedOption ? selectedOption.value : "";
    setSelectedParticipant(participant);
  };

  const handleSelectedAssigneeChange = (
    selectedOption: SingleValue<OptionType>
  ) => {
    const assignee = selectedOption ? selectedOption.value : "";
    setSelectedAssignee(assignee);
  };

  const transferParticipant = async () => {
    setIsLoading(true);
    const participantName = `${
      participantOptions?.filter((p) => p.value === selectedParticipant)[0]
        .label
    }`;
    if (currentUser !== null && selectedParticipant && selectedAssignee) {
      const authToken = await currentUser.getIdToken();
      reassigneParticipant(selectedParticipant, selectedAssignee, authToken)
        .then((res) => {
          setSuccessMessage(
            `Successfully transferred participant ${participantName}`
          );
          setSelectedAssignee(undefined);
          setSelectedParticipant(undefined);
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage("Error processing request.");
        });
    }

    setIsLoading(false);
  };

  return (
    <div className="bg-gray-100 p-5 rounded-3xl mt-5">
      {errorMessage !== "" && (
        <Banner
          dismissBanner={() => setErrorMessage("")}
          message={errorMessage}
          status="error"
        />
      )}
      {successMessage !== "" && (
        <Banner
          dismissBanner={() => setSuccessMessage("")}
          message={successMessage}
          status="success"
        />
      )}
      <h3 className="inter-font text-text text-xl">Transfer Participant</h3>
      <div className="form max-w-[600px] mx-auto bg-white p-5 rounded-3xl">
        <div>
          <div className="title">
            Which participant are you wanting to transfer?
          </div>
          <div className="remove-input-txt-border p-2 px-4 mx-auto">
            <Select
              id="participants"
              name="participants"
              value={participantOptions.find(
                (option) => option.value === selectedParticipant
              )}
              onChange={handleSelectedParticipantChange}
              options={participantOptions}
              className="your-custom-class text-start"
              placeholder={"Choose who to assign to..."}
              classNamePrefix="select"
            />
          </div>
        </div>
        {selectedParticipant && (
          <div>
            <div className="title">
              Who do you want to transfer this participant to?
            </div>
            <div className="remove-input-txt-border p-2 px-4 mx-auto">
              <Select
                id="participants"
                name="participants"
                value={assigneeOptions.find(
                  (option) => option.value === selectedAssignee
                )}
                onChange={handleSelectedAssigneeChange}
                options={assigneeOptions}
                className="your-custom-class text-start"
                placeholder={"Choose who to assign to..."}
                classNamePrefix="select"
              />
            </div>
          </div>
        )}
        {selectedParticipant && selectedAssignee && (
          <div className="mt-2">
            <div className="title text-red-600 items-center align-middle leading-8">
              Transferring
              <span className="flex-inline bg-gray-200 text-blue-600 p-1.5 rounded mx-1 gap-5 leading-10">
                <FontAwesomeIcon icon={faClipboardUser} className="me-1" />
                <span>
                  {
                    participantOptions.filter(
                      (participant) => participant.value === selectedParticipant
                    )[0].label
                  }
                </span>
              </span>
              will result in all of their assigned tasks and drive files being
              moved to
              <span className="flex-inline bg-gray-200 text-blue-600 p-1.5 rounded mx-1 gap-5 me-0">
                <FontAwesomeIcon icon={faUser} className="me-1" />
                <span>
                  {
                    assigneeOptions.filter(
                      (assignee) => assignee.value === selectedAssignee
                    )[0].label
                  }
                </span>
              </span>
              's drive. Are you sure you want to continue?
            </div>
            <div className="p-2 px-4 mx-auto flex justify-end">
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div
                  className="bg-[#3DD598] flex text-white p-2 rounded items-center align-middle justify-center gap-2 hover:cursor-pointer hover:rotate-icon"
                  onClick={transferParticipant}
                >
                  <FontAwesomeIcon
                    icon={faRepeat}
                    className="transition-transform duration-500"
                  />
                  Transfer Particiapant
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransferParticipantComponent;
