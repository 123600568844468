import React from "react";
import { useNavigate } from "react-router-dom";

interface ClosedTaskTableProps {
  closedTaskIds: number[];
  closedLateTaskIds: number[];
  organizationName: string;
}

const ClosedTaskTable: React.FC<ClosedTaskTableProps> = ({
  closedTaskIds,
  closedLateTaskIds,
  organizationName,
}) => {
  const navigate = useNavigate();

  // Determine the maximum length between the two lists
  const maxLength = Math.max(closedTaskIds.length, closedLateTaskIds.length);

  return (
    <div className="overflow-x-auto rounded-md border max-h-[100px] relative text-xs flex-1 blue-scrollbar">
      <table className="min-w-full divide-y divide-gray-200 rounded-t-lg">
        {/* Table Header */}
        <thead className="bg-gray-50 rounded-t-lg">
          <tr>
            <th className="px-2 py-1.5 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
              Closed On Time
            </th>
            <th className="px-2 py-1.5 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
              Closed Late
            </th>
          </tr>
        </thead>

        {/* Table Body */}
        <tbody className="bg-white divide-y divide-gray-200">
          {Array.from({ length: maxLength }).map((_, index) => (
            <tr key={index}>
              <td className="px-2 py-1 whitespace-nowrap text-xs text-blue-600">
                {closedTaskIds[index] !== undefined ? (
                  <div
                  className="cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/task/${organizationName + "-" + closedTaskIds[index]}`
                      )
                    }
                  >
                    {organizationName + "-" + closedTaskIds[index]}
                  </div>
                ) : (
                  ""
                )}
              </td>
              <td className="px-2 py-1 whitespace-nowrap text-xs text-blue-600">
                {closedLateTaskIds[index] !== undefined ? (
                  <div
                  className="cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/task/${organizationName + "-" + closedLateTaskIds[index]}`
                      )
                    }
                  >
                    {organizationName + "-" + closedLateTaskIds[index]}
                  </div>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {closedLateTaskIds.length === 0 && closedTaskIds.length === 0 && (
        <div className="text-muted">No results found...</div>
      )}
    </div>
  );
};

export default ClosedTaskTable;
