import React from "react";

const LandingPageNavbar: React.FC = () => {

  return (
          <header className="relative w-full mx-auto max-w-6xl p-6 flex justify-between items-center text-gray-200">
            <h1 className="text-3xl font-bold tracking-wide">Sprout</h1>
            <nav className="space-x-6">
              <a href="#features" className="hover:text-white">Features</a>
              <a href="#about" className="hover:text-white">About</a>
              <a href="#contact" className="hover:text-white">Contact</a>
            </nav>
          </header>
  );
};

export default LandingPageNavbar;
