// A simple formatting toolbar

import { Workbook } from "../../../../../interfaces/sproutSheets/Workbook";

export function FormattingToolbar({
    workbook,
    setWorkbook,
    selectedCell
  }: {
    workbook: Workbook;
    setWorkbook: React.Dispatch<React.SetStateAction<Workbook>>;
    selectedCell: { row: number; col: number } | null;
  }) {
    if (!selectedCell) return null;
  
    const handleChangeStyle = (styleKey: string, value: any) => {
      if (!selectedCell) return;
      const { row, col } = selectedCell;
      setWorkbook(prev => {
        const newSheets = [...prev.sheets];
        const currentSheet = { ...newSheets[prev.activeSheetIndex] };
        const newRows = [...currentSheet.rows];
        const rowObj = { ...newRows[row] };
        const newCells = [...rowObj.cells];
        const cell = { ...newCells[col] };
        cell.style = { ...cell.style, [styleKey]: value };
        newCells[col] = cell;
        rowObj.cells = newCells;
        newRows[row] = rowObj;
        currentSheet.rows = newRows;
        newSheets[prev.activeSheetIndex] = currentSheet;
        return { ...prev, sheets: newSheets };
      });
    };
  
    return (
      <div style={{ marginBottom: '8px' }}>
        <button onClick={() => handleChangeStyle('bold', true)}>Bold</button>
        <button onClick={() => handleChangeStyle('italic', true)}>Italic</button>
        <button onClick={() => handleChangeStyle('backgroundColor', 'yellow')}>BG Yellow</button>
        <button onClick={() => handleChangeStyle('color', 'red')}>Text Red</button>
        {/* etc. */}
      </div>
    );
  }
  