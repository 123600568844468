import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import TaskCreationLKValues from "../../../../../interfaces/lkValues/TaskCreationLKValues";
import { useAuth } from "../../../../../firebase/AuthProvider";
import { getTaskLKValues } from "../../../../../services/taskServices/TaskLKService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faAnglesUp,
} from "@fortawesome/free-solid-svg-icons";
import NewTemplateTask from "../../../../../interfaces/templateTask/NewTemplateTask";
import Banner from "../../../../ui/Banner/Banner";
import TemplateTaskOption from "../../../../../interfaces/templateTask/TaskGroupOption";
import { getTaskGroupOptions } from "../../../../../services/templateService/TemplateTaskService";
import Role from "../../../../../interfaces/identity/Role";
import { getRoles } from "../../../../../services/userServices/UserRolesService";

interface OptionType {
  value: string;
  label: string;
}

interface TemplateTaskCreationProps {
  setValue: (id: NewTemplateTask) => void;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
}

const TemplateTaskCreation: React.FC<TemplateTaskCreationProps> = ({
  setValue,
  errorMessage,
  setErrorMessage,
}) => {
  const { currentUser, currentOrganization } = useAuth();

  const [taskOrganizationOptions, setTaskOrganizationOptions] = useState<
    OptionType[]
  >([]);

  const [taskTypeOptions, setTaskTypeOptions] = useState<OptionType[]>([]);
  const [taskGroupSelectOptions, setTaskGroupSelectOptions] = useState<
    OptionType[]
  >([]);
  const [taskSubTypeOptions, setTaskSubTypeOptions] = useState<OptionType[]>(
    []
  );
  const [waiverTypeOptions, setWaiverTypeOptions] = useState<OptionType[]>([]);
  const [userRoleTypeOptions, setUserRoleTypeOptions] = useState<OptionType[]>(
    []
  );

  const [newTask, setNewTask] = useState<NewTemplateTask>({
    title: "",
    description: "",
    taskOrganization: "",
    taskType: "",
    assignmentType: "",
    taskSubType: "",
    dueDateCalculationType: "",
    dueDateOffset: "",
    creationDateCalculationType: "",
    creationDateOffset: "",
    creationDateCron: "",
    dueDateCron: "",
    priority: "Low",
    waiverId: "",
    taskGroup: "",
    role: ""
  });

  const assignmentTypeOptions = [
    {
      value: "participants",
      label: "Participants",
    },
    {
      value: "employees",
      label: "Employees",
    },
    {
      value: "employeesWithRole",
      label: "Employees with role",
    },
    {
      value: "sc",
      label: "SC",
    },
    {
      value: "participantsWithWaiver",
      label: "Participants with waiver",
    },
  ];

  const [lkOptions, setLkOptions] = useState<
    TaskCreationLKValues | null | undefined
  >();

  const [taskGroupOptions, setTaskGroupOptions] = useState<
    TemplateTaskOption[] | null
  >();

  const [userRoleOptions, setUserRoleOptions] = useState<Role[] | null>();

  useEffect(() => {
    // Fetch the task with taskId and update the state
    const fetchLkValues = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedValues: TaskCreationLKValues | null =
          await getTaskLKValues(authToken);
        const fetchedTemplateOptions: TemplateTaskOption[] | null =
          await getTaskGroupOptions(authToken);
        const fetchedUserRoleOptions: Role[] | null = await getRoles(authToken);
        setLkOptions(fetchedValues);
        setUserRoleOptions(fetchedUserRoleOptions);
        setTaskGroupOptions(fetchedTemplateOptions);
      }
    };

    if (lkOptions) {
      const formattedTaskOrganizations = lkOptions.taskOrganizations.map(
        (org) => ({
          value: org.id.toString(),
          label: org.type,
        })
      );
      const formattedTaskTypes = lkOptions.taskTypes.map((tt) => ({
        value: tt.id.toString(),
        label: tt.type,
      }));
      const formattedTaskSubTypes = lkOptions.taskSubTypes.map((tst) => ({
        value: tst.id.toString(),
        label: tst.type,
      }));
      const formattedWaiverTypes = lkOptions.waivers.map((waiver) => ({
        value: waiver.id.toString(),
        label: waiver.waiverName,
      }));

      setWaiverTypeOptions(formattedWaiverTypes);
      setTaskTypeOptions(formattedTaskTypes);
      setTaskSubTypeOptions(formattedTaskSubTypes);
      setTaskOrganizationOptions(formattedTaskOrganizations);
    }

    if (userRoleOptions) {
      const formattedRoleTypes = userRoleOptions.map((role) => ({
        value: role.name,
        label: role.name,
      }));

      setUserRoleTypeOptions(formattedRoleTypes);
    }
    if (taskGroupOptions) {
      const formattedTaskGroupOptions = taskGroupOptions.map((taskGroup) => ({
        value: taskGroup.taskGroupId.toString(),
        label: taskGroup.taskGroupName,
      }));

      setTaskGroupSelectOptions(formattedTaskGroupOptions);
    }
    if (!lkOptions) {
      fetchLkValues();
    }
  }, [lkOptions]);

  const handleTaskOrganizationChange = (
    selectedOption: SingleValue<OptionType>
  ) => {
    const taskOrganization = selectedOption ? selectedOption.value : "";
    setNewTask({ ...newTask, taskOrganization });
  };

  const handleTaskTypeChange = (selectedOption: SingleValue<OptionType>) => {
    const taskType = selectedOption ? selectedOption.value : "";
    setNewTask({ ...newTask, taskType });
  };

  const handleTaskSubTypeChange = (selectedOption: SingleValue<OptionType>) => {
    const taskSubType = selectedOption ? selectedOption.value : "";
    setNewTask({ ...newTask, taskSubType });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setNewTask({ ...newTask, [name]: value });
  };

  const handleCustomChange = (
    fieldName: keyof NewTemplateTask,
    fieldValue: string | undefined
  ) => {
    setNewTask({ ...newTask, [fieldName]: fieldValue });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle submitting the new task
    if (validateFields()) {
      setValue(newTask);
    }
  };

  const validateFields = () => {
    if (newTask.title.trim() === "") {
      setErrorMessage("Template Task Title is a required field.");
      return false;
    } else if (newTask.description.trim() === "") {
      setErrorMessage("Template Task Description is a required field.");
      return false;
    } else if (newTask.assignmentType === "") {
      setErrorMessage(
        "Template Task Assignment Distribution is a required field."
      );
      return false;
    } else if (newTask.taskOrganization === "") {
      setErrorMessage("Template Task Organization is a required field.");
      return false;
    } else if (newTask.taskType === "") {
      setErrorMessage("Template Task Type is a required field.");
      return false;
    } else if (newTask.taskSubType === "") {
      setErrorMessage("Template Task Sub Type is a required field.");
      return false;
    } else if (
      newTask.assignmentType === "participantsWithWaiver" &&
      newTask.waiverId === ""
    ) {
      // Set error
      setErrorMessage(
        "An assignment type of `Participant With Waiver` requires a waiver to be selected."
      );
      return false;
    } else if (
      newTask.assignmentType === "EmployeesWithRole" && newTask.role === ""
    ) {
      setErrorMessage("An employee role is required if Employees With Role option is selected.");
      return false;
    }
    return true;
  };

  const dismissBanner = () => {
    setErrorMessage("");
  };

  return (
    <div className="remove-input-txt-border">
      <h1 className="heading-2 text-center">New Template</h1>
      <form
        onSubmit={handleSubmit}
        className="max-w-md mx-auto mt-8 bg-card dark:bg-card-dark shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-5">
          {errorMessage !== "" && (
            <Banner
              status="warning"
              dismissBanner={dismissBanner}
              message={errorMessage}
            />
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="title"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Title:
          </label>
          <input
            type="text"
            id="title"
            name="title"
            placeholder="Give your task a title..."
            value={newTask.title}
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-text dark:text-text leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="description"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Description:
          </label>
          <textarea
            id="description"
            name="description"
            placeholder="A brief description of what the task entails..."
            value={newTask.description}
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-text dark:text-text leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="taskGroup"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Task Group:
          </label>
          <Select
            options={taskGroupSelectOptions}
            value={taskGroupSelectOptions.find(
              (option) => option.value === newTask.taskGroup
            )}
            onChange={(selectedValue) =>
              handleCustomChange("taskGroup", selectedValue?.value)
            }
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="assignmentType"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Assignment Distribution:
          </label>
          <Select
            options={assignmentTypeOptions}
            value={assignmentTypeOptions.find(
              (option) => option.value === newTask.assignmentType
            )}
            onChange={(selectedValue) =>
              handleCustomChange("assignmentType", selectedValue?.value)
            }
          />
        </div>
        {newTask.assignmentType === "participantsWithWaiver" && (
          <div className="mb-4">
            <label
              htmlFor="assignmentType"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Waiver Type:
            </label>
            <Select
              options={waiverTypeOptions}
              value={waiverTypeOptions.find(
                (option) => option.value === newTask.waiverId
              )}
              onChange={(selectedValue) =>
                handleCustomChange("waiverId", selectedValue?.value)
              }
            />
          </div>
        )}
        {newTask.assignmentType === "employeesWithRole" && (
          <div className="mb-4">
            <label
              htmlFor="assignmentType"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Role Type:
            </label>
            <Select
              options={userRoleTypeOptions}
              value={userRoleTypeOptions.find(
                (option) => option.value === newTask.role
              )}
              onChange={(selectedValue) =>
                handleCustomChange("role", selectedValue?.value)
              }
            />
          </div>
        )}
        <div className="mb-4">
          <label
            htmlFor="taskOrganization"
            className="block text-gray-700 text-sm font-bold mb-2 dark:text-text-dark"
          >
            Task Organization:
          </label>
          <Select
            id="taskOrganization"
            name="taskOrganization"
            value={taskOrganizationOptions.find(
              (option) => option.value === newTask.taskOrganization
            )}
            onChange={handleTaskOrganizationChange}
            options={taskOrganizationOptions}
            className="your-custom-class"
            classNamePrefix="select"
          />
        </div>
        {newTask.taskOrganization && (
          <div className="mb-4">
            <label
              htmlFor="taskType"
              className="block text-gray-700 text-sm font-bold mb-2 dark:text-text-dark"
            >
              Task Type:
            </label>
            <Select
              id="taskType"
              name="taskType"
              value={taskTypeOptions.find(
                (option) => option.value === newTask.taskType
              )}
              onChange={handleTaskTypeChange}
              options={taskTypeOptions}
              className="your-custom-class"
              classNamePrefix="select"
            />
          </div>
        )}
        {newTask.taskType && (
          <div className="mb-4">
            <label
              htmlFor="taskSubType"
              className="block text-gray-700 text-sm font-bold mb-2 dark:text-text-dark"
            >
              Task Sub Type:
            </label>
            <Select
              id="taskSubType"
              name="taskSubType"
              value={taskSubTypeOptions.find(
                (option) => option.value === newTask.taskSubType
              )}
              onChange={handleTaskSubTypeChange}
              options={taskSubTypeOptions}
              className="your-custom-class"
              classNamePrefix="select"
            />
          </div>
        )}
        <div className="flex justify-end">
          <button
            onClick={(e) => handleSubmit}
            type="submit"
            className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Create Template
          </button>
        </div>
      </form>
    </div>
  );
};

export default TemplateTaskCreation;
