import {
  faArrowUpFromBracket,
  faDownload,
  faEye,
  faFileAudio,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileWord,
  faSignature,
  faTrash,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AttachmentState } from "../../../../interfaces/task/TaskFiles/AttachmentState";
import FileActionModal from "./FileActionModals/FileActionModal";
import { useAuth } from "../../../../firebase/AuthProvider";
import {
  deleteFileById,
  downloadFile,
} from "../../../../services/fileServices/FileService";
import FileUploadPopUp from "../../fileUploadComponent/FileUploadPopUp";

interface FileCardProps {
  id: string;
  attachmentId: number;
  filetype: string;
  filename: string;
  author: string;
  lastUpdated: string;
  createDate: string;
  location: string;
  state: AttachmentState;
  reload: () => void;
  canUserDeleteFile: boolean;
  taskId: string;
  pendingSignatureState?: boolean | null;
}

const FileCard: React.FC<FileCardProps> = ({
  filetype,
  filename,
  attachmentId,
  location,
  author,
  lastUpdated,
  createDate,
  id,
  state,
  reload,
  pendingSignatureState,
  canUserDeleteFile,
  taskId,
}) => {
  const { currentUser } = useAuth();
  const [hovered, setHovered] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadFileToggle, setUploadFileToggle] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleView = () => {
    navigate(`/view/file/${id}`);
  };

  const handleDownload = async () => {
    try {
      if (currentUser) {
        const authToken: string = await currentUser.getIdToken();
        const blob = await downloadFile(id, authToken);
        if (blob) {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename; // Use the provided fileName
          document.body.appendChild(a);
          a.click();

          URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleNewUpload = () => {
    setUploadFileToggle(!uploadFileToggle);
  };

  const handleDelete = () => setShowModal(true);

  const handleSignatureRequest = () => {
    navigate(`/edit/file/${id}`);
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      if (currentUser) {
        const authToken: string = await currentUser.getIdToken();
        await deleteFileById(id, authToken);
        // Assuming delete is successful
        setIsLoading(false);
        setShowModal(false);
        reload();
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error deleting the file:", error);
    }
  };

  return (
    <div
      className="card bg-card dark:bg-card-dark text-text dark:text-text-dark cursor-pointer flex 
            hover:shadow-lg rounded-lg relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => setShowActions(!showActions)}
    >
      {uploadFileToggle && (
        <div className="absolute right-0 z-50  flex align-center items-center gap-10 w-full h-full">
          <div
            className="bg-muted p-5 rounded-full w-10 h-10 flex items-center justify-center cursor-pointer hover:bg-muted-dark"
            onClick={handleNewUpload}
          >
            <FontAwesomeIcon icon={faX} />
          </div>
          <div>
            <FileUploadPopUp
              taskId={taskId}
              toggleFileUpload={handleNewUpload}
              attachmentId={attachmentId}
            />
          </div>
        </div>
      )}
      <div className="flex w-40 relative">
        {state.state === "IN REVIEW" && (
          <div className="rounded-l-lg border-l-lg bg-warning w-1 h-full"></div>
        )}
        {state.state === "ISSUES FOUND" && (
          <div className="rounded-l-lg border-l-lg bg-red-500 w-1 h-full"></div>
        )}
        {state.state === "READY" && (
          <div className="rounded-l-lg border-l-lg bg-ready w-1 h-full"></div>
        )}
        <div className="flex p-2">
          {filetype === "PDF" && (
            <div className="mx-2 text-red-500 text-md">
              <FontAwesomeIcon icon={faFilePdf} />
            </div>
          )}
          {filetype === "Image" && (
            <div className="mx-2 text-purple-700 text-md">
              <FontAwesomeIcon icon={faFileImage} />
            </div>
          )}
          {filetype === "Audio" && (
            <div className="mx-2 text-cyan-800 text-md">
              <FontAwesomeIcon icon={faFileAudio} />
            </div>
          )}
          {filetype === "Excel" && (
            <div className="mx-2 text-green-500 text-md">
              <FontAwesomeIcon icon={faFileExcel} />
            </div>
          )}
          {filetype === "Word" && (
            <div className="mx-2 text-blue-500 text-md">
              <FontAwesomeIcon icon={faFileWord} />
            </div>
          )}
          <div className="me-2 h-full truncate max-w-24">{filename}</div>
        </div>
        {pendingSignatureState && (
          <div className="absolute -top-2 -right-1 bg-white shadow-md rounded-full p-1 text-xs text-blue-600 border-blue-400 border">
            <FontAwesomeIcon icon={faSignature} />
          </div>
        )}
        {pendingSignatureState === false && (
          <div className="absolute -top-2 -right-1 bg-white shadow-md rounded-full p-1 text-xs text-green-600 border-green-400 border">
            <FontAwesomeIcon icon={faSignature} />
          </div>
        )}
      </div>
      {hovered && !showActions && (
        <div
          className="tooltip absolute bg-card dark:bg-card-dark text-text dark:text-text-dark p-2 rounded shadow-lg text-xs max-w-80 min-w-full w-auto z-40 whitespace-nowrap"
          style={{ top: "120%", left: "50%", transform: "translateX(-50%)" }}
        >
          <div className="flex justify-between truncate">
            <p className="font-bold">File name: &nbsp;</p> {filename}
          </div>
          <div className="flex justify-between truncate">
            <p className="font-bold">File Type: &nbsp;</p> {filetype}
          </div>
          <div className="flex justify-between truncate">
            <p className="font-bold">Author: &nbsp;</p> {author}
          </div>
          <div className="flex justify-between truncate">
            <p className="font-bold">Created: &nbsp;</p> {createDate}
          </div>
          <div className="flex justify-between truncate">
            <p className="font-bold">Updated: &nbsp;</p> {lastUpdated}
          </div>
        </div>
      )}
      {showModal && (
        <FileActionModal
          onConfirm={confirmDelete}
          onCancel={() => setShowModal(false)}
          isLoading={isLoading}
        >
          Are you sure you want to delete this file?
        </FileActionModal>
      )}
      {showActions && (
        <div
          className="border absolute bg-white dark:bg-gray-700 text-black dark:text-white shadow-lg text-xs z-50"
          style={{
            top: "100%",
            left: "50%",
            transform: "translateX(-50%) translateY( 10%)",
          }}
        >
          <div
            onClick={handleView}
            className="flex items-center justify-start hover:bg-blue-500 hover:text-white cursor-pointer pr-5 p-2"
          >
            <FontAwesomeIcon icon={faEye} className="w-6" /> View
          </div>
          <div
            onClick={handleDownload}
            className="flex items-center justify-start mt-2 hover:bg-blue-500 hover:text-white cursor-pointer pr-5 p-2"
          >
            <FontAwesomeIcon icon={faDownload} className="w-6" /> Download
          </div>
          {canUserDeleteFile && (
            <div
              onClick={handleNewUpload}
              className="flex items-center justify-start mt-2 hover:bg-blue-500 hover:text-white cursor-pointer pr-5 p-2"
            >
              <FontAwesomeIcon icon={faArrowUpFromBracket} className="w-6" />{" "}
              New Draft
            </div>
          )}
          {state.state !== "COMPLETED" && canUserDeleteFile && (
            <div
              onClick={handleSignatureRequest}
              className="flex items-center justify-start mt-2 hover:bg-blue-500 hover:text-white cursor-pointer pr-5 p-2"
            >
              <FontAwesomeIcon icon={faSignature} className="w-6" /> Sign
            </div>
          )}
          {state.state !== "COMPLETED" && canUserDeleteFile && (
            <div
              onClick={handleDelete}
              className="flex items-center justify-start mt-2 hover:bg-red-500 hover:text-white cursor-pointer pr-5 p-2"
            >
              <FontAwesomeIcon icon={faTrash} className="w-6" /> Delete
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileCard;
