import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../firebase/AuthProvider";
import {
  approveTask,
  getTaskWorkflow,
  rejectTask,
} from "../../../../services/taskServices/TaskWorkflowServices";
import TaskWorkflow from "../../../../interfaces/task/workflow/TaskWorkflow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faBan,
  faCancel,
  faCheck,
  faCircleCheck,
  faCircleNotch,
  faCircleXmark,
  faClockRotateLeft,
  faForwardStep,
  faHourglassHalf,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import Select, { SingleValue } from "react-select";
import TaskApproval from "../../../../interfaces/task/workflow/TaskApproval";
import { formatDateToWords } from "../../../../utilities/dateUtils";
import LoadingSpinner from "../../../ui/Loading/LoadingSpinner";
import Banner from "../../../ui/Banner/Banner";
import DownloadButton from "../../../ui/Buttons/DownloadButton";

interface TaskWorkflowDiagramProps {
  taskId: string;
  isReady: boolean;
  status: string;
  reload: boolean;
  setReload: (anything: boolean) => void;
}
interface OptionType {
  value: string;
  label: string;
}

const TaskWorkflowDiagram: React.FC<TaskWorkflowDiagramProps> = ({
  taskId,
  isReady,
  status,
  reload,
  setReload,
}) => {
  const { currentUser, currentRoles } = useAuth();
  const [workflowItems, setWorkflowItems] = useState<TaskWorkflow[]>();
  const [rejectStepPrompt, setRejectStepPrompt] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assignmentOptions, setAssignmentOptions] = useState<OptionType[]>([]); // Adjusted type for ReactSelect
  const [assignee, setAssignee] = useState<TaskApproval | undefined>(); // Adjusted type for ReactSelect
  const [durationInMinutes, setDurationInMinutes] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  useEffect(() => {
    const fetchWorkflows = async () => {
      if (currentUser !== null && taskId !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedWorkflows = await getTaskWorkflow(taskId, authToken);
        return fetchedWorkflows;
      }
    };

    if (workflowItems !== null) {
      const idPromise = fetchWorkflows();

      idPromise.then((items) => {
        if (items !== null) {
          // Redirect to new task here.
          items?.sort((a, b) => a.stepOrder - b.stepOrder);
          setWorkflowItems(items);
        } else {
          console.error("Failed to create comment");
        }
      });
    }
  }, [currentUser, taskId]);

  const handleNextStep = async () => {
    setIsLoading(true);
    if (currentUser !== null) {
      const authToken = await currentUser.getIdToken();
      approveTask(taskId, authToken)
        .then((res) => {
          if (!res.success) {
            setError(`${res.message}`);
            setSuccessMessage("");
          } else {
            setSuccessMessage(`Task successfully approved!`);
            setError("");
            setReload(!reload);
          }
        })
        .catch((err) => {
          setSuccessMessage("");
          setError(`${err.message}`);
        })
        .finally(() => {});
    }
    setIsLoading(false);
  };

  const toggleRejectStepPrompt = () => {
    setRejectStepPrompt(!rejectStepPrompt);
  };

  const validateRejectFrom = () => {
    if (assignee === null || assignee === undefined) {
      setError("You must select a user to assign to on rejection.");
      return false;
    }
    return true;
  };

  const handleRejectStep = async () => {
    if (currentUser !== null && validateRejectFrom() && assignee) {
      const authToken = await currentUser.getIdToken();
      rejectTask(taskId, feedback, assignee?.id, durationInMinutes, authToken)
        .then((res) => {
          setReload(!reload);
        })
        .catch((err) => {
          console.log("Error:", err);
          setReload(!reload);
        })
        .finally(() => {});
    }
  };

  const handleFeedbackChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setFeedback(e.target.value);
  };

  const handleDurationInMinutesChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 0) {
      setDurationInMinutes(value);
    }
  };

  const handleAssignmentChange = (selectedOption: SingleValue<OptionType>) => {
    const assignee = selectedOption ? selectedOption.value : "";
    const newAssignee = workflowItems?.find(
      (wi) => wi.taskApproval?.id.toString() === assignee
    )?.taskApproval;
    if (newAssignee) {
      setAssignee(newAssignee);
    }
  };

  const filteredList = workflowItems?.filter(
    (obj) => obj.taskApproval?.approvalDate === undefined
  );
  const maxStepOrderObject =
    filteredList !== undefined && filteredList?.length > 0
      ? filteredList?.reduce((prev, current) =>
          prev.stepOrder < current.stepOrder ? prev : current
        )
      : undefined;

  const roleName = maxStepOrderObject?.role.name;
  const isInCurrentUserRoles = currentRoles?.some(
    (role) => role.name === roleName
  );
  const hasNullApprovalDate = workflowItems?.some((obj) => {
    const conditionMet =
      obj.stepOrder === 1 &&
      (!obj.taskApproval || obj.taskApproval.approvalDate === undefined);

    return conditionMet;
  });

  useEffect(() => {
    if (workflowItems !== undefined) {
      const maxId = workflowItems.reduce((max, obj) => {
        // Check if TaskApproval is defined
        if (obj.taskApproval !== undefined && obj.stepOrder > max) {
          return obj.stepOrder;
        }
        return max;
      }, 0);

      const maxIdMinusOne = workflowItems.find(
        (obj) => obj.stepOrder === maxId - 1
      )?.taskApproval;

      const previousApprovers = workflowItems.filter(
        (obj) => obj.stepOrder < maxId
      );

      const options = previousApprovers.map((item) => ({
        value: item?.taskApproval?.id?.toString() || "", // Ensure value is always a string
        label:
          item.taskApproval?.approver?.firstName &&
          item.taskApproval?.approver?.lastName
            ? `${item.taskApproval.approver.firstName} ${item.taskApproval.approver.lastName} (${item.role.name})`
            : "",
      }));

      if (maxIdMinusOne && options) {
        setAssignee(maxIdMinusOne);
        setAssignmentOptions(options);
      }
    }
  }, [workflowItems]);

  return (
    <div className="mb-5 w-full">
      <div className="my-4">
        {successMessage !== "" && (
          <Banner
            status="success"
            message={successMessage}
            dismissBanner={() => setSuccessMessage("")}
          />
        )}
        {error !== "" && (
          <Banner
            status="error"
            message={error}
            dismissBanner={() => setError("")}
          />
        )}
      </div>
      <div className="flex justify-evenly mx-3 text-sm w-full">
        <div className="w-full"></div>
        <div className="w-full flex">
          {workflowItems &&
            workflowItems.map((item, index) => (
              <div
                key={index}
                className="flex justify-start align-middle group relative"
              >
                <div className="relative border w-16 h-16 dark:text-text-dark shadow-lg rounded-full p-2 flex justify-center items-center align-middle">
                  <div>
                    <div className="text-xl text-center">
                      {item.role.name === "SC" && "Sc"}
                      {item.role.name === "SUPERVISOR" && "Sup"}
                      {item.role.name === "PROGRAM MANAGER" && "PM"}
                      {item.role.name === "OWNER" && "BOSS"}
                    </div>
                    {item.taskApproval?.approvalDate && (
                      <div className="rounded-full p-2 bg-green-600 w-5 h-5 flex justify-center items-center align-middle absolute bottom-[-10px] right-[21px]">
                        <div className="text-green-100 flex justify-center items-center align-middle text-center">
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </div>
                      </div>
                    )}
                    {item.taskApproval &&
                      !item.taskApproval.approvalDate &&
                      !item.taskApproval.rejected && (
                        <div className="rounded-full p-2 bg-yellow-600 w-5 h-5 flex justify-center items-center align-middle absolute bottom-[-10px] right-[21px]">
                          <div className="text-yellow-100 flex justify-center items-center align-middle text-center">
                            <FontAwesomeIcon icon={faClockRotateLeft} />
                          </div>
                        </div>
                      )}
                    {!item.taskApproval && (
                      <div className="rounded-full p-2 bg-gray-300 w-5 h-5 flex justify-center items-center align-middle absolute bottom-[-10px] right-[21px]">
                        <div className="text-gray-100 flex justify-center items-center align-middle text-center">
                          <FontAwesomeIcon icon={faBan} />
                        </div>
                      </div>
                    )}
                    {item.taskApproval?.rejected && (
                      <div className="rounded-full bg-red-600 w-5 h-5 flex justify-center items-center align-middle absolute bottom-[-10px] right-[21px]">
                        <div className="text-red-100">
                          <FontAwesomeIcon icon={faCircleXmark} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {item.stepOrder < workflowItems.length && (
                  <div className="my-auto mx-2 text-xl">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                )}
                <div className="absolute text-xs min-w-[200px] top-20 bg-gray-800 text-white px-2 py-1 rounded-md opacity-0 group-hover:opacity-80 transition-opacity duration-300">
                  <div>
                    {item.taskApproval?.approvalDate && (
                      <div>
                        <div>
                          Approved by{" "}
                          {item.approver?.firstName +
                            " " +
                            item.approver?.lastName}{" "}
                        </div>
                        <div className="text-[10px] text-muted-dark">
                          {formatDateToWords(item.taskApproval.approvalDate)}
                        </div>
                      </div>
                    )}
                    {item.taskApproval &&
                      !item.taskApproval.approvalDate &&
                      !item.taskApproval.rejected && (
                        <div className="text-center">
                          Assigned to{" "}
                          {item.approver?.firstName +
                            " " +
                            item.approver?.lastName}
                        </div>
                      )}
                    {item.taskApproval?.rejected && (
                      <div>
                        Rejected by{" "}
                        {item.approver?.firstName +
                          " " +
                          item.approver?.lastName}
                      </div>
                    )}
                    {!item.taskApproval && (
                      <div className="text-center text-muted-dark">
                        Approval not yet assigned
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
        {isInCurrentUserRoles ? (
          <div className="flex flex-wrap md:flex-nowrap justify-end items-end align-bottom text-end pe-5 gap-2 px-5 flex-col w-full">
            {!hasNullApprovalDate && (
              <div className="flex justify-end">
                <button
                  onClick={toggleRejectStepPrompt}
                  className="reject-button rounded-3xl text-xs flex justify-between items-center gap-2 w-24"
                >
                  <div className="ps-2 justify-self-center">Reject</div>
                  <div className="reject-button-icon flex items-center justify-center m-0.5 rounded-full bg-red-600 text-white px-1 w-6 h-6">
                    <FontAwesomeIcon className="text-sm" icon={faX} />
                  </div>
                </button>

                {rejectStepPrompt && (
                  <div className="absolute mt-2 border p-5 rounded-lg border-opacity-100 bg-white dark:bg-gray-800 remove-input-txt-border">
                    <div className="text-start">Assign to</div>
                    <Select
                      id="assignment"
                      name="assignment"
                      value={assignmentOptions.find(
                        (option) => option.value === assignee?.id.toString()
                      )}
                      onChange={handleAssignmentChange}
                      options={assignmentOptions}
                      className="your-custom-class text-start"
                      placeholder="Choose who to assign to..."
                      classNamePrefix="select"
                    />
                    <div className="text-start mt-3">
                      How long did it take you to review? (in minutes)
                    </div>
                    <input
                      id="durationInMinutes"
                      name="durationInMinutes"
                      placeholder="0"
                      type="number"
                      min="0"
                      value={durationInMinutes}
                      onChange={handleDurationInMinutesChange}
                      required
                      className="shadow resize-none border rounded w-full py-2 px-3 text-text dark:text-text leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <div className="text-start mt-3">Feedback</div>
                    <textarea
                      id="feedback"
                      name="feedback"
                      placeholder="Feedback..."
                      value={feedback}
                      onChange={handleFeedbackChange}
                      required
                      className="shadow resize-none border rounded w-full py-2 px-3 text-text dark:text-text leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <div className="flex justify-end mt-2">
                      <button
                        onClick={handleRejectStep}
                        className="bg-red-600 w-full rounded text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
                      >
                        Reject Task
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="flex flex-col md:flex-row justify-end w-full">
              {status !== "COMPLETED" && (
                <div className="flex justify-end">
                  {isReady && !isLoading && (
                    <button
                      className={`approve-button border-green-600 rounded-3xl text-xs flex justify-center items-center gap-2 w-24`}
                      onClick={handleNextStep}
                      disabled={isLoading} // Disables button when loading
                    >
                      <div className="ps-2">
                        {isLoading ? "Processing..." : "Approve"}
                      </div>
                      <div
                        className={`approve-button-icon flex items-center justify-center m-0.5 rounded-full px-1 w-6 h-6 ${
                          isLoading ? "loading-spinner" : ""
                        }`}
                      >
                        <FontAwesomeIcon
                          className="text-sm"
                          icon={isLoading ? faCircleNotch : faCheck}
                        />
                      </div>
                    </button>
                  )}

                  {isReady && isLoading && (
                    <button
                      disabled
                      type="button"
                      className="text-sm font-medium w-full text-center text-white bg-[#3DD598] rounded border border-gray-200 hover:bg-[#3DD598] focus:ring-2 focus:ring-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 inline-flex justify-center items-center"
                    >
                      <LoadingSpinner size="sm" w={5} h={5} />
                    </button>
                  )}

                  {!isReady && (
                    <button
                      disabled
                      className="text-gray-400 bg-gray-100 rounded-3xl text-xs flex justify-center items-center gap-2 w-full md:w-auto"
                    >
                      <div className="ps-2">Approve</div>
                      <div className="flex items-center justify-center m-0.5 rounded-full text-gray-400 bg-white px-1 w-6 h-6">
                        <FontAwesomeIcon className="text-sm" icon={faCancel} />
                      </div>
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-end w-full text-end align-center items-center pe-5 gap-2"></div>
        )}
      </div>
    </div>
  );
};

export default TaskWorkflowDiagram;
