import React, { useEffect, useState } from "react";
import StylizedLineChart, { TasksClosedData } from "../../../charts/LineChart";
import CardLineChart, {
  CardLineChartData,
} from "../../../charts/CardLineChart";
import "./styles/DashboardOverview.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendDown,
  faArrowTrendUp,
  faClock,
  faPerson,
  faThumbTack,
} from "@fortawesome/free-solid-svg-icons";
import PieChartComponent, {
  PieChartComponentData,
} from "../../../charts/PieChart";
import StylizedAreaChart from "../../../charts/LineChart";
import AdminTaskTable from "../../../tasks/AdminTaskTable";
import { useAuth } from "../../../../../firebase/AuthProvider";
import { fetchOrganizationTasksWithCache } from "../../../../../services/taskServices/TaskService";
import TaskList from "../../../../../interfaces/task/TaskList";
import AdminTasksTable from "./tasksComponent/AdminTasksTable";
import { getClosedTaskStatsForTeam } from "../../../../../services/statServices/OwnerStatsService";
import { ClosedTaskStatsForTeamResponse } from "../../../../../interfaces/stats/OwnerDashboardStats";
import StackedPillBarChart from "../../../analytics/tasksClosedInMonthChart/StackedPillBarChart";
import TasksClosedChart from "../../../analytics/tasksClosedInMonthChart/TasksClosedChart";

const DashboardOverview: React.FC = () => {
  const { currentUser } = useAuth();

  const [tasks, setTasks] = useState<TaskList[]>([]);

  // const chartData: CardLineChartData[] = [
  //   { label: "Week 1", value: 8 },
  //   { label: "Week 2", value: 12 },
  //   { label: "Week 3", value: 6 },
  //   { label: "Week 4", value: 15 },
  // ];
  const chartData2: CardLineChartData[] = [
    { label: "Week 1", value: 8 },
    { label: "Week 2", value: 12 },
    { label: "Week 3", value: 15 },
    { label: "Week 4", value: 3 },
  ];

  const closedData: PieChartComponentData[] = [
    { name: "Weekly", value: 14 },
    { name: "Monthly", value: 6 },
    { name: "Quarterly", value: 3 },
    { name: "Yearly", value: 8 },
    { name: "Interim", value: 12 },
  ];

  // Tasks open by type
  const openData: PieChartComponentData[] = [
    { name: "Weekly", value: 5 },
    { name: "Monthly", value: 10 },
    { name: "Quarterly", value: 2 },
    { name: "Yearly", value: 4 },
    { name: "Interim", value: 5 },
  ];

  const tasksClosedData = [
    { label: "Week 1", Alice: 8, Bob: 5, Charlie: 12 },
    { label: "Week 2", Alice: 10, Bob: 7, Charlie: 15 },
    { label: "Week 3", Alice: 12, Bob: 4, Charlie: 9 },
    { label: "Week 4", Alice: 14, Bob: 9, Charlie: 16 },
  ];

  // Sample: average time to close tasks (in hours) each week
  const averageCloseTimeData = [
    { label: "Week 1", Alice: 3.4, Bob: 5.2, Charlie: 4.1 },
    { label: "Week 2", Alice: 2.8, Bob: 5.9, Charlie: 3.7 },
    { label: "Week 3", Alice: 3.0, Bob: 4.5, Charlie: 2.9 },
    { label: "Week 4", Alice: 2.6, Bob: 4.2, Charlie: 3.1 },
  ];

  const employees = ["Bob", "Charlie"];
  interface UserTaskData {
    name: string;
    onTime: number;
    late: number;
  }


  
  return (
    <div className="blue-scrollbar">
      <h2 className="text-2xl">Blossom SCA Overview</h2>
        <TasksClosedChart/>
    </div>
  );
};

export default DashboardOverview;

{
  /* <div className="card-overviews">
        <div className="overall-task-closures overview-card relative">
          <div className="overview-card-info">
            <div className="icon">
              <FontAwesomeIcon icon={faThumbTack} />
            </div>
            <div>
              <div className="value">
                {chartData[chartData.length - 1].value}{" "}
                <span
                  className={`trend ${
                    chartData[chartData.length - 1].value -
                      chartData[chartData.length - 2].value >
                    0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  <FontAwesomeIcon
                    icon={
                      chartData[chartData.length - 1].value -
                        chartData[chartData.length - 2].value >
                      0
                        ? faArrowTrendUp
                        : faArrowTrendDown
                    }
                  />
                  {Math.abs(
                    chartData[chartData.length - 1].value -
                      chartData[chartData.length - 2].value
                  )}
                </span>
              </div>
              <div className="label">Tasks Closed</div>
            </div>
          </div>
          <div className="card-graph-container">
            <CardLineChart data={chartData} />
          </div>
        </div>
        <div className="overall-task-closures overview-card relative">
          <div className="overview-card-info">
            <div className="icon">
              <FontAwesomeIcon icon={faClock} />
            </div>
            <div>
              <div className="value">
                {chartData2[chartData2.length - 1].value}{" "}
                <span
                  className={`trend ${
                    chartData2[chartData2.length - 1].value -
                      chartData2[chartData2.length - 2].value <
                    0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  <FontAwesomeIcon
                    icon={
                      chartData2[chartData2.length - 1].value -
                        chartData2[chartData2.length - 2].value >
                      0
                        ? faArrowTrendUp
                        : faArrowTrendDown
                    }
                  />
                  {Math.abs(
                    chartData2[chartData2.length - 1].value -
                      chartData2[chartData2.length - 2].value
                  )}
                </span>
              </div>
              <div className="label">Time Spent</div>
            </div>
          </div>
          <div className="card-graph-container">
            <CardLineChart data={chartData2} invert />
          </div>
        </div>
        <div className="overall-task-closures overview-card relative">
          <div className="overview-card-info">
            <div className="icon">
              <FontAwesomeIcon icon={faPerson} />
            </div>
            <div>
              <div className="value">
                {chartData2[chartData2.length - 1].value}{" "}
                <span
                  className={`trend ${
                    chartData2[chartData2.length - 1].value -
                      chartData2[chartData2.length - 2].value >
                    0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  <FontAwesomeIcon
                    icon={
                      chartData2[chartData2.length - 1].value -
                        chartData2[chartData2.length - 2].value >
                      0
                        ? faArrowTrendUp
                        : faArrowTrendDown
                    }
                  />
                  {Math.abs(
                    chartData2[chartData2.length - 1].value -
                      chartData2[chartData2.length - 2].value
                  )}
                </span>
              </div>
              <div className="label">New Participants</div>
            </div>
          </div>
          <div className="card-graph-container">
            <CardLineChart data={chartData2} />
          </div>
        </div>
      </div> */
}

{
  /* <div className="flex gap-10 flex-wrap w-full justify-center">
        <div className="mt-5 gap-10 flex flex-col max-w-[40rem] w-full">
          <div className="w-full border p-2 rounded-lg">
            <h3>Tasks Closed</h3>
            <StylizedAreaChart
              data={tasksClosedData}
              employees={employees}
              height={300}
              width="100%"
            />
          </div>

          <div className="w-full border p-2 rounded-lg">
            <h3>Average Time to Close</h3>
            <StylizedAreaChart
              data={averageCloseTimeData}
              employees={employees}
              height={300}
              width="100%"
            />
          </div>
        </div>
        <div className="flex flex-col flex-wrap gap-2 max-w-[40rem] mt-5 items-center">
          <div
            style={{ flex: 1 }}
            className="max-w-80 min-w-56 bg-gray-50 rounded-lg p-2 border"
          >
            <h2>Tasks Closed by Type</h2>
            <PieChartComponent data={closedData} />
          </div>

          <div
            style={{ flex: 1 }}
            className="max-w-80 min-w-56 bg-gray-50 rounded-lg p-2 border"
          >
            <h2>Open Tasks by Type</h2>
            <PieChartComponent data={openData} />
          </div>
        </div>
      </div> */
}
