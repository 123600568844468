import React, { useState } from "react";
import { scdFormConfig, scdFormRules } from "./formsConfig";
import { WizardForm } from "./DynamicForm";
import JsonSyntaxHighlighter from "../../../utilities/formattedDisplayComponents/jsonSyntaxHighlighter";
import { useAuth } from "../../../firebase/AuthProvider";
import Banner from "../../ui/Banner/Banner";

export const FormPage: React.FC = () => {
  const { currentUser } = useAuth();
  const [selectedForm, setSelectedForm] = useState<string>("SCD_Form");
  const [completedData, setCompletedData] = useState<Record<
    string,
    any
  > | null>(null);

  // For showing banner messages
  const [banner, setBanner] = useState<{
    message: string;
    status: string;
  } | null>(null);

  // You can show/hide the banner using these
  const dismissBanner = () => setBanner(null);

  // Reusable function to do the PDF POST
  const postPdfRequest = async (data: Record<string, any>) => {
    if (!currentUser) {
      setBanner({
        status: "warning",
        message: "No current user is logged in.",
      });
      return null;
    }
  
    try {
      const authToken = await currentUser.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/form/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            pdfTemplateName: "SCD_Log.json",
            fields: data,
          }),
        }
      );
  
      if (!response.ok) {
        const error = await response.json();
        setBanner({
          status: "error",
          message: `Error: ${error?.errorMessage ?? "PDF generation failed."}`,
        });
        return null;
      }
  
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
  
      return { success: true };
    } catch (err: any) {
      setBanner({
        status: "error",
        message: `Unexpected error: ${err?.message}`,
      });
      return null;
    }
  };
  

  // Single attempt on form submit
  const handleFormSubmit = async (answers: Record<string, any>) => {
    setCompletedData(answers);

    const result = await postPdfRequest(answers);
    if (result && result.success) {
      // window.open(result.pdfUrl, "_blank");
    } else if (result && !result.success) {
      // If the server responded but success=false
      // setBanner({
      //   status: "error",
      //   message: `Error: ${result?.errorMessage ?? "PDF generation failed."}`,
      // });
    }
  };

  // Let the user re-submit with the same data by clicking "Refresh" button
  const handleRefreshPdf = async () => {
    if (!completedData) return; // No data to re-submit
    const result = await postPdfRequest(completedData);
    if (result && result.success) {
      // window.open(result.pdfUrl, "_blank");
    } else if (result && !result.success) {
      // setBanner({
      //   status: "error",
      //   message: `Error: ${result?.errorMessage ?? "PDF generation failed."}`,
      // });
    }
  };

  const currentFormConfig = scdFormConfig[selectedForm];
  const currentRulesConfig = scdFormRules[selectedForm];

  return (
    <div className="min-h-screen bg-[#121C3B] flex flex-col">
      <div className="flex w-full">
        {!completedData && currentFormConfig && currentRulesConfig && (
          <div className="w-full max-w-6xl">
            <WizardForm
              formConfig={currentFormConfig}
              rulesConfig={currentRulesConfig}
              onSubmit={handleFormSubmit}
            />
          </div>
        )}
      </div>

      {completedData && (
        <div className="bg-[#0F1730] p-4 text-white shadow-md rounded w-full max-w-3xl mt-10 overflow-auto mx-auto max-h-[85vh]">
          {/* BANNER at the top if needed */}
          {banner && (
            <div className="mb-5">
            <Banner
              message={banner.message}
              status={banner.status}
              dismissBanner={dismissBanner}
            />
            </div>
          )}
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-semibold">
              Thanks for completing the form!
            </h2>
            {/* "Refresh" button to try the PDF generation again */}
            <button
              onClick={handleRefreshPdf}
              className="bg-blue-700 hover:bg-blue-800 text-white px-3 py-1 rounded text-xs"
            >
              Refresh PDF
            </button>
          </div>
          <div className="max-h-[65vh] overflow-y-auto rounded blue-scrollbar text-xs">
            <JsonSyntaxHighlighter
              jsonString={JSON.stringify(completedData, null, 2)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
