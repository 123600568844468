import React, { useEffect, useMemo, useState } from "react";
import Select, { MultiValue, ActionMeta } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faX } from "@fortawesome/free-solid-svg-icons";
import { getTaskLKValues } from "../../../../../../services/taskServices/TaskLKService";
import Participant from "../../../../../../interfaces/Participant";
import Status from "../../../../../../interfaces/task/Status";
import { User } from "../../../../../../interfaces/User";
import { useAuth } from "../../../../../../firebase/AuthProvider";
import { getAllParticipants } from "../../../../../../services/participantServices/ParticipantService";

interface OptionType {
  value: string;
  label: string;
}

interface AdminTasksFilterProps {
  /** A callback the parent uses to refetch or apply filters. */
  onFilterChange: (filters: {
    status?: string[];
    type?: string[];
    date?: string[];
    assignees?: string[];
    participants?: string[];
  }) => void;

  /** Possible options for each filter. For example, loaded from server or defined in code. */
  assigneeOptions: OptionType[];
  participantOptions: OptionType[];
  typeOptions: OptionType[];
  statusOptions: OptionType[];
  dateOptions: OptionType[];
}

/**
 * A reusable filter UI for Admin Tasks, very similar to your TasksPageNew filters.
 * - Toggling the filter form with a "Filters" button.
 * - MultiValue selects with react-select.
 * - "Clear Filters" button sets all filters to empty arrays.
 */
const AdminTasksFilter: React.FC<AdminTasksFilterProps> = ({
  onFilterChange,
  assigneeOptions,
  participantOptions,
  typeOptions,
  statusOptions,
  dateOptions,
}) => {
    const {currentUser} = useAuth();
  const [toggleFilterForm, setToggleFilterForm] = useState<boolean>(false);

  // Local state for each filter
  const [assigneeFilter, setAssigneeFilter] = useState<MultiValue<OptionType>>([]);
  const [participantFilter, setParticipantFilter] = useState<MultiValue<OptionType>>([]);
  const [typeFilter, setTypeFilter] = useState<MultiValue<OptionType>>([]);
  const [statusFilter, setStatusFilter] = useState<MultiValue<OptionType>>([]);
  const [dateFilter, setDateFilter] = useState<MultiValue<OptionType>>([]);

  /** Whenever any filter changes, call onFilterChange so the parent can re-fetch or apply the filters */
  useEffect(() => {
    onFilterChange({
      assignees: assigneeFilter.map((o) => o.value),
      participants: participantFilter.map((o) => o.value),
      type: typeFilter.map((o) => o.value),
      status: statusFilter.map((o) => o.value),
      date: dateFilter.map((o) => o.value),
    });
  }, [assigneeFilter, participantFilter, typeFilter, statusFilter, dateFilter, onFilterChange]);

  // ---------------------
  // Handlers
  // ---------------------
  const handleAssigneeFilterChange = (newValue: MultiValue<OptionType>, _meta: ActionMeta<OptionType>) => {
    setAssigneeFilter(newValue);
    // Immediately apply them (or you could debounce):
  };

  const handleParticipantFilterChange = (newValue: MultiValue<OptionType>, _meta: ActionMeta<OptionType>) => {
    setParticipantFilter(newValue);
  };

  const handleTypeFilterChange = (newValue: MultiValue<OptionType>) => {
    setTypeFilter(newValue);
  };

  const handleStatusFilterChange = (newValue: MultiValue<OptionType>) => {
    setStatusFilter(newValue);
  };

  const handleDateFilterChange = (newValue: MultiValue<OptionType>) => {
    setDateFilter(newValue);
  };

  /** Clears all selected filters */
  const clearFilters = () => {
    setAssigneeFilter([]);
    setParticipantFilter([]);
    setTypeFilter([]);
    setStatusFilter([]);
    setDateFilter([]);
    // Then apply
    onFilterChange({});
  };

  return (
    <div className="relative">
        <div className="flex justify-between items-center">
          <button
            onClick={() => setToggleFilterForm((prev) => !prev)}
            className="flex gap-2 align-center items-center p-3 pe-5 shadow-sm my-2 ms-auto rounded-2xl bg-white text-muted border"
          >
            <div className="rounded-full bg-[#F5F5F5] w-6 h-6 text-xs flex items-center justify-center">
              <FontAwesomeIcon icon={faFilter} className="text-[10px]" />
            </div>
            <div className="text-sm">Filters</div>
          </button>
        </div>

        <div className="absolute rounded-3xl min-w-[500px] right-0 top-20">
          {toggleFilterForm && (
            <div className="remove-input-txt-border absolute -mt-5 right-0 min-w-[500px] border rounded-md shadow-lg ms-auto w-2/5 p-5 bg-card my-5 dark:bg-card-dark dark:text-text-dark z-30">
              <div className="flex justify-between align-center items-center">
                <div className="font-bold text-lg">Filters</div>
                <div
                  className="m-1 p-1 pe-2 bg-muted-dark rounded-3xl text-sm flex items-center align-middle gap-2 cursor-pointer text-[11px]"
                  onClick={clearFilters}
                >
                  <FontAwesomeIcon icon={faX} className="text-[11px] ps-2" />
                  <div className="text-xs">Clear Filters</div>
                </div>
              </div>

              {/* Assignee Filter */}
              <h3 className="inter-font text-text mt-3">Assignees</h3>
              <Select
                isMulti
                options={assigneeOptions}
                value={assigneeFilter}
                onChange={handleAssigneeFilterChange}
                placeholder="Filter by Assignee"
              />

              {/* Participant Filter */}
              <h3 className="inter-font text-text mt-3">Participants</h3>
              <Select
                isMulti
                options={participantOptions}
                value={participantFilter}
                onChange={handleParticipantFilterChange}
                placeholder="Filter by Participant"
              />

              {/* Type Filter */}
              <h3 className="inter-font text-text mt-3">Task Type</h3>
              <Select
                isMulti
                options={typeOptions}
                value={typeFilter}
                onChange={handleTypeFilterChange}
                placeholder="Filter by Type"
              />

              {/* Status Filter */}
              <h3 className="inter-font text-text mt-3">Task Status</h3>
              <Select
                isMulti
                options={statusOptions}
                value={statusFilter}
                onChange={handleStatusFilterChange}
                placeholder="Filter by Status"
              />

              {/* Relative Date Filter */}
              <h3 className="inter-font text-text mt-3">Task Relative Date</h3>
              <Select
                isMulti
                options={dateOptions}
                value={dateFilter}
                onChange={handleDateFilterChange}
                placeholder="Filter by Date"
              />
            </div>
          )}
        </div>
      </div>
  );
};

export default AdminTasksFilter;
