export function toTitleCase(input: string) {
  if (!input) return ""; // Handle null/undefined input
  return input
    .toLowerCase() // Convert the entire string to lower case
    .split(" ") // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
    .join(" "); // Join the words back into a string
}

// Extract first letters of each word for initials
export function getInitials(name: string): string {
  return name
    .split(/\s+/) // split on whitespace
    .map((word) => word[0]?.toUpperCase() || "")
    .join("");
}
