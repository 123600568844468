// WizardForm.tsx
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Select from "react-select";
import {
  computeAllRequiredFields,
  evaluateCondition,
  validateFormData,
} from "./RuleEngine";
import { useAuth } from "../../../firebase/AuthProvider";
import { IconDefinition, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchDynamicOptions } from "./formsConfig";
import { getParticipantInfo } from "../../../services/participantServices/ParticipantService";

interface WizardFormProps {
  formConfig: FormConfig; // the steps & questions
  rulesConfig: RulesConfig; // the advanced rule definitions
  onSubmit: (answers: Record<string, any>) => void;
}

type DynamicOptionsMap = Record<string, SelectOption[]>;
type SelectOption = { value: string; label: string };

export const WizardForm: React.FC<WizardFormProps> = ({
  formConfig,
  rulesConfig,
  onSubmit,
}) => {
  const { currentUser } = useAuth();
  const { steps } = formConfig;
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [dynamicOptions, setDynamicOptions] = useState<DynamicOptionsMap>({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);
  const closeSidebar = () => setIsSidebarOpen(false);

  // Example: run global validation whenever formData changes
  useEffect(() => {
    const ruleErrors = validateFormData(rulesConfig, formData);
    const localRequiredErrors = computeAllRequiredFields(steps, formData);
    const newErrors = { ...localRequiredErrors, ...ruleErrors };

    // Compare old vs new to avoid meaningless re-renders
    if (!shallowEqual(errors, newErrors)) {
      setErrors(newErrors);
    }
  }, [formData, rulesConfig, steps]);

  function shallowEqual<T extends Record<string, any>>(
    objA: T,
    objB: T
  ): boolean {
    // If they are literally the same reference, return true.
    if (objA === objB) {
      return true;
    }

    // If either is null/undefined (or they're different types), return false.
    if (
      !objA ||
      !objB ||
      typeof objA !== "object" ||
      typeof objB !== "object"
    ) {
      return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    // If they have different numbers of keys, they’re not equal.
    if (keysA.length !== keysB.length) {
      return false;
    }

    // Check each key’s value for strict equality at the top level.
    for (const key of keysA) {
      if (objA[key] !== objB[key]) {
        return false;
      }
    }

    return true;
  }

  interface StepBarProps {
    steps: WizardStep[];
    currentStepIndex: number;
    errors: Record<string, string>;
    onStepClick?: (idx: number) => void;
  }

  function VerticalStepBar({
    steps,
    currentStepIndex,
    errors,
    onStepClick,
  }: StepBarProps) {
    return (
      <div className="relative flex flex-col items-start">
        <div className="relative z-10 flex flex-col gap-10">
          {/* Single vertical timeline line */}
          <div className="absolute right-5 top-0 bottom-0 w-px bg-gray-700 z-0" />

          {steps.map((step, idx) => {
            const isActive = idx === currentStepIndex;
            const stepHasError = step.questions.some((q) => errors[q.id]);
            const isCompleted = idx < currentStepIndex && !stepHasError;

            return (
              <div
                key={step.label}
                className="relative flex items-center gap-4 pl-2 cursor-pointer"
                onClick={() => onStepClick?.(idx)}
              >
                {/* Left text block */}
                <div className="w-32 text-right pr-4">
                  <p className="text-sm font-semibold text-white">
                    {step.label}
                  </p>
                  <p className="text-xs text-gray-400">
                    {isCompleted ? "Completed" : "Pending"}
                  </p>
                </div>

                {/* Icon bubble */}
                <div
                  className={`
                    w-10 h-10 rounded-full flex items-center justify-center transition-colors
                    ${
                      isCompleted
                        ? "bg-[#33DD5C]"
                        : isActive
                        ? "bg-blue-600"
                        : "bg-gray-700"
                    }
                    ${stepHasError ? "ring-2 ring-red-500" : ""}
                  `}
                >
                  <FontAwesomeIcon
                    icon={step.icon ?? faBars}
                    className="text-white w-4 h-4"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  useEffect(() => {
    let isMounted = true;

    async function loadAllDynamicSources() {
      const allQuestions = formConfig.steps.flatMap((step) => step.questions);
      for (const q of allQuestions) {
        if (q.dynamicSource && currentUser) {
          const authToken = await currentUser.getIdToken();
          const data = await fetchDynamicOptions(q.dynamicSource, authToken);
          if (data && isMounted) {
            setDynamicOptions((prev) => ({
              ...prev,
              [q.id]: data,
            }));
          }
        }
      }
    }

    loadAllDynamicSources();
    return () => {
      isMounted = false;
    };
  }, [formConfig, currentUser]);

  const handleNext = async () => {
    // Make the function async
    if (currentStepIndex < steps.length - 1) {
      // Navigate to the next step if not on the last step
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      // --- FINAL SUBMISSION LOGIC ---
      // Check for validation errors first
      if (Object.keys(errors).length > 0) {
        console.warn("Submission blocked due to validation errors:", errors);
        alert("Please fix the errors before submitting.");
        return; // Stop submission if errors exist
      }

      // Check for authenticated user
      if (!currentUser) {
        console.error("User not authenticated. Cannot submit.");
        alert("Authentication error. Please log in again.");
        return; // Stop if no user
      }

      // Start loading state for submission process
      setIsSubmitting(true);
      console.log("Starting final submission process...");
      console.log("Initial formData:", JSON.parse(JSON.stringify(formData))); // Log initial data

      try {
        // Get fresh auth token
        const authToken = await currentUser.getIdToken();
        // Create a mutable copy of formData to potentially enrich or modify
        const enrichedFormData = { ...formData }; // Use this copy for all modifications
        // Get all questions from the config
        const allQuestions = formConfig.steps.flatMap((step) => step.questions);
        // Array to hold all the data fetching promises for dynamic sources
        const fetchPromises: Promise<void>[] = [];

        // --- Step 1: Handle Dynamic Source Data Fetching ---
        allQuestions.forEach((q) => {
          if (q.dynamicSource && enrichedFormData[q.id] != null) {
            const source = q.dynamicSource;
            const selectedIdOrIds = enrichedFormData[q.id];
            console.log(
              `Found dynamic field: ${q.id}, source: ${source}, value: ${selectedIdOrIds}`
            );

            // --- Handle Single Select Dynamic Field ---
            if (!q.isMulti && typeof selectedIdOrIds === "string") {
              const promise = (async () => {
                try {
                  console.log(
                    `Workspaceing full data for ${source} with ID: ${selectedIdOrIds}`
                  );
                  let fetchedData: any = null;
                  switch (source) {
                    case "participants":
                      fetchedData = await getParticipantInfo(
                        selectedIdOrIds,
                        authToken
                      );
                      break;
                    // Add other cases
                    default:
                      console.warn(
                        `No fetcher defined for dynamic source: ${source}`
                      );
                  }
                  if (fetchedData) {
                    console.log(
                      `Successfully fetched data for ${q.id}:`,
                      fetchedData
                    );
                    enrichedFormData[q.id] = fetchedData; // Update the copy
                  } else {
                    console.warn(
                      `No data found for ${source} with ID: ${selectedIdOrIds}. Keeping ID.`
                    );
                  }
                } catch (fetchError) {
                  console.error(
                    `Error fetching data for ${q.id} (ID: ${selectedIdOrIds}):`,
                    fetchError
                  );
                }
              })();
              fetchPromises.push(promise);
            }
            // --- Handle Multi Select Dynamic Field ---
            else if (
              q.isMulti &&
              Array.isArray(selectedIdOrIds) &&
              selectedIdOrIds.length > 0
            ) {
              console.warn(
                `Multi-select fetch-on-submit for ${q.id} requires a bulk fetch API.`
              );
              // Add bulk fetch logic here if needed, pushing promise to fetchPromises
            }
          }
        });

        // Wait for all dynamic data fetching to complete
        await Promise.all(fetchPromises);
        console.log("Dynamic data fetching complete.");
        console.log(
          "FormData after fetching:",
          JSON.parse(JSON.stringify(enrichedFormData))
        );

        // --- Step 2: Apply Prefixes ---
        console.log("Applying prefixes...");
        allQuestions.forEach((q) => {
          const fieldId = q.id;
          // Check if it's a text field, has a prefix defined, and the current value is a string
          if (
            q.type === "text" && // Ensure it's a text input
            q.prefix && // Ensure prefix property exists and is not empty
            typeof enrichedFormData[fieldId] === "string" // Crucially, ensure the value is a string
          ) {
            console.log(
              `Applying prefix "${q.prefix}" to field "${fieldId}". Old value: "${enrichedFormData[fieldId]}"`
            );
            // Prepend the prefix to the existing string value in the copy
            enrichedFormData[fieldId] = q.prefix + enrichedFormData[fieldId];
            console.log(
              `New value for "${fieldId}": "${enrichedFormData[fieldId]}"`
            );
          }
        });
        console.log("Prefix application complete.");

        // --- Step 3: Final Submission ---
        console.log(
          "Final enriched/prefixed formData:",
          JSON.parse(JSON.stringify(enrichedFormData))
        ); // Log final data
        onSubmit(enrichedFormData); // Call the original onSubmit prop with the fully processed data
      } catch (error) {
        console.error("Error during submission data preparation:", error);
        alert(
          "An error occurred while preparing your submission. Please try again."
        );
      } finally {
        setIsSubmitting(false); // Ensure loading state is turned off
      }
    }
  };

  function handlePrev() {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  }

  const handleInputChange = useCallback(
    (questionId: string) => (val: any) => {
      setFormData((prev) => ({ ...prev, [questionId]: val }));
    },
    []
  );

  return (
    <div className="bg-[#121C3B] min-h-screen">
      {/* 1) MOBILE HEADER: Hamburger Icon */}
      {/* Display only on mobile */}
      {isSubmitting && (
        <div className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          <div className="text-white text-xl animate-pulse">Submitting...</div>
          {/* Optional: Add a more sophisticated spinner component */}
        </div>
      )}
      <header className="lg:hidden p-2 bg-[#121C3B]">
        <button
          onClick={toggleSidebar}
          className="text-white hover:text-gray-200 focus:outline-none"
        >
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
      </header>

      {/* 2) DESKTOP WRAPPER (flex on desktop, block on mobile) */}
      <div className="flex flex-col lg:flex-row">
        {/* 3) SIDEBAR */}
        <aside
          className={`
            z-40
            w-64
            bg-[#0F1730]
            text-white
            overflow-y-auto
            p-4
            lg:p-6
            transform
            transition-transform
            duration-300

            /* ---------- MOBILE OVERLAY ---------- */
            fixed top-0 bottom-0 left-0  /* remove from normal flow on mobile */
            ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"}

            /* ---------- DESKTOP STICKY ---------- */
            lg:static          /* becomes normal flow child on desktop */
            lg:sticky
            lg:top-0
            lg:h-screen
            lg:translate-x-0   /* no sliding on desktop */
          `}
        >
          <VerticalStepBar
            steps={steps}
            currentStepIndex={currentStepIndex}
            errors={errors}
            onStepClick={(idx) => {
              setCurrentStepIndex(idx);
              closeSidebar(); // close automatically on mobile
            }}
          />
        </aside>

        {/* 4) BACKDROP (mobile only) */}
        {/* Renders behind the sidebar if open on mobile */}
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
            onClick={closeSidebar}
          />
        )}

        {/* 5) MAIN CONTENT */}
        <main className="flex-1 p-4 sm:p-6 md:p-8 lg:p-10">
          <div className="max-w-3xl mx-auto">
            <StepPanel
              step={steps[currentStepIndex]}
              currentStepIndex={currentStepIndex}
              totalSteps={steps.length}
              formData={formData}
              errors={errors}
              isSubmitting={isSubmitting}
              onChange={handleInputChange}
              onNext={handleNext}
              onPrev={handlePrev}
              dynamicOptions={dynamicOptions}
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export function isQuestionVisible(
  q: QuestionnaireItem,
  data: Record<string, any>
): boolean {
  if (q.showIf) {
    for (const cond of q.showIf) {
      if (!evaluateCondition(cond, data)) return false;
    }
  }
  if (q.hideIf) {
    for (const cond of q.hideIf) {
      if (evaluateCondition(cond, data)) return false;
    }
  }
  return true;
}

/** Convert any string[] to {value,label}[], or leave it alone if it’s already object[] */
function normalizeOptions(
  input: Array<string | SelectOption> | undefined
): SelectOption[] {
  if (!Array.isArray(input) || input.length === 0) {
    return [];
  }

  if (typeof input[0] === "string") {
    return (input as string[]).map((str) => ({
      value: str,
      label: str,
    }));
  }

  // Already formatted correctly
  return input as SelectOption[];
}
function renderInput(
  q: QuestionnaireItem,
  value: any,
  onChange: (val: any) => void,
  dynamicOptions: DynamicOptionsMap
) {
  const dynamic = dynamicOptions[q.id];
  const staticOptions = q.options;

  // Memoize options for react-select
  // const finalOptions = useMemo(() => {
  //   if (dynamic && dynamic.length > 0) return dynamic;
  //   return normalizeOptions(staticOptions);
  // }, [dynamic, staticOptions]);

  switch (q.type) {
    case "text":
      return (
        <input
          type="text"
          className={`
          w-full 
          rounded-md 
          px-4 
          py-2 
          bg-[#0F1730] 
          focus:bg-[#1F2A53] 
          text-white 
          border 
          border-gray-600 
          placeholder-gray-400
          hover:border-[#33DD5C]
          ${q.disabled ? "italic hover:border-gray-600" : ""}
          focus:outline-none      
          focus:ring-0               
          focus:border-[#33DD5C]     
        `}
          disabled={q.disabled ? q.disabled : false}
          placeholder={q.placeholder ? q.placeholder : "Enter text here..."}
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
        />
      );
    case "paragraph": {
      const initialRows = 3;
      const maxRows = 10;

      // --- Estimate max height in pixels ---
      // This is less accurate than using getComputedStyle within a component's effect.
      // Adjust these values based on your actual CSS (font-size, line-height, padding, border)
      const estimatedLineHeight = 24; // Approx pixels per line (e.g., 1.5rem for 16px base)
      const verticalPadding = 16; // Approx pixels for padding-top + padding-bottom (py-2 -> 8px + 8px)
      const borderHeight = 2; // Approx pixels for border-top + border-bottom
      const estimatedMaxHeight =
        maxRows * estimatedLineHeight + verticalPadding + borderHeight;

      // --- Event handler for resizing ---
      const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = event.target;

        // 1. Temporarily reset height to allow browser to calculate scrollHeight
        textarea.style.height = "auto";

        // 2. Get the scroll height needed for the content
        const scrollHeight = textarea.scrollHeight;

        // 3. Apply the new height, but cap it at the estimated max height
        textarea.style.height = `${Math.min(
          scrollHeight,
          estimatedMaxHeight
        )}px`;

        // 4. IMPORTANT: Call the original onChange passed from the parent component
        //    to update the actual form state.
        onChange(textarea.value);
      };

      // Estimate min height for Tailwind class (visual consistency)
      const minHeightRem = initialRows * 1.5; // Adjust 1.5 based on your line height

      return (
        <textarea
          rows={initialRows} // Gives browser initial height hint
          className={`
              w-full
              rounded-md
              px-4
              py-2
              bg-[#0F1730]
              focus:bg-[#1F2A53]
              text-white
              border
              border-gray-600
              placeholder-gray-400
              hover:border-[#33DD5C]
              focus:outline-none
              focus:ring-0
              focus:border-[#33DD5C]
              ${
                q.disabled
                  ? "italic hover:border-gray-600 cursor-not-allowed"
                  : ""
              }
              overflow-y-auto  /* Show scrollbar if content exceeds height */
              resize-none      /* Disable manual resizing handle */
              block            /* Ensure proper layout */
              min-h-[${minHeightRem}rem] /* Tailwind class for min-height */
            `}
          style={{
            // Height is now controlled dynamically ONLY by the handleInput function.
            // Setting an explicit style height here would interfere.
            // Ensure box-sizing: border-box; is applied globally or on this element
            // if your max-height calculations need to be very precise.
            boxSizing: "border-box",
          }}
          disabled={q.disabled ?? false}
          placeholder={q.placeholder ?? "Enter text here..."}
          value={value || ""}
          // Use onInput for immediate feedback while typing.
          // The handleInput function takes care of resizing AND calling the original onChange.
          onInput={handleInput}
          // Note: We don't need a separate onChange if onInput handles calling the passed onChange.
        />
      );
    }
    case "radio":
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {q.options?.map((opt: any) => {
            const selected = value === opt;
            return (
              <div
                key={opt}
                onClick={() => onChange(opt)}
                className={`
                  cursor-pointer rounded-lg px-4 py-3 border text-sm
                  ${
                    selected
                      ? "bg-[#0F1730] border-[#33DD5C] text-white"
                      : "bg-[#0F1730] border-gray-600 text-gray-300"
                  }
                  hover:border-[#33DD5C] transition-colors
                `}
              >
                {opt}
              </div>
            );
          })}
        </div>
      );

    case "react-select": {
      let finalOptions: SelectOption[] = [];

      if (dynamic && dynamic.length > 0) {
        finalOptions = dynamic;
      } else {
        finalOptions = normalizeOptions(staticOptions);
      }

      return (
        <div className="react-select-wrapper remove-input-txt-border text-[#0F1730]">
          <Select
            isMulti={q.isMulti}
            options={finalOptions}
            value={
              q.isMulti
                ? finalOptions.filter((o) => (value || []).includes(o.value))
                : finalOptions.find((o) => o.value === value) || null
            }
            onChange={(selected) => {
              if (q.isMulti) {
                onChange((selected as SelectOption[]).map((s) => s.value));
              } else {
                onChange(selected ? (selected as SelectOption).value : null);
              }
            }}
            styles={{
              control: (base, state) => ({
                ...base,
                backgroundColor: "#1F2A53",
                color: "#fff",
                borderColor: state.isFocused ? "#33DD5C" : "#444",
                boxShadow: state.isFocused ? "0 0 0 2px #33DD5C" : "none",
                "&:hover": {
                  borderColor: "#33DD5C",
                },
              }),
              singleValue: (base) => ({
                ...base,
                color: "#fff",
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: "#0F1730",
                color: "#fff",
              }),
              multiValueLabel: (base) => ({
                ...base,
                color: "#fff", // Dark readable text
                fontWeight: 600,
              }),
              multiValueRemove: (base) => ({
                ...base,
                color: "white",
                ":hover": {
                  backgroundColor: "crimson",
                  color: "white",
                },
              }),
              input: (base) => ({
                ...base,
                color: "#fff",
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: "#1F2A53",
                color: "#fff",
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected
                  ? "#0F1730"
                  : state.isFocused
                  ? "#2F3A65"
                  : "#1F2A53",
                color: state.isSelected ? "#fff" : "#fff",
                ":active": {
                  backgroundColor: "#0F1730",
                },
              }),
              placeholder: (base) => ({
                ...base,
                color: "#ccc",
              }),
            }}
          />
        </div>
      );
    }

    case "time":
      return (
        <input
          type="time"
          className="w-full rounded-md px-4 py-2 bg-[#1F2A53] text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-[#33DD5C] placeholder-gray-400"
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
        />
      );

    case "date":
      return (
        <input
          type="date"
          className="w-full 
          hover:border-[#33DD5C]
          rounded-md px-4 py-2 bg-[#1F2A53] text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-[#33DD5C]"
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
        />
      );

    case "datetime":
      return (
        <input
          type="datetime-local"
          className="w-full 
          hover:border-[#33DD5C]
          rounded-md px-4 py-2 bg-[#1F2A53] text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-[#33DD5C] placeholder-gray-400"
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
        />
      );

    case "checkbox":
      return (
        <label className="inline-flex items-center space-x-2 text-sm text-white">
          <input
            type="checkbox"
            checked={!!value}
            onChange={(e) => onChange(e.target.checked)}
          />
          <span>{q.label}</span>
        </label>
      );

    default:
      return null;
  }
}

interface StepPanelProps {
  step: WizardStep;
  currentStepIndex: number;
  totalSteps: number;
  formData: Record<string, any>;
  errors: Record<string, string>;
  onChange: (questionId: string) => (val: any) => void;
  onNext: () => void;
  onPrev: () => void;
  dynamicOptions: DynamicOptionsMap;
  isSubmitting: boolean;
}

function StepPanel({
  step,
  currentStepIndex,
  totalSteps,
  formData,
  errors,
  onChange,
  onNext,
  onPrev,
  dynamicOptions,
  isSubmitting,
}: StepPanelProps) {
  // Filter out any questions hidden by showIf/hideIf logic
  // const visibleQuestions = step.questions.filter((q) =>
  //   isQuestionVisible(q, formData)
  // );

  const visibleQuestions = step.questions.filter((q) =>
    isQuestionVisible(q, formData)
  );

  return (
    <div
      className={`
    bg-[#0F1730] text-text-dark shadow-lg rounded-xl p-8 transition-all duration-300
  `}
    >
      <div className="mb-4">
        <p className="text-sm text-gray-500">
          Step {currentStepIndex + 1} / {totalSteps}
        </p>
        <h2 className="text-xl font-semibold">{step.label}</h2>
        <p className="text-sm text-gray-500 mt-2">
          {/* Some instructions or sub-heading if needed */}
          Please fill in the required details below.
        </p>
      </div>

      <div className="space-y-4">
        {visibleQuestions.map((q) => {
          // const isVisible = isQuestionVisible(q, formData);

          const val = formData[q.id];
          const err = errors[q.id] || "";

          return (
            <div key={q.id}>
              <label className="block font-medium mb-1">
                {q.label}
                {q.validation?.required && (
                  <span className="text-red-500 ml-1">*</span>
                )}
              </label>
              {renderInput(
                q,
                val,
                onChange(q.id), // ✅ use pre-memoized function
                dynamicOptions
              )}

              {err && <div className="text-red-500 text-sm mt-1">{err}</div>}
            </div>
          );
        })}
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between mt-8">
        {currentStepIndex > 0 && (
          <button
            type="button"
            className="bg-transparent border border-gray-500 text-gray-300 px-6 py-2 rounded-xl hover:bg-gray-700"
            onClick={onPrev}
            disabled={isSubmitting}
          >
            Back
          </button>
        )}
        <button
          type="button"
          className="bg-[#26cd85] text-text-dark font-semibold px-6 py-2 rounded-xl hover:bg-[#26cd85ad] transition"
          onClick={onNext}
          disabled={isSubmitting}
        >
          {isSubmitting
            ? "Submitting..."
            : currentStepIndex < totalSteps - 1
            ? "Next Step"
            : "Complete"}
        </button>
      </div>
    </div>
  );
}

export type QuestionType =
  | "text"
  | "checkbox"
  | "radio"
  | "react-select"
  | "date"
  | "time"
  | "datetime"
  | "paragraph";

// For "showIf"/"hideIf" logic
export interface Condition {
  field: string; // which field in formData to check
  operator: string; // e.g. "EQUALS", "INCLUDES", etc.
  value: any;
}

export interface QuestionnaireItem {
  id: string;
  label: string;
  type: QuestionType;
  options?: Array<string | { value: string; label: string }>; // For select, radio, etc.
  isMulti?: boolean; // For react-select multi
  showIf?: Condition[];
  placeholder?: string;
  prefix?: string;
  dynamicSource?: "participants";
  disabled?: boolean;
  hideIf?: Condition[];
  // Minimal built-in validations (e.g. required)
  validation?: {
    required?: boolean;
  };
}

/** Each step in the wizard has multiple questions */
export interface WizardStep {
  id: string;
  label: string;
  icon?: IconDefinition; // ✅ change to icon object
  questions: QuestionnaireItem[];
}

/** The full config for a form (wizard steps + optional rule set). 
    We keep them separate below, but you can unify if you prefer. */
export interface FormConfig {
  formId: string;
  steps: WizardStep[];
}

/** The building blocks of our rule engine. */

export interface ConditionDefinition {
  field: string; // e.g. "contactType"
  operator: string; // e.g. "INCLUDES", "EQUALS", etc.
  value: any;
}

export interface CheckDefinition {
  /** either specify an operator-based check or a special type like "TIME_DIFF" */
  type?: string;
  field?: string;
  operator?: string;
  value?: any;
  startField?: string; // for TIME_DIFF, etc.
  endField?: string;
  minMinutes?: number;
}

export interface ValidationRule {
  id: string;
  description?: string;
  when?: ConditionDefinition[]; // Conditions that must be met for rule to apply
  checks: CheckDefinition[]; // The actual checks that must pass
  errorMessage: string;
  errorField?: string; // which field to highlight for the error
}

export interface RulesConfig {
  formId: string;
  validationRules: ValidationRule[];
}
