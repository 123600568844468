import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import {
  fetchSignatureRequestByToken,
  fillSignatureBox,
  submitSignatureRequest,
} from "../../../../services/fileServices/signatures/SignatureService";
import { SignatureRequest } from "../../../../interfaces/file/fileSignature/SignatureRequest";
import {
  SignatureBox,
  SignatureType,
} from "../../../../interfaces/file/fileSignature/SignatureBox";
import { getFileByIdByToken } from "../../../../services/fileServices/FileService";
import Banner from "../../../ui/Banner/Banner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faFont,
  faICursor,
  faSignature,
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { getInitials } from "../../../../utilities/stringUtils";
import { getTodayDateString } from "../../../../utilities/dateUtils";
import "./PDFViewer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// Minimal Step 1: Asking user name
const EnterNameStep: React.FC<{
  tempName: string;
  setTempName: (val: string) => void;
  onContinue: () => void;
  errorMessage: string;
}> = ({ tempName, setTempName, onContinue, errorMessage }) => {
  return (
    <div className="min-w-100 mx-auto flex justify-center items-center min-h-screen bg-[#F7F9FC]">
      <div className="bg-white p-6 rounded shadow-md">
        <h2 className="text-xl font-bold mb-4">Welcome!</h2>
        <p className="mb-2">Please enter your name to begin signing:</p>
        <input
          type="text"
          placeholder="Your Name"
          value={tempName}
          onChange={(e) => setTempName(e.target.value)}
          className="border p-2 w-full"
        />
        {errorMessage && (
          <div className="mt-2 text-red-600 text-sm">{errorMessage}</div>
        )}
        <button
          onClick={onContinue}
          className="mt-4 bg-blue-600 text-white px-4 py-2 rounded shadow hover:bg-blue-700"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

// Minimal Step 2: Disclosure
const DisclosureAgreement: React.FC<{ onAgree: () => void }> = ({
  onAgree,
}) => {
  const [checked, setChecked] = useState<boolean>(false);
  return (
    <div className="min-w-100 mx-auto flex justify-center items-center min-h-screen bg-[#F7F9FC]">
      <div className="bg-white p-6 rounded shadow-md max-w-md">
        <h2 className="text-xl font-bold mb-4">
          Electronic Signature Disclosure
        </h2>
        <p className="text-sm">
          By signing this document electronically, you agree that your
          electronic signature is the legal equivalent of your handwritten
          signature...
        </p>
        <div className="mt-3 flex items-center gap-2">
          <input
            type="checkbox"
            id="agreeCheckbox"
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
          <label htmlFor="agreeCheckbox" className="text-sm">
            I agree to the terms above
          </label>
        </div>
        <button
          disabled={!checked}
          onClick={onAgree}
          className={`mt-4 px-4 py-2 rounded ${
            checked
              ? "bg-blue-600 text-white hover:bg-blue-700"
              : "bg-gray-300 text-gray-600 cursor-not-allowed"
          }`}
        >
          Continue to Sign
        </button>
      </div>
    </div>
  );
};

const GuestSignPage: React.FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  // Steps: 1) name, 2) disclosure, 3) sign doc
  const [tempName, setTempName] = useState("");
  const [userName, setUserName] = useState(""); // once they confirm
  const [agreedToDisclosure, setAgreedToDisclosure] = useState(false);

  // Request & PDF data
  const [requestData, setRequestData] = useState<SignatureRequest | null>(null);
  const [fileBlobUrl, setFileBlobUrl] = useState<string>("");
  const [numPages, setNumPages] = useState<number>(0);

  // UI states
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // PDF page size tracking
  const [pageSizes, setPageSizes] = useState<
    Record<number, { width: number; height: number }>
  >({});

  // "Active" or "Selected" box for the side panel
  const [selectedBoxId, setSelectedBoxId] = useState<string | null>(null);
  const [showTextModal, setShowTextModal] = useState(false);
  const [textModalValue, setTextModalValue] = useState("");
  const [activeSignatureBox, setActiveSignatureBox] =
    useState<SignatureBox | null>(null);

  // For expanding/collapsing side sections
  const [activeNav, setActiveNav] = useState<string | null>("signature-boxes");

  // Zoom factor
  const [scale, setScale] = useState(1.0);

  const pdfContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log("showTextModal:", showTextModal);
  }, [showTextModal]);

  // Load request by token
  useEffect(() => {
    if (!token) {
      setErrorMessage("No token provided");
      return;
    }
    (async () => {
      try {
        const data = await fetchSignatureRequestByToken(token);
        if (!data) {
          setErrorMessage("Invalid or expired signature request");
          return;
        }
        setRequestData(data);

        const fileUrl = await getFileByIdByToken(token);
        if (!fileUrl) {
          setErrorMessage("Cannot load PDF file");
          return;
        }
        setFileBlobUrl(fileUrl);
      } catch (err) {
        console.error(err);
        setErrorMessage("Failed to load signature request");
      }
    })();
  }, [token]);

  // PDF loaded
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const onPageRenderSuccess = (page: any) => {
    const viewport = page.getViewport({ scale });
    setPageSizes((prev) => ({
      ...prev,
      [page.pageNumber]: { width: viewport.width, height: viewport.height },
    }));
  };

  // // Signing
  // const handleSignBox = async (box: SignatureBox) => {
  //   if (!requestData) return;
  //   // Basic check: only sign boxes assigned to this user
  //   if (
  //     box.assignedSignerEmail.toLowerCase() !==
  //     requestData.signerEmail.toLowerCase()
  //   ) {
  //     setErrorMessage("You are not authorized to sign this field.");
  //     return;
  //   }
  //   const signatureToApply = userName;
  //   const success = await fillSignatureBox(token!, box.id, signatureToApply);
  //   if (success) {
  //     // Update local state
  //     setRequestData((prev) => {
  //       if (!prev) return prev;
  //       const updatedBoxes = prev.signatureBoxes.map((b) =>
  //         b.id === box.id ? { ...b, signatureValue: signatureToApply } : b
  //       );
  //       return { ...prev, signatureBoxes: updatedBoxes };
  //     });
  //     setSuccessMessage(`Field signed!`);
  //   } else {
  //     setErrorMessage("Error signing field.");
  //   }
  // };

  // Submit entire request
  const handleSubmitSignatures = async () => {
    try {
      const success = await submitSignatureRequest(token!);
      if (success) {
        setSuccessMessage("Your signatures have been submitted. Thank you!");
        // Possibly navigate away or show a "thank you" screen
        // navigate("/");
      } else {
        setErrorMessage("Error submitting your signatures.");
      }
    } catch (err) {
      console.error(err);
      setErrorMessage("Error submitting signatures.");
    }
  };

  // Step 1: Enter Name
  if (!userName) {
    const handleNameContinue = () => {
      if (tempName.trim() !== "") {
        setUserName(tempName.trim());
      } else {
        setErrorMessage("Please enter a valid name.");
      }
    };
    return (
      <EnterNameStep
        tempName={tempName}
        setTempName={setTempName}
        onContinue={handleNameContinue}
        errorMessage={errorMessage}
      />
    );
  }

  // Step 2: Disclosure
  if (!agreedToDisclosure) {
    return <DisclosureAgreement onAgree={() => setAgreedToDisclosure(true)} />;
  }

  // Step 3: Main signing UI (similar to PDFEditor layout)
  // If still loading data...
  if (!requestData || !fileBlobUrl) {
    return <div>Loading...</div>;
  }

  // Possibly "toggle" side panel sections
  const toggleNav = (sectionName: string) => {
    if (activeNav === sectionName) {
      setActiveNav(null);
    } else {
      setActiveNav(sectionName);
    }
  };

  // ...
  // Utility to fill a box's signatureValue, then call your existing fillSignatureBox service
  const fillBoxValue = async (box: SignatureBox, value: string) => {
    const success = await fillSignatureBox(token!, box.id, value);
    if (!success) {
      setErrorMessage("Error signing field.");
      return false;
    }
    // If success, update local state
    setRequestData((prev) => {
      if (!prev) return prev;
      const updatedBoxes = prev.signatureBoxes.map((b) =>
        b.id === box.id ? { ...b, signatureValue: value } : b
      );
      return { ...prev, signatureBoxes: updatedBoxes };
    });
    return true;
  };

  const handleSignBox = async (box: SignatureBox) => {
    if (!requestData) return;
    // Only allow signing if the box is assigned to this signer
    if (
      box.assignedSignerEmail.toLowerCase() !==
      requestData.signerEmail.toLowerCase()
    ) {
      setErrorMessage("You are not authorized to fill this field.");
      return;
    }

    // If the box already has a value, maybe do nothing or allow re-edit:
    if (box.signatureValue && box.signatureValue.trim() !== "") {
      // Optionally allow re-edit here.
      return;
    }

    try {
      // Decide the new value based on boxType
      const type = box.boxType.name; // or just box.boxType if stored as a string/enum
      let newValue = "";

      switch (type) {
        case SignatureType.SIGNATURE:
          // Full name
          newValue = userName;
          break;

        case SignatureType.INITIALS:
          newValue = getInitials(userName);
          break;

        case SignatureType.DATE:
          newValue = getTodayDateString();
          break;

        case SignatureType.TEXT: {
          // Instead of using window.prompt, open a modal pop-up.
          setActiveSignatureBox(box);
          setShowTextModal(true);
          return; // Exit here so the modal can handle the input.
        }

        default:
          console.warn("Unknown box type: ", type);
          return;
      }

      // For non-TEXT types, proceed to fill the box.
      const success = await fillBoxValue(box, newValue);
      if (success) {
        setSuccessMessage("Field filled successfully!");
      }
    } catch (err) {
      console.error(err);
      setErrorMessage("Error filling field.");
    }
  };

  // Called when the user clicks "Submit" in the modal.
  const handleTextModalSubmit = async () => {
    if (!activeSignatureBox) return;
    const typedText = textModalValue.trim();
    if (!typedText) {
      // Optionally, show an error or simply cancel.
      setShowTextModal(false);
      return;
    }

    try {
      const success = await fillBoxValue(activeSignatureBox, typedText);
      if (success) {
        setSuccessMessage("Field filled successfully!");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Error filling field.");
    }
    // Reset modal state.
    setShowTextModal(false);
    setActiveSignatureBox(null);
    setTextModalValue("");
  };

  const handleTextModalCancel = () => {
    console.log("clicked cancel");
    console.log(showTextModal);
    setShowTextModal(false);
    setActiveSignatureBox(null);
    setTextModalValue("");
  };

  const handleSelectBox = (boxId: string) => {
    setSelectedBoxId(boxId);
    scrollToBox(boxId);
  };

  // We'll define a scrollToBox function:
  const scrollToBox = (boxId: string) => {
    console.log("Called here");
    // 1) find the box element
    const boxElement = document.getElementById(`box-${boxId}`);
    if (!boxElement || !pdfContainerRef.current) {
      console.log("Oh no! We couldn't find it!");
      return;
    }

    // 2) measure how far boxElement is from the top of the container
    const containerTop = pdfContainerRef.current.getBoundingClientRect().top;
    const elTop = boxElement.getBoundingClientRect().top;
    const scrollOffset =
      elTop - containerTop + pdfContainerRef.current.scrollTop - 50;

    pdfContainerRef.current.scrollTo({
      top: scrollOffset,
      behavior: "smooth",
    });
  };

  // find the selected box in requestData
  const selectedBox = requestData.signatureBoxes.find(
    (b) => b.id === selectedBoxId
  );

  return (
    <div className="relative bg-[#F7F9FC] sm:min-w-[640px] overflow-y-auto h-screen w-full">
      {/* Top background area */}
      <div className="h-[300px] w-full bg-navBackground rounded-b-3xl"></div>

      {/* Main container */}
      <div
        className="relative -top-64 bg-white rounded-3xl shadow-lg w-11/12 mx-auto 
          min-h-[600px] -mt-10 -mb-48"
      >
        {/* Top bar */}
        <div className="bg-white border-b flex justify-between items-center px-4 py-3 rounded-t-3xl">
          <div className="flex gap-4 items-center">
            <button
              onClick={() => navigate("/")}
              className="border px-3 py-1 rounded-full hover:bg-gray-100"
            >
              X
            </button>
            <h1 className="text-xl font-bold">Sign Document</h1>
          </div>
          <div className="flex gap-2">
            <button
              onClick={handleSubmitSignatures}
              className="bg-blue-600 text-white px-4 py-2 rounded"
            >
              Submit
            </button>
          </div>
        </div>

        {/* Banners for errors / successes */}
        <div className="mx-6 mt-2">
          {errorMessage && (
            <Banner
              status="error"
              message={errorMessage}
              dismissBanner={() => setErrorMessage("")}
            />
          )}
          {successMessage && (
            <Banner
              status="success"
              message={successMessage}
              dismissBanner={() => setSuccessMessage("")}
            />
          )}
        </div>

        <div className="flex gap-4">
          {/* LEFT SIDE: PDF + Zoom Controls */}
          <div className="w-full flex flex-col justify-center items-center align-middle">
            {/* Zoom controls */}
            <div className="flex gap-2 mb-4 justify-end">
              <label htmlFor="scale" className="text-sm">
                Zoom:
              </label>
              <input
                type="range"
                id="scale"
                min="0.5"
                max="2.0"
                step="0.1"
                value={scale}
                onChange={(e) => setScale(parseFloat(e.target.value))}
              />
              <span className="text-sm">{scale.toFixed(1)}x</span>
            </div>

            {/* PDF Rendering */}
            <div
              style={{
                border: "1px solid #ccc",
                maxHeight: "70vh", // or whatever you want
                overflowY: "auto",
              }}
              className="pdfViewer relative w-full flex flex-col justify-start items-center overflow-y-auto bg-gray-50"
              ref={pdfContainerRef}
            >
              <Document
                file={fileBlobUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                className="bg-gray-100 max-h-svh"
              >
                {Array.from({ length: numPages }, (_, index) => {
                  const pageNumber = index + 1;
                  const pageSize = pageSizes[pageNumber] || {
                    width: 0,
                    height: 0,
                  };
                  return (
                    <div
                      key={pageNumber}
                      style={{ position: "relative", marginBottom: "20px" }}
                      className=""
                    >
                      <Page
                        pageNumber={pageNumber}
                        renderTextLayer={false}
                        scale={scale}
                        onRenderSuccess={onPageRenderSuccess}
                        className="bg-gray-50"
                      />

                      {requestData.signatureBoxes
                        .filter((b) => b.pageNumber === pageNumber)
                        .map((box) => {
                          // Are we the assigned signer? Is it already signed?
                          const isAssigned =
                            box.assignedSignerEmail.toLowerCase() ===
                            requestData.signerEmail.toLowerCase();
                          const isSigned =
                            !!box.signatureValue &&
                            box.signatureValue.trim() !== "";

                          // Scale the coordinates
                          const scaledX = box.x * (scale / 1.2);
                          const scaledY = box.y * (scale / 1.2);
                          const scaledW = box.width * (scale / 1.2);
                          const scaledH = box.height * (scale / 1.2);

                          return (
                            <div
                              key={box.id}
                              id={`box-${box.id}`}
                              style={{
                                position: "absolute",
                                left: scaledX,
                                top: scaledY,
                                width: scaledW,
                                height: scaledH,
                                border: "1px solid",
                                borderColor: isSigned ? "blue" : "orange",
                                backgroundColor: isSigned
                                  ? "#cdddff"
                                  : "rgba(255, 165, 0, 0.3)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: isAssigned ? "pointer" : "default",
                                boxShadow:
                                  selectedBoxId === box.id
                                    ? "0 0 6px 2px rgba(0, 153, 255, 0.8)"
                                    : "none",
                              }}
                              onClick={() => {
                                if (!isSigned && isAssigned) {
                                  // sign the box
                                  handleSignBox(box);
                                  setSelectedBoxId(box.id);
                                }
                              }}
                            >
                              {/* Modal pop-up for TEXT signature type */}
                              {showTextModal && (
                                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                                  <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-80">
                                    <h3 className="text-lg font-semibold mb-4">
                                      Enter Text
                                    </h3>
                                    <input
                                      type="text"
                                      value={textModalValue}
                                      onChange={(e) =>
                                        setTextModalValue(e.target.value)
                                      }
                                      className="w-full p-2 border rounded mb-4"
                                      placeholder="Enter text for this field"
                                    />
                                    <div className="flex justify-end space-x-2">
                                      <button
                                        type="button"
                                        onClick={handleTextModalCancel}
                                        className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        onClick={handleTextModalSubmit}
                                        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="p-1 flex items-center gap-2 text-sm">
                                {box.boxType.name === SignatureType.DATE && (
                                  <FontAwesomeIcon icon={faCalendarDays} />
                                )}
                                {box.boxType.name ===
                                  SignatureType.SIGNATURE && (
                                  <FontAwesomeIcon icon={faSignature} />
                                )}
                                {box.boxType.name ===
                                  SignatureType.INITIALS && (
                                  <FontAwesomeIcon icon={faFont} />
                                )}
                                {box.boxType.name === SignatureType.TEXT && (
                                  <FontAwesomeIcon icon={faICursor} />
                                )}
                                {isSigned ? (
                                  <span>{box.signatureValue}</span>
                                ) : (
                                  <span className="text-xs italic">
                                    {isAssigned ? (
                                      "Click to Sign"
                                    ) : (
                                      <div>
                                        {!box.signatureValue ? (
                                          <span className="text-xs italic">
                                            {box.assignedSignerEmail}
                                          </span>
                                        ) : (
                                          <span>{box.signatureValue}</span>
                                        )}
                                      </div>
                                    )}
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              </Document>
            </div>
          </div>

          {/* RIGHT SIDE: Panel with "Signature Boxes" or other info */}
          <div className="w-64 border-l bg-white rounded-br-3xl">
            <div className="border-b p-4">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleNav("document")}
              >
                <h2 className="font-bold">Document</h2>
                <FontAwesomeIcon
                  icon={
                    activeNav === "document" ? faChevronDown : faChevronRight
                  }
                />
              </div>
              {activeNav === "document" && (
                <div className="mt-2 text-sm text-gray-600">
                  <div>
                    <strong>Filename: </strong>
                    {requestData.fileId}
                  </div>
                  <div>
                    <strong>Assigned Signer: </strong>
                    {requestData.signerEmail}
                  </div>
                </div>
              )}
            </div>
            <div className="p-4 border-b">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleNav("signature-boxes")}
              >
                <h2 className="font-bold">Signature Boxes</h2>
                <FontAwesomeIcon
                  icon={
                    activeNav === "signature-boxes"
                      ? faChevronDown
                      : faChevronRight
                  }
                />
              </div>
              {activeNav === "signature-boxes" && (
                <div className="mt-2">
                  {requestData.signatureBoxes.map((box) => {
                    const isSigned =
                      !!box.signatureValue && box.signatureValue.trim() !== "";
                    return (
                      <div
                        key={box.id}
                        onClick={() => handleSelectBox(box.id)}
                        className={`p-2 mb-1 border rounded cursor-pointer overflow-hidden text-ellipsis ${
                          box.id === selectedBoxId ? "bg-blue-100" : ""
                        }`}
                      >
                        <div className="flex items-center gap-2 text-sm truncate line-clamp-1">
                          {/* Show appropriate icon */}
                          {box.boxType.name === SignatureType.DATE && (
                            <FontAwesomeIcon
                              className="w-6"
                              icon={faCalendarDays}
                            />
                          )}
                          {box.boxType.name === SignatureType.SIGNATURE && (
                            <FontAwesomeIcon
                              className="w-6"
                              icon={faSignature}
                            />
                          )}
                          {box.boxType.name === SignatureType.INITIALS && (
                            <FontAwesomeIcon className="w-6" icon={faFont} />
                          )}
                          {box.boxType.name === SignatureType.TEXT && (
                            <FontAwesomeIcon className="w-6" icon={faICursor} />
                          )}
                          <span>
                            {box.assignedSignerEmail}
                            {isSigned ? " (signed)" : " (unsigned)"}
                          </span>
                        </div>
                      </div>
                    );
                  })}

                  {requestData.signatureBoxes.length === 0 && (
                    <div className="text-xs text-gray-500">
                      No fields to sign.
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestSignPage;
