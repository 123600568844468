// Dashboard.tsx
import React, { useEffect } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import Tasks from "../tasks/Tasks";
import AdminDashboard from "./AdminDashboard";
import { useNavigate } from "react-router-dom";

const Dashboard: React.FC = () => {
  const { currentRoles } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentRoles) {
      currentRoles.map((role) => {
        if (role.name === 'OWNER') { 
          navigate('/dashboard/owner')
        }
      })
    }
  }, [currentRoles]);

  return (
    <div className="relative bg-background dark:bg-background-dark sm:min-w-[640px] overflow-x-hidden max-w-full flex-grow flex-wrap">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
        <div className="relative flex flex-wrap flex-grow bg-[#F7F9FC] dark:bg-[#141416] h-auto">
          <div className="relative mx-auto min-h-full flex flex-wrap flex-grow h-auto -top-40">
            <Tasks />
          </div>
        </div>
    </div>
  );
};

export default Dashboard;