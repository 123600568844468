import React, { useEffect, useState } from "react";
import { getAttachmentsByTaskId } from "../../../../services/taskServices/TaskAttachmentService";
import TaskAttachment from "../../../../interfaces/task/TaskFiles/TaskAttachment";
import { getAttachmentRequirementsByTaskId } from "../../../../services/taskServices/TaskAttachmentRequirementsService";
import FileCard from "../../drive/File/FileCard";
import FileUpload from "../../fileUploadComponent/FileUpload";
import FormCard from "../../drive/File/FormCard";
import { useAuth } from "../../../../firebase/AuthProvider";
import { useNavigate } from "react-router-dom";
import { TaskRequiresAttachment } from "../../../../interfaces/task/TaskFiles/TaskRequiresAttachment";
import { formatDateToWords } from "../../../../utilities/dateUtils";

interface TaskAttachmentsProps {
  taskId: string;
  refreshTaskActivity: () => void;
  canUserDeleteFile: boolean;
}

const TaskAttachments: React.FC<TaskAttachmentsProps> = ({
  taskId,
  refreshTaskActivity,
  canUserDeleteFile,
}) => {
  const { currentUser, currentRoles } = useAuth();
  const navigate = useNavigate();

  const [selectedRequiredFile, setSelectedRequiredFile] =
    useState<TaskRequiresAttachment>();
  const [reload, setReload] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<TaskAttachment[] | null>([]);
  const [attachmentRequirements, setAttachmentRequirements] = useState<
    TaskRequiresAttachment[] | null
  >([]);

  const setSelectedRequirement = (file: TaskRequiresAttachment) => {
    if (selectedRequiredFile !== file) {
      setSelectedRequiredFile(file);
    } else {
      setSelectedRequiredFile(undefined);
    }
  };

  const toggleFileUpload = (success: boolean) => {
    setSelectedRequiredFile(undefined);
    if (success) {
      setReload(!reload);
      refreshTaskActivity();
    }
  };

  useEffect(() => {
    if (taskId !== undefined && currentUser !== null) {
      const fetchTaskAttachments = async () => {
        const authToken: string = await currentUser.getIdToken();
        const fetchedAttachments = await getAttachmentsByTaskId(
          taskId,
          authToken
        );
        setAttachments(fetchedAttachments);
      };
      const fetchTaskAttachmentRequirements = async () => {
        const authToken: string = await currentUser.getIdToken();
        const fetchedAttachmentRequirements =
          await getAttachmentRequirementsByTaskId(taskId, authToken);
        setAttachmentRequirements(fetchedAttachmentRequirements);
      };

      fetchTaskAttachments();
      fetchTaskAttachmentRequirements();
    }
  }, [taskId, reload]);

  const navigateToNewRequirementPage = () => {
    navigate(`addRequiredFile`);
  };

  const reloadFn = () => {
    setReload(!reload);
  };

  console.log(attachments)

  return (
    <div>
      {((attachments && attachments.length > 0) ||
        (attachmentRequirements && attachmentRequirements?.length > 0)) && (
        <div>
          <div className="flex mt-5">
            <h4 className="text-base text-text inline inter-font">
              Attachments
            </h4>
          </div>
          <div className="my-2 bg-background dark:bg-background-dark dark:text-text-dark p-5 rounded-lg  mx-2">
            {selectedRequiredFile && (
              <FileUpload
                toggleFileUpload={toggleFileUpload}
                taskId={taskId}
                attachment={selectedRequiredFile}
              />
            )}

            <div>
              {attachmentRequirements && attachmentRequirements.length > 0 && (
                <div className="my-4">
                  <div className="text-xs mb-2 block underline">
                    Required Files{" "}
                    {currentRoles?.some((role) => role.name === "ADMIN") && (
                      <button
                        className="text-blue-700 ms-2"
                        onClick={navigateToNewRequirementPage}
                      >
                        Add/Remove
                      </button>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-3">
                    {attachmentRequirements &&
                      attachmentRequirements.length > 0 &&
                      attachmentRequirements?.map((attachment, index) => (
                        <div
                          onClick={() => setSelectedRequirement(attachment)}
                          key={index}
                          tabIndex={100}
                        >
                          <FormCard
                            id={attachment.id.toString()}
                            filename={attachment.templateFile.fileName}
                            filetype={attachment.templateFile.type.type}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              )}
              <div className="my-4">
                <div className="text-xs mb-2 underline">Attached Files</div>
                <div className="flex flex-wrap gap-y-4">
                  {attachments?.map((attachment, index) => (
                    <div key={index} className="mx-1">
                      {attachment.pendingSignatureRequest}
                      <FileCard
                        id={attachment.file.id}
                        attachmentId={attachment.id}
                        reload={reloadFn}
                        filename={attachment.file.filename}
                        pendingSignatureState={attachment.pendingSignatureRequest}
                        location={
                          attachment.file.location
                            ? attachment.file.location
                            : ""
                        }
                        author={
                          attachment.file.author.firstName +
                          " " +
                          attachment.file.author.lastName
                        }
                        lastUpdated={formatDateToWords(
                          attachment.file.updateDate
                        )}
                        createDate={formatDateToWords(
                          attachment.file.createDate
                        )}
                        state={attachment.state}
                        filetype={attachment.file.filetype.type}
                        canUserDeleteFile={canUserDeleteFile}
                        taskId={taskId}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskAttachments;
