import React, { ChangeEvent, useEffect, useState } from "react";
import { useAuth } from "../../../../firebase/AuthProvider";
import TaskAction from "../../../../interfaces/task/taskActions/TaskAction";
import { getChatsForTask } from "../../../../services/taskServices/TaskCommentsService";
import { getTimeAgo } from "../../../../utilities/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUser,
  faPaperPlane,
  faSmile,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { createCommentOnTaskId } from "../../../../services/taskServices/TaskActionService";
import Avatar from "../../../../interfaces/avatar/Avatar";
import { getAvatarsFromStorage } from "../../../../services/fileServices/FileService";
import UserInfo from "../../../../interfaces/UserInfo";
import { getAllUsersInOrganization } from "../../../../services/userServices/UserInfoService";
import {
  TaskCommentMention,
  TaskCommentRequest,
} from "../../../../interfaces/taskAction/TaskCommentRequest";
import EmojiPicker, {
  EmojiItem,
  emojiData,
} from "../../emojiPicker/EmojiPicker";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../ui/Loading/LoadingSpinner";

interface TaskChatComponentProps {
  taskId: string;
}

const TaskChatComponent: React.FC<TaskChatComponentProps> = ({ taskId }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [chats, setChats] = useState<TaskAction[]>([]);
  const [comment, setComment] = useState<string>("");
  const [reload, setReload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [avatars, setAvatars] = useState<Avatar[] | null>([]);
  const [isTagging, setIsTagging] = useState<boolean>(false);
  const [tagSearch, setTagSearch] = useState<string>("");
  const [users, setUsers] = useState<UserInfo[] | null>([]);
  const [suggestedUsers, setSuggestedUsers] = useState<UserInfo[]>([]);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0); // Track the highlighted user
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [savedRange, setSavedRange] = useState<Range | null>(null);
  const [isEmojiTagging, setIsEmojiTagging] = useState<boolean>(false);
  const [emojiTagSearch, setEmojiTagSearch] = useState<string>("");
  const [suggestedEmojis, setSuggestedEmojis] = useState<EmojiItem[]>([]);
  const [highlightedEmojiIndex, setHighlightedEmojiIndex] = useState<number>(0);

  const [taggedEntities, setTaggedEntities] = useState<TaskCommentMention[]>(
    []
  );

  const commentRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchTaskChats = async () => {
      if (taskId && currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedChats = await getChatsForTask(taskId, authToken);
        if (fetchedChats) {
          setChats(fetchedChats);
        }
      }
    };
    fetchTaskChats();
  }, [taskId, reload]);

  useEffect(() => {
    const fetchAvatars = async () => {
      const storedAvatars = getAvatarsFromStorage();
      setAvatars(storedAvatars);
    };

    const fetchUsers = async () => {
      if (currentUser) {
        const authToken = await currentUser.getIdToken();
        const fetchedUsers = await getAllUsersInOrganization(authToken);
        setUsers(fetchedUsers);
      }
    };

    fetchAvatars();
    fetchUsers();
  }, [currentUser]);

  const submitComment = async () => {
    setIsLoading(true);
    if (
      commentRef.current &&
      commentRef.current.innerText.trim() !== "" &&
      currentUser !== null
    ) {
      // Use the new function to get the message content without HTML tags
      const messageContent = getMessageContent(commentRef.current);

      // Extract mentions from the content
      const newTaggedEntities: TaskCommentMention[] = [];
      const regex = /{user:(.+?)}/g;
      let match;
      while ((match = regex.exec(messageContent)) !== null) {
        const userId = match[1];
        newTaggedEntities.push({
          mentionedEntityType: "user",
          mentionedEntityId: userId,
        });
      }

      // Update taggedEntities state
      setTaggedEntities(newTaggedEntities);

      const commentData: TaskCommentRequest = {
        taskId: parseInt(taskId),
        description: messageContent, // Message content with placeholders
        mentions: newTaggedEntities,
      };

      const createComment = async () => {
        const authToken: string = await currentUser.getIdToken();
        const createdCommentId = await createCommentOnTaskId(
          commentData,
          authToken
        );
        return createdCommentId;
      };

      const idPromise = createComment();

      idPromise.then((id) => {
        if (id !== null && commentRef.current) {
          commentRef.current.innerHTML = ""; // Clear the content
          setTaggedEntities([]); // Reset tagged entities
          setReload(!reload);
        } else {
          console.error("Failed to create comment");
        }
      });
    }
    setIsLoading(false);
  };

  const handleEmojiClick = (emoji: string) => {
    insertEmojiAtCursor(emoji);
    setShowEmojiPicker(false);
  };

  function insertEmojiAtCursor(emoji: string) {
    if (commentRef.current) {
      // Focus the contentEditable div
      commentRef.current.focus();
      const sel = window.getSelection();

      let range;
      if (savedRange) {
        range = savedRange;
      } else if (sel?.rangeCount) {
        range = sel.getRangeAt(0);
      }

      if (range) {
        range.deleteContents();
        const textNode = document.createTextNode(emoji);
        range.insertNode(textNode);

        // Move the cursor after the inserted emoji
        range.setStartAfter(textNode);
        range.collapse(true);
        sel?.removeAllRanges();
        sel?.addRange(range);

        // Update the savedRange
        setSavedRange(range.cloneRange());

        // Update comment state
        setComment(getMessageContent(commentRef.current));
      }
    }
  }

  function saveSelection() {
    const sel = window.getSelection();
    if (sel && sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      let container = range.commonAncestorContainer;
      if (commentRef.current?.contains(container)) {
        // Save the selection range
        setSavedRange(range.cloneRange());
      }
    }
  }

  const handleInput = () => {
    if (commentRef.current) {
      const sel = window.getSelection();
      const range = sel?.getRangeAt(0);

      if (range) {
        const container = range.startContainer;
        const offset = range.startOffset;

        // Combine text nodes if necessary
        const textBeforeCaret = container.textContent?.slice(0, offset) || "";

        // Emoji Tagging Detection
        const lastColon = textBeforeCaret.lastIndexOf(":");
        const lastSpace = textBeforeCaret.lastIndexOf(" ");

        if (lastColon > lastSpace && lastColon >= 0) {
          const search = textBeforeCaret.slice(lastColon + 1).toLowerCase();

          if (search.trim() && search !== emojiTagSearch) {
            setIsEmojiTagging(true);
            setEmojiTagSearch(search);

            // Filter the emojiData based on the search query
            const filteredEmojis = emojiData.filter((item) =>
              item.keywords.some((keyword) =>
                keyword.toLowerCase().includes(search.toLowerCase())
              )
            );
            setSuggestedEmojis(filteredEmojis);
            setHighlightedEmojiIndex(0);
          }
        } else {
          setIsEmojiTagging(false);
          setSuggestedEmojis([]);
        }

        // Ensure we only trigger suggestions after the most recent '@'
        const lastAtSymbol = textBeforeCaret.lastIndexOf("@");

        // Check if the latest '@' is valid and not followed by a space
        if (lastAtSymbol > lastSpace && lastAtSymbol >= 0) {
          const search = textBeforeCaret.slice(lastAtSymbol + 1).toLowerCase();

          // Trigger tagging only if we're typing a new tag
          if (search.trim() && search !== tagSearch) {
            setIsTagging(true);
            setTagSearch(search);

            const filteredUsers = users?.filter(
              (user) =>
                user.displayName.toLowerCase().startsWith(search) ||
                user.firstName.toLowerCase().startsWith(search) ||
                user.lastName.toLowerCase().startsWith(search)
            );
            setSuggestedUsers(filteredUsers || []);
            setHighlightedIndex(0);
          }
        } else {
          setIsTagging(false);
          setSuggestedUsers([]);
        }

        // Update comment state using the new function
        setComment(getMessageContent(commentRef.current));

        // Parse mentions to update taggedEntities
        const tempMessageContent = getMessageContent(commentRef.current);
        const newTaggedEntities: TaskCommentMention[] = [];

        const regex = /{user:(.+?)}/g;
        let match;
        while ((match = regex.exec(tempMessageContent)) !== null) {
          const userId = match[1];
          if (!newTaggedEntities.some((m) => m.mentionedEntityId === userId)) {
            newTaggedEntities.push({
              mentionedEntityType: "user",
              mentionedEntityId: userId,
            });
          }
        }

        setTaggedEntities(newTaggedEntities);
      }
    }
  };

  const handleTagSelection = (userId: string, displayName: string) => {
    const tagPlaceholder = `{user:${userId}}`;
    const tagHtml = `<span contenteditable="false" class="bg-blue-100 text-blue-700 rounded shadow p-0.5" data-tag="${tagPlaceholder}" spellcheck="false">${displayName}</span>&nbsp;`;
    const sel = window.getSelection();

    if (sel?.rangeCount && commentRef.current) {
      const range = sel.getRangeAt(0);
      const container = range.startContainer;

      if (container.nodeType === Node.TEXT_NODE) {
        const textNode = container as Text;
        const textContent = textNode.textContent || "";

        // Find the position of the last '@' symbol in the text before the caret
        const cursorPosition = range.startOffset;
        const textBeforeCursor = textContent.slice(0, cursorPosition);
        const lastAtSymbol = textBeforeCursor.lastIndexOf("@");

        if (lastAtSymbol >= 0) {
          // Create a new range to select from the last '@' symbol up to the cursor
          const newRange = document.createRange();
          newRange.setStart(textNode, lastAtSymbol);
          newRange.setEnd(textNode, cursorPosition);

          // Delete the selected text (i.e., '@us')
          newRange.deleteContents();

          // Now, insert the mention at the new cursor position
          const el = document.createElement("div");
          el.innerHTML = tagHtml;

          const frag = document.createDocumentFragment();
          let node;
          let lastNode: Node | null = null;
          while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node);
          }

          // Insert the fragment at the cursor position
          const currentRange = sel.getRangeAt(0);
          currentRange.insertNode(frag);

          // Move the cursor after the inserted content
          if (lastNode) {
            currentRange.setStartAfter(lastNode);
            currentRange.collapse(true);
            sel.removeAllRanges();
            sel.addRange(currentRange);

            // Save the new selection range
            setSavedRange(currentRange.cloneRange());
          }

          // Update the comment state
          setComment(getMessageContent(commentRef.current));

          // Reset the tagging state
          setTagSearch("");
          setIsTagging(false);
          setSuggestedUsers([]);
        }
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const sel = window.getSelection();
    if (sel?.rangeCount && sel.isCollapsed) {
      const range = sel.getRangeAt(0);
      const container = range.startContainer;
      const offset = range.startOffset;

      if (e.key === "Backspace") {
        if (container.nodeType === Node.TEXT_NODE) {
          const textNode = container as Text;
          if (offset === 0) {
            // Check if previous sibling is a mention
            if (
              textNode.previousSibling &&
              textNode.previousSibling.nodeType === Node.ELEMENT_NODE
            ) {
              const prevElement = textNode.previousSibling as HTMLElement;
              if (prevElement.getAttribute("data-tag")) {
                e.preventDefault();
                prevElement.remove();

                // Update the comment state
                if (commentRef.current) {
                  setComment(getMessageContent(commentRef.current));
                }

                // Remove the corresponding user ID from the taggedEntities state
                const tagContent = prevElement.getAttribute("data-tag") || "";
                const tagMatch = tagContent.match(/{user:(.+?)}/);
                if (tagMatch) {
                  const userIdToRemove = tagMatch[1];
                  setTaggedEntities((prev) =>
                    prev.filter((id) => id.mentionedEntityId !== userIdToRemove)
                  );
                }
              }
            }
          }
        } else if (container.nodeType === Node.ELEMENT_NODE) {
          const element = container as HTMLElement;
          if (
            offset === 0 &&
            element.previousSibling &&
            element.previousSibling.nodeType === Node.ELEMENT_NODE
          ) {
            const prevElement = element.previousSibling as HTMLElement;
            if (prevElement.getAttribute("data-tag")) {
              e.preventDefault();
              prevElement.remove();

              // Update the comment state
              if (commentRef.current) {
                setComment(getMessageContent(commentRef.current));
              }

              // Remove the corresponding user ID from the taggedEntities state
              const tagContent = prevElement.getAttribute("data-tag") || "";
              const tagMatch = tagContent.match(/{user:(.+?)}/);
              if (tagMatch) {
                const userIdToRemove = tagMatch[1];
                setTaggedEntities((prev) =>
                  prev.filter((id) => id.mentionedEntityId !== userIdToRemove)
                );
              }
            }
          }
        }
      }
      if (e.key === "Escape") {
        // Cancel user tagging
        e.preventDefault();
        setIsTagging(false);
        setShowEmojiPicker(false);
        setSuggestedUsers([]);
      }

      // Handle Emoji Suggestions
      if (isEmojiTagging && suggestedEmojis.length > 0) {
        if (e.key === "ArrowDown") {
          // Navigate down the emoji suggestions
          e.preventDefault();
          setHighlightedEmojiIndex((prevIndex) =>
            prevIndex + 1 < suggestedEmojis.length ? prevIndex + 1 : 0
          );
        } else if (e.key === "ArrowUp") {
          // Navigate up the emoji suggestions
          e.preventDefault();
          setHighlightedEmojiIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : suggestedEmojis.length - 1
          );
        } else if (e.key === "Enter" || e.key === "Tab" || e.key === "Space") {
          // Select the highlighted emoji
          e.preventDefault();
          const selectedEmoji = suggestedEmojis[highlightedEmojiIndex];
          handleEmojiTagSelection(selectedEmoji.emoji);
        } else if (e.key === "Escape") {
          // Cancel emoji tagging
          e.preventDefault();
          setIsEmojiTagging(false);
          setSuggestedEmojis([]);
        }
        // Do not submit the message when emoji suggestions are displayed
      }
      // Handle User Mentions
      else if (isTagging && suggestedUsers.length > 0) {
        if (e.key === "ArrowDown") {
          // Navigate down the user suggestions
          e.preventDefault();
          setHighlightedIndex((prevIndex) =>
            prevIndex + 1 < suggestedUsers.length ? prevIndex + 1 : 0
          );
        } else if (e.key === "ArrowUp") {
          // Navigate up the user suggestions
          e.preventDefault();
          setHighlightedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : suggestedUsers.length - 1
          );
        } else if (e.key === "Enter" || e.key === "Tab" || e.key === "Space") {
          // Select the highlighted user
          e.preventDefault();
          const selectedUser = suggestedUsers[highlightedIndex];
          handleTagSelection(
            selectedUser.id,
            selectedUser.displayName ||
              `${selectedUser.firstName} ${selectedUser.lastName}`
          );
        } else if (e.key === "Escape") {
          // Cancel user tagging
          e.preventDefault();
          setIsTagging(false);
          setShowEmojiPicker(false);
          setSuggestedUsers([]);
        }
        // Do not submit the message when user suggestions are displayed
      } else {
        // No suggestions displayed, handle Enter key for message submission or newline
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          // Submit the message
          submitComment();
        } else if (e.key === "Enter" && e.shiftKey) {
          e.preventDefault();
          // Insert a line break at the cursor position
          insertLineBreakAtCursor();
        }
      }
    }
  };

  const handleEmojiTagSelection = (emoji: string) => {
    const sel = window.getSelection();

    if (sel?.rangeCount && commentRef.current) {
      const range = sel.getRangeAt(0);
      const container = range.startContainer;

      if (container.nodeType === Node.TEXT_NODE) {
        const textNode = container as Text;
        const textContent = textNode.textContent || "";

        // Find the position of the last ':' symbol in the text before the caret
        const cursorPosition = range.startOffset;
        const textBeforeCursor = textContent.slice(0, cursorPosition);
        const lastColon = textBeforeCursor.lastIndexOf(":");

        if (lastColon >= 0) {
          // Create a new range to select from the last ':' symbol up to the cursor
          const newRange = document.createRange();
          newRange.setStart(textNode, lastColon);
          newRange.setEnd(textNode, cursorPosition);

          // Delete the selected text (i.e., ':smile')
          newRange.deleteContents();

          // Now, insert the emoji at the new cursor position
          const emojiTextNode = document.createTextNode(emoji);
          newRange.insertNode(emojiTextNode);

          // Move the cursor after the inserted emoji
          newRange.setStartAfter(emojiTextNode);
          newRange.collapse(true);
          sel.removeAllRanges();
          sel.addRange(newRange);

          // Save the new selection range
          setSavedRange(newRange.cloneRange());

          // Update the comment state
          setComment(getMessageContent(commentRef.current));

          // Reset the emoji tagging state
          setEmojiTagSearch("");
          setIsEmojiTagging(false);
          setSuggestedEmojis([]);
        }
      }
    }
  };

  function insertLineBreakAtCursor() {
    const sel = window.getSelection();
    if (sel && sel.rangeCount) {
      const range = sel.getRangeAt(0);
      const br = document.createElement("br");
      range.insertNode(br);
      range.setStartAfter(br);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      setSavedRange(range.cloneRange());
      // Update the comment state
      if (commentRef.current) {
        setComment(getMessageContent(commentRef.current));
      }
    }
  }

  const findAvatarById = (userId: string | undefined): string | null => {
    if (userId) {
      const avatarObj = avatars?.find((avatar) => avatar.id === userId);
      return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
    } else {
      return null;
    }
  };

  function getMessageContent(element: HTMLElement): string {
    let message = "";
    element.childNodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        message += node.textContent;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const el = node as HTMLElement;
        if (
          el.tagName.toLowerCase() === "span" &&
          el.getAttribute("data-tag")
        ) {
          message += el.getAttribute("data-tag");
        } else if (el.tagName.toLowerCase() === "br") {
          message += "\n";
        } else {
          // Recursively process child nodes
          message += getMessageContent(el);
        }
      }
    });
    return message;
  }

  return (
    <div className="rounded-lg h-full max-h-[750px] bg-card dark:bg-card-dark">
      <h2 className="rounded-t-lg p-5 text-2xl inter-font text-text dark:text-white border-b font-extrabold dark:bg-background-dark dark:border-card-dark">
        Chat Logs
      </h2>
      <div className="flex flex-col justify-between max-h-[700px] p-2">
        <div className="overflow-y-auto h-[575px] dark:scrollbar-track-stone-600 dark:scrollbar-thumb-primary">
          {chats.map((chat, index) => {
            // Split the description into parts to replace the placeholders with JSX elements
            const renderDescription = () => {
              if (!chat.description) {
                return null;
              }

              // Split the description into parts based on the placeholder pattern and newlines
              let parts = chat.description.split(/({user:[^}]+}|\n)/g);

              return parts.map((part, i) => {
                if (part === "\n") {
                  return <br key={i} />;
                }
                const match = part.match(/{user:(.+)}/);
                if (match) {
                  const userId = match[1];
                  const mentionedUser = users?.find(
                    (user) => user.id === userId
                  );

                  if (mentionedUser) {
                    return (
                      <span
                        key={i}
                        className="bg-blue-100 text-blue-700 rounded p-0.5 cursor-pointer mx-0.5 text-sm px-1"
                        onClick={() =>
                          navigate(`/profile/user/${mentionedUser.id}`)
                        }
                      >
                        <FontAwesomeIcon icon={faCircleUser} className="mr-1" />
                        {mentionedUser.displayName}
                      </span>
                    );
                  } else {
                    return `{Unknown User}`;
                  }
                } else {
                  return part;
                }
              });
            };

            return (
              <div key={index} className="w-full text-sm">
                {chat.author?.id === currentUser?.uid ? (
                  <div className="flex gap-2 justify-end mt-5 align-top w-full">
                    <div className="min-w-[300px] flex justify-end flex-col">
                      <div className="text-wrap break-words bg-[#1271ff] rounded-lg ms-auto shadow-lg p-1.5 w-fit text-white max-w-[350px] gap-1 justify-center align-middle items-center">
                        {renderDescription()}
                      </div>
                      <div className="text-muted text-[10px] text-end">
                        {getTimeAgo(chat.createDate)}
                      </div>
                    </div>
                    <div className="user h-8 w-8 text-sm font-bold bg-text-dark rounded-full flex items-center justify-center">
                      {findAvatarById(chat.author?.id) ? (
                        <img
                          src={findAvatarById(chat.author?.id)!}
                          alt="profile_picture"
                          className="h-full w-full rounded-full"
                        />
                      ) : chat.author?.firstName && chat.author.lastName ? (
                        chat?.author?.firstName[0] + chat.author?.lastName[0]
                      ) : (
                        "??"
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-nowrap gap-2 items-center mt-5 w-full">
                    <div className="user h-8 w-8 text-sm font-bold bg-text-dark rounded-full flex items-center justify-center">
                      {findAvatarById(chat.author?.id) ? (
                        <img
                          src={findAvatarById(chat.author?.id)!}
                          alt="profile_picture"
                          className="h-full w-full rounded-full"
                        />
                      ) : chat.author?.firstName && chat.author.lastName ? (
                        chat?.author?.firstName[0] + chat.author?.lastName[0]
                      ) : (
                        "??"
                      )}
                    </div>
                    <div className="max-w-[300px] flex items-start justify-start flex-col">
                      <div className="text-wrap break-words bg-white rounded-lg ms-auto shadow-lg p-1.5 w-fit text-text max-w-[350px] gap-1 justify-center align-middle items-center border">
                        {renderDescription()}
                      </div>
                      <div className="text-muted text-[10px] text-end">
                        {getTimeAgo(chat.createDate)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="p-1 flex items-center align-middle justify-between border-t py-5 gap-2 max-w-full">
          <div className="w-10/12 flex items-center align-middle relative">
            <div
              className="resize-none overflow-y-auto max-h-[200px] min-h-[58px] rounded-xl border dark:bg-card-dark border-text-dark bg-background p-2 border-none w-full"
              contentEditable
              onInput={(e) => {
                handleInput();
                saveSelection();
              }}
              onKeyDown={handleKeyDown}
              ref={commentRef} // You'll need to set up a ref to access the div content
            >
              {/* Render the current comment state here if needed */}
            </div>
            {commentRef.current?.innerText.trim() === "" && (
              <div className="absolute text-muted left-2 top-2 z-50 pointer-events-none">
                Enter your comment...
              </div>
            )}
            {isTagging && suggestedUsers.length > 0 && (
              <div className="absolute bottom-14 left-0 bg-white dark:bg-card-dark border dark:border-card-dark rounded-lg shadow-lg w-full max-h-48 overflow-y-auto z-10">
                {suggestedUsers.map((user, index) => (
                  <div
                    key={index}
                    onMouseDown={(e) => e.preventDefault()}
                    className={`cursor-pointer p-2 flex gap-2 items-center align-middle ${
                      index === highlightedIndex
                        ? "bg-gray-300 dark:bg-gray-700"
                        : "hover:bg-gray-200 dark:hover:bg-card-dark"
                    }`}
                    onClick={() =>
                      handleTagSelection(
                        user.id, // Pass the user's ID
                        user.displayName || `${user.firstName} ${user.lastName}`
                      )
                    }
                  >
                    <div className="user h-8 w-8 text-sm font-bold bg-text-dark rounded-full flex items-center justify-center">
                      {findAvatarById(user.id) ? (
                        <img
                          src={findAvatarById(user.id)!}
                          alt="profile_picture"
                          className="h-full w-full rounded-full"
                        />
                      ) : user.firstName && user.lastName ? (
                        user.firstName[0] + user.lastName[0]
                      ) : (
                        "??"
                      )}
                    </div>
                    {user.displayName || `${user.firstName} ${user.lastName}`}
                  </div>
                ))}
              </div>
            )}
            {/* Emoji Suggestions */}
            {isEmojiTagging && suggestedEmojis.length > 0 && (
              <div className="absolute bottom-14 left-0 bg-white dark:bg-card-dark border dark:border-card-dark rounded-lg shadow-lg w-full max-h-48 overflow-y-auto z-10">
                {suggestedEmojis.map((item, index) => (
                  <div
                    key={index}
                    onMouseDown={(e) => e.preventDefault()}
                    className={`cursor-pointer p-2 flex gap-2 items-center align-middle ${
                      index === highlightedEmojiIndex
                        ? "bg-gray-300 dark:bg-gray-700"
                        : "hover:bg-gray-200 dark:hover:bg-card-dark"
                    }`}
                    onClick={() => handleEmojiTagSelection(item.emoji)}
                  >
                    <span className="text-xl">{item.emoji}</span>
                    <span>{item.keywords[0]}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* Emoji Button */}
          <div className="mx-1">
            <button
              className="mx-2 bg-white border rounded-full p-2 w-10 h-10 shadow-sm hover:bg-gray-100"
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => {
                if (commentRef.current) {
                  commentRef.current.focus();
                }
                setShowEmojiPicker(!showEmojiPicker);
              }}
            >
              😊  
            </button>
            {/* Emoji Picker */}
            {showEmojiPicker && (
              <div className="absolute bottom-64   right-0 bg-white dark:bg-card-dark border dark:border-card-dark rounded-lg shadow-lg w-64 max-h-48 z-10">
                <EmojiPicker onSelect={handleEmojiClick} />
              </div>
            )}
          </div>
          <div className="w-full max-w-[40px] mx-1">
            {!isLoading ? (
              <div
                className="rounded-full p-2 w-10 h-10 flex items-center align-middle justify-center bg-blue-500 text-white text-base"
                onClick={submitComment}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </div>
            ) : (
              <button
                disabled
                type="button"
                className="p-1 text-sm rounded-full font-medium w-full text-center text-white bg-[#3DD598] border border-gray-200 hover:bg-[#3DD598] hover:text-white focus:z-10 focus:ring-2 focus:ring-white focus:text-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex justify-center items-center"
              >
                <LoadingSpinner text={false} w={4} h={4} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskChatComponent;
