import { faLink, faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

export function parseMessageContent(text: string) {
  const urlRegex = /((?:https?:\/\/|ftp:\/\/)\S+)/gi;
  const tokens: Array<string | { url: string }> = [];
  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = urlRegex.exec(text)) !== null) {
    const url = match[1];
    if (match.index > lastIndex) {
      tokens.push(text.slice(lastIndex, match.index));
    }
    tokens.push({ url });
    lastIndex = match.index + url.length;
  }

  if (lastIndex < text.length) {
    tokens.push(text.slice(lastIndex));
  }

  return tokens.map((token, i) => {
    if (typeof token === "string") {
      // Plain text
      return <span key={i} className=" break-words whitespace-pre-wrap">{token}</span>;
    } else {
      // We have a URL. Check if it’s from your domain + route
      const isSproutTask = /^(?:https?:\/\/)sprout\.heai\.ai\/task\/([^/]+)/i;
      const match = token.url.match(isSproutTask);

      if (match) {
        // Extract the taskId or whatever is in the path
        const taskId = match[1]; // e.g. the part after /task/
        return <SproutTaskLink key={i} url={token.url} taskId={taskId} />;
      } else {
        // External link => normal <a>
        return (
          <a
            key={i}
            href={token.url}
            target="_blank"
            rel="noopener noreferrer"
            className="italic underline break-all"
          >
            {token.url}
          </a>
        );
      }
    }
  });
}

interface SproutTaskLinkProps {
  url: string;
  taskId: string;
}

const SproutTaskLink: React.FC<SproutTaskLinkProps> = ({ url, taskId }) => {
  return (
    <a
    href={url}
      className="bg-blue-200 text-blue-700 cursor-pointer font-bold text-sm"
      style={{
        display: "inline-flex",
        alignItems: "center",
        gap: "6px",
        padding: "2px 4px",
        borderRadius: "4px",
      }}
    >
      <FontAwesomeIcon icon={faLink} />
      <div>
        {taskId}
      </div>
    </a>
  );
};

export default SproutTaskLink;
