import EventNotification from "../../interfaces/eventNotification/EventNotification";

type ReplacementType = {
  [key: string]: string;
};

export const renderMessageWithLinks = (
  message: string,
  notification: EventNotification
) => {
  const replacements: ReplacementType = {
    "{task}": "task",
    "{author}": "user",
    "{file}": "file",
    "{activity}": "task_action",
  };

  const parts = message.split(/(\{[a-zA-Z]+\})/g); // Split the message by placeholders
  return parts.map((part, index) => {
    if (replacements[part]) {
      const type = replacements[part];
      const resource = notification.notificationResources?.find(
        (res: any) => res.resourceType === type
      );
      if (type === "task_action") {
        return (
          <span className="clamp-3" key={index}>
            {notification.notificationResources?.find(
              (res: any) => res.resourceType === type
            )?.resourceName || "Activity"}
          </span>
        );
      }
      if (type === "user") {
        return (
          <span className="clamp-3" key={index}>
            {notification.notificationResources?.find(
              (res: any) => res.resourceType === type
            )?.resourceName || "Activity"}
          </span>
        );
      }
      if (type === "task") {
        return (
          <span className="clamp-3" key={index}>
            {notification.notificationResources?.find(
              (res: any) => res.resourceType === type
            )?.resourceName || "Activity"}
          </span>
        );
      }
      if (resource) {
        return (
          <span
            className="inline clamp-3"
            key={index}
            // href={resource.resourceId}
          >
            {resource.resourceName}
          </span>
        );
      }
    }
    return <span key={index}>{part}</span>; // If no replacement, return the part as is
  });
};
