// TaskWorkflowService.ts

import TaskApprover from "../../interfaces/task/taskActions/TaskApprover";
import TaskWorkflow from "../../interfaces/task/workflow/TaskWorkflow";
import TaskWorkflowGroup from "../../interfaces/task/workflow/TaskWorkflowGroup";
import WorkflowGroupRequest from "../../interfaces/task/workflow/WorkflowGroupRequest";

// Function to fetch a task by its ID
export async function approveTask(
  taskId: string,
  authToken: string
): Promise<{ success: boolean; message?: string; approver?: TaskApprover | null }> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/workflow/approve/${taskId}`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    // Parse JSON response
    const responseData = await response.json();

    if (!response.ok) {
      // Return error message from API
      return { success: false, message: responseData.message };
    }

    // Map the task data to your Task interface
    const approver: TaskApprover = {
      id: responseData.approverId,
      taskId: responseData.taskId,
      approver: responseData.approver,
      approverRole: responseData.approverRole,
      approvalDate: responseData.approvalDate,
    };

    return { success: true, approver };
  } catch (error) {
    console.error("Error approving task:", error);
    return { success: false, message: "A network error occurred. Please try again." };
  }
}


export async function rejectTask(
  taskId: string,
  feedback: string,
  newAssignee: number,
  durationInMinutes: number,
  authToken: string
): Promise<TaskApprover | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/workflow/reject/${taskId}`;

    const feedbackBody = { 
      feedback: feedback, 
      taskApprovalId: newAssignee, 
      durationInMinutes: durationInMinutes
    }

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json" 
      },
      body: JSON.stringify(feedbackBody),
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const taskData = await response.json();

    // Map the task data to your Task interface
    const approver: TaskApprover | null = {
      id: taskData.id,
      taskId: taskData.taskId,
      approver: taskData.approver,
      approverRole: taskData.approverRole,
      approvalDate: taskData.approvalDate,
      // Add other necessary properties
    };

    return approver;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}

export async function getTaskWorkflow(
  taskId: string,
  authToken: string
): Promise<TaskWorkflow[] | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/workflow/task/${taskId}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      throw new Error(`Unable to get workflow for task ${taskId}`);
    }

    const resData = await response.json();

    // Map the task data to your Task interface
    const workflows: TaskWorkflow[] = resData.map((workflow: any) => ({
      id: workflow.id,
      taskId: workflow.taskId,
      approvalFlowId: workflow.approvalFlowId,
      stepOrder: workflow.stepOrder,
      role: workflow.role,
      approver: workflow.approver,
      taskApproval: workflow.taskApproval,
    }));

    return workflows;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}

export async function getWorkflowGroups(
  authToken: string
): Promise<TaskWorkflowGroup | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/workflow`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      throw new Error(`Unable to get workflows`);
    }

    const workflows = await response.json();

    return workflows;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Unable to get workflows:", error);
    throw new Error("Unable to get workflows");
  }
}

// Function to fetch a task by its ID
export async function publishWorkGroup(
  workflowGroupRequest: WorkflowGroupRequest,
  authToken: string
): Promise<boolean | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/workflow/new`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(workflowGroupRequest),
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    return true;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Unable to create new workflow group:", error);
    return null;
  }
}

// Function to fetch a task by its ID
export async function deleteWorkflowGroupByName(
  groupName: string,
  authToken: string
): Promise<boolean | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/workflow/workflowGroup/${groupName}`;

    const response = await fetch(apiUrl, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    return true;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Unable to delete workflow group:", error);
    return null;
  }
}
