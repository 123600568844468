import React, { useEffect, useState } from "react";
import {
  createCommentOnTaskId,
  getTaskActionsByTaskId,
  markTaskActionForDeletion,
  markTaskActionForUnDeletion,
  updateTaskAction,
} from "../../../services/taskServices/TaskActionService";
import { useAuth } from "../../../firebase/AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import Banner from "../../ui/Banner/Banner";
import TaskAction from "../../../interfaces/task/taskActions/TaskAction";
import {
  formatDateForInput,
  formatDateToWords,
  getTimeAgo,
} from "../../../utilities/dateUtils";
import { useLocation } from "react-router-dom";
import AssigneeWithTooltip from "./AssigneeWithTooltip";
import { TaskActionUpdateRequest } from "../../../interfaces/task/taskActions/TaskActionUpdateRequest";
import LoadingSpinner from "../../ui/Loading/LoadingSpinner";

interface TaskActivityProps {
  taskId: string;
}

const TaskActivity: React.FC<TaskActivityProps> = ({ taskId }) => {
  const location = useLocation();
  const { currentRoles } = useAuth();

  const [activities, setActivities] = useState<
    TaskAction[] | null | undefined
  >();
  const [sortedList, setSortedList] = useState<TaskAction[]>();
  const [activityFocused, setActivityFocused] = useState<number>();
  const [banner, setBanner] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const [reload, setReload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false); // Add state for edit mode
  const [infoMessage, setInfoMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [originalActivities, setOriginalActivities] = useState<
    {
      id: number;
      description: string;
      completionTime: string;
      deleted: boolean;
    }[]
  >([]);

  const [editedActivities, setEditedActivities] = useState<
    {
      id: number;
      description: string;
      completionTime: string;
      deleted: boolean;
    }[]
  >([]);
  const [selectedField, setSelectedField] = useState<string | null>(null); // Track which field is selected for editing

  const toggleBanner = () => {
    setBanner(!banner);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const activity = searchParams.get("focusedActivity");
    if (activity !== null) {
      setActivityFocused(parseInt(activity, 10));
    }
  }, [location]);

  useEffect(() => {
    if (taskId !== undefined) {
      const fetchTaskActivities = async () => {
        if (currentUser != null) {
          const authToken: string = await currentUser.getIdToken();
          const fetchedActivities = await getTaskActionsByTaskId(
            taskId,
            authToken
          );
          setActivities(fetchedActivities);

          const initialActivities =
            fetchedActivities?.map((activity) => ({
              id: activity.id,
              description: activity.description || "",
              completionTime: activity.completionTime
                ? new Date(activity.completionTime).toISOString()
                : "", // Convert Date to string
              deleted: activity.deleted,
            })) || [];

          setEditedActivities(initialActivities);
          setOriginalActivities(initialActivities); // Set original values
        }
      };
      fetchTaskActivities();
    }
  }, [taskId, banner, reload]);

  useEffect(() => {
    if (activities) {
      const sortedActivities = [...activities].sort((a, b) => {
        let dateB: Date | undefined = undefined;
        let dateA: Date | undefined = undefined;

        if (a.completionTime !== undefined) {
          dateA = new Date(a.completionTime);
        }
        if (b.completionTime !== undefined) {
          dateB = new Date(b.completionTime);
        }

        if (!dateA) return 1;
        if (!dateB) return -1;
        if (dateA === undefined || dateB === undefined) {
          console.error("Invalid date objects");
          return 0;
        }

        const result = dateA.getTime() - dateB.getTime();
        return result;
      });
      setSortedList(sortedActivities);
    }
  }, [activities, banner, reload]);

  useEffect(() => {
    if (activities) {
      // Sort activities once
      const sortedActivities = [...activities].sort((a, b) => {
        let dateB: Date | undefined = undefined;
        let dateA: Date | undefined = undefined;
        if (a.completionTime !== undefined) {
          dateA = new Date(a.completionTime);
        }
        if (b.completionTime !== undefined) {
          dateB = new Date(b.completionTime);
        }
        if (!dateA) return 1;
        if (!dateB) return -1;
        if (dateA === undefined || dateB === undefined) {
          console.error("Invalid date objects");
          return 0;
        }
        const result = dateA.getTime() - dateB.getTime();
        return result;
      });

      setSortedList(sortedActivities);
    }
  }, [activities]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleDescriptionChange = (index: number, value: string) => {
    const updatedActivities = [...editedActivities];
    updatedActivities[index].description = value;
    setEditedActivities(updatedActivities);
  };

  const handleTimestampChange = (index: number, value: string) => {
    const updatedActivities = [...editedActivities];
    updatedActivities[index].completionTime = value; // This will be a string from the input
    setEditedActivities(updatedActivities);
  };

  const handleFieldSelect = (field: string) => {
    setSelectedField(field);
  };

  const handleSave = async () => {
    if (currentUser) {
      const authToken = await currentUser.getIdToken();

      const actionsToUpdate: TaskActionUpdateRequest[] = editedActivities
        .map((editedActivity) => {
          const original = originalActivities.find(
            (originalActivity) => originalActivity.id === editedActivity.id
          );

          if (!original) return null;

          const changes: Partial<TaskActionUpdateRequest> = {};

          // Normalize and round dates before comparison by rounding down
          const activityTime = roundDownToMinute(
            new Date(editedActivity.completionTime)
          ).getTime();
          const originalTime = roundDownToMinute(
            new Date(original.completionTime)
          ).getTime();

          // Check if description or completionTime has changed
          const descriptionChanged =
            editedActivity.description !== original.description;
          const completionTimeChanged = activityTime !== originalTime;

          if (descriptionChanged || completionTimeChanged) {
            // Include both fields if either has changed
            changes.description = editedActivity.description;
            changes.completionTime =
              editedActivity.completionTime || original.completionTime;
          }

          if (editedActivity.deleted !== original.deleted) {
            changes.deleted = editedActivity.deleted;
          }

          if (Object.keys(changes).length > 0) {
            return {
              taskActionId: editedActivity.id.toString(),
              ...changes,
            };
          }
          return null;
        })
        .filter((action) => action !== null) as TaskActionUpdateRequest[];

      if (actionsToUpdate.length > 0) {
        const success = await updateTaskAction(authToken, actionsToUpdate);

        if (success) {
          setIsEditing(false);
          setReload(!reload);
          setInfoMessage("");
          setErrorMessage("");

          // Reset original values after save
          setOriginalActivities(editedActivities);
        } else {
          setErrorMessage("Unable to update records.");
          setInfoMessage("");
          console.error("Failed to update task actions");
        }
      } else {
        setInfoMessage("No changes detected, no records were updated.");
        setErrorMessage("");
        setIsEditing(false);
      }
    }
  };

  const roundDownToMinute = (date: Date) => {
    const roundedDate = new Date(date);
    roundedDate.setSeconds(0, 0); // Set seconds and milliseconds to zero
    return roundedDate;
  };

  // Soft delete
  const handleDelete = async (actionId: number) => {
    setIsLoading(true);
    if (currentUser) {
      const authToken = await currentUser.getIdToken();
      markTaskActionForDeletion(authToken, actionId.toString());
      setReload(!reload);
    }
    setIsLoading(false);
  };

  const handleUnDelete = async (actionId: number) => {
    setIsLoading(true);
    if (currentUser) {
      const authToken = await currentUser.getIdToken();
      markTaskActionForUnDeletion(authToken, actionId.toString());
      setReload(!reload);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div className="mt-5 flex justify-between items-center align-middle mb-4">
        <h4 className="text-text text-sm inter-font">Task Activity</h4>
        {currentRoles &&
          currentRoles?.filter((role) => role.name === "SUPERVISOR").length >
            0 && (
            <div>
              {isEditing && (
                <div className="flex gap-2">
                  <div
                    className="text-sm cursor-pointer bg-gray-100 rounded p-2 hover:bg-gray-200"
                    onClick={handleEditClick}
                  >
                    Cancel
                  </div>{" "}
                  <div
                    className="text-sm cursor-pointer bg-[#3DD598] text-white rounded p-2 hover:bg-[#35bd87]"
                    onClick={handleSave}
                  >
                    Save
                  </div>
                </div>
              )}
              {!isEditing && (
                <div
                  className="text-sm mx-2 cursor-pointer bg-gray-100 rounded p-2 hover:bg-gray-200"
                  onClick={handleEditClick}
                >
                  {isEditing ? "Save" : "Edit"}
                </div>
              )}
            </div>
          )}
      </div>
      {banner && (
        <Banner
          message={"Successfully uploaded file!"}
          status={"Success"}
          dismissBanner={() => setBanner(false)}
        />
      )}
      {errorMessage !== "" && (
        <Banner
          message={errorMessage}
          status={"error"}
          dismissBanner={() => setErrorMessage("")}
        />
      )}
      {infoMessage !== "" && (
        <Banner
          message={infoMessage}
          status={"info"}
          dismissBanner={() => setInfoMessage("")}
        />
      )}
      <div>
        {!isEditing && (
          <ul className="pe-10 relative flex flex-col gap-3 py-12 pl-6 before:absolute before:top-0 before:left-6 before:h-full before:border before:-translate-x-1/2 before:border-accent before:border-dashed after:absolute after:top-6 after:left-6 after:bottom-6 after:border after:-translate-x-1/2 after:border-accent">
            {sortedList && sortedList.length > 0 ? (
              sortedList
                .filter(
                  (act) =>
                    act.completionTime !== null &&
                    act.completionTime !== undefined &&
                    act.actionSubType.actionSubTypeName !== "COMMENT"
                )
                .map((activity, index) => (
                  <li
                    key={index}
                    className={`relative pl-6 ${
                      activity.id === activityFocused
                        ? "text-white bg-green-100 dark:bg-green-900"
                        : ""
                    }`}
                  >
                    {activity.actionSubType.actionSubTypeName ===
                    "TASK COMPLETED" ? (
                      <div>
                        <span className="absolute left-0 z-10 flex items-center justify-center w-5 h-5 text-text dark:text-text-dark -translate-x-1/2 -translate-y-0.5 rounded-full ring-1 ring-green-100 success-bg">
                          <FontAwesomeIcon
                            className="success-text text-sm"
                            icon={faCheck}
                          />
                        </span>
                        <div className="flex flex-col flex-1 gap-0">
                          <div className="text-xs font-medium text-muted flex justify-between">
                            <div className="flex gap-1">
                              <div>{activity.description}</div>
                              {activity.updateDate !== undefined &&
                                activity.updatedBy !== undefined && (
                                  <div className="text-muted">{"(edited)"}</div>
                                )}
                            </div>
                            <div className="text-xs text-muted max-w-[120px] w-full">
                              {activity.completionTime !== undefined &&
                                getTimeAgo(activity.completionTime)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {activity.actionSubType.actionSubTypeName ===
                          "TASK APPROVAL" && (
                          <span className="absolute left-0 z-10 flex items-center justify-center w-2 h-2 text-ready dark:text-text-dark -translate-x-1/2 translate-y-1 rounded-full ring-1 ring-green-500 bg-green-500"></span>
                        )}
                        {activity.actionSubType.actionSubTypeName ===
                          "REJECTED" && (
                          <span className="absolute left-0 z-10 flex items-center justify-center w-2 h-2 text-text dark:text-text-dark -translate-x-1/2 translate-y-1 rounded-full ring-1 ring-danger bg-danger"></span>
                        )}
                        {activity.actionSubType.actionSubTypeName !==
                          "TASK APPROVAL" &&
                          activity.actionSubType.actionSubTypeName !==
                            "REJECTED" && (
                            <span className="absolute left-0 z-10 flex items-center justify-center w-2 h-2 text-text dark:text-text-dark -translate-x-1/2 translate-y-1 rounded-full ring-1 ring-accent bg-accent"></span>
                          )}
                        <div className="flex flex-col flex-1 gap-0">
                          <div className="text-xs font-medium text-muted flex justify-between">
                            <div className="flex gap-1">
                              <span className="font-bold text-text dark:text-text-dark max-w-[120px] text-nowrap">
                                {activity.author?.firstName +
                                  " " +
                                  activity.author?.lastName}
                              </span>{" "}
                              <div>
                                <div
                                  className={`${
                                    activity.deleted ? "line-through" : ""
                                  }`}
                                >
                                  {activity.description}
                                  {activity.updateDate !== undefined &&
                                    activity.updatedBy !== undefined && (
                                      <div className="text-muted underline rounded inline ms-1">
                                        {"edited"}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`text-xs text-muted max-w-[120px] w-full ${
                                activity.deleted ? "line-through" : ""
                              }`}
                            >
                              {activity.completionTime !== undefined &&
                                getTimeAgo(activity.completionTime)}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                ))
            ) : (
              <div className="text-center text-xs text-muted">
                There isn't any activity on this task yet!
              </div>
            )}
          </ul>
        )}
      </div>
      {isEditing &&
        sortedList &&
        sortedList.length > 0 &&
        sortedList
          .filter(
            (act) =>
              act.completionTime !== null &&
              act.completionTime !== undefined &&
              act.actionSubType.actionSubTypeName !== "COMMENT"
          )
          .map((activity, index) => (
            <div
              className="border relative rounded-xl p-3 text-sm flex align-middle items-center justify-between mt-5 cursor-pointer bg-gray-50"
              key={index}
              onClick={() => handleFieldSelect(`activity-${index}`)} // Set selected field
            >
              {activity.deleted ? (
                <div>
                  {isLoading ? (
                    <div className="absolute -mr-3 -top-3 right-0 bg-white border text-white rounded-full w-6 h-6 flex cursor-default items-center justify-center align-middle">
                      <LoadingSpinner text={false} w={5} h={5}/>
                    </div>
                  ) : (
                    <div
                      className="absolute -mr-3 -top-3 right-0 bg-[#3DD598] text-white rounded-full w-6 h-6 p-2 flex items-center justify-center align-middle shake"
                      onClick={() => handleUnDelete(activity.id)}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {isLoading ? (
                    <div className="absolute -mr-3 -top-3 right-0 bg-white text-white rounded-full w-6 h-6 flex cursor-default items-center justify-center align-middle">
                      <LoadingSpinner text={false} w={5} h={5}/>
                    </div>
                  ) : (
                    <div
                      className="absolute -mr-3 -top-3 right-0 bg-danger text-white rounded-full w-6 h-6 p-2 flex items-center justify-center align-middle shake z-40"
                      onClick={() => handleDelete(activity.id)}
                    >
                      <FontAwesomeIcon icon={faX} />
                    </div>
                  )}
                </div>
              )}
              <div className="min-w-[32px] me-2 flex justify-center align-middle items-center">
                {activity.author && (
                  <AssigneeWithTooltip assignee={activity.author} />
                )}
              </div>
              <div className="flex flex-col w-full me-2">
                <div className="text-muted">Description</div>
                {selectedField === `activity-${index}` ? (
                  <input
                    type="text"
                    value={
                      editedActivities[index]?.description ||
                      activity.description
                    }
                    onChange={(e) =>
                      handleDescriptionChange(index, e.target.value)
                    }
                    className="text-sm rounded-lg"
                  />
                ) : (
                  <span>{activity.description}</span>
                )}
              </div>
              <div className="flex flex-col text-end">
                <div className="text-muted">Timestamp</div>
                {selectedField === `activity-${index}` ? (
                  <input
                    type="datetime-local"
                    value={
                      editedActivities[index]?.completionTime ||
                      (activity.completionTime
                        ? formatDateForInput(new Date(activity.completionTime))
                        : "")
                    }
                    onChange={(e) =>
                      handleTimestampChange(index, e.target.value)
                    }
                    className="text-sm rounded-lg max-w-[200px]"
                  />
                ) : (
                  <span className=" min-w-[120px]">
                    {activity.completionTime
                      ? formatDateToWords(activity.completionTime)
                      : "N/A"}
                  </span>
                )}
              </div>
            </div>
          ))}
    </div>
  );
};

export default TaskActivity;
