export interface SignatureBox {
  id: string;
  signatureRequestId: string;
  pageNumber: number;
  x: number;
  y: number;
  width: number;
  height: number;
  assignedSignerEmail: string;
  signatureValue?: string;
  signedAt?: string;
  boxType: { id: number, name: SignatureType } ;
}

export interface SignatureField {
  id: string;
  pageNumber: number;
  x: number;
  y: number;
  width: number;
  height: number;
  signerEmail?: string;
  signatureValue?: string;
  signatureType: SignatureType;
}

export enum SignatureType {
  DATE = 'Date',
  INITIALS = 'Initials',
  SIGNATURE = 'Signature',
  TEXT = 'Text',
}

