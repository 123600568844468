export function convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  
  export function formatFileSize(bytes: number): string {
    if (bytes === 0) return "0 Bytes";

    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const formattedSize = (bytes / Math.pow(1024, i)).toFixed(2);

    return `${formattedSize} ${sizes[i]}`;
}

export function shortenFileName(fileName: string): string {
  // Remove extension (.docx, .pdf, etc.) by capturing everything before the last dot
  const lastDotIndex = fileName.lastIndexOf('.');
  let baseName = fileName;
  if (lastDotIndex > 0) {
    baseName = fileName.substring(0, lastDotIndex);
  }

  // Trim extra spaces
  baseName = baseName.trim();

  // Optionally, you could do more transformations (replacing multiple spaces, etc.)
  // But for now, just return the trimmed name
  return baseName;
}
