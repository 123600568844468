import { ClosedTaskStatsForTeamResponse } from "../../interfaces/stats/OwnerDashboardStats";

/**
 * Fetch closed task stats for a given team and month.
 */
export async function getClosedTaskStatsForTeam(
  authToken: string,
  teamId: number,
  month: number
): Promise<ClosedTaskStatsForTeamResponse | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/stats/tasksClosed/${teamId}/${month}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    // If we get a 4xx or 5xx status, handle that gracefully
    if (!response.ok) {
      console.error("Failed to fetch closed task stats:", response.statusText);
      return null;
    }

    // Deserialize JSON
    const res: ClosedTaskStatsForTeamResponse = await response.json();
    console.log('response, ', res)

    // Return the typed response
    return res;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching closed task stats:", error);
    return null;
  }
}
