// file: pages/chat/ChatPage.tsx
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../firebase/AuthProvider";
import { Conversation, sendMessage } from "../../../../../services/chatService/ChatService";
import Avatar from "../../../../../interfaces/avatar/Avatar";
import { getAvatarsFromStorage } from "../../../../../services/fileServices/FileService";
import ConversationList from "../../../realtimeChatComponents/ConversationListComponent";
import ChatWindow from "../../../realtimeChatComponents/ChatWindow";

const OwnerChatPage: React.FC = () => {
  const { currentUser } = useAuth();
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation | null>(null);
  const [avatars, setAvatars] = useState<Avatar[]>([]);
  const [authToken, setAuthToken] = useState<string>("");

  // Grab the token once
  useEffect(() => {
    let isMounted = true;
    if (!currentUser) return;
    currentUser.getIdToken().then((token) => {
      if (isMounted) setAuthToken(token);
    });
    return () => {
      isMounted = false;
    };
  }, [currentUser]);

  // Load any stored avatars
  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars || []);
  }, []);

  const userId = currentUser?.uid; // The local user’s ID from Firebase

  // Handler for sending a new message
  const handleSendMessage = async (message: string, conversationId: number) => {
    if (!message.trim()) return;
    if (!selectedConversation) {
      // Possibly create a new conversation if not selected
      if (!conversationId) {
        alert("No conversation selected");
        return;
      }
    }
    try {
      const token = await currentUser?.getIdToken();
      if (!token || !conversationId) return;
      await sendMessage(token, conversationId, message);
    } catch (err) {
      console.error("Failed to send message:", err);
    }
  };

  const handleSelectConversation = (conversation: Conversation) => {
    if (!currentUser) return;
    const uid = currentUser.uid;

    // // Figure out who the other participant is
    // let recipient;
    // if (uid === conversation.participantA.id) {
    //   recipient = conversation.participantB.id;
    // } else if (uid === conversation.participantB.id) {
    //   recipient = conversation.participantA.id;
    // } else {
    //   return;
    // }

    // setRecipientId(recipient);
    setSelectedConversation(conversation);
  };

  return (
    <div className="relative bg-white h-full">
      <div className="overflow-x-auto flex h-full">
        <div
          className="bg-white flex-1 overflow-hidden h-full"
          style={{ display: "flex" }}
        >
          {/* Show conversation list only if we have a token and user ID */}
          {authToken !== "" && userId && (
            <ConversationList
              authToken={authToken}
              userId={userId}
              avatars={avatars}
              selectedConversationId={selectedConversation?.id || null}
              onSelectConversation={handleSelectConversation}
            />
          )}

          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            {selectedConversation && currentUser && userId && (
              <div className="h-full">
                <ChatWindow
                    height="h-full"
                  authToken={authToken}
                  avatars={avatars}
                  currentUserId={userId}
                  sendMessageFn={handleSendMessage}
                  conversation={selectedConversation}
                />
              </div>
            )}

            {!selectedConversation && (
              <div className="bg-[#F7F9FC] h-full w-full">
                <div className="text-muted text-center absolute top-1/2 left-1/2 text-xs">
                  Please select a conversation or create a new one to begin.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnerChatPage;
