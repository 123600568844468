import React, { createContext, useContext, useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { useAuth } from "../firebase/AuthProvider";

/**
 * The shape of our context: a single HubConnection or null.
 */
const ChatConnectionContext = createContext<signalR.HubConnection | null>(null);

interface ChatConnectionProviderProps {
  children: React.ReactNode;
}

export const ChatConnectionProvider: React.FC<ChatConnectionProviderProps> = ({
  children,
}) => {
  const { currentUser } = useAuth();
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);

  useEffect(() => {
    if (!currentUser || connection) return; // Prevent creating multiple connections
  
    const getNewConn = async () => {
      const authToken: string = await currentUser.getIdToken();
  
      const newConn = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_BASE_URL}/chatHub`, {
          accessTokenFactory: () => authToken,
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
  
      try {
        await newConn.start();
        console.log("[Global] SignalR connection started.");
        setConnection(newConn);
      } catch (err) {
        // console.error("[Global] Failed to connect SignalR:", err);
      }
    };
  
    getNewConn();
  
    return () => {
      setConnection((prevConnection) => {
        if (prevConnection) {
          prevConnection
            .stop()
            .then(() => console.log("[Global] SignalR connection stopped."))
            .catch(console.error);
        }
        return null; // Ensure state resets
      });
    };
  
  }, [currentUser]); 

  return (
    <ChatConnectionContext.Provider value={connection}>
      {children}
    </ChatConnectionContext.Provider>
  );
};

/**
 * A small helper hook that returns our global chat connection (or null).
 */
export function useChatConnection(): signalR.HubConnection | null {
  return useContext(ChatConnectionContext);
}
