import {
    faCheck,
    faCopy
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import React, { useState } from "react";
  import type { CSSProperties } from "react";
  
  interface JsonSyntaxHighlighterProps {
    jsonString?: string;
  }
  
  const styles: CSSProperties = {
    backgroundColor: "#0d1428",
    color: "#fff",
    fontFamily: "monospace",
    fontSize: "0.6rem",
    padding: "12px",
    borderRadius: "6px",
    overflowX: "auto",
    whiteSpace: "pre-wrap",
    lineHeight: "1.5",
  };
  
  const tokenColors: Record<string, string> = {
    key: "#85C1FF",
    string: "#33DD5C",
    number: "#E8C07D",
    boolean: "#FF7B72",
    null: "#888888",
  };
  
  const JsonSyntaxHighlighter: React.FC<JsonSyntaxHighlighterProps> = ({
    jsonString,
  }) => {
    const [copyString, setCopyString] = useState<JSX.Element>(
      <div className="flex justify-center items-center">
        <FontAwesomeIcon icon={faCopy} /> Copy
      </div>
    );
  
    if (!jsonString) {
      return <pre style={styles}>No data</pre>;
    }
  
    // Syntax highlight
    const highlighted = jsonString.replace(
      /(\".*?\":)|(\".*?\")|(\b\d+(\.\d+)?\b)|\b(true|false|null)\b/g,
      (match) => {
        if (/^".*?"(?=\s*:)/.test(match)) {
          return `<span style="color: ${tokenColors.key}">${match}</span>`;
        } else if (/^".*"$/.test(match)) {
          return `<span style="color: ${tokenColors.string}">${match}</span>`;
        } else if (/^\d+(\.\d+)?$/.test(match)) {
          return `<span style="color: ${tokenColors.number}">${match}</span>`;
        } else if (/true|false/.test(match)) {
          return `<span style="color: ${tokenColors.boolean}">${match}</span>`;
        } else if (/null/.test(match)) {
          return `<span style="color: ${tokenColors.null}">${match}</span>`;
        }
        return match;
      }
    );
  
    // Copy button handler
    const handleCopy = () => {
      navigator.clipboard.writeText(jsonString).then(
        () => {
          setCopyString(
            <div className="flex justify-center items-center">
              <FontAwesomeIcon icon={faCheck} /> Copied
            </div>
          );
          // Revert to "Copy" after 2 seconds
          setTimeout(() => {
            setCopyString(
              <div className="flex justify-center items-center">
                <FontAwesomeIcon icon={faCopy} /> Copy
              </div>
            );
          }, 2000);
        },
        (err) => {
          // If copy fails, do nothing or set some error text if you wish
        }
      );
    };
  
    return (
      <div className="relative" style={styles}>
        {/* Floating Copy Button */}
        <button
          onClick={handleCopy}
          className="absolute top-2 right-2 bg-blue-700 hover:bg-blue-800 text-white px-2 py-1 rounded text-xs"
          style={{ cursor: "pointer" }}
        >
          {copyString}
        </button>
  
        {/* Render highlighted JSON */}
        <pre
          style={{
            margin: 0,
            backgroundColor: "transparent",
            whiteSpace: "pre-wrap",
          }}
          dangerouslySetInnerHTML={{ __html: highlighted }}
        />
      </div>
    );
  };
  
  export default JsonSyntaxHighlighter;
  