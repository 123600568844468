import React, { useEffect, useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import TaskWorkflowGroup from "../../../interfaces/task/workflow/TaskWorkflowGroup";
import { getWorkflowGroups, publishWorkGroup } from "../../../services/taskServices/TaskWorkflowServices";
import TaskCreationLKValues from "../../../interfaces/lkValues/TaskCreationLKValues";
import { getTaskLKValues } from "../../../services/taskServices/TaskLKService";
import { getRoles } from "../../../services/userServices/UserRolesService";
import Role from "../../../interfaces/identity/Role";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faMinusCircle,
  faPlus,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import Select, { SingleValue } from "react-select";
import NewWorkflowGroup from "../../../interfaces/task/workflow/NewWorkflowGroup";
import Banner from "../../ui/Banner/Banner";
import WorkflowGroupRequest from "../../../interfaces/task/workflow/WorkflowGroupRequest";

interface OptionType {
  value: string;
  label: string;
}

interface NewWorkflowsProps {
  toggleForm: () => void;
}

const NewWorkflows: React.FC<NewWorkflowsProps> = ({ toggleForm }) => {
  const { currentUser } = useAuth();
  const [approvers, setApprovers] = useState<string[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [roleTypeOptions, setRoleTypeOptions] = useState<OptionType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [workflowGroupName, setWorkflowGroupName] = useState<string>("");

  useEffect(() => {
    const fetchLkValues = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedValues: Role[] | null = await getRoles(authToken);
        if (fetchedValues) {
          setRoles(fetchedValues);
        }
      }
    };

    if (roles) {
      const formattedRoleOptions = roles.map((role) => ({
        value: role.id.toString(),
        label: role.name,
      }));

      if (formattedRoleOptions) {
        setRoleTypeOptions(formattedRoleOptions);
      }
    }
    if (roles.length === 0) {
      fetchLkValues();
    }
  }, [roles]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setWorkflowGroupName(value);
  };

  const handleApproverChange = (
    index: number,
    selectedOption: SingleValue<OptionType>
  ) => {
    const updatedApprovers = [...approvers];
    if (selectedOption) {
      updatedApprovers[index] = selectedOption.value;
    }
    setApprovers(updatedApprovers);
  };

  const addApprover = () => {
    setApprovers([...approvers, ""]);
  };

  const dismissErrorBanner = () => { 
    setErrorMessage("");
  }

  const removeApprover = (index: number) => {
    const updatedApprovers = approvers.filter((_, i) => i !== index);
    setApprovers(updatedApprovers);
  };

  const validateApprovers = (): boolean => {
    // Check if any approver in the array is an empty string.
    const isValidated = approvers.every(approver => approver.trim() !== '');
    const isNameValidated = workflowGroupName.trim() !== '';
    return isValidated && isNameValidated;
  };  

  const publishWorkflowGroup = async () => {

    if ( validateApprovers() && currentUser ) {
        const authToken: string = await currentUser.getIdToken();
        const groupRequest: WorkflowGroupRequest = {Name: workflowGroupName, Approvers: approvers}
        await publishWorkGroup(groupRequest, authToken)
        toggleForm();
        setErrorMessage("")
    } else { 
        setErrorMessage("Unable to create new Workflow Group, please make sure all fields are filled out.");
    }
  };

  return (
    <div className="bg-card dark:bg-card-dark text-text dark:text-text-dark rounded-lg shadow-lg p-5 mt-10">
      <div>
        <div className="mb-5">{ errorMessage !== "" && <Banner message={errorMessage} dismissBanner={dismissErrorBanner} status="error" />}</div>
        <div className="title important flex justify-between">
          <div>New Workflow Group</div>
          <div
            onClick={toggleForm}
            className="text-muted text-xl cursor-pointer hover:bg-background dark:hover:bg-background-dark p-1 rounded-full h-10 w-10 flex items-center justify-center"
          >
            <FontAwesomeIcon icon={faX} />
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="title"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Workflow Group Name:
          </label>
          <input
            type="text"
            id="title"
            name="title"
            placeholder="Give your workflow group a title..."
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-text dark:text-text-dark leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        {approvers.map((approver, index) => (
          <div key={index} className="mt-5">
            <label className="text-text dark:text-text-dark text-sm font-bold mb-2 flex gap-2 items-center">
              Approver {index + 1}:
              {index === 0 && (
                <div className="relative flex items-center justify-center">
                  <div className="group">
                    <button className="text-blue-600 bg-white w-[10px] h-[10px] rounded-full flex justify-center items-center">
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </button>
                    <div className="absolute left-full mb-2 hidden group-hover:block -bottom-4 m-3">
                      <div className="bg-blue-500 text-blue-200 -bottom-10 text-xs -left-2 rounded py-1 px-2 z-20 w-40">
                        The first approver is always overridden by the assignee of a task; their submission counts as the first approval
                      </div>
                      <div className="w-3 h-3 absolute bg-blue-500 bottom-2 -left-1.5 transform rotate-45 z-0"></div>
                    </div>
                  </div>
                </div>
              )}
            </label>{" "}
            <div className="flex gap-2 justify-between">
              <div className="w-full">
                <Select
                  options={roleTypeOptions}
                  onChange={(selectedOption) =>
                    handleApproverChange(index, selectedOption)
                  }
                  value={roleTypeOptions.find(
                    (option) => option.value === approver
                  )}
                />
              </div>
              <button
                onClick={() => removeApprover(index)}
                className="text-red-500 text-xl"
              >
                <FontAwesomeIcon icon={faMinusCircle} />
              </button>
            </div>
          </div>
        ))}
        <div className="block text-center mt-10">
          <div className="hover:bg-background inline p-2 rounded-md hover:shadow-md">
            <button onClick={addApprover}>
              <FontAwesomeIcon icon={faPlus} /> Add Approver
            </button>
          </div>
        </div>
        <button onClick={publishWorkflowGroup} className="primary-button mt-5 ms-auto block">Submit</button>
      </div>
    </div>
  );
};

export default NewWorkflows;
