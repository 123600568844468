// SkeletonStackedPillBarChart.tsx
import React from "react";

const SkeletonStackedPillBarChart: React.FC = () => {
  return (
    <div className="p-4 px-6 space-y-3 animate-pulse">
      {/* Simulate a chart title/legend */}

      {/* Simulate a few rows of stacked bars */}
      <div className="flex items-center space-x-2 mb-2">
        <div className="h-4 bg-gray-200 rounded-full w-1/12" />
        <div className="h-4 bg-gray-200 rounded-full w-full" />
      </div>
      <div className="flex items-center space-x-2 mb-2">
        <div className="h-4 bg-gray-200 rounded-full w-1/12" />
        <div className="h-4 bg-gray-200 rounded-full w-full" />
      </div>
      <div className="flex items-center space-x-2">
        <div className="h-4 bg-gray-200 rounded-full w-1/12" />
        <div className="h-4 bg-gray-200 rounded-full w-full" />
      </div>
      <div className="flex items-center space-x-2 mb-2">
        <div className="h-4 bg-gray-200 rounded-full w-1/12" />
        <div className="h-4 bg-gray-200 rounded-full w-full" />
      </div>
      <div className="flex items-center space-x-2 mb-2">
        <div className="h-4 bg-gray-200 rounded-full w-1/12" />
        <div className="h-4 bg-gray-200 rounded-full w-full" />
      </div>
      <div className="flex items-center space-x-2">
        <div className="h-4 bg-gray-200 rounded-full w-1/12" />
        <div className="h-4 bg-gray-200 rounded-full w-full" />
      </div>
    </div>
  );
};

export default SkeletonStackedPillBarChart;
