import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useAuth } from "../../../firebase/AuthProvider";
import { useNavigate } from "react-router-dom";
import { publishTemplateFile } from "../../../services/fileServices/FileService";
import { getFileLKValues } from "../../../services/fileServices/FileLKValuesService";
import { FileType } from "../../../interfaces/task/TaskFiles/FileType";
import NewTemplateFile from "../../../interfaces/task/TaskFiles/NewTemplateFile";
import Banner from "../../ui/Banner/Banner";

interface OptionType {
  value: string;
  label: string;
}

const AddTemplateFile: React.FC = () => {
  const { currentUser, currentOrganization } = useAuth();
  const navigate = useNavigate();
  const [fileTypeOptions, setFileTypeOptions] = useState<OptionType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [newTemplate, setNewTemplate] = useState<NewTemplateFile>({
    fileName: "",
    typeId: "",
    formId: "",
  });

  const [lkOptions, setLkOptions] = useState<FileType[] | null | undefined>();

  useEffect(() => {
    // Fetch the task with taskId and update the state
    const fetchLkValues = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedValues: FileType[] | null = await getFileLKValues(
          authToken
        );
        setLkOptions(fetchedValues);
      }
    };

    if (lkOptions) {
      const formattedFileTypeOptions = lkOptions.map((type) => ({
        value: type.id.toString(),
        label: type.type,
      }));

      setFileTypeOptions(formattedFileTypeOptions);
    }
    if (!lkOptions) {
      fetchLkValues();
    }
  }, [lkOptions]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setNewTemplate({ ...newTemplate, [name]: value });
  };

  const handleTemplateFileTypeChange = (
    selectedOption: SingleValue<OptionType>
  ) => {
    const typeId = selectedOption ? selectedOption.value : "";
    setNewTemplate({ ...newTemplate, typeId });
  };

  const toggleMessage = () => { 
    setErrorMessage("");
  }

  const readyToSubmit = () => {
    if ( newTemplate.fileName === "" ) {
      setErrorMessage("Filename is required to submit");
      return false;
    }
    if ( newTemplate.typeId === "" ) {

      setErrorMessage("File Type is required to submit");
      return false;
    }

    // if ( newTemplate.formId === "" ) {
    //   return false;
    // }
    toggleMessage();
    return true;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle submitting the new task
    if (readyToSubmit()) {
      const createTask = async () => {
        if (currentUser !== null) {
          const authToken: string = await currentUser.getIdToken();
          const newTaskId: number | null = await publishTemplateFile(
            newTemplate,
            authToken
          );
          return newTaskId;
        }
        return null;
      };
      const idPromise = createTask();

      idPromise.then((id) => {
        if (id !== null) {
          // Redirect to new task here.
          navigate(`/edit/templateFiles`);
        } else {
          console.error("Failed to create task");
        }
      });
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewTemplate({ ...newTemplate, [name]: value });
  };

  return (
    <div className="mt-5">
      <h1 className="heading-2 text-center">New Template File</h1>
      { errorMessage !== ""  && <Banner message={errorMessage} status="error" dismissBanner={toggleMessage} /> }
      <form
        onSubmit={handleSubmit}
        className="max-w-xl mx-auto mt-8 bg-card dark:text-text dark:bg-card-dark shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            htmlFor="title"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Filename:
          </label>
          <input
            type="text"
            id="fileName"
            name="fileName"
            placeholder="Give your file a default name..."
            value={newTemplate.fileName}
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="requiredFiles"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Required Files{" "}
            <span className="text-muted font-normal">(Optional)</span>:
          </label>
          <Select
            options={fileTypeOptions}
            onChange={handleTemplateFileTypeChange}
            value={fileTypeOptions.find(
              (option) => option.value === newTemplate.typeId
            )}
          />
        </div>
        <div className="flex justify-end">
          <button
            onClick={(e) => handleSubmit}
            type="submit"
            className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Create Template
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddTemplateFile;
