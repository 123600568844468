import React, {
  useEffect,
  useState,
  useRef,
  ReactNode,
} from "react";
import { useChatConnection } from "../../../contexts/ChatConnectionContext";
import {
  Conversation,
  Message,
  getMessagesForConversation,
} from "../../../services/chatService/ChatService";
import { getTimeAgo } from "../../../utilities/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faFile,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import Avatar from "../../../interfaces/avatar/Avatar";
import { groupMessagesByDate } from "../../../utilities/chat/groupingUtils";
import { motion } from "framer-motion";
import { parseMessageContent } from "../../../utilities/chat/textUtils";
import EmojiPicker from "../emojiPicker/EmojiPicker";
import ChatInput from "./ChatInput";
import "./ChatWindowStyles.css";

interface ChatWindowProps {
  authToken: string;
  currentUserId: string;
  conversation: Conversation;
  sendMessageFn: (
    input: string,
    conversationId: number,
    attachmentUrl?: File
  ) => void;
  avatars: Avatar[];
  height?: string;
}

const reactionOptions = ["👍", "❤️", "😄", "😮", "😢", "😠"];
// You can customize these

const ChatWindow: React.FC<ChatWindowProps> = ({
  authToken,
  currentUserId,
  conversation,
  sendMessageFn,
  avatars,
  height = "h-[75vh]",
}) => {
  const connection = useChatConnection();

  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [localText, setLocalText] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [typingUsers, setTypingUsers] = useState<string[]>([]);
  const [toggledReactionMenu, setToggledReactionMenu] = useState<number | null>(
    null
  );
  const [displayDates, setDisplayDates] = useState<number[]>([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  // === FILE PREVIEW STATE (moved from ChatInput) ===
  const [selectedFile, setSelectedFile] = useState<File>();
  const [filePreviewUrl, setFilePreviewUrl] = useState("");
  const [fileTypeIcon, setFileTypeIcon] = useState<ReactNode | undefined>();

  // NEW: track which message is hovered
  const [hoveredMessageId, setHoveredMessageId] = useState<number | null>(null);

  const conversationBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!selectedFile) {
      setFilePreviewUrl("");
      setFileTypeIcon(undefined);
      return;
    }

    // Check file type by MIME or extension
    const mime = selectedFile.type.toLowerCase();
    const fileName = selectedFile.name.toLowerCase();
    const hasExt = (ext: string) => fileName.endsWith(ext);

    if (mime.startsWith("image/")) {
      // image => create a blob URL
      const blobUrl = URL.createObjectURL(selectedFile);
      setFilePreviewUrl(blobUrl);
      setFileTypeIcon(undefined);
      return () => {
        // cleanup the old objectURL
        URL.revokeObjectURL(blobUrl);
      };
    } else {
      // set an icon
      let icon: ReactNode = <FontAwesomeIcon icon={faFile} className="text-muted text-2xl"/>;
      if (mime.includes("pdf") || hasExt(".pdf")) {
        icon = <FontAwesomeIcon icon={faFilePdf} className="text-red-600 text-2xl"/>;
      } else if (mime.includes("word") || hasExt(".doc") || hasExt(".docx")) {
        icon = <FontAwesomeIcon icon={faFileWord} className="text-blue-600 text-2xl"/>;
      } else if (mime.includes("excel") || hasExt(".xls") || hasExt(".xlsx")) {
        icon = <FontAwesomeIcon icon={faFileExcel} className="text-green-600 text-2xl"/>;
      } else if (
        mime.includes("powerpoint") ||
        hasExt(".ppt") ||
        hasExt(".pptx")
      ) {
        icon = <FontAwesomeIcon icon={faFilePowerpoint} className="text-orange-600 text-2xl"/>;
      }
      setFilePreviewUrl("");
      setFileTypeIcon(icon);
    }
  }, [selectedFile]);

  // =============== LOAD MESSAGES ===============
  useEffect(() => {
    const loadMessages = async () => {
      setLoading(true);
      try {
        const data = await getMessagesForConversation(
          authToken,
          conversation.id
        );
        setMessages(data);
      } catch (err) {
        console.error("Error loading messages:", err);
      } finally {
        setLoading(false);
      }
    };

    if (conversation.id) {
      loadMessages();
    }
  }, [authToken, conversation.id]);

  // =============== JOIN + EVENT HANDLERS ===============
  useEffect(() => {
    if (!connection || !conversation.id) return;

    connection
      .invoke("JoinConversation", conversation.id)
      .then(() => {
        // Mark it read
        return connection.invoke("MarkConversationRead", conversation.id);
      })
      .catch((err) => console.error("JoinConversation error:", err));

    // ReceiveMessage -> push into messages if belongs to this conversation
    const handleReceiveMessage = (msg: Message) => {
      if (msg.conversationId !== conversation.id) return;
      setMessages((prev) => [...prev, msg]);
    };
    connection.on("ReceiveMessage", handleReceiveMessage);

    // Listen for reaction updates
    const handleReceiveReaction = (
      convId: number,
      messageId: number,
      userId: string,
      reactionType: string | null,
      action: string
    ) => {
      if (convId !== conversation.id) return; // ignore if for another conversation

      setMessages((prevMessages) =>
        prevMessages.map((m) => {
          if (m.id !== messageId) return m;

          // Copy existing reactions (or empty if none)
          const existingReactions = m.reactions ? [...m.reactions] : [];

          // If "removed", remove that user's reaction
          if (action === "removed") {
            // Filter out the reaction from that user
            const updated = existingReactions.filter(
              (r) => r.userId !== userId
            );
            return { ...m, reactions: updated };
          }
          // If "updated" or "added", we set or replace the reaction
          else if (reactionType) {
            // Check if user had an existing reaction
            const idx = existingReactions.findIndex((r) => r.userId === userId);
            if (idx >= 0) {
              // update
              existingReactions[idx] = { userId, reactionType: reactionType };
            } else {
              // add
              existingReactions.push({ userId, reactionType: reactionType });
            }
            return { ...m, reactions: existingReactions };
          }

          return m;
        })
      );
    };
    connection.on("ReceiveReaction", handleReceiveReaction);

    // Typing status
    const handleTypingStatus = (
      convId: number,
      typingUserId: string,
      currentlyTyping: boolean
    ) => {
      if (convId !== conversation.id) return;
      if (typingUserId === currentUserId) return;
      setTypingUsers((prev) => {
        if (currentlyTyping) {
          if (!prev.includes(typingUserId)) return [...prev, typingUserId];
          return prev;
        } else {
          return prev.filter((id) => id !== typingUserId);
        }
      });
    };
    connection.on("ReceiveTypingStatus", handleTypingStatus);

    return () => {
      connection.off("ReceiveMessage", handleReceiveMessage);
      connection.off("ReceiveReaction", handleReceiveReaction);
      connection.off("ReceiveTypingStatus", handleTypingStatus);
      connection
        .invoke("LeaveConversation", conversation.id)
        .catch(console.error);
    };
  }, [connection, conversation.id, currentUserId]);

  // =============== SEND REACTION HANDLER ===============
  const handleAddReaction = async (messageId: number, reactionType: string) => {
    if (!connection) return;
    try {
      await connection.invoke("SendReaction", messageId, reactionType);
      setToggledReactionMenu(null);
    } catch (err) {
      console.error("Error sending reaction:", err);
    }
  };

  useEffect(() => {
    if (!isTyping) return;
    const timer = setTimeout(() => {
      setIsTyping(false);
      if (connection) {
        connection
          .invoke("SendTypingStatus", conversation.id, false)
          .catch(console.error);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [isTyping, connection, conversation.id]);

  // Autoscroll
  useEffect(() => {
    if (conversationBodyRef.current) {
      conversationBodyRef.current.scrollTop =
        conversationBodyRef.current.scrollHeight;
    }
  }, [messages]);

  // Helper: find an avatar by userId
  const findAvatarById = (id: string): string | null => {
    const avatarObj = avatars.find((a) => a.id === id);
    return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
  };

  const participantList = conversation.participants
    .filter((participant) => participant.id !== currentUserId)
    .map((p) => p.displayName || p.firstName + " " + p.lastName)
    .join(", ");

  const toggleReactionMenu = (msgId: number) => {
    if (toggledReactionMenu === msgId) {
      setToggledReactionMenu(null);
    } else {
      setToggledReactionMenu(msgId);
    }
  };

  const toggleDisplayDate = (msgId: number) => {
    setDisplayDates(
      (prevData) =>
        prevData.includes(msgId)
          ? prevData.filter((num) => num !== msgId) // Remove if exists
          : [...prevData, msgId] // Add if not exists
    );
  };

  const grouped = groupMessagesByDate(messages);

  return (
    <div
      style={{ flex: 1, display: "flex", flexDirection: "column" }}
      className={`bg-[#F7F9FC] ${height}`}
    >
      {/* HEADER */}
      <div
        className="bg-white p-2 py-4 border-l"
        style={{
          borderBottom: "1px solid #ccc",
          fontWeight: "bold",
        }}
      >
        Conversation with {participantList}
      </div>

      {/* MESSAGES LIST */}
      <div
        className="p-2 message-list"
        ref={conversationBodyRef}
        style={{
          flex: 1,
          overflowY: "auto",
          margin: "0.5rem 0",
        }}
      >
        {loading ? (
          <div>Loading messages...</div>
        ) : (
          <div style={{ padding: "8px" }}>
            {grouped.map((group: any, index) => (
              <div key={index}>
                {/* Date Header */}
                <div
                  className="text-sm"
                  style={{
                    textAlign: "center",
                    margin: "1rem 0",
                    color: "#777",
                  }}
                >
                  {group.dateLabel}
                </div>

                {group.messages.map((msg: Message) => {
                  const isMine = msg.author.id === currentUserId;
                  const isGroup =
                    conversation.participants.filter(
                      (p) => p.id !== currentUserId
                    ).length > 1;
                  return (
                    <div
                      key={msg.id}
                      className="mx-1 my-4 relative"
                      style={{ textAlign: isMine ? "right" : "left" }}
                      // HOVER events
                      onMouseEnter={() => setHoveredMessageId(msg.id)}
                      onMouseLeave={() => setHoveredMessageId(null)}
                    >
                      {/* Author name/avatar for group convos */}
                      {msg.author.id !== currentUserId && isGroup && (
                        <div className="flex items-center align-middle">
                          <div className="h-5 w-5 text-white mr-2 rounded-full bg-blue-500 flex justify-center align-middle items-center">
                            <img
                              src={
                                msg.author.id
                                  ? findAvatarById(msg.author.id) ??
                                    "/default-avatar.png"
                                  : "/default-avatar.png"
                              }
                              className="w-5 h-5"
                              alt="profile_picture"
                              style={{ borderRadius: "50%" }}
                            />
                          </div>
                          <strong className="block">
                            {msg.author.displayName ||
                              msg.author.firstName + " " + msg.author.lastName}
                          </strong>
                        </div>
                      )}

                      <div
                        className={`flex ${
                          isMine ? "justify-end" : "justify-start"
                        } items-center align-middle min-w-2/3`}
                        onClick={() => toggleDisplayDate(msg.id)}
                      >
                        {/* The message bubble */}
                        <div
                          className={`relative rounded-xl text-start border shadow-md max-w-[600px] ${
                            isMine ? "bg-blue-500 text-white ms-20" : "bg-white"
                          }`}
                          style={{
                            display: "inline-block",
                            padding: "6px 10px",
                          }}
                        >
                          <div className="text-wrap whitespace-pre-wrap">
                            {parseMessageContent(msg.messageContent)}
                          </div>

                          {/* Reaction display (small row of emojis) */}
                          {msg.reactions && msg.reactions.length > 0 && (
                            <div
                              className={`mt-1 flex gap-1 absolute ${
                                isMine
                                  ? "justify-end left-0"
                                  : "justify-start right-0"
                              }`}
                              style={{ fontSize: "0.85em" }}
                            >
                              {Object.entries(
                                msg.reactions.reduce<Record<string, number>>(
                                  (acc, r) => {
                                    acc[r.reactionType] =
                                      (acc[r.reactionType] || 0) + 1;
                                    return acc;
                                  },
                                  {}
                                )
                              ).map(([reaction, count], i) => (
                                <span
                                  key={`${msg.id}-${reaction}-${i}`}
                                  className={`px-1 ${
                                    isMine ? "left-0" : "right-0"
                                  } rounded-full bg-white shadow-md text-muted text-xs cursor-pointer relative z-40`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (!isMine)
                                      handleAddReaction(msg.id, reaction);
                                  }}
                                >
                                  {reaction}
                                  {count > 1 ? (
                                    <div className="absolute -bottom-1 -right-1 text-[0.8em] rounded-full w-3 h-3 flex justify-center align-middle items-center bg-white">
                                      {count.toString()}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              ))}
                            </div>
                          )}

                          {/* Reaction menu on hover */}
                          {!isMine && toggledReactionMenu === msg.id && (
                            <div
                              className="absolute z-50"
                              style={{
                                bottom: "-40px",
                                right:
                                  msg.messageContent.length < 22 ? "auto" : "0",
                                left:
                                  msg.messageContent.length < 22 ? "0" : "auto",
                                display: "flex",
                                gap: "4px",
                                background: "white",
                                borderRadius: "16px",
                                padding: "4px 8px",
                                boxShadow: "0 2px 5px rgba(0,0,0,.2)",
                              }}
                            >
                              {reactionOptions.map((reaction) => (
                                <button
                                  key={reaction + msg.id}
                                  onClick={() =>
                                    handleAddReaction(msg.id, reaction)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "1.1em",
                                  }}
                                >
                                  {reaction}
                                </button>
                              ))}
                            </div>
                          )}
                        </div>

                        {!isMine && hoveredMessageId === msg.id && (
                          <div
                            className="max-w-10 ms-2 hover:bg-gray-200 rounded-full p-1 h-7 w-7 flex items-center justify-center align-middle cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleReactionMenu(msg.id);
                            }}
                          >
                            <FontAwesomeIcon icon={faEllipsis} />
                          </div>
                        )}
                      </div>

                      {/* Timestamp */}
                      {displayDates.includes(msg.id) && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.3, ease: "easeOut" }}
                          style={{
                            fontSize: "0.7em",
                            color: "#888",
                            overflow: "hidden",
                            marginTop:
                              msg.reactions && msg.reactions.length > 0
                                ? "15px"
                                : "0",
                          }}
                        >
                          {" "}
                          {getTimeAgo(new Date(msg.createDate))}
                        </motion.div>
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* INPUT */}
      <div className="flex flex-col relative">
        {selectedFile && (
          <div className="file-preview flex items-center gap-2 border p-2 rounded-md shadow-sm mx-2">
            {filePreviewUrl ? (
              <img
                src={filePreviewUrl}
                alt="preview"
                style={{ width: "40px", height: "40px", objectFit: "cover" }}
              />
            ) : (
              <div>{fileTypeIcon}</div>
            )}
            <div className="flex-1">
              <p className="truncate" style={{ maxWidth: "200px" }}>
                {selectedFile.name}
              </p>
              <p className="text-xs text-gray-500">
                {(selectedFile.size / 1024).toFixed(2)} KB
              </p>
            </div>
            <button
              onClick={() => {
                setSelectedFile(undefined);
                setFilePreviewUrl("");
                setFileTypeIcon(undefined);
              }}
              className="text-sm text-red-500 hover:underline"
            >
              Remove
            </button>
          </div>
        )}
        <div className="absolute -top-8">
          {typingUsers.length > 0 && (
            <div
              className="mb-2"
              style={{ fontStyle: "italic", color: "#555", margin: "0.5rem" }}
            >
              {typingUsers.length === 1
                ? `${
                    conversation.participants.find(
                      (p) => p.id === typingUsers[0]
                    )?.firstName
                  } is typing...`
                : "Multiple people are typing..."}
            </div>
          )}
        </div>
        <div className="flex" style={{ padding: "0.5rem" }}>
          {/* Render your actual EmojiPicker */}
          {showEmojiPicker && (
            <div
              style={{
                position: "absolute",
                bottom: "3rem",
                right: "3rem",
                zIndex: 1000,
              }}
            >
              <EmojiPicker
                onSelect={(emoji) => {
                  // Insert the emoji into the input
                  setLocalText((prev) => prev + emoji);
                  setShowEmojiPicker(false);
                }}
              />
            </div>
          )}
          <ChatInput
            sendMessageFn={sendMessageFn}
            conversation={conversation}
            text={localText}
            updateText={setLocalText}
            onSelectFile={setSelectedFile} // pass the parent's setter
          />
          <button
            type="button"
            onClick={() => setShowEmojiPicker((prev) => !prev)}
            className="mx-2 bg-white border rounded-full p-2 w-10 h-10 shadow-sm hover:bg-gray-100"
          >
            😊
          </button>
          <div className="rounded-full w-10 h-10 flex items-center align-middle justify-center bg-blue-500 text-white text-base">
            <button
              onClick={() => {
                console.log('selectedFile', selectedFile?.name)
                if (localText.trim().length > 0 || selectedFile)
                  sendMessageFn(localText.trim(), conversation.id, selectedFile);
                  setLocalText("");
              }}
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
