import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useAuth } from "../../../../firebase/AuthProvider";
import { ClosedTaskStatsForTeamResponse } from "../../../../interfaces/stats/OwnerDashboardStats";
import { getClosedTaskStatsForTeam } from "../../../../services/statServices/OwnerStatsService";
import StackedPillBarChart from "./StackedPillBarChart";
import ClosedTaskTable from "./ClosedTaskTable";
import { getMonthLabelFromMonthsAgo } from "../../../../utilities/dateUtils";
import SkeletonStackedPillBarChart from "../../../ui/LoadingSkeleton/SkeletonStackedPillBarChart";

const TasksClosedChart: React.FC = () => {
  const { currentUser, currentUserInfo } = useAuth();
  const [closedTaskStats, setClosedTaskStats] =
    useState<ClosedTaskStatsForTeamResponse>();

  const [selectedUser, setSelectedUser] = useState<string>();
  const [monthsAgo, setMonthsAgo] = useState<number>(0); // Default to current month
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setSelectedUser(undefined);
    async function fetchData() {
      setIsLoading(true);
      if (!currentUser) return;
      const authToken: string = await currentUser.getIdToken();
      const teamId = 1;

      const stats = await getClosedTaskStatsForTeam(authToken, teamId, monthsAgo);
      if (stats) setClosedTaskStats(stats);
      setIsLoading(false);
    }
    fetchData();
  }, [monthsAgo]);

  const toggleSelectedUser = (userId: string) => {
    setSelectedUser((prev) => (prev === userId ? undefined : userId));
  };

  return (
    <div>
      <div className="max-w-[400px] border rounded shadow-sm">
        <div className="flex justify-between align-middle items-center">
          <h4 className="px-2 text-sm font-bold">
            Tasks Completed{" "}
            <span className="text-muted font-normal text-xs">
              ({getMonthLabelFromMonthsAgo(monthsAgo)})
            </span>
          </h4>
          <MonthAgoSelector onChange={(value) => {
            console.log("Changing to value: ", value)
            setMonthsAgo(value)
          }} />
        </div>
        {closedTaskStats && !isLoading && (
          <StackedPillBarChart data={closedTaskStats} selectUser={toggleSelectedUser} />
        )}
        {isLoading && <SkeletonStackedPillBarChart />}
        <div>
          {selectedUser && currentUserInfo && (
            <ClosedTaskTable
              organizationName={currentUserInfo?.organization.name}
              closedLateTaskIds={
                closedTaskStats?.teamStats.find((member) => member.userId === selectedUser)
                  ?.lateTaskIds ?? []
              }
              closedTaskIds={
                closedTaskStats?.teamStats.find((member) => member.userId === selectedUser)
                  ?.onTimeTaskIds ?? []
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TasksClosedChart;

interface OptionType {
  value: number; // 0-12 (months ago)
  label: string;
}

const generateMonthOptions = (): OptionType[] => {
  const options: OptionType[] = [];

  for (let i = 0; i <= 12; i++) {
    const label =
      i === 0
        ? "This Month"
        : i === 1
        ? "Last Month"
        : i === 12
        ? "Last Year"
        : `${i} months ago`;

    options.push({ value: i, label });
  }

  return options;
};

interface MonthAgoSelectorProps {
  onChange: (monthsAgo: number) => void;
}

// Custom styles for a smaller, compact dropdown
const customStyles = {
  control: (provided: any) => ({
    ...provided,
    minHeight: "30px",
    height: "30px",
    padding: "0px",
    fontSize: "0.875rem",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "2px 6px",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
    padding: "0px",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "30px",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: "2px",
  }),
  menu: (provided: any) => ({
    ...provided,
    fontSize: "0.875rem",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    padding: "4px 8px",
    backgroundColor: state.isFocused ? "#E2E8F0" : "white",
    color: "#1A202C",
  }),
};

const MonthAgoSelector: React.FC<MonthAgoSelectorProps> = ({ onChange }) => {
  const monthOptions = generateMonthOptions();
  const [selectedOption, setSelectedOption] = useState<OptionType>(monthOptions[0]);

  const handleChange = (selected: OptionType | null) => {
    if (selected) {
      console.log('Selected value: ', selected)
      setSelectedOption(selected);
      onChange(selected.value);
    }
  };

  return (
    <div className="flex gap-0 items-center remove-input-txt-border">
      <Select
        className="w-48 text-xs p-0"
        options={monthOptions}
        value={selectedOption}
        onChange={handleChange}
        placeholder="Select month"
        styles={customStyles}
      />
    </div>
  );
};
