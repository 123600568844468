import React from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import DashboardOverview from "./dashboardElements/DashboardOverview";
import DashboardRegionalView from "./dashboardElements/DashboardRegionalView";
import DashboardTeamView from "./dashboardElements/DashboardTeamView";
import './OwnerDashboard.css'
import DashboardTasks from "./dashboardElements/DashboardTasks";

const OwnerDashboardElement: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get("view") || "overview";

  return (
    <div className="h-screen flex flex-col w-full p-4">
      {/* Navigation Tabs */}
      <div className="flex gap-5 text-xl">
        <NavLink
          to="?view=overview"
          className={`sub-nav-item ${view === "overview" ? "sub-nav-active" : ""}`}
        >
          Overview
        </NavLink>
        <NavLink
          to="?view=tasks"
          className={`sub-nav-item ${view === "tasks" ? "sub-nav-active" : ""}`}
        >
          Tasks
        </NavLink>
        <NavLink
          to="?view=teams"
          className={`sub-nav-item ${view === "teams" ? "sub-nav-active" : ""}`}
        >
          Teams
        </NavLink>
      </div>

      {/* Content Area */}
      <div className="flex-1 rounded-lg p-4 overflow-y-auto w-full bg-white blue-scrollbar">
        {view === "tasks" && <DashboardTasks />}
        {view === "teams" && <DashboardTeamView />}
        {view === "overview" && <DashboardOverview />}
      </div>
    </div>
  );
};

export default OwnerDashboardElement;
