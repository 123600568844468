// components/NotificationPanel.tsx
import React from "react";
import NotificationBlock from "../../components/notifications/NotificationBlock";
import EventNotification from "../../../interfaces/eventNotification/EventNotification";

interface NotificationPanelProps {
  notifications: EventNotification[];
}

const NotificationPanel: React.FC<NotificationPanelProps> = ({ notifications }) => {
  return (
    <div>
      {notifications.length > 0 ? (
        notifications.map((notification) => (
          <div key={notification.id} className="">
              <NotificationBlock notification={notification} />
          </div>
        ))
      ) : (
        <div className="p-2 text-gray-500">No notifications</div>
      )}
    </div>
  );
};

export default NotificationPanel;
