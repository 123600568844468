// file: pages/chat/ChatPage.tsx
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import {
  Conversation,
  sendMessage,
} from "../../../services/chatService/ChatService";
import ChatWindow from "./ChatWindow";
import Avatar from "../../../interfaces/avatar/Avatar";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import ConversationList from "./ConversationListComponent";

const ChatPage: React.FC = () => {
  const { currentUser } = useAuth();
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation | null>(null);
  const [avatars, setAvatars] = useState<Avatar[]>([]);
  const [authToken, setAuthToken] = useState<string>("");

  // Grab the token once
  useEffect(() => {
    let isMounted = true;
    if (!currentUser) return;
    currentUser.getIdToken().then((token) => {
      if (isMounted) setAuthToken(token);
    });
    return () => {
      isMounted = false;
    };
  }, [currentUser]);

  // Load any stored avatars
  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars || []);
  }, []);

  const userId = currentUser?.uid; // The local user’s ID from Firebase

  // Handler for sending a new message
  const handleSendMessage = async (
    message: string,
    conversationId: number,
    file?: File
  ) => {
    // 1) Ensure we have a conversation
    if (!conversationId) {
      alert("No conversation selected");
      return;
    }

    // 2) If there's neither text nor file, there's nothing to send
    if (!message.trim() && !file) {
      return;
    }

    let attachmentUrl: string | undefined;

    // 3) Upload the file if present
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch("/api/chat/upload-attachment", {
          method: "POST",
          body: formData,
        });
        if (!response.ok) {
          throw new Error("File upload failed.");
        }
        const data = await response.json();
        attachmentUrl = data.attachmentUrl;
      } catch (error) {
        console.error("File upload error:", error);
      }
    }

    // 4) Now send the chat message
    try {
      const token = await currentUser?.getIdToken();
      if (!token) {
        console.error("No auth token found.");
        return;
      }

      await sendMessage(token, conversationId, message, attachmentUrl);
    } catch (err) {
      console.error("Failed to send message:", err);
    }
  };

  const handleSelectConversation = (conversation: Conversation) => {
    if (!currentUser) return;
    const uid = currentUser.uid;

    // // Figure out who the other participant is
    // let recipient;
    // if (uid === conversation.participantA.id) {
    //   recipient = conversation.participantB.id;
    // } else if (uid === conversation.participantB.id) {
    //   recipient = conversation.participantA.id;
    // } else {
    //   return;
    // }

    // setRecipientId(recipient);
    setSelectedConversation(conversation);
  };

  return (
    <div className="relative bg-white dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full h-[88vh]">
      <div className="bg-black w-full h-[300px] rounded-b-3xl"></div>
      <div className="overflow-x-auto -top-64 -mb-64 relative dark:bg-card-dark dark:text-text-dark bg-white rounded-3xl shadow-lg w-11/12 mx-auto -mt-10">
        <div
          className="bg-white h-[75vh] overflow-hidden"
          style={{ display: "flex" }}
        >
          {/* Show conversation list only if we have a token and user ID */}
          {authToken !== "" && userId && (
            <ConversationList
              authToken={authToken}
              userId={userId}
              avatars={avatars}
              selectedConversationId={selectedConversation?.id || null}
              onSelectConversation={handleSelectConversation}
            />
          )}

          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            {selectedConversation && currentUser && userId && (
              <div>
                <ChatWindow
                  authToken={authToken}
                  avatars={avatars}
                  currentUserId={userId}
                  sendMessageFn={handleSendMessage}
                  conversation={selectedConversation}
                />
              </div>
            )}

            {!selectedConversation && (
              <div className="bg-[#F7F9FC] h-full w-full">
                <div className="text-muted text-center absolute top-1/2 left-1/2">
                  Please select a conversation or create a new one to begin.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
