import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from "recharts";
import CustomTooltip from "./CustomTooltip";
import { ClosedTaskStatsForTeamResponse } from "../../../../interfaces/stats/OwnerDashboardStats";

interface StackedPillBarChartProps {
  data: ClosedTaskStatsForTeamResponse;
  selectUser: (userId: string) => void;
}

const StackedPillBarChart: React.FC<StackedPillBarChartProps> = ({ data, selectUser }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data.teamStats}
        layout="vertical"
        margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
      >
        <XAxis 
          type="number" 
          className="text-xs"
          axisLine={false} 
          tickLine={false}
          allowDecimals={false}
          hide={true}
        />
        <YAxis 
          dataKey="displayName" 
          type="category" 
          className="text-xs"
          axisLine={false} 
          tickLine={false}
        />

        {/* Attach your custom tooltip here */}
        <Tooltip content={<CustomTooltip />} cursor={false} />

        {/* <Legend /> */}

        {/* Left (bottom) bar */}
        <Bar
          dataKey="closedOnTime"
          name="On Time"     // <--- This will appear in tooltip as entry.name
          stackId="a"
          fill="#3DD598"
          radius={[20, 0, 0, 20]}
          background={{ fill: "#f0f0f0", stroke: "#ccc", radius: 20 }}
          maxBarSize={15}
          onClick={(entry) => {
            selectUser(entry.payload.userId);
          }}
        />

        {/* Right (top) bar */}
        <Bar
          dataKey="closedLate"
          name="Late"       // <--- This will appear in tooltip as entry.name
          stackId="a"
          fill="#be123c"
          radius={[0, 20, 20, 0]}
          maxBarSize={15}
          onClick={(entry) => {
            selectUser(entry.payload.userId);
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedPillBarChart;