// PDFEditor.tsx

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../firebase/AuthProvider";
import DriveFile from "../../../../interfaces/file/DriveFile";
import {
  SignatureField,
  SignatureType,
} from "../../../../interfaces/file/fileSignature/SignatureBox";
import {
  getDriveFileById,
  getFileById,
} from "../../../../services/fileServices/FileService";
import { CreateSignatureRequestPayload } from "../../../../interfaces/file/fileSignature/SignatureCreationRequests";
import { createSignatureRequest } from "../../../../services/fileServices/signatures/SignatureService";
import PDFViewer from "./PDFViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faChevronDown,
  faChevronRight,
  faFont,
  faICursor,
  faSignature,
  faUserPen,
} from "@fortawesome/free-solid-svg-icons";
import { formatFileSize } from "../../../../utilities/fileUtils";
import {
  formatDateTime,
} from "../../../../utilities/dateUtils";
import Banner from "../../../ui/Banner/Banner";

const PDFEditor: React.FC = () => {
  const { currentUser } = useAuth();
  const { fileId } = useParams();
  const navigate = useNavigate();

  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileMetadata, setFileMetadata] = useState<DriveFile | null>(null);
  const [scale, setScale] = useState(1.0);
  const [signatureFields, setSignatureFields] = useState<SignatureField[]>([]);
  const [selectedBoxId, setSelectedBoxId] = useState<string | null>(null);
  const [activeNav, setActiveNav] = useState<string | null>("document");

  // For demonstration, a basic array of recipients you can manage
  const [recipients, setRecipients] = useState<string[]>([]);

  // Toggling "Add Box" mode
  const [isPlacingBox, setIsPlacingBox] = useState<boolean>(false);

  // Suppose we get the user’s permission from your backend or Firestore:
  const [userCanEdit, setUserCanEdit] = useState<boolean>(false);

  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  // Feedback / loading states
  const [requestFeedback, setRequestFeedback] = useState<string | null>(null);
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);

  useEffect(() => {
    // Example permission check
    // @TODO: Handle Authorization properly
    setUserCanEdit(true);
  }, [currentUser, fileId, navigate]);

  useEffect(() => {
    const fetchFile = async () => {
      if (!fileId || !currentUser) return;

      try {
        const authToken = await currentUser.getIdToken();
        const file = await getDriveFileById(fileId, authToken);
        if (file) {
          setFileMetadata(file);

          const url = await getFileById(fileId, authToken);
          if (url) {
            setFileUrl(url);
          }
        }
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };

    fetchFile();
  }, [fileId, currentUser]);

  // Called by PDFViewer whenever signatureFields change
  const handleSignatureFieldsChange = (fields: SignatureField[]) => {
    setSignatureFields(fields);
  };

  // Called by PDFViewer when user clicks a box (to select it)
  const handleBoxSelect = (boxId: string) => {
    setActiveNav("signature-boxes");
    setSelectedBoxId(boxId);
  };

  // The currently selected signature box
  const selectedBox = signatureFields.find((b) => b.id === selectedBoxId);

  // Utility to update the selected box
  const updateSelectedBox = (updates: Partial<SignatureField>) => {
    if (!selectedBox) return;
    setSignatureFields((prev) =>
      prev.map((box) =>
        box.id === selectedBoxId ? { ...box, ...updates } : box
      )
    );
  };

  /**
   * Called by PDFViewer when user clicks on the PDF to place a new box
   */
  const handlePlaceBox = (pageNumber: number, x: number, y: number) => {
    // Turn off "placing" mode once we place the box
    setIsPlacingBox(false);

    // Create a new signature box with default size, type, etc.
    const newBox: SignatureField = {
      id: crypto.randomUUID(),
      pageNumber,
      x,
      y,
      width: 150,
      height: 40,
      signerEmail: "", // user can fill in
      signatureValue: "", // user can fill in
      signatureType: SignatureType.SIGNATURE,
    };

    setSignatureFields((prev) => [...prev, newBox]);
    setSelectedBoxId(newBox.id); // auto-select the newly created box
  };

  const handleRequestSignature = async () => {
    if (!fileId || !currentUser) return;
    if (signatureFields.length === 0) {
      setErrorMessage(
        "Please add at least one signature box before requesting."
      );
      return;
    }

    setIsRequestLoading(true);

    try {
      const authToken = await currentUser.getIdToken();

      // For simplicity, let's just request the signature for the first box's signer
      const mainSignerEmail =
        signatureFields[0].signerEmail || currentUser.email || "";

      const payload: CreateSignatureRequestPayload = {
        fileId,
        signatureBoxes: signatureFields.map((field) => ({
          pageNumber: field.pageNumber,
          x: field.x,
          y: field.y,
          width: field.width,
          height: field.height,
          signatureValue: field.signatureValue ?? "",
          assignedSignerEmail: field.signerEmail ?? mainSignerEmail,
          boxType: field.signatureType
        })),
      };

      const result = await createSignatureRequest(payload, authToken);
      if (result) {
        setSuccessMessage("Signature request created successfully!");
      } else {
        setErrorMessage("Error creating signature request.");
      }
    } catch (error) {
      console.error("Error creating signature request:", error);
      setErrorMessage("Error creating signature request.");
    }
    setIsRequestLoading(false);
  };

  const toggleNav = (elementName: string) => {
    if (activeNav === elementName) {
      setActiveNav(null);
    } else {
      setActiveNav(elementName);
    }
  };

  const toggleSelectedBox = (boxId: string) => {
    if (selectedBoxId && selectedBoxId === boxId) {
      setSelectedBoxId(null);
    } else {
      setSelectedBoxId(boxId);
    }
  };

  return (
    <div
      className="relative bg-[#F7F9FC] dark:bg-background-dark sm:min-w-[640px] 
    overflow-x-auto max-w-full overflow-hidden h-[100vh]"
    >
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>

      <div
        className="-top-64 relative dark:bg-card-dark dark:text-text-dark 
                        bg-[#F7F9FF] rounded-3xl shadow-lg w-11/12 mx-auto 
                        min-h-[600px] -mt-10 overflow-auto mb-10"
      >
        <div className="bg-white border-b">
          <div className="flex justify-between items-center px-4">
            <div className="flex gap-4 items-center align-middle">
              <button
                onClick={() => navigate(`/view/file/${fileId}`)}
                className="border px-3 py-1 rounded-full hover:bg-gray-100"
              >
                X
              </button>
              <h1 className="text-xl font-bold">
                Editing: {fileMetadata?.fileName}
              </h1>
            </div>
            {/* Request signature button */}
            <div className="p-4">
              <button
                onClick={handleRequestSignature}
                className="bg-blue-600 text-white px-4 py-2 rounded"
                disabled={isRequestLoading}
              >
                {isRequestLoading ? "Sending..." : "Request Signature"}
              </button>
            </div>
          </div>
          <div className="messages mx-10">
            {successMessage !== "" && (
              <Banner
                status="success"
                message={successMessage}
                dismissBanner={() => setSuccessMessage("")}
              />
            )}
            {errorMessage !== "" && (
              <Banner
                status="error"
                message={errorMessage}
                dismissBanner={() => setErrorMessage("")}
              />
            )}
          </div>
        </div>

        {/* Feedback banner */}
        {requestFeedback && (
          <div className="mb-4 bg-green-100 text-green-700 p-2 rounded">
            {requestFeedback}
          </div>
        )}

        {/* Only render viewer if we have a URL and permission */}
        {fileUrl && userCanEdit && (
          <div className="flex gap-4">
            {/* Left side: PDF + control panel */}
            <div className="flex-1">
              {/* PDF Zoom Controls */}
              <div className="flex items-center gap-2 mb-2 justify-end">
                <label htmlFor="scale">Zoom: </label>
                <input
                  type="range"
                  id="scale"
                  min="0.5"
                  max="2.0"
                  step="0.1"
                  value={scale}
                  onChange={(e) => setScale(parseFloat(e.target.value))}
                />
                <span>{scale.toFixed(1)}x</span>
              </div>

              {/* Button to add a new box by clicking on the PDF */}
              <div className="flex gap-2 mb-4 justify-end">
                <button
                  onClick={() => setIsPlacingBox(!isPlacingBox)}
                  className={`px-4 py-2 rounded ${
                    isPlacingBox
                      ? "bg-orange-500 text-white"
                      : "bg-blue-600 text-white"
                  }`}
                >
                  {isPlacingBox ? "Cancel Add Box" : "Add Box"}
                </button>
              </div>

              <PDFViewer
                fileUrl={fileUrl}
                scale={scale}
                canEdit={true}
                selectedSignerEmail=""
                onSignatureFieldsChange={handleSignatureFieldsChange}
                signatureFields={signatureFields}
                onBoxSelect={handleBoxSelect}
                // <-- Pass a callback for placing new boxes
                isPlacingBox={isPlacingBox}
                onPlaceBox={handlePlaceBox}
              />
            </div>

            <div></div>

            {/* Right side: Panel with signature boxes + recipients */}
            <div className="w-64 border-l bg-white">
              <div className="p-4 border-b">
                <div
                  className="flex justify-between items-center align-middle cursor-pointer"
                  onClick={() => toggleNav("document")}
                >
                  <h2 className="font-bold">Document</h2>
                  <FontAwesomeIcon
                    className="text-inactive cursor-pointer"
                    icon={
                      activeNav === "document" ? faChevronDown : faChevronRight
                    }
                  />
                </div>
                {activeNav === "document" && (
                  <div className="flex flex-col text-sm mt-2 gap-3">
                    <div>
                      <div className="font-bold">File Type</div>
                      <div className="text-muted text-xs">
                        {fileMetadata?.mimeType || "N/A"}
                      </div>
                    </div>
                    <div>
                      <div className="font-bold">File Name</div>
                      <div className="text-muted text-xs">
                        {fileMetadata?.fileName}
                      </div>
                    </div>
                    <div>
                      <div className="font-bold">Created</div>
                      <div className="text-muted text-xs">
                        {fileMetadata?.createDate
                          ? formatDateTime(new Date(fileMetadata.createDate))
                          : "N/A"}
                      </div>
                    </div>
                    <div>
                      <div className="font-bold">Last Updated</div>
                      <div className="text-muted text-xs">
                        {fileMetadata?.updateDate
                          ? formatDateTime(new Date(fileMetadata.updateDate))
                          : "N/A"}
                      </div>
                    </div>
                    <div>
                      <div className="font-bold">File Size</div>
                      <div className="text-muted text-xs">
                        {formatFileSize(fileMetadata?.size || 0)}
                      </div>
                    </div>
                    <div>
                      <div className="font-bold">Version</div>
                      <div className="text-muted text-xs">
                        {fileMetadata?.versionId
                          ? "v" + fileMetadata.versionId
                          : "v1"}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="p-4 border-b">
                <div
                  className="flex justify-between items-center align-middle cursor-pointer"
                  onClick={() => toggleNav("signature-boxes")}
                >
                  <h2 className="font-bold">Signature Boxes</h2>
                  <FontAwesomeIcon
                    className="text-inactive cursor-pointer"
                    icon={
                      activeNav === "signature-boxes"
                        ? faChevronDown
                        : faChevronRight
                    }
                  />
                </div>
                {activeNav === "signature-boxes" && (
                  <div className="mt-2">
                    {signatureFields.map((field) => (
                      <div
                        key={field.id}
                        className={`p-2 mb-2 cursor-pointer border rounded
                          ${
                            field.id === selectedBoxId
                              ? "bg-blue-100 text-blue-700"
                              : ""
                          }`}
                        onClick={() => toggleSelectedBox(field.id)}
                      >
                        <div className="flex align-middle items-center gap-2">
                          {field.signatureType === SignatureType.DATE && (
                            <FontAwesomeIcon className="w-6" icon={faCalendarDays} />
                          )}
                          {field.signatureType === SignatureType.SIGNATURE && (
                            <FontAwesomeIcon className="w-6" icon={faSignature} />
                          )}
                          {field.signatureType === SignatureType.INITIALS && (
                            <FontAwesomeIcon className="w-6" icon={faFont} />
                          )}
                          {field.signatureType === SignatureType.TEXT && (
                            <FontAwesomeIcon className="w-6" icon={faICursor} />
                          )}{" "}
                          <div>{field.signerEmail || "(none)"}</div>
                        </div>
                        {/* <div>Page: {field.pageNumber}</div>
                      <div>Type: {field.signatureType || "Signature"}</div>
                      <div>Assigned: {field.signerEmail || "(none)"}</div> */}
                      </div>
                    ))}
                    {signatureFields.length === 0 && (
                      <div className="text-muted text-xs">
                        There are currently no Signature Fields created.
                        Right-click anywhere on the PDF to add a new field.
                      </div>
                    )}

                    {selectedBox && (
                      <div className="mt-4 p-2 border rounded">
                        <h3 className="font-semibold">Edit Box</h3>

                        {/* Signature Type */}
                        <label className="block mt-2 text-sm">
                          Signature Type
                        </label>
                        <select
                          className="border w-full p-1"
                          value={
                            selectedBox.signatureType || SignatureType.SIGNATURE
                          }
                          onChange={(e) =>
                            updateSelectedBox({
                              signatureType: e.target.value as SignatureType,
                            })
                          }
                        >
                          <option value={SignatureType.DATE}>Date</option>
                          <option value={SignatureType.INITIALS}>
                            Initials
                          </option>
                          <option value={SignatureType.SIGNATURE}>
                            Signature
                          </option>
                          <option value={SignatureType.TEXT}>Text</option>
                        </select>

                        {/* Signer Email */}
                        <label className="block mt-2 text-sm">
                          Signer Email
                        </label>
                        <input
                          type="email"
                          className="border w-full p-1"
                          value={selectedBox.signerEmail || ""}
                          onChange={(e) =>
                            updateSelectedBox({ signerEmail: e.target.value })
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* Recipients Panel (optional) */}
              <div className="p-4 border-b">
                <div
                  className="flex justify-between items-center align-middle cursor-pointer"
                  onClick={() => toggleNav("recipients")}
                >
                  <h2 className="font-bold">Recipients</h2>
                  <FontAwesomeIcon
                    className="text-inactive cursor-pointer"
                    icon={
                      activeNav === "recipients"
                        ? faChevronDown
                        : faChevronRight
                    }
                  />
                </div>{" "}
                {activeNav === "recipients" && (
                  <div className="mt-2">
                    {signatureFields.filter((field) => field.signerEmail).map((field, i) => (
                      <div key={i} className="border rounded-lg  p-2 mb-1 text-sm flex gap-2 items-center align-middle">
                        <FontAwesomeIcon icon={faUserPen}/>{field.signerEmail}
                      </div>
                    ))}
                    {signatureFields.length === 0 && (
                      <div className="text-muted text-xs">
                        There are currently no Assigned Recipients.
                        Right-click anywhere on the PDF to add a new field. Assign someone to that field for recipients.
                      </div>
                    )}
                    {/* <button
                      className="bg-gray-200 px-2 py-1 rounded text-sm"
                      onClick={() => {
                        const email = prompt("Enter recipient email:");
                        if (email) {
                          setRecipients((prev) => [...prev, email]);
                        }
                      }}
                    >
                      + Add Recipient
                    </button> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PDFEditor;
