import React, { useState, useEffect, memo, useCallback } from 'react';
import * as XLSX from 'xlsx';
import { useAuth } from '../../../../firebase/AuthProvider';
import LoadingSpinner from '../../../ui/Loading/LoadingSpinner';

// Define props for an individual cell
type SheetCellProps = {
  value: string;
  isSelected: boolean;
  onBlur: (newValue: string) => void;
  onSelect: () => void;
};

function areCellPropsEqual(prevProps: SheetCellProps, nextProps: SheetCellProps) {
  // Re-render only if value or isSelected changes
  return (
    prevProps.value === nextProps.value &&
    prevProps.isSelected === nextProps.isSelected
  );
}

/**
 * A memoized cell that only re-renders if the cell's text or selected state changes.
 */
const SheetCell = memo(
  ({ value, isSelected, onBlur, onSelect }: SheetCellProps) => {
    return (
      <td
        style={{
          border: '1px solid #ccc',
          backgroundColor: isSelected ? 'lightblue' : '',
        }}
        onClick={onSelect}
        className='w-full'
      >
        <input
          type="text"
          defaultValue={value || ''}
          onBlur={(e) => onBlur(e.target.value)}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
          }}
        />
      </td>
    );
  },
  areCellPropsEqual
);

// Props for a row
type SheetRowProps = {
  rowIndex: number;
  rowData: string[];
  selectedCell: { row: number; col: number } | null;
  onCellBlur: (colIndex: number, newValue: string) => void;
  onCellSelect: (colIndex: number) => void;
};

/**
 * A row that renders multiple cells.
 * Each cell is told whether it's selected, plus blur/select callbacks.
 */
function SheetRow({
  rowIndex,
  rowData,
  selectedCell,
  onCellBlur,
  onCellSelect,
}: SheetRowProps) {
  return (
    <tr>
      {rowData.map((cellValue, colIndex) => {
        const isSelected =
          selectedCell?.row === rowIndex && selectedCell?.col === colIndex;

        return (
          <SheetCell
            key={colIndex}
            value={cellValue}
            isSelected={isSelected}
            onBlur={(newVal) => onCellBlur(colIndex, newVal)}
            onSelect={() => onCellSelect(colIndex)}
          />
        );
      })}
    </tr>
  );
}

// Props for the main SpreadsheetEditor
type SpreadsheetEditorProps = {
  fileUrl: string; // The URL to fetch the Excel/CSV file from
};

/**
 * Main Spreadsheet Editor
 * 
 * Receives `fileUrl` as a prop, fetches that file, and displays/edit/saves data.
 */
function SpreadsheetEditor({ fileUrl }: SpreadsheetEditorProps) {
  const { currentUser } = useAuth();

  const [tableData, setTableData] = useState<string[][]>([]);
  const [selectedCell, setSelectedCell] = useState<{ row: number; col: number } | null>(null);

  useEffect(() => {
    async function getFile() {
      try {
        // If you need authorization from firebase:
        const response = await fetch(fileUrl);
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: 'array', cellStyles: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json<string[]>(worksheet, {
          header: 1,
          defval: '',
        });
        setTableData(jsonData);
      } catch (error) {
        console.error('Error fetching or parsing file:', error);
      }
    }

    if (fileUrl) {
      getFile();
    }
  }, [fileUrl, currentUser]);

  /**
   * Called after the user blurs a cell in row "rowIndex" and column "colIndex".
   */
  const handleCellBlur = useCallback(
    (rowIndex: number, colIndex: number, newValue: string) => {
      setTableData((prevData) => {
        const updatedData = [...prevData];
        updatedData[rowIndex] = [...updatedData[rowIndex]];
        updatedData[rowIndex][colIndex] = newValue;
        return updatedData;
      });
    },
    []
  );

  /**
   * Called when the user clicks a cell to select it.
   */
  const handleCellSelect = useCallback((rowIndex: number, colIndex: number) => {
    setSelectedCell({ row: rowIndex, col: colIndex });
  }, []);

  /**
   * Saves data back to a new XLSX file (multipart/form-data POST).
   * Adjust as needed if your server endpoint or method is different.
   */
  const saveData = async () => {
    try {
      const worksheet = XLSX.utils.aoa_to_sheet(tableData);
      const newWorkbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Sheet1');

      const wbOut = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbOut], { type: 'application/octet-stream' });

      const formData = new FormData();
      formData.append('file', blob, 'updatedFile.xlsx');

      // Example: POST to /api/file
      // Change to your real endpoint or reuse fileUrl if it's also an upload route
      const res = await fetch('/api/file', {
        method: 'POST',
        body: formData,
      });

      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      alert('File saved successfully!');
    } catch (error) {
      console.error('Error saving data:', error);
      alert('Failed to save file.');
    }
  };

  if (!tableData.length) {
    return <LoadingSpinner/>;
  }

  return (
    <div>
      <h1>Sprout Sheets</h1>
      <table className='bg-white w-full'>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <SheetRow
              key={rowIndex}
              rowIndex={rowIndex}
              rowData={row}
              selectedCell={selectedCell}
              onCellBlur={(colIndex, newVal) => handleCellBlur(rowIndex, colIndex, newVal)}
              onCellSelect={(colIndex) => handleCellSelect(rowIndex, colIndex)}
            />
          ))}
        </tbody>
      </table>
      <button onClick={saveData} style={{ marginTop: '1rem' }}>
        Save
      </button>
    </div>
  );
}

export default SpreadsheetEditor;
