// TaskPage.tsx

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Task from "../../../interfaces/task/Task"; // Import your Task interface
import TaskComponent from "./TaskComponent";
import {
  deleteTaskById,
  getTaskById,
} from "../../../services/taskServices/TaskService";
import Status from "../../../interfaces/task/Status";
import { useAuth } from "../../../firebase/AuthProvider";
import TaskChatComponent from "./TaskChatComponent/TaskChatComponent";
import ConfirmDeleteModal from "../../ui/ConfirmationModals/ConfirmDeleteModal";
import { createAdministrativeTransactionRequest } from "../../../services/AdministrativeRequestServices/AdministrativeRequestService";
import Banner from "../../ui/Banner/Banner";
import Unathorized from "../errorPages/Unathorized";

const TaskPage: React.FC = () => {
  const { currentRoles } = useAuth();
  const navigate = useNavigate();
  const { taskId } = useParams(); // Get the taskId from the route parameter
  const [task, setTask] = useState<Task | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageStatus, setMessageStatus] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [unauthorized, setUnauthorized] = useState<boolean>(false);
  const [statusOptions, setStatusOptions] = useState<Status[] | null>([
    {
      name: "READY",
    },
    {
      name: "ISSUES FOUND",
    },
    {
      name: "IN REVIEW",
    },
  ]);

  const { currentUser } = useAuth();

  useEffect(() => {
    const taskIdNum = taskId?.split("-")[1];

    // Fetch the task with taskId and update the state
    if (taskIdNum !== undefined) {
      setIsLoading(true);
      const fetchTask = async () => {
        if (currentUser !== null) {
          const authToken: string = await currentUser.getIdToken();
          try {
            const fetchedTask = await getTaskById(taskIdNum, authToken);
            setTask(fetchedTask);
            setIsLoading(false);
          } catch (error: any) {
            if (error.message === "Unauthorized") {
              setUnauthorized(true);
            } else {
              console.error("Error fetching task", error);
            }
            setIsLoading(false);
          }
        }
      };
      fetchTask();
    }
  }, [taskId, statusOptions, currentUser]);

  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      if (currentUser) {
        const authToken: string = await currentUser.getIdToken();
        if (task) {
          await deleteTaskById(authToken, task?.id);
          // Assuming delete is successful
          setIsLoading(false);
          setShowModal(false);
          navigate("/home");
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error deleting the task:", error);
    }
  };

  if (unauthorized) {
    return <Unathorized />;
  }

  // This function will submit a "Task Reactivation" request.
  const submitRequest = async () => {
    if (!currentUser || !taskId) return;

    try {
      const authToken: string = await currentUser.getIdToken();

      // Assume these IDs are known or retrieved from your backend/config
      const TASK_REACTIVATION_TRANSACTION_TYPE_ID = "Task Reactivation"; // Replace with actual ID
      const PENDING_STATUS_ID = "pending"; // Replace with actual status ID for a "Pending" request

      const newRequest = {
        requesterId: currentUser.uid,
        transactionTypeId: TASK_REACTIVATION_TRANSACTION_TYPE_ID,
        statusId: PENDING_STATUS_ID,
        taskId: parseInt(taskId.split("-")[1]),
      };

      const createdRequest = await createAdministrativeTransactionRequest(
        authToken,
        newRequest
      );
      if (createdRequest) {
        // Handle success: navigate somewhere, show a success message, etc.
        setMessage(
          "Task Reactivation request submitted successfully to the organization admin."
        );
        setMessageStatus("success");
      } else {
        setMessage("Failed to submit request. Please try again later.");
        setMessageStatus("error");
      }
    } catch (error) {
      console.error("Error submitting Task Reactivation request:", error);
      setMessage("Failed to submit request. Please try again later.");
      setMessageStatus("error");
    }
  };

  const dismissBanner = () => {
    setMessage("");
  };

  return (
    <div className="relative bg-background dark:bg-background-dark sm:min-w-[640px] max-w-full inter-font">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="relative justify-center flex flex-wrap bg-[#F7F9FF] dark:bg-[#141416] -top-64 -mt-10 w-11/12 mx-auto rounded-3xl shadow-sm border pb-10">
        <div className="flex flex-row justify-between px-10 py-5 items-center align-middle w-full">
          <div className="text-2xl font-medium text-gray-900 inter-font text-start pb-0 ">
            Task
          </div>
          <div className="">
            {currentRoles?.some((role) => role.name === "PROGRAM MANAGER") && (
              <div className="flex gap-2">
                {task?.status.name === "COMPLETED" && !isLoading && (
                  <div
                    className="bg-[#3dd598] text-white rounded px-3 py-1 cursor-pointer"
                    onClick={() => submitRequest()}
                  >
                    Request Reactivation
                  </div>
                )}
                {task?.status.name !== "COMPLETED" && !isLoading && (
                  <div
                    className="bg-red-600 text-white rounded px-3 py-1 cursor-pointer"
                    onClick={() => setShowModal(true)}
                  >
                    Delete
                  </div>
                )}
              </div>
            )}
            {showModal && (
              <ConfirmDeleteModal
                onConfirm={confirmDelete}
                onCancel={() => setShowModal(false)}
                isLoading={isLoading}
              >
                Are you sure you want to delete this task?
              </ConfirmDeleteModal>
            )}
          </div>
        </div>
        {unauthorized ? (
          <Unathorized />
        ) : (
          <div className="w-full">
            {task && (
              <div className="w-full">
                {message !== "" && (
                  <div className="w-11/12 mx-auto mb-5">
                    <Banner
                      message={message}
                      dismissBanner={dismissBanner}
                      status={messageStatus}
                    />
                  </div>
                )}
                <div className="flex flex-wrap w-full justify-center gap-4 px-4">
                  {/* Task Section */}
                  <div className="bg-card dark:bg-card-dark rounded-lg p-2 w-full sm:w-3/4 lg:w-7/12 max-w-[800px] flex-grow">
                    <TaskComponent task={task} statusOptions={statusOptions} />
                  </div>

                  {/* Chat Section */}
                  <div className="rounded-lg w-full sm:w-3/4 lg:w-4/12 max-w-[600px] flex-grow">
                    <TaskChatComponent taskId={task.id} />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskPage;
