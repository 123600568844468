// EmojiPicker.tsx
import React, { useState } from "react";

interface EmojiPickerProps {
  onSelect: (emoji: string) => void;
}

const EmojiPicker: React.FC<EmojiPickerProps> = ({ onSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter emojis based on the search term
  const filteredEmojis = emojiData.filter((item) =>
    item.keywords.some((keyword) =>
      keyword.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div className="emoji-picker bg-white dark:bg-card-dark border dark:border-card-dark rounded-lg shadow-lg w-64 max-h-80 overflow-y-auto z-10 p-2">
      <input
        type="text"
        placeholder="Search emojis..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-2 mb-2 border rounded"
      />
      <div className="flex flex-wrap">
        {filteredEmojis.map((item, index) => (
          <button
            key={index}
            className="cursor-pointer p-2 text-xl focus:outline-none"
            onClick={() => onSelect(item.emoji)}
          >
            {item.emoji}
          </button>
        ))}
      </div>
    </div>
  );
};

export default EmojiPicker;

// emojiData.ts
export interface EmojiItem {
  emoji: string;
  keywords: string[];
}

// emojiData.ts
export const emojiData: EmojiItem[] = [
  // Happy and Smiling Emojis
  { emoji: "😀", keywords: ["grinning", "face", "smile", "happy", "joy"] },
  { emoji: "😃", keywords: ["grinning", "face", "big", "eyes", "happy"] },
  { emoji: "😄", keywords: ["grinning", "face", "smile", "eyes", "happy"] },
  { emoji: "😁", keywords: ["beaming", "face", "smile", "happy"] },
  {
    emoji: "😆",
    keywords: ["grinning", "squinting", "face", "laugh", "happy"],
  },
  { emoji: "😅", keywords: ["grinning", "face", "sweat", "nervous"] },
  { emoji: "😂", keywords: ["face", "tears", "joy", "laugh", "happy"] },
  { emoji: "🤣", keywords: ["rolling", "floor", "laughing", "rofl"] },
  { emoji: "😊", keywords: ["smiling", "face", "smile", "blush", "happy"] },
  { emoji: "😇", keywords: ["smiling", "face", "halo", "angel", "innocent"] },
  { emoji: "🙂", keywords: ["slightly", "smiling", "face", "smile"] },
  { emoji: "😉", keywords: ["winking", "face", "wink", "playful"] },
  { emoji: "😍", keywords: ["smiling", "face", "heart", "eyes", "love"] },
  { emoji: "🥰", keywords: ["smiling", "face", "hearts", "love", "affection"] },
  { emoji: "😘", keywords: ["face", "blowing", "kiss", "love"] },

  // Love and Affection Emojis
  { emoji: "😗", keywords: ["kissing", "face", "affection"] },
  { emoji: "😙", keywords: ["kissing", "face", "smile"] },
  { emoji: "😚", keywords: ["kissing", "face", "closed", "eyes"] },
  { emoji: "❤️", keywords: ["heart", "love", "affection", "romance"] },
  { emoji: "💋", keywords: ["kiss", "lipstick", "love", "affection"] },

  // Playful and Silly Emojis
  { emoji: "😋", keywords: ["face", "savoring", "food", "yum"] },
  { emoji: "😛", keywords: ["face", "tongue", "playful", "silly"] },
  { emoji: "😜", keywords: ["winking", "face", "tongue", "silly"] },
  { emoji: "🤪", keywords: ["zany", "face", "crazy", "silly"] },
  { emoji: "😝", keywords: ["squinting", "face", "tongue", "silly"] },
  { emoji: "🤑", keywords: ["money", "mouth", "face", "rich"] },

  // Gestures and Reactions
  { emoji: "🤗", keywords: ["hugging", "face", "hug", "smile"] },
  { emoji: "🤭", keywords: ["face", "hand", "mouth", "oops"] },
  { emoji: "🤫", keywords: ["shushing", "face", "quiet", "secret"] },
  { emoji: "🤔", keywords: ["thinking", "face", "thoughtful", "hmm"] },
  { emoji: "🤐", keywords: ["zipper", "mouth", "face", "secret"] },
  { emoji: "🤨", keywords: ["face", "raised", "eyebrow", "skeptical"] },
  { emoji: "😐", keywords: ["neutral", "face", "meh", "indifferent"] },
  { emoji: "😑", keywords: ["expressionless", "face", "blank"] },

  // Sad and Crying Emojis
  { emoji: "😔", keywords: ["pensive", "face", "sad", "disappointed"] },
  { emoji: "😞", keywords: ["disappointed", "face", "sad", "down"] },
  { emoji: "😟", keywords: ["worried", "face", "concerned", "sad"] },
  { emoji: "😢", keywords: ["crying", "face", "sad", "tear"] },
  { emoji: "😭", keywords: ["loudly", "crying", "face", "sad"] },
  { emoji: "😩", keywords: ["weary", "face", "tired", "frustrated"] },
  { emoji: "😫", keywords: ["tired", "face", "exhausted"] },
  { emoji: "🥺", keywords: ["pleading", "face", "puppy", "eyes"] },

  // Angry and Annoyed Emojis
  { emoji: "😠", keywords: ["angry", "face", "mad", "irritated"] },
  { emoji: "😡", keywords: ["pouting", "face", "angry", "mad"] },
  { emoji: "🤬", keywords: ["cursing", "face", "angry", "mad", "swear"] },
  { emoji: "😤", keywords: ["face", "triumph", "proud", "snort"] },

  // Surprise and Shock Emojis
  { emoji: "😯", keywords: ["hushed", "face", "surprised", "silent"] },
  { emoji: "😲", keywords: ["astonished", "face", "shocked", "wow"] },
  { emoji: "😱", keywords: ["screaming", "face", "fear", "horror"] },
  { emoji: "🤯", keywords: ["mind", "blown", "exploding", "head"] },

  // Sleepy and Tired Emojis
  { emoji: "😴", keywords: ["sleeping", "face", "zzz", "tired"] },
  { emoji: "😪", keywords: ["sleepy", "face", "tired", "zzz"] },
  { emoji: "😌", keywords: ["relieved", "face", "relaxed", "calm"] },

  // Confused and Disbelief Emojis
  { emoji: "😕", keywords: ["confused", "face", "puzzled", "uncertain"] },
  { emoji: "🙁", keywords: ["slightly", "frowning", "face", "sad"] },
  { emoji: "🤥", keywords: ["lying", "face", "liar", "nose"] },

  // Celebratory and Party Emojis
  { emoji: "🥳", keywords: ["partying", "face", "celebration", "fun"] },
  { emoji: "🎉", keywords: ["party", "celebration", "confetti"] },
  { emoji: "🎊", keywords: ["confetti", "ball", "party", "celebration"] },

  // Animal Emojis
  { emoji: "🐶", keywords: ["dog", "puppy", "animal"] },
  { emoji: "🐱", keywords: ["cat", "animal", "cute"] },
  { emoji: "🐭", keywords: ["mouse", "animal", "cute"] },
  { emoji: "🐹", keywords: ["hamster", "animal", "cute"] },
  { emoji: "🦊", keywords: ["fox", "animal", "clever"] },
  { emoji: "🐼", keywords: ["panda", "bear", "animal", "cute"] },
  { emoji: "🐻", keywords: ["bear", "animal"] },

  // Food Emojis
  { emoji: "🍏", keywords: ["green", "apple", "fruit"] },
  { emoji: "🍔", keywords: ["burger", "food", "fast"] },
  { emoji: "🍕", keywords: ["pizza", "food", "fast"] },
  { emoji: "🍣", keywords: ["sushi", "food", "japanese"] },
  { emoji: "🍦", keywords: ["ice", "cream", "dessert", "food"] },
  { emoji: "🍰", keywords: ["cake", "dessert", "food", "sweet"] },

  // Weather and Nature Emojis
  { emoji: "🌞", keywords: ["sun", "happy", "weather", "bright"] },
  { emoji: "🌧️", keywords: ["rain", "weather", "storm", "cloud"] },
  { emoji: "⛈️", keywords: ["thunderstorm", "weather", "storm"] },
  { emoji: "🌈", keywords: ["rainbow", "weather", "happy"] },

  // Objects and Miscellaneous
  { emoji: "💡", keywords: ["light", "bulb", "idea", "bright"] },
  { emoji: "🔥", keywords: ["fire", "lit", "burn", "hot"] },
  { emoji: "💀", keywords: ["skull", "death", "danger"] },
  { emoji: "🎁", keywords: ["gift", "present", "birthday"] },
  { emoji: "📱", keywords: ["phone", "mobile", "device", "communication"] },

  // Additional Love and Affection Emojis
  { emoji: "💖", keywords: ["sparkling", "heart", "love"] },
  { emoji: "💞", keywords: ["revolving", "hearts", "love"] },
  { emoji: "💓", keywords: ["heartbeat", "love", "affection"] },

  // Additional Playful and Silly Emojis
  { emoji: "😮", keywords: ["open", "mouth", "shock", "surprised"] },

  // Additional Angry and Annoyed Emojis
  { emoji: "😮‍💨", keywords: ["exhale", "relief", "disappointed"] },

  // Additional Celebratory and Party Emojis
  { emoji: "🎂", keywords: ["cake", "birthday", "celebration"] },

  // Additional Animal Emojis
  { emoji: "🦁", keywords: ["lion", "roar", "animal"] },

  // Additional Food Emojis
  { emoji: "🍎", keywords: ["apple", "red", "fruit"] },
  { emoji: "🎂", keywords: ["cake", "birthday", "celebration"] },

  // Additional Weather and Nature Emojis
  { emoji: "☀️", keywords: ["sun", "sunny", "hot"] },

  // Additional Objects and Miscellaneous
  { emoji: "🖥️", keywords: ["computer", "pc", "desktop"] },
  { emoji: "🕶️", keywords: ["sunglasses", "cool", "style"] },
  { emoji: "🚀", keywords: ["rocket", "space", "launch", "fast"] },

  // Additional Love and Affection Emojis
  { emoji: "💗", keywords: ["growing", "heart", "love", "affection"] },
  { emoji: "💘", keywords: ["heart", "arrow", "love", "crush"] },
  { emoji: "💝", keywords: ["gift", "heart", "love", "present"] },
  { emoji: "💟", keywords: ["heart", "decorative", "symbol"] },

  // Additional Playful and Silly Emojis
  { emoji: "🫣", keywords: ["peeking", "eye", "shy", "curious"] },
  { emoji: "🫢", keywords: ["shocked", "surprised", "oops"] },

  // Additional Angry and Annoyed Emojis
  { emoji: "😒", keywords: ["unimpressed", "side-eye", "annoyed"] },

  // Additional Celebratory and Party Emojis
  { emoji: "🥂", keywords: ["cheers", "champagne", "celebration"] },
  { emoji: "🍾", keywords: ["bottle", "champagne", "celebrate"] },
  { emoji: "🎈", keywords: ["balloon", "party", "celebration"] },

  // Additional Animal Emojis
  { emoji: "🐵", keywords: ["monkey", "animal", "cute"] },
  { emoji: "🐸", keywords: ["frog", "animal", "ribbit"] },
  { emoji: "🦄", keywords: ["unicorn", "magic", "fantasy"] },
  { emoji: "🐢", keywords: ["turtle", "slow", "animal"] },
  { emoji: "🦖", keywords: ["t-rex", "dinosaur", "extinct"] },
  { emoji: "🦉", keywords: ["owl", "wise", "bird"] },

  // Additional Food Emojis
  { emoji: "🥑", keywords: ["avocado", "fruit", "green", "healthy"] },
  { emoji: "🍩", keywords: ["donut", "dessert", "sweet"] },
  { emoji: "🍉", keywords: ["watermelon", "fruit", "summer"] },
  { emoji: "🥦", keywords: ["broccoli", "vegetable", "healthy"] },
  { emoji: "🍩", keywords: ["donut", "dessert", "sweet"] },
  { emoji: "🍿", keywords: ["popcorn", "movie", "snack"] },

  // Additional Weather and Nature Emojis
  { emoji: "🌤️", keywords: ["partly sunny", "cloud", "weather"] },
  { emoji: "🌪️", keywords: ["tornado", "storm", "windy"] },
  { emoji: "❄️", keywords: ["snowflake", "cold", "winter"] },
  { emoji: "🔥", keywords: ["fire", "hot", "lit"] },
  { emoji: "🌊", keywords: ["wave", "ocean", "sea"] },
  { emoji: "🪵", keywords: ["wood", "log", "nature"] },

  // Additional Objects and Miscellaneous
  { emoji: "💡", keywords: ["idea", "lightbulb", "bright"] },
  { emoji: "🎮", keywords: ["video game", "controller", "fun"] },
  { emoji: "💰", keywords: ["money", "bag", "rich"] },
  { emoji: "🔑", keywords: ["key", "lock", "security"] },
  { emoji: "🧸", keywords: ["teddy bear", "plush", "toy"] },
  { emoji: "🎯", keywords: ["target", "bullseye", "goal"] },

  // Additional Activity and Sports Emojis
  { emoji: "⚽", keywords: ["soccer", "football", "sports"] },
  { emoji: "🏀", keywords: ["basketball", "sports", "hoops"] },
  { emoji: "🏈", keywords: ["football", "sports", "game"] },
  { emoji: "🎳", keywords: ["bowling", "game", "fun"] },
  { emoji: "⛷️", keywords: ["skiing", "snow", "sports"] },
  { emoji: "🎭", keywords: ["drama", "theater", "mask"] },
  { emoji: "🏆", keywords: ["trophy", "champion", "win"] },

  // Additional Travel and Transportation Emojis
  { emoji: "🚗", keywords: ["car", "automobile", "drive"] },
  { emoji: "✈️", keywords: ["airplane", "flight", "travel"] },
  { emoji: "🚢", keywords: ["ship", "boat", "cruise"] },
  { emoji: "🚲", keywords: ["bicycle", "bike", "ride"] },

  // Additional Flags
  { emoji: "🇺🇸", keywords: ["USA", "United States", "flag"] },
  { emoji: "🇨🇦", keywords: ["Canada", "flag", "maple"] },
  { emoji: "🇬🇧", keywords: ["UK", "United Kingdom", "flag"] },
  { emoji: "🇦🇺", keywords: ["Australia", "flag", "kangaroo"] },
];
