// UserRolesService.ts
import { RegionDto } from "../../interfaces/team/RegionHierarchy";
import { TeamUser } from "../../interfaces/team/TeamUser";

// Function to fetch a user's role by their user id
export async function getTeamBySupervisor(authToken: string): Promise<TeamUser[] | null> {

  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/users/supervisorTeam` 

  try {
    const response = await fetch(
      apiUrl, 
      {headers: { 
        'Authorization': `Bearer ${authToken}`,
        "Content-Type": "application/json",
      }}
    );

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const userData: TeamUser[] = await response.json();
    // Map the role data to Role interface

    return userData;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}

export async function getRegionHierarchy(
  authToken: string,
  regionId: number,
): Promise<RegionDto | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/users/organization/${regionId}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.error("Failed to fetch region hierarchy:", response.statusText);
      return null;
    }

    const data: RegionDto = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching region hierarchy:", err);
    return null;
  }
}