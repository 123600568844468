import {
  faBan,
  faCircleCheck,
  faCircleInfo,
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface BannerProps {
  message: string;
  dismissBanner: () => void;
  status: string;
}

const Banner: React.FC<BannerProps> = ({
  status,
  message,
  dismissBanner,
}) => {
  return (
    <div>
      {status === "success" && (
        <div className="success-banner">
          <div className="flex justify-between align-middle">
            <div className="flex gap-4">
              <div className="text-lg">
                <FontAwesomeIcon icon={faCircleCheck} />
              </div>
              <div>
                <p className="font-bold">Success!</p>
                <p className="text-sm">{message}</p>
              </div>
            </div>
            <div className="text-lg cursor-pointer" onClick={dismissBanner}>
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>
        </div>
      )}
      {status === "warning" && (
        <div className="warning-banner">
          <div className="flex justify-between align-middle">
            <div className="flex gap-4">
              <div className="text-lg">
                <FontAwesomeIcon icon={faTriangleExclamation} />
              </div>
              <div>
                <p className="font-bold">Warning!</p>
                <p className="text-sm">{message}</p>
              </div>
            </div>
            <div className="text-lg cursor-pointer" onClick={dismissBanner}>
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>
        </div>
      )}
      {status === "info" && (
        <div className="info-banner">
          <div className="flex justify-between align-middle">
            <div className="flex gap-4">
              <div className="text-xl">
                <FontAwesomeIcon icon={faCircleInfo} />
              </div>
              <div>
                <p className="font-bold">Information</p>
                <p className="text-sm">{message}</p>
              </div>
            </div>
            <div className="text-lg cursor-pointer" onClick={dismissBanner}>
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>
        </div>
      )}
      {status === "error" && (
        <div className="error-banner">
          <div className="flex justify-between align-middle">
            <div className="flex gap-4">
              <div className="text-lg">
                <FontAwesomeIcon icon={faBan} />
              </div>
              <div>
                <p className="font-bold">Error!</p>
                <p className="text-sm">{message}</p>
              </div>
            </div>
            <div className="text-lg cursor-pointer" onClick={dismissBanner}>
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
