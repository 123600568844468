import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../firebase/AuthProvider";
import {
  getUserFileById,
  getUserFileMetadata,
} from "../../../services/fileServices/UserDriverService";
import { UserFile } from "../../../interfaces/userDrive/userFile";
import UserFilePDFViewer from "./pdfViewer/UserFilePDFViewer";
import FileViewer from "react-file-viewer";
import { formatFileSize } from "../../../utilities/fileUtils";
import SpreadsheetEditor from "./excel/SpreadsheetEditor";
import { formatDateTime } from "../../../utilities/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const UserFileViewPage: React.FC = () => {
  const { currentUser } = useAuth();
  const { fileId } = useParams();
  const navigate = useNavigate();

  const [fileUrl, setFileUrl] = useState("");
  const [fileMetadata, setFileMetadata] = useState<UserFile | null>(null);
  const [blobMimeType, setBlobMimeType] = useState<string>("");
  const [scale, setScale] = useState(1.0);
  const [zoom, setZoom] = useState<number>(1.0);
  const [textContent, setTextContent] = useState<string>("");

  useEffect(() => {
    const fetchFile = async () => {
      if (!currentUser || !fileId) return;
      try {
        const authToken = await currentUser.getIdToken();
        const file = await getUserFileMetadata(fileId, authToken);
        if (!file) return;

        setFileMetadata(file);
        const fileLoc = await getUserFileById(Number(fileId), authToken);
        if (!fileLoc) return;

        setFileUrl(fileLoc);
        const response = await fetch(fileLoc);
        const blob = await response.blob();

        let inferredMimeType = file.mimeType || blob.type;
        setBlobMimeType(inferredMimeType);

        if (inferredMimeType === "text/plain") {
          const text = await response.text();
          setTextContent(text);
        }
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };
    fetchFile();
  }, [currentUser, fileId]);

  const renderViewer = () => {
    if (!fileUrl) return <div>Loading...</div>;

    const extension = fileMetadata?.name?.split(".").pop()?.toLowerCase();

    if (blobMimeType === "application/pdf" || extension === "pdf") {
      return <UserFilePDFViewer fileUrl={fileUrl} scale={scale} />;
    }

    if (blobMimeType.startsWith("image/")) {
      return (
        <div className="flex flex-col items-center overflow-hidden h-full">
          <img src={fileUrl} alt={fileMetadata?.name} className="max-w-full" />
          <div className="flex items-center gap-2 mt-2">
            <label>Zoom: </label>
            <input
              type="range"
              min="0.5"
              max="3.0"
              step="0.1"
              value={zoom}
              onChange={(e) => setZoom(parseFloat(e.target.value))}
            />
            <span>{zoom.toFixed(1)}x</span>
          </div>
        </div>
      );
    }

    if (blobMimeType === "text/plain") {
      return (
        <pre className="p-4 bg-white overflow-auto h-full">{textContent}</pre>
      );
    }

    if (blobMimeType.startsWith("video/")) {
      return <video src={fileUrl} controls className="w-full h-full" />;
    }

    if (blobMimeType.includes("spreadsheet")) {
      return (
        <div className="overflow-auto h-full">
          <SpreadsheetEditor fileUrl={fileUrl} />
        </div>
      );
    }

    return <FileViewer fileType={extension || "unknown"} filePath={fileUrl} />;
  };

  return (
    <div className="relative bg-[#F7F9FC] dark:bg-background-dark sm:min-w-[640px] overflow-hidden max-w-full h-screen">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark rounded-b-3xl"></div>
      <div className="-top-64 relative bg-white dark:bg-card-dark rounded-3xl shadow-lg w-11/12 mx-auto min-h-[600px] -mt-10 flex flex-col h-[70vh]">
        <div className="p-5 flex justify-between border-b">
          <h1 className="text-xl font-bold">{fileMetadata?.name}</h1>
          <button className="border px-3 py-1 rounded" onClick={() => {}}>
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>

        <div className="flex max-h-[65vh] overflow-y-auto">
          <div className="flex-1 p-4 overflow-hidden h-full">
            {renderViewer()}
          </div>
          <div className="w-64 border-l bg-white sticky top-0 self-start h-full overflow-auto p-4">
            <div className="border-b font-bold pb-2">Document Info</div>
            <div className="text-sm flex flex-col gap-3 mt-2">
              <div>
                <div className="font-bold">File Type</div>
                <div className="text-wrap break-words">
                  {fileMetadata?.mimeType || "N/A"}
                </div>
              </div>
              <div>
                <div className="font-bold">Created</div>
                <div>
                  {fileMetadata?.createDate
                    ? formatDateTime(new Date(fileMetadata.createDate))
                    : "N/A"}
                </div>
              </div>
              <div>
                <div className="font-bold">File Size</div>
                <div>{formatFileSize(fileMetadata?.size || 0)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFileViewPage;
