// formsConfig.ts

import { getAllParticipants } from "../../../services/participantServices/ParticipantService";
import { FormConfig, RulesConfig } from "./DynamicForm";
import {
  faUser,
  faMapLocationDot,
  faAddressBook,
  faBolt,
  faFolderOpen,
  faClipboardQuestion,
  faSignature,
} from "@fortawesome/free-solid-svg-icons";

/**
 * Key: The shortened name of the form (no extension).
 * Value: The array of WizardSteps for that form.
 */
// formsConfig.ts

export const scdFormConfig: Record<string, FormConfig> = {
  // We store exactly one "SCD_Form" (or rename to your doc's name)
  SCD_Form: {
    formId: "SCD_Form",
    steps: [
      {
        id: "step1",
        label: "Participant & Contact Info",
        icon: faUser,
        questions: [
          {
            id: "participantSelect",
            label: "Select Participant",
            type: "react-select",
            dynamicSource: "participants", // we handle this special
            validation: { required: true },
          },
          {
            id: "caseNumber",
            placeholder: "Enter case number here...",
            label: "Participant Case Number",
            type: "text",
            validation: { required: true }
          },
          {
            id: "dateOfContact",
            label: "Date of Contact",
            type: "date",
            validation: { required: true },
          },
          {
            id: "contactType",
            label: "Select Contact Type",
            type: "react-select",
            isMulti: true,
            options: ["Monthly", "Quarterly", "Annual", "Interim"],
            validation: { required: true },
          },
          // For monthly/interim we need Start/End time with min 15 min difference
          // For quarterly/annual, doc says Start=SEE, End=EVV, but let's treat them as read-only or hidden inputs
          {
            id: "beginTime",
            label: "Start Time (HH:MM)",
            type: "time",
            showIf: [
              {
                field: "contactType",
                operator: "INCLUDES",
                value: "Monthly",
              },
            ],
          },
          {
            id: "endTime",
            label: "End Time (HH:MM)",
            type: "time",
            showIf: [
              {
                field: "contactType",
                operator: "INCLUDES",
                value: "Monthly",
              },
            ],
          },
          // You can do the same for "Annual" if you prefer
        ],
      },
      {
        id: "step2",
        label: "Location & Service Activity",
        icon: faMapLocationDot,
        questions: [
          {
            id: "placeOfService",
            label: "Place of Service",
            type: "react-select",
            options: [
              { value: "02", label: "02.  Place of Residence" },
              { value: "09", label: "09.  Day Program or ADHC Facility" },
              { value: "10", label: "10.  Mental Health Clinic" },
              { value: "12", label: "12.  School" },
              { value: "13", label: "13.  Support Coordination Agency" },
              { value: "14", label: "14.  Jail or Correctional Facility" },
              { value: "15", label: "15.  Day Care or nursery school" },
              { value: "16", label: "16.  OT, PT, Speech Therapist’s Office" },
              { value: "18", label: "18.  Early Intervention Provider" },
              { value: "19", label: "19.  Service Provider’s Place of Business" },
              { value: "21", label: "21.  Hospital" },
              { value: "22", label: "22.  Medical/Public Health Clinic" },
              { value: "23", label: "23.  ICF/DD" },
              { value: "24", label: "24.  Nursing Facility" },
              { value: "99", label: "99.  Other Community Location" }
            ],            
            validation: { required: true },
          },
          {
            id: "typeOfContact",
            label: "Type of Contact",
            type: "react-select",
            options: [
              { value: "1", label: "1. In person" },
              { value: "2", label: "2. Telephone" },
              { value: "3", label: "3. Written" },
              { value: "6", label: "6. Documentation" },
              { value: "8", label: "8. Telehealth" }
            ],            
            validation: { required: true },
          },
          {
            id: "serviceActivity",
            label: "Service Activity (check all that apply)",
            type: "react-select",
            isMulti: true,
            options: [
              { value: "00", label: "00. No service provided (non billable)" },
              { value: "01", label: "01. Initial Contact" },
              { value: "03", label: "03. Service Planning, Implementation, and Follow-up" },
              { value: "04", label: "04. Unannounced Visit / Health and Safety" },
              { value: "13", label: "13. Annual POC meeting" },
              { value: "15", label: "15. Emergency Event Tracking" },
              { value: "16", label: "16. Initial POC meeting" },
              { value: "20", label: "20. Medical Consultation" },
              { value: "21", label: "21. Health Management" },
              { value: "22", label: "22. Medical Crisis Management" },
              { value: "23", label: "23. Medical Crisis Training & TA - School" },
              { value: "24", label: "24. Medical Crisis Training & TA - Community" },
              { value: "25", label: "25. Intense Informing for Complex Health Needs" },
              { value: "37", label: "37. CIR Tracking" },
              { value: "38", label: "38. Documentation" },
              { value: "39", label: "39. Travel (not billable)" },
              { value: "41", label: "41. Monthly Monitoring Contact" },
              { value: "53", label: "53. Quarterly Visit" },
              { value: "56", label: "56. Advocacy" },
              { value: "59", label: "59. Monitoring provider records/notes/billing" },
              { value: "60", label: "60. Transition and Closure" },
              { value: "76", label: "76. Assessment" },
              { value: "97", label: "97. Medicaid Eligibility (non billable)" },
              { value: "99", label: "99. Additional Contact (non billable)" }
            ],            
            validation: { required: true },
          },
        ],
      },
      {
        id: "step3",
        label: "Monthly Monitoring",
        icon: faAddressBook,
        questions: [
          {
            id: "monthlyMonitoringServices",
            label: "Which Services are Monitored?",
            type: "react-select",
            isMulti: true,
            placeholder: "Select all services that were monitored",
            options: [
              "IFS",
              "DH",
              "ADHC",
              "FS",
              "Skilled Nursing",
              "Pre-Voc",
              "SE",
            ],
            validation: { required: true },
          },

          // IFS service logic
          {
            id: "monitor_IFS",
            label: "Outcome for IFS",
            type: "react-select",
            options: [
              { value: "01", label: "01. Service received and delivered..." },
              { value: "02", label: "02. Participant temporarily admitted..." },
              { value: "03", label: "03. Service was declined..." },
              { value: "11", label: "11. Facility unscheduled closures..." },
              { value: "12", label: "12. No provider in transport radius" },
              { value: "13", label: "13. Transportation NOT available" },
              { value: "21", label: "21. Worker absence with natural support" },
              { value: "22", label: "22. Participant refused back-up worker" },
              { value: "23", label: "23. DSP did NOT provide care" },
              { value: "31", label: "31. B/U Staffing Plan not followed" },
            ],
            placeholder: "Select outcome for IFS",
            showIf: [
              {
                field: "monthlyMonitoringServices",
                operator: "CONTAINS",
                value: "IFS",
              },
            ],
          },
          {
            id: "remediate_IFS",
            label: "Remediation for IFS",
            type: "react-select",
            options: [
              { value: "01", label: "01. Remediation In Progress" },
              { value: "02", label: "02. Assisted participant in locating other provider(s)" },
              { value: "11", label: "11. Back-Up Staffing Plan revised" },
              { value: "12", label: "12. POC Revision completed" },
              { value: "13", label: "13. New worker in place" },
              { value: "21", label: "21. Transportation resource located" },
            ],
            placeholder: "Select remediation for IFS",
            showIf: [
              {
                field: "monitor_IFS",
                operator: "CONTAINS_ANY",
                value: ["13", "23", "31"],
              },
            ],
          },
          {
            id: "comment_IFS",
            label: "Remediation Comment for IFS",
            type: "text",
            placeholder: "Explain how SC addressed the IFS issue",
            showIf: [
              {
                field: "monitor_IFS",
                operator: "CONTAINS_ANY",
                value: ["13", "23", "31"],
              },
            ],
          },

          // DH service logic
          {
            id: "monitor_DH",
            label: "Outcome for DH",
            type: "react-select",
            options: [
              { value: "01", label: "01. Service received and delivered..." },
              { value: "02", label: "02. Participant temporarily admitted..." },
              { value: "03", label: "03. Service was declined..." },
              { value: "11", label: "11. Facility unscheduled closures..." },
              { value: "12", label: "12. No provider in transport radius" },
              { value: "13", label: "13. Transportation NOT available" },
              { value: "21", label: "21. Worker absence with natural support" },
              { value: "22", label: "22. Participant refused back-up worker" },
              { value: "23", label: "23. DSP did NOT provide care" },
              { value: "31", label: "31. B/U Staffing Plan not followed" },
            ],
            placeholder: "Select outcome for DH",
            showIf: [
              {
                field: "monthlyMonitoringServices",
                operator: "CONTAINS",
                value: "DH",
              },
            ],
          },
          {
            id: "remediate_DH",
            label: "Remediation for DH",
            type: "react-select",
            options: [
              { value: "01", label: "01. Remediation In Progress" },
              {
                value: "02",
                label: "02. Assisted participant in locating other provider(s)",
              },
              { value: "11", label: "11. Back-Up Staffing Plan revised" },
              { value: "12", label: "12. POC Revision completed" },
              { value: "13", label: "13. New worker in place" },
              { value: "21", label: "21. Transportation resource located" },
            ],
            placeholder: "Select remediation for DH",
            showIf: [
              {
                field: "monitor_DH",
                operator: "CONTAINS_ANY",
                value: ["13", "23", "31"],
              },
            ],
          },
          {
            id: "comment_DH",
            label: "Remediation Comment for DH",
            type: "text",
            placeholder: "Explain how SC addressed the DH issue",
            showIf: [
              {
                field: "monitor_DH",
                operator: "CONTAINS_ANY",
                value: ["13", "23", "31"],
              },
            ],
          },
        ],
      },
      {
        id: "step4",
        label: "Service Participant(s)",
        icon: faUser,
        questions: [
          {
            id: "serviceParticipantSelections",
            label: "Service Participant (max 4)",
            type: "react-select",
            isMulti: true,
            options: [
              { value: "01", label: "01. Recipient/Participant" },
              { value: "02", label: "02. Parent/Legal Guardian" },
              { value: "03", label: "03. Other Family Member" },
              { value: "04", label: "04. Responsible Representative" },
              { value: "07", label: "07. Education" },
              { value: "08", label: "08. Health Care Provider" },
              { value: "09", label: "09. Supportive Services/Resources" },
              { value: "10", label: "10. Program Office" },
              { value: "11", label: "11. Medicaid Eligibility Office" },
              { value: "12", label: "12. Waiver Service Provider" },
              { value: "13", label: "13. Non-Medicaid other Provider" },
              { value: "16", label: "16. Advocacy Representative" },
              { value: "17", label: "17. Nurse Consultant" },
              { value: "18", label: "18. Statistical Resources Inc." },
              { value: "19", label: "19. Healthy Louisiana Contact" },
              { value: "20", label: "20. MFP/MPL Transition Coordinator" },
              { value: "21", label: "21. Ombudsman" },
              { value: "99", label: "99. Other" }
            ],
          },

          // === Autofill for 01 ===
          {
            id: "participantDetail_01",
            label: "Participant (autofilled)",
            type: "text",
            disabled: true,
            placeholder: "Participant name will autofill",
            showIf: [
              {
                field: "serviceParticipantSelections",
                operator: "CONTAINS",
                value: "01",
              },
            ],
          },

          // === Custom fields for 02 or 03 ===
          {
            id: "participantDetail_02_03_nameFirst",
            label: "First Name (02/03)",
            type: "text",
            placeholder: "Enter first name",
            showIf: [
              {
                field: "serviceParticipantSelections",
                operator: "CONTAINS_ANY",
                value: ["02", "03"],
              },
            ],
          },
          {
            id: "participantDetail_02_03_nameLast",
            label: "Last Name (02/03)",
            type: "text",
            placeholder: "Enter last name",
            showIf: [
              {
                field: "serviceParticipantSelections",
                operator: "CONTAINS_ANY",
                value: ["02", "03"],
              },
            ],
          },
          {
            id: "participantDetail_02_03_relationship",
            label: "Relationship to Participant (02/03)",
            type: "text",
            placeholder: "e.g., Parent, Guardian, Aunt",
            showIf: [
              {
                field: "serviceParticipantSelections",
                operator: "CONTAINS_ANY",
                value: ["02", "03"],
              },
            ],
          },

          // === Generic fields for all others ===
          {
            id: "participantDetail_other_nameFirst",
            label: "First Name (Other)",
            type: "text",
            placeholder: "Enter first name",
            showIf: [
              {
                field: "serviceParticipantSelections",
                operator: "DOES_NOT_CONTAIN_ANY",
                value: [
                  "01",
                  "02",
                  "03",
                ],
              },
            ],
          },
          {
            id: "participantDetail_other_nameLast",
            label: "Last Name (Other)",
            type: "text",
            placeholder: "Enter last name",
            showIf: [
              {
                field: "serviceParticipantSelections",
                operator: "DOES_NOT_CONTAIN_ANY",
                value: [
                  "01",
                  "02",
                  "03",
                ],
              },
            ],
          },
          {
            id: "participantDetail_other_relationship",
            label: "Relationship to Participant (Other)",
            type: "text",
            placeholder: "e.g., Case Manager, Teacher",
            showIf: [
              {
                field: "serviceParticipantSelections",
                operator: "DOES_NOT_CONTAIN_ANY",
                value: [
                  "01",
                  "02",
                  "03",
                ],
              },
            ],
          },
        ],
      },
      {
        id: "step5",
        label: "Support Coordinator Actions",
        icon: faBolt,
        questions: [
          {
            id: "scActions",
            label: "Select SC Actions (multi)",
            type: "react-select",
            isMulti: true,
            options: [
              "Resolution of Accessing POC Services",
              "Continue to Monitor", // must always be selected
              "Revise Emergency Plan",
              "Revise Backup Staffing Plan",
              "POC Revision",
              "FOC Offered",
              "Referral for Services",
              "Other (Specify)",
            ],
            validation: { required: true },
          },
          {
            id: "comment_scActions_resolution",
            label: "Comment about Resolution of Accessing POC Services",
            type: "text",
            showIf: [
              {
                field: "scActions",
                operator: "CONTAINS",
                value: "Resolution of Accessing POC Services",
              },
            ],
          },
          {
            id: "comment_scActions_monitor",
            label: "Comment about Continue to Monitor",
            type: "text",
            showIf: [
              {
                field: "scActions",
                operator: "CONTAINS",
                value: "Continue to Monitor",
              },
            ],
          },
          {
            id: "comment_scActions_emergencyPlan",
            label: "Comment about Revising Emergency Plan",
            type: "text",
            showIf: [
              {
                field: "scActions",
                operator: "CONTAINS",
                value: "Revise Emergency Plan",
              },
            ],
          },
          {
            id: "comment_scActions_backupStaffing",
            label: "Comment about Revising Backup Staffing Plan",
            type: "text",
            showIf: [
              {
                field: "scActions",
                operator: "CONTAINS",
                value: "Revise Backup Staffing Plan",
              },
            ],
          },
          {
            id: "comment_scActions_pocRevision",
            label: "Comment about POC Revision",
            type: "text",
            showIf: [
              {
                field: "scActions",
                operator: "CONTAINS",
                value: "POC Revision",
              },
            ],
          },
          {
            id: "comment_scActions_foc",
            label: "Comment about FOC Offered",
            type: "text",
            showIf: [
              {
                field: "scActions",
                operator: "CONTAINS",
                value: "FOC Offered",
              },
            ],
          },
          {
            id: "comment_scActions_referral",
            label: "Comment about Referral for Services",
            type: "text",
            showIf: [
              {
                field: "scActions",
                operator: "CONTAINS",
                value: "Referral for Services",
              },
            ],
          },
          {
            id: "comment_scActions_other",
            label: "Comment about Other (Specify)",
            type: "text",
            showIf: [
              {
                field: "scActions",
                operator: "CONTAINS",
                value: "Other (Specify)",
              },
            ],
          },
        ],
      },
      {
        id: "step6",
        label: "Home Binder Review",
        icon: faFolderOpen,
        questions: [
          {
            id: "wasHomeBinderReviewed",
            label: "Was home binder reviewed during this contact?",
            type: "radio",
            options: ["Yes", "No"],
            showIf: [
              // show this if user has "Quarterly" or "Annual" contact
              {
                field: "contactType",
                operator: "CONTAINS_ANY",
                value: ["Quarterly", "Annual"],
              },
            ],
          },
          {
            id: "missingHomeBinderItems",
            label: "Which items are missing?",
            type: "react-select",
            isMulti: true,
            options: [
              "POC",
              "Revisions",
              "Service Logs",
              "Progress Notes",
              "Payroll Reports",
              "Protocols",
              "Sign In Sheets",
            ],
            showIf: [
              {
                field: "wasHomeBinderReviewed",
                operator: "EQUALS",
                value: "Yes",
              },
            ],
          },
          {
            id: "whyNotHomeBinder",
            label: "If No, why not?",
            type: "react-select",
            options: ["PHE", "Other (specify)"],
            showIf: [
              {
                field: "wasHomeBinderReviewed",
                operator: "EQUALS",
                value: "No",
              },
            ],
          },
          {
            id: "comment_whyNotHomeBinder",
            label: "Details if 'Other'",
            type: "text",
            showIf: [
              {
                field: "whyNotHomeBinder",
                operator: "EQUALS",
                value: "Other (specify)",
              },
            ],
          },
        ],
      },
      {
        id: "step7",
        label: "Yes/No Questions",
        icon: faClipboardQuestion,
        questions: [
          {
            id: "q_problemsServices",
            label:
              "1. Has Participant had problems receiving services as written in the Plan of Care?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_problemsServices",
            placeholder: "Add your comment here...",
            label: "Comment about problems with services",
            type: "text",
            prefix: "1. ",
            validation: { required: true },
            showIf: [
              { field: "q_problemsServices", operator: "EQUALS", value: "Yes" },
            ],
          },
          {
            id: "q_problemsGoals",
            label: "2. Has the participant had problems with goals being met?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_problemsGoals",
            placeholder: "Add your comment here...",
            label: "Comment about problems with goals being met",
            type: "text",
            prefix: "2. ",
            validation: { required: true },
            showIf: [
              { field: "q_problemsGoals", operator: "EQUALS", value: "Yes" },
            ],
          },
          {
            id: "q_problemsContact",
            label:
              "3. Has the participant had problems with contacting family/friends? (i.e., person has the types of relationships they want and they have contact at their desired frequency)",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_problemsContact",
            placeholder: "Add your comment here...",
            label: "Comment about problems with contacting family/friends",
            type: "text",
            prefix: "3. ",
            validation: { required: true },
            showIf: [
              { field: "q_problemsContact", operator: "EQUALS", value: "Yes" },
            ],
          },
          {
            id: "q_problemsPreferences",
            label:
              "4. Has the participant had problems with preferences being respected (i.e. services being delivered at their preferred times, preferred staff, preferred location)?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_problemsPreferences",
            placeholder: "Add your comment here...",
            label: "Comment about problems with preferences being respected",
            type: "text",
            prefix: "4. ",
            validation: { required: true },
            showIf: [
              {
                field: "q_problemsPreferences",
                operator: "EQUALS",
                value: "Yes",
              },
            ],
          },
          {
            id: "q_problemsHealthcare",
            label:
              "5. Has the participant had problems accessing non-waiver health care services?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_problemsHealthcare",
            placeholder: "Add your comment here...",
            label:
              "Comment about problems accessing non-waiver health care services",
            type: "text",
            prefix: "5. ",
            validation: { required: true },
            showIf: [
              {
                field: "q_problemsHealthcare",
                operator: "EQUALS",
                value: "Yes",
              },
            ],
          },
          {
            id: "q_problemsCommunity",
            label:
              "6. Has the participant had problems participating in the community? (i.e., going to their preferred places)",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_problemsCommunity",
            placeholder: "Add your comment here...",
            label: "Comment about problems participating in the community",
            type: "text",
            prefix: "6. ",
            validation: { required: true },
            showIf: [
              {
                field: "q_problemsCommunity",
                operator: "EQUALS",
                value: "Yes",
              },
            ],
          },
          {
            id: "q_problemsWork",
            label:
              "7. Has the person had problems working/volunteering consistent with their goals and desires?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_problemsWork",
            placeholder: "Add your comment here...",
            label: "Comment about problems working/volunteering",
            type: "text",
            prefix: "7. ",
            validation: { required: true },
            showIf: [
              { field: "q_problemsWork", operator: "EQUALS", value: "Yes" },
            ],
          },
          {
            id: "q_problemsBackupWorker",
            label:
              "8. Has the participant had problems getting a backup worker when a worker cannot report to work as scheduled?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_problemsBackupWorker",
            placeholder: "Add your comment here...",
            label: "Comment about problems getting a backup worker",
            type: "text",
            prefix: "8. ",
            validation: { required: true },
            showIf: [
              {
                field: "q_problemsBackupWorker",
                operator: "EQUALS",
                value: "Yes",
              },
            ],
          },
          {
            id: "q_incidents",
            label:
              "9. Has the participant had falls, injuries, hospitalizations, been restrained, or been a victim of verbal abuse, physical abuse, neglect, or exploitation?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_incidents",
            placeholder: "Add your comment here...",
            label: "Comment about incidents",
            type: "text",
            prefix: "9. ",
            validation: { required: true },
            showIf: [
              { field: "q_incidents", operator: "EQUALS", value: "Yes" },
            ],
          },
          {
            id: "q_changeMedical",
            label:
              "10. Has the participant had a substantial change in medical condition?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_changeMedical",
            placeholder: "Add your comment here...",
            label: "Comment about medical condition change",
            type: "text",
            prefix: "10. ",
            validation: { required: true },
            showIf: [
              { field: "q_changeMedical", operator: "EQUALS", value: "Yes" },
            ],
          },
          {
            id: "q_changeFunction",
            label:
              "11. Has the participant had a substantial change in the ability to do things for himself/herself?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_changeFunction",
            placeholder: "Add your comment here...",
            label: "Comment about change in functional ability",
            type: "text",
            prefix: "11. ",
            validation: { required: true },
            showIf: [
              { field: "q_changeFunction", operator: "EQUALS", value: "Yes" },
            ],
          },
          {
            id: "q_envNeeds",
            label:
              "12. Does the participant have an identified need for environmental modifications or assistive devices?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_envNeeds",
            placeholder: "Add your comment here...",
            label:
              "Comment about environmental modification/assistive device need",
            type: "text",
            prefix: "12. ",
            validation: { required: true },
            showIf: [{ field: "q_envNeeds", operator: "EQUALS", value: "Yes" }],
          },
          {
            id: "q_changeCaregiver",
            label:
              "13. Has the participant had a change in non-paid caregivers or living situation?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_changeCaregiver",
            placeholder: "Add your comment here...",
            label: "Comment about caregiver/living situation change",
            type: "text",
            prefix: "13. ",
            validation: { required: true },
            showIf: [
              { field: "q_changeCaregiver", operator: "EQUALS", value: "Yes" },
            ],
          },
          {
            id: "q_changeEmergencySupport",
            label:
              "14. Has the participant had a change in who will assist them in the event of an emergency?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_changeEmergencySupport",
            placeholder: "Add your comment here...",
            label: "Comment about change in emergency support",
            type: "text",
            prefix: "14. ",
            validation: { required: true },
            showIf: [
              {
                field: "q_changeEmergencySupport",
                operator: "EQUALS",
                value: "Yes",
              },
            ],
          },
          {
            id: "q_changeMeds",
            label:
              "15. Has the participant had a change in medications/treatments and/or who gives them?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
          {
            id: "comment_q_changeMeds",
            placeholder: "Add your comment here...",
            label: "Comment about change in medications/treatments",
            type: "text",
            prefix: "15. ",
            validation: { required: true },
            showIf: [
              { field: "q_changeMeds", operator: "EQUALS", value: "Yes" },
            ],
          },
        ],
      },
      {
        id: "step8",
        label: "Comments & Signature",
        icon: faSignature,
        questions: [
          {
            id: "comment_final",
            label: "Any other comments?",
            type: "paragraph",
          },
          {
            id: "permissionToESign",
            label: "Do you give Sprout permission to e-sign your name?",
            type: "radio",
            options: ["Yes", "No"],
            validation: { required: true },
          },
        ],
      },
    ],
  },
};

// rulesConfig.ts

export const scdFormRules: Record<string, RulesConfig> = {
  SCD_Form: {
    formId: "SCD_Form",
    validationRules: [
      {
        id: "rule-monthly-serviceActivity",
        description: "Monthly contact requires 03 or 41",
        when: [
          { field: "contactType", operator: "INCLUDES", value: "Monthly" },
        ],
        checks: [
          {
            field: "serviceActivity",
            operator: "CONTAINS_ANY",
            value: [
              "03",
              "41",
            ],
          },
        ],
        errorMessage:
          "For Monthly contact, you must select either '03' or '41' in serviceActivity.",
        errorField: "serviceActivity",
      },
      {
        id: "rule-quarterly-serviceActivity",
        description: "Quarterly contact requires 53",
        when: [
          { field: "contactType", operator: "INCLUDES", value: "Quarterly" },
        ],
        checks: [
          {
            field: "serviceActivity",
            operator: "CONTAINS",
            value: "53",
          },
        ],
        errorMessage:
          "For Quarterly contact, you must select '53' in serviceActivity.",
        errorField: "serviceActivity",
      },
      {
        id: "rule-annual-serviceActivity",
        description: "Annual contact requires 13 or 16",
        when: [{ field: "contactType", operator: "CONTAINS", value: "Annual" }],
        checks: [
          {
            field: "serviceActivity",
            operator: "CONTAINS_ANY",
            value: ["13"],
          },
        ],
        errorMessage:
          "For Annual contact, you must select '13' or '16' in serviceActivity.",
        errorField: "serviceActivity",
      },
      {
        id: "rule-monthly-time-min15",
        description: "If monthly contact, start/end must be 15+ minutes apart",
        when: [
          { field: "contactType", operator: "INCLUDES", value: "Monthly" },
        ],
        checks: [
          {
            type: "TIME_DIFF",
            startField: "beginTime",
            endField: "endTime",
            minMinutes: 15,
          },
        ],
        errorMessage:
          "Monthly contact requires at least 15 minutes between start/end time.",
        errorField: "endTime",
      },
      {
        id: "rule-continueToMonitor",
        description: "SC Actions must include 'Continue to Monitor'",
        when: [],
        checks: [
          {
            field: "scActions",
            operator: "CONTAINS",
            value: "Continue to Monitor",
          },
        ],
        errorMessage: "You must select 'Continue to Monitor' in SC Actions.",
        errorField: "scActions",
      },
      {
        id: "rule-esign-required",
        description: "User must choose 'Yes' for e-sign permission",
        when: [],
        checks: [
          {
            field: "permissionToESign",
            operator: "EQUALS",
            value: "Yes",
          },
        ],
        errorMessage: "You must allow e-sign to complete this form.",
        errorField: "permissionToESign",
      },
    ],
  },
};

// dynamicSources.ts

export async function fetchDynamicOptions(
  source: string,
  authToken: string
): Promise<Array<{ value: string; label: string }> | null> {
  switch (source) {
    case "participants": {
      const participants = await getAllParticipants(authToken);
      if (!participants) return null;
      // Convert each participant into { value, label }
      const returnVal = participants.map((p) => ({
        value: p.id.toString(),
        label: `${p.firstName} ${p.lastName}`,
      }));

      return returnVal;
    }

    // Example for any other objects we would want to pull
    // case "organizations": {
    //   const orgs = await getAllOrganizations(authToken);
    //   if (!orgs) return null;
    //   return orgs.map((org) => ({
    //     value: org.id.toString(),
    //     label: org.name,
    //   }));
    // }

    // etc. If you have someOtherSource, do likewise

    default:
      return null; // or throw
  }
}
