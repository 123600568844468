import React from "react";
import { formatDateToWords } from "../../../utilities/dateUtils";
import { toTitleCase } from "../../../utilities/stringUtils";
import TaskList from "../../../interfaces/task/TaskList";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../interfaces/avatar/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../ui/Loading/LoadingSpinner";
import "./Tasks.css";
import SkeletonRow from "../../ui/LoadingSkeleton/SkeletonRow";

type AdminTaskTableProps = {
  tasks: TaskList[];
  sortKey: string;
  avatars: Avatar[];
  pageSize: number;
  sortDirection: "asc" | "desc";
  onChangeSort: (columnKey: string) => void;
  loading: boolean;
};

const AdminTaskTable: React.FC<AdminTaskTableProps> = ({
  tasks,
  avatars,
  sortKey,
  pageSize,
  sortDirection,
  onChangeSort,
  loading,
}) => {
  const navigate = useNavigate();

  const findAvatarById = (userId: string): string | null => {
    const avatarObj = avatars?.find((avatar) => avatar.id === userId);
    return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
  };

  return (
    <div className="overflow-x-auto rounded-md border min-h-[500px] relative">
      <table className="min-w-full divide-y divide-gray-200 rounded-t-lg">
        {/* Table Header */}
        <thead className="bg-gray-50 rounded-t-lg admin-task-table-head">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider admin-task-id">
              ID
            </th>
            <SortableHeader
              label="Title"
              columnKey="title"
              sortKey={sortKey}
              sortDirection={sortDirection}
              onChangeSort={onChangeSort}
            />
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider admin-task-type">
              Type
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider admin-task-assignees">
              Assignees
            </th>
            <SortableHeader
              label="Start Date"
              columnKey="startDate"
              sortKey={sortKey}
              sortDirection={sortDirection}
              onChangeSort={onChangeSort}
            />
            <SortableHeader
              label="Due Date"
              columnKey="endDate"
              sortKey={sortKey}
              sortDirection={sortDirection}
              onChangeSort={onChangeSort}
            />
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider admin-task-status">
              Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider admin-task-attachment flex items-center justify-center align-middle">
              <FontAwesomeIcon icon={faPaperclip} />
            </th>
          </tr>
        </thead>

        {/* Table Body */}
        <tbody className="bg-white divide-y divide-gray-200 admin-task-table-body">
          {!loading &&
            tasks.map((task, index) => (
              <tr
                key={task.id}
                className={`hover:bg-gray-50 ${
                  index % 2 === 1 ? "" : ""
                } cursor-pointer`}
                onClick={() =>
                  navigate(`/task/${task.organization.name}-${task.id}`)
                }
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 admin-task-id">
                  {task.organization.name + "-" + task.id}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 admin-task-title">
                  {task.title}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 admin-task-type">
                  {toTitleCase(task.subType?.type)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex items-center align-middle admin-task-assignees">
                  {task.assignees
                    .filter((a) => !a.isReporter)
                    .map((user, index) => {
                      const avatarUrl = findAvatarById(user.user.id);
                      return (
                        <div
                          className="w-7 h-7 my-auto font-bold flex items-center justify-center rounded-full bg-gray-300 dark:text-text mr-1"
                          key={index}
                        >
                          {avatarUrl ? (
                            <img
                              src={avatarUrl}
                              alt={`${user.user.firstName} ${user.user.lastName}`}
                              className="w-full h-full rounded-full object-cover"
                            />
                          ) : (
                            <div className="text-[12px]">
                              {user.user.firstName[0] + user.user.lastName[0]}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  {(!task.assignees || task.assignees.length === 0) && (
                    <div className="w-7 h-7 my-auto font-bold flex items-center justify-center rounded-full bg-gray-300 dark:text-text">
                      <div className="text-xs">N/A</div>
                    </div>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 admin-task-start">
                  {formatDateToWords(task.startDate)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 admin-task-due">
                  {formatDateToWords(task.endDate)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 admin-task-status">
                  <div
                    className={`p-1 ${
                      task.status.name.includes("REVIEW")
                        ? "bg-yellow-100 text-yellow-700"
                        : task.status.name.includes("COMPLETE")
                        ? "bg-green-100 text-green-700"
                        : task.status.name.includes("ISSUE")
                        ? "bg-red-100 text-red-700"
                        : "bg-blue-300 text-blue-700"
                    } text-xs rounded inline`}
                  >
                    {task.status.name}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex justify-center items-center align-middle admin-task-attachment">
                  <input
                    type="checkbox"
                    disabled
                    checked={task.hasAttachments}
                    onChange={() => {}}
                    className="form-checkbox h-4 w-4 text-brandSecondary border-gray-300 rounded"
                  />
                </td>
              </tr>
            ))}

          {loading &&
            Array.from({ length: pageSize }).map((_, rowIndex) => (
              <SkeletonRow key={rowIndex} columns={8} />
            ))}
        </tbody>
      </table>
      {loading && (
        <div className="flex gap-2 w-full justify-center align-middle items-center">
          <div className="flex justify-center my-4">
            <LoadingSpinner />
          </div>
        </div>
      )}
      {tasks.length === 0 && !loading && (
        <div className="absolute text-muted text-center -translate-y-1/2  translate-x-1/2 right-1/2 top-1/2 flex flex-col items-center align-middle justify-center">
          <FontAwesomeIcon className="text-8xl mb-5" icon={faMagnifyingGlass} />
          <div>No results found</div>
        </div>
      )}
    </div>
  );
};

export default AdminTaskTable;

type SortableHeaderProps = {
  label: string;
  columnKey: string;
  sortKey: string;
  sortDirection: "asc" | "desc";
  onChangeSort: (columnKey: string) => void;
};

const SortableHeader: React.FC<SortableHeaderProps> = ({
  label,
  columnKey,
  sortKey,
  sortDirection,
  onChangeSort,
}) => {
  const isActive = sortKey === columnKey;
  const arrow = isActive ? (sortDirection === "asc" ? "↑" : "↓") : "⇅";

  return (
    <th
      scope="col"
      className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider cursor-pointer admin-task-sortable"
      onClick={() => onChangeSort(columnKey)}
    >
      <div className="inline-flex items-center">
        {label}
        <span className="ml-1 text-xs">{arrow}</span>
      </div>
    </th>
  );
};
