// ruleEngine.ts

import { Console } from "console";
import { RulesConfig, WizardStep, isQuestionVisible } from "./DynamicForm";

/** Validate the formData using the provided rules. */
export function validateFormData(
  rulesConfig: RulesConfig,
  formData: Record<string, any>
): Record<string, string> {
  const { validationRules } = rulesConfig;
  const errors: Record<string, string> = {};

  for (const rule of validationRules) {
    // 1) Check "when" conditions
    const whenOk = (rule.when || []).every((cond) =>
      evaluateCondition(cond, formData)
    );
    if (!whenOk) {
      // rule not applicable
      continue;
    }
    // 2) Check the "checks"
    const checksOk = rule.checks.every((chk) => evaluateCheck(chk, formData));
    if (!checksOk) {
      // We have an error
      const fieldKey = rule.errorField || rule.id;
      errors[fieldKey] = rule.errorMessage;
    }
  }

  return errors;
}

export function evaluateCondition(
  cond: { field: string; operator: string; value: any },
  data: Record<string, any>
): boolean {
  const actualVal = data[cond.field];

  switch (cond.operator) {
    case "INCLUDES":
    case "CONTAINS":
      return Array.isArray(actualVal) ? actualVal.includes(cond.value) : false;

    case "DOES_NOT_CONTAIN":
      return Array.isArray(actualVal) ? !actualVal.includes(cond.value) : false;

    case "CONTAINS_ANY":
      return Array.isArray(actualVal) && Array.isArray(cond.value)
        ? cond.value.some((v: any) => actualVal.includes(v))
        : cond.value.includes(actualVal); // 👈 Add this to support string comparisons

    case "DOES_NOT_CONTAIN_ANY":
      return Array.isArray(actualVal) && Array.isArray(cond.value)
        ? cond.value.every((v: any) => !actualVal.includes(v))
        : false;

    case "CONTAINS_ALL":
      return Array.isArray(actualVal) && Array.isArray(cond.value)
        ? cond.value.every((v: any) => actualVal.includes(v))
        : false;

    case "DOES_NOT_CONTAIN_ALL":
      return Array.isArray(actualVal) && Array.isArray(cond.value)
        ? cond.value.some((v: any) => !actualVal.includes(v))
        : false;

    case "EQUALS":
      return actualVal === cond.value;

    default:
      return false;
  }
}

function evaluateCheck(
  chk: {
    type?: string;
    field?: string;
    operator?: string;
    value?: any;
    startField?: string;
    endField?: string;
    minMinutes?: number;
  },
  data: Record<string, any>
): boolean {
  if (chk.type === "TIME_DIFF") {
    return checkTimeDiff(
      data[chk.startField || ""],
      data[chk.endField || ""],
      chk.minMinutes || 0
    );
  }

  const actualVal = data[chk.field || ""];

  switch (chk.operator) {
    case "EQUALS":
      return actualVal === chk.value;

    case "CONTAINS":
      return Array.isArray(actualVal) ? actualVal.includes(chk.value) : false;

    case "DOES_NOT_CONTAIN":
      return Array.isArray(actualVal) ? !actualVal.includes(chk.value) : false;

    case "CONTAINS_ANY":
      return Array.isArray(actualVal) && Array.isArray(chk.value)
        ? chk.value.some((x) => actualVal.includes(x))
        : false;

    case "DOES_NOT_CONTAIN_ANY":
      return Array.isArray(actualVal) && Array.isArray(chk.value)
        ? chk.value.every((x) => !actualVal.includes(x))
        : false;

    case "CONTAINS_ALL":
      return Array.isArray(actualVal) && Array.isArray(chk.value)
        ? chk.value.every((x) => actualVal.includes(x))
        : false;

    case "DOES_NOT_CONTAIN_ALL":
      return Array.isArray(actualVal) && Array.isArray(chk.value)
        ? chk.value.some((x) => !actualVal.includes(x))
        : false;

    default:
      return true;
  }
}

export function computeAllRequiredFields(
  steps: WizardStep[],
  formData: Record<string, any>
): Record<string, string> {
  const newErrors: Record<string, string> = {};
  for (const step of steps) {
    for (const q of step.questions) {
      if (isQuestionVisible(q, formData)) {
        if (q.validation?.required) {
          const val = formData[q.id];
          if (!val || (Array.isArray(val) && val.length === 0)) {
            newErrors[q.id] = "This field is required.";
          }
        }
      }
    }
  }
  return newErrors;
}

function checkTimeDiff(start: string, end: string, minMinutes: number) {
  if (!start || !end) return false;
  const [sh, sm] = start.split(":").map(Number);
  const [eh, em] = end.split(":").map(Number);
  const startTotal = sh * 60 + sm;
  const endTotal = eh * 60 + em;
  return endTotal - startTotal >= minMinutes;
}
