import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../firebase/AuthProvider";
import { RegionDto } from "../../../../../interfaces/team/RegionHierarchy";
import { getRegionHierarchy } from "../../../../../services/userServices/TeamService";

const DashboardTeamView: React.FC = () => {
  const { currentUser } = useAuth();
  const [region, setRegion] = useState<RegionDto>();

  useEffect(() => {
    async function fetchData() {
      if (!currentUser) return;
      const authToken: string = await currentUser.getIdToken();
      const data = await getRegionHierarchy(authToken, 2);
      if (data) {
        setRegion(data);
      }
    }
    fetchData();
  }, []);

  console.log(region);

  return (
    <div>
      <h1>Dashboard Team View</h1>
      <h3 className="region">Region {region?.id}</h3>
      <div>
        {region ? (
          <pre>{JSON.stringify(region, null, 2)}</pre>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default DashboardTeamView;
