import { Sheet } from "xlsx";

// utils.ts
export function columnIndexToLetter(index: number): string {
  let result = "";
  let n = index;
  while (n >= 0) {
    result = String.fromCharCode((n % 26) + 65) + result;
    n = Math.floor(n / 26) - 1;
  }
  return result;
}

export function createEmptySheet(name: string): Sheet {
  // example: create a 20x10 empty sheet
  const rows = Array.from({ length: 20 }, () => ({
    cells: Array.from({ length: 10 }, () => ({
      value: "",
      style: {},
      type: "text",
    })),
    height: 24,
  }));

  return {
    name,
    rows,
    columnWidths: Array(10).fill(100), // default column width
  };
}
