import React, { useState, useEffect, useRef, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Rnd } from "react-rnd";
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../firebase/AuthProvider";
import {
  SignatureField,
  SignatureType,
} from "../../../../interfaces/file/fileSignature/SignatureBox";
import "./PDFViewer.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faFont,
  faICursor,
  faSignature,
  faX,
} from "@fortawesome/free-solid-svg-icons";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PDFViewerProps {
  fileUrl: string;
  scale: number;
  canEdit?: boolean;

  /**
   * If true, a left-click on the PDF will place a new box
   * at that location (with a default type, e.g. Signature).
   * If false, you won't place new boxes on left-click.
   */
  isPlacingBox?: boolean;

  /**
   * Called by the parent when a new box is placed (left-click).
   */
  onPlaceBox?: (pageNumber: number, x: number, y: number) => void;

  /**
   * The currently selected signer email (if relevant).
   */
  selectedSignerEmail: string;

  /**
   * The array of signature fields to render on top of the PDF.
   * This is controlled by the parent; the viewer just displays them.
   */
  signatureFields: SignatureField[];

  /**
   * Called whenever the fields change (drag, resize, delete, or add).
   */
  onSignatureFieldsChange?: (fields: SignatureField[]) => void;

  /**
   * Called if the user clicks on an existing box (for highlighting).
   */
  onBoxSelect?: (boxId: string) => void;
}

interface ContextMenuState {
  visible: boolean;
  pageNumber: number;

  // Coordinates for adding a box inside the PDF
  pdfX: number;
  pdfY: number;

  // Mouse position for positioning the context menu in the viewport
  menuX: number;
  menuY: number;
}

const PDFViewer: React.FC<PDFViewerProps> = ({
  fileUrl,
  scale,
  canEdit = false,
  isPlacingBox = false,
  onPlaceBox,
  selectedSignerEmail,
  signatureFields,
  onSignatureFieldsChange,
  onBoxSelect,
}) => {
  const { currentUser } = useAuth();
  const { fileId } = useParams();

  // Store raw PDF data if needed for flattening, etc.
  const pdfDataRef = useRef<ArrayBuffer | null>(null);

  const [numPages, setNumPages] = useState<number | null>(null);
  const [fileBlobUrl, setFileBlobUrl] = useState<string | null>(null);

  // Track rendered PDF page sizes so we can overlay boxes accurately.
  const [pageSizes, setPageSizes] = useState<{
    [pageNumber: number]: { width: number; height: number };
  }>({});

  // Track right-click context menu state for adding boxes by type.
  const [contextMenu, setContextMenu] = useState<ContextMenuState | null>(null);

  // Load the PDF data from fileUrl (if needed).
  useEffect(() => {
    async function fetchPDF() {
      if (!fileUrl || !currentUser || !fileId) return;

      const authToken = await currentUser.getIdToken();

      try {
        const response = await fetch(fileUrl);
        const arrayBuffer = await response.arrayBuffer();
        pdfDataRef.current = arrayBuffer;
        setFileBlobUrl(fileUrl);
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    }

    if (fileUrl) {
      fetchPDF();
    }
  }, [fileUrl]);

  // PDF load success: set page count
  const onDocumentLoadSuccess = useCallback((pdf: any) => {
    setNumPages(pdf.numPages);
  }, []);

  // Each page render: store the rendered size
  const onPageRenderSuccess = useCallback(
    (pageProxy: any) => {
      const viewport = pageProxy.getViewport({ scale });
      setPageSizes((prev) => ({
        ...prev,
        [pageProxy.pageNumber]: {
          width: viewport.width,
          height: viewport.height,
        },
      }));
    },
    [scale]
  );

  /**
   * Handle left-click on the PDF overlay (only if isPlacingBox = true).
   * This places a new box with a default type, or calls onPlaceBox if provided.
   */
  const handleOverlayLeftClick = (
    pageNumber: number,
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    if (!canEdit || !isPlacingBox) return;
    // Only place a box if clicked on the background (not on an RND child).
    if (e.target !== e.currentTarget) return;

    const rect = (e.currentTarget as HTMLDivElement).getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;

    onPlaceBox?.(pageNumber, offsetX, offsetY);
  };

  /**
   * Handle right-click on the PDF overlay: show custom context menu (if canEdit).
   * The user can then pick which type of box to add.
   */
  const handleOverlayContextMenu = (
    pageNumber: number,
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    if (!canEdit) return;
    e.preventDefault(); // prevent default browser menu

    const rect = (e.currentTarget as HTMLDivElement).getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;

    // We'll store the PDF offsets for placing the new box,
    // plus the actual mouse coordinates for the menu. We’ll position
    // the menu using position: fixed, so we use clientX/clientY directly.
    setContextMenu({
      visible: true,
      pageNumber,
      pdfX: offsetX,
      pdfY: offsetY,
      menuX: e.clientX,
      menuY: e.clientY,
    });
  };

  /**
   * Hide the context menu
   */
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  /**
   * Add a new field at the right-click PDF coords, of a certain type.
   */
  const handleAddField = (type: SignatureType) => {
    if (!contextMenu) return;

    const newField: SignatureField = {
      id: crypto.randomUUID(),
      pageNumber: contextMenu.pageNumber,
      x: contextMenu.pdfX,
      y: contextMenu.pdfY,
      width: 150,
      height: 40,
      signerEmail: selectedSignerEmail || "",
      signatureValue: "",
      signatureType: type,
    };

    onSignatureFieldsChange?.([...signatureFields, newField]);
    setContextMenu(null);
  };

  /**
   * Drag/resize an existing box => update it in the array
   */
  const updateFieldPositionAndSize = (
    id: string,
    x: number,
    y: number,
    width: number,
    height: number
  ) => {
    if (!canEdit) return;
    onSignatureFieldsChange?.(
      signatureFields.map((field) =>
        field.id === id ? { ...field, x, y, width, height } : field
      )
    );
  };

  /**
   * Delete a box
   */
  const deleteSignatureBox = (id: string) => {
    if (!canEdit) return;
    onSignatureFieldsChange?.(
      signatureFields.filter((field) => field.id !== id)
    );
  };

  /**
   * Example flatten logic (optional)
   */
  const handleFlattenSignatures = async () => {
    if (!pdfDataRef.current) return;
    try {
      const pdfDoc = await PDFDocument.load(pdfDataRef.current);
      pdfDoc.registerFontkit(fontkit);

      let signatureFont = null;
      try {
        const fontBytes = await fetch("/fonts/SignatureFont.ttf").then((res) =>
          res.arrayBuffer()
        );
        signatureFont = await pdfDoc.embedFont(fontBytes);
      } catch (err) {
        console.warn("Could not load signature font, using default instead.");
      }

      for (const field of signatureFields) {
        if (!field.signatureValue) continue;

        const page = pdfDoc.getPage(field.pageNumber - 1);
        const pageWidth = page.getWidth();
        const pageHeight = page.getHeight();
        const renderedW = pageSizes[field.pageNumber]?.width || 1;
        const renderedH = pageSizes[field.pageNumber]?.height || 1;
        const xScale = pageWidth / renderedW;
        const yScale = pageHeight / renderedH;
        const pdfX = field.x * xScale;
        const pdfY = (renderedH - field.y - field.height) * yScale;

        const fontSize = 20;
        const textY = pdfY + (field.height * yScale - fontSize) / 2;

        page.drawText(field.signatureValue, {
          x: pdfX,
          y: textY,
          size: fontSize,
          font: signatureFont ?? undefined,
          color: rgb(0, 0, 0),
        });
      }

      const updatedPdfBytes = await pdfDoc.save();
      const blob = new Blob([updatedPdfBytes], { type: "application/pdf" });
      const newBlobUrl = URL.createObjectURL(blob);
      window.open(newBlobUrl, "_blank");
    } catch (err) {
      console.error("Error flattening signatures:", err);
    }
  };

  return (
    <div
      className="pdfViewer relative w-full flex flex-col justify-start overflow-y-auto bg-gray-50"
      // If you want to hide the system context menu entirely outside pages:
      onContextMenu={(e) => {
        if (canEdit) e.preventDefault();
      }}
    >
      {fileBlobUrl && (
        <Document
          file={fileBlobUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          className="mx-auto"
        >
          {Array.from({ length: numPages || 0 }, (_, pageIdx) => {
            const pageNumber = pageIdx + 1;
            return (
              <div
                key={pageIdx}
                style={{
                  position: "relative",
                  marginBottom: "20px",
                }}
              >
                <Page
                  pageNumber={pageNumber}
                  renderTextLayer={false}
                  scale={scale}
                  onRenderSuccess={onPageRenderSuccess}
                />
                {pageSizes[pageNumber] && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: pageSizes[pageNumber].width,
                      height: pageSizes[pageNumber].height,
                      pointerEvents: canEdit ? "auto" : "none",
                    }}
                    // Left-click => place a default box if isPlacingBox
                    onClick={(e) => handleOverlayLeftClick(pageNumber, e)}
                    // Right-click => show context menu
                    onContextMenu={(e) =>
                      handleOverlayContextMenu(pageNumber, e)
                    }
                  >
                    {signatureFields
                      .filter((f) => f.pageNumber === pageNumber)
                      .map((field) => {
                        return (
                          <Rnd
                            key={field.id}
                            size={{ width: field.width, height: field.height }}
                            position={{ x: field.x, y: field.y }}
                            onDragStop={(e, data) =>
                              updateFieldPositionAndSize(
                                field.id,
                                data.x,
                                data.y,
                                field.width,
                                field.height
                              )
                            }
                            onResizeStop={(_e, _dir, ref, _delta, position) =>
                              updateFieldPositionAndSize(
                                field.id,
                                position.x,
                                position.y,
                                parseFloat(ref.style.width),
                                parseFloat(ref.style.height)
                              )
                            }
                            bounds="parent"
                            disableDragging={!canEdit}
                            enableResizing={canEdit}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              onBoxSelect?.(field.id);
                            }}
                          >
                            <div className="relative h-full w-full">
                              {canEdit && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteSignatureBox(field.id);
                                  }}
                                  className="absolute -top-2 -right-2 z-50 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs"
                                >
                                  x
                                </button>
                              )}
                              <div className={`h-full w-full flex items-center justify-center border ${field.signatureValue ? "border-green-400 bg-green-200 bg-opacity-20" : "border-blue-400 bg-blue-200 bg-opacity-20"}`}>
                                {/* Display an icon + e-mail or placeholder */}
                                <div className={`flex w-full items-center align-middle gap-2 ${field.signatureValue ? "text-green-600" : "text-blue-600"} p-2 text-sm overflow-hidden truncate`}>
                                  {field.signatureType ===
                                    SignatureType.DATE && (
                                    <FontAwesomeIcon icon={faCalendarDays} />
                                  )}
                                  {field.signatureType ===
                                    SignatureType.SIGNATURE && (
                                    <FontAwesomeIcon icon={faSignature} />
                                  )}
                                  {field.signatureType ===
                                    SignatureType.INITIALS && (
                                    <FontAwesomeIcon icon={faFont} />
                                  )}
                                  {field.signatureType ===
                                    SignatureType.TEXT && (
                                    <FontAwesomeIcon icon={faICursor} />
                                  )}
                                  <span className="inter-font text-xs truncate">
                                    {field.signerEmail || "No email"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Rnd>
                        );
                      })}
                  </div>
                )}
              </div>
            );
          })}
        </Document>
      )}

      {/* Optional flatten button 
      {canEdit && signatureFields.length > 0 && (
        <div className="w-full flex justify-end mt-3">
          <button
            onClick={handleFlattenSignatures}
            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition"
          >
            Flatten Signatures
          </button>
        </div>
      )} */}

      {/* Custom context menu for right-click.
          We use position: fixed so it won't shift if the container scrolls.
      */}
      {contextMenu?.visible && (
        <div
          className="fixed bg-gray-800 text-muted align-middle items-center shadow-xl flex"
          style={{
            top: contextMenu.menuY,
            left: contextMenu.menuX,
            zIndex: 9999,
          }}
        >
          <div
            className="p-2 w-10 flex justify-center items-center hover:bg-gray-700 cursor-pointer"
            onClick={() => handleAddField(SignatureType.SIGNATURE)}
          >
            <FontAwesomeIcon icon={faSignature} />
          </div>
          <div
            className="p-2 w-10 flex justify-center items-center hover:bg-gray-700 cursor-pointer"
            onClick={() => handleAddField(SignatureType.DATE)}
          >
            <FontAwesomeIcon icon={faCalendarDays} />
          </div>
          <div
            className="p-2 w-10 flex justify-center items-center hover:bg-gray-700 cursor-pointer"
            onClick={() => handleAddField(SignatureType.INITIALS)}
          >
            <FontAwesomeIcon icon={faFont} />
          </div>
          <div
            className="p-2 w-10 flex justify-center items-center hover:bg-gray-700 cursor-pointer"
            onClick={() => handleAddField(SignatureType.TEXT)}
          >
            <FontAwesomeIcon icon={faICursor} />
          </div>
          <div
            className="p-2 hover:bg-gray-700 cursor-pointer text-sm text-gray-300 flex items-center justify-center"
            onClick={handleCloseContextMenu}
          >
            <FontAwesomeIcon icon={faX} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
