import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { components } from "react-select";
import { useAuth } from "../../../firebase/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import ActionSubType from "../../../interfaces/task/taskActions/ActionSubType";
import TaskActionRequest from "../../../interfaces/task/taskActions/TaskActionRequest";
import {
  createTaskAction,
  getTaskActionSubTypes,
} from "../../../services/taskServices/TaskActionService";
import { getTemplateFiles } from "../../../services/fileServices/FileService";
import TemplateFile from "../../../interfaces/task/TaskFiles/TemplateFile";
import FileUploadFromTemplate from "../fileUploadComponent/FileUploadFromTemplate";
import { getTasks } from "../../../services/taskServices/TaskService";
import TaskList from "../../../interfaces/task/TaskList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardUser } from "@fortawesome/free-solid-svg-icons";

interface OptionType {
  value: string;
  label: string;
}

const CustomOption = (props: any) => {
  return (
    <components.Option {...props}>
      <span>
        <strong>{"("}{props.data.taskId}{")"}</strong> {props.data.title}
        <div className="flex gap-2 items-center align-middle">
        <FontAwesomeIcon icon={faClipboardUser} />{props.data.participantName}
        </div>
      </span>
    </components.Option>
  );
};

const CreateTaskActivityComponent: React.FC = () => {
  const { currentUser, currentOrganization } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [actionTypes, setActionTypes] = useState<ActionSubType[] | null>(null);
  const [actionTypeOptions, setActionTypeOptions] = useState<OptionType[]>([]);
  const [taskOptions, setTaskOptions] = useState<OptionType[]>([]);
  const [taskId, setTaskId] = useState<string>("");
  const [fetchedTasks, setFetchedTasks] = useState<TaskList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [attachmentRequirements, setAttachmentRequirements] =
    useState<TemplateFile[]>();
  const [selectedAttachment, setSelectedAttachment] = useState<
    TemplateFile | undefined
  >(undefined);

  const [recommendedTimeImmutable, setRecommendedTimeImmutable] =
    useState<boolean>(false);
  const [descriptionImmutable, setDescriptionImmutable] =
    useState<boolean>(false);

  const labelsToFilterOut = [
    "COMMENT",
    "TASK FILE DELETED",
    "FILE UPLOAD",
    "TASK APPROVAL",
    "TASK SUBMISSION",
    "TASK COMPLETED",
    "REJECTED",
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const taskId = searchParams.get("taskId");
    if (taskId !== "" && taskId) {
      setTaskId(taskId);
      setNewAction({ ...newAction, taskId });
    }
  }, [location, taskId]);

  useEffect(() => {
    if (!actionTypes && currentUser) {
      const getActionTypes = async () => {
        const authToken = await currentUser.getIdToken();
        const retrievedTypes = await getTaskActionSubTypes(authToken);
        if (retrievedTypes) {
          const filteredOptions = retrievedTypes.filter(
            (option) => !labelsToFilterOut.includes(option.actionSubTypeName)
          );
          setActionTypes(filteredOptions);
        }
      };
      getActionTypes();
    }
  }, [currentUser]);

  useEffect(() => {
    if (actionTypes && currentUser && taskId !== "") {
      const fetchTaskAttachmentRequirements = async () => {
        if (currentUser) {
          const authToken: string = await currentUser.getIdToken();
          const fetchedAttachmentRequirements = await getTemplateFiles(
            authToken
          );
          if (fetchedAttachmentRequirements) {
            setAttachmentRequirements(fetchedAttachmentRequirements);
          }
        }
      };

      fetchTaskAttachmentRequirements();
    }
  }, [actionTypes, currentUser]);

  useEffect(() => {
    if (currentUser) {
      const fetchTasks = async () => {
        if (currentUser) {
          const authToken: string = await currentUser.getIdToken();
          const fetchTasksForUser = await getTasks(authToken);
          if (fetchTasksForUser) {
            setFetchedTasks(fetchTasksForUser.tasks);
          }
        }
      };

      fetchTasks();
    }
  }, [currentUser]);

  // Initialize completionDate with current date
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  const initialCompletionDate = `${year}-${month}-${day}`;

  // Initialize completionTime with current time
  const currentHour = String(currentDate.getHours()).padStart(2, "0");
  const currentMinute = String(currentDate.getMinutes()).padStart(2, "0");
  const initialCompletionTime = `${currentHour}:${currentMinute}`;

  const [newAction, setNewAction] = useState<TaskActionRequest>({
    taskId: taskId.split("-")[1],
    description: "",
    subTypeId: "",
    durationInMinutes: "15",
    completionDate: initialCompletionDate,
    completionTime: initialCompletionTime,
    requiresFile: null,
  });

  useEffect(() => {
    if (actionTypes && actionTypes.length > 0) {
      const formattedActionTypes = actionTypes.map((at) => ({
        value: at.id.toString(),
        label: at.actionSubTypeName,
      }));
      setActionTypeOptions(formattedActionTypes);
    }

    if (fetchedTasks && fetchedTasks.length > 0) {
      const formattedTasks = fetchedTasks.map((task) => ({
        value: task.id.toString(),
        label: `(${currentOrganization?.name}-${task.id}) ${task.title} - ${task.participant?.firstName} ${task.participant?.lastName}`,
        taskId: currentOrganization?.name + "-" + task.id,
        title: task.title,
        participantName: `${task.participant?.firstName} ${task.participant?.lastName}`,
      }));
      setTaskOptions(formattedTasks);
    }
  }, [actionTypes, fetchedTasks, currentOrganization]); // React only when actionTypes or fetchedTasks change

  const handleActionTypeChange = (
    selectedActionType: SingleValue<OptionType>
  ) => {
    const subTypeId = selectedActionType ? selectedActionType.value : "";
    const currentNewAction = { ...newAction };
    currentNewAction.subTypeId = subTypeId;

    const actionSubType = actionTypes?.filter(
      (at) => at.id === parseInt(subTypeId, 10)
    )[0];
    if (actionSubType?.description) {
      setDescriptionImmutable(true);
      currentNewAction.description = actionSubType.description;
    } else {
      setDescriptionImmutable(false);
      currentNewAction.description = "";
    }
    if (actionSubType?.recommendedTimeInMinutes) {
      setRecommendedTimeImmutable(true);
      currentNewAction.durationInMinutes =
        actionSubType.recommendedTimeInMinutes.toString();
    } else {
      setRecommendedTimeImmutable(false);
      currentNewAction.durationInMinutes = "0";
    }
    if (actionSubType?.requiresFile) {
      // setRequiresFile(true);
      currentNewAction.requiresFile = actionSubType.requiresFile.toString();
      updateRequiredFile(actionSubType.requiresFile.toString());
    } else {
      currentNewAction.requiresFile = "";
      setSelectedAttachment(undefined);
    }
    setNewAction(currentNewAction);
  };

  const handleTaskIdChange = (selectedOption: SingleValue<OptionType>) => {
    const taskId = selectedOption ? selectedOption.value : "";
    setTaskId(taskId);
    navigate(`/task/new/activity?taskId=${taskId}`);
    window.location.reload();
    // setNewAction((prevAction) => ({
    //   ...prevAction,
    //   taskId: taskId,
    // }));
  };

  const updateRequiredFile = (requiresFile: string) => {
    setSelectedAttachment(
      attachmentRequirements?.find(
        (attachment) => attachment.id === parseInt(requiresFile)
      )
    );
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    // Ensure value is not below 0
    if (parseInt(value) < 0) {
      value = "0";
    }
    // Round value to nearest multiple of 15
    // value = (Math.round(parseInt(value) / 15) * 15).toString();
    setNewAction({ ...newAction, durationInMinutes: value.toString() });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setNewAction({ ...newAction, [name]: value });
  };

  const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAction({ ...newAction, completionDate: e.target.value });
  };

  const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAction({ ...newAction, completionTime: e.target.value });
  };

  const validateFields = (): boolean => {
    // Check if any required fields are empty
    if (
      !newAction.subTypeId ||
      !newAction.description ||
      !newAction.durationInMinutes ||
      !newAction.completionDate ||
      !newAction.completionTime ||
      !newAction.taskId
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | undefined = undefined
  ) => {
    if (e) {
      e.preventDefault();
    }
    setIsLoading(true);
    // Validate fields
    if (!validateFields()) {
      // Handle invalid fields (e.g., show error message)
      console.error("Please fill out all required fields.");
      setIsLoading(false);
      return;
    }
    if (currentUser) {
      const authToken: string = await currentUser.getIdToken();
      // console.log('POSTING NEW ACTION', newAction)
      await createTaskAction(authToken, newAction)
        .then((data) => {
          // console.log('Success:', data);
          // Navigate to success page or handle success state
          navigate(
            `/task/${currentOrganization?.name}-${taskId}?focusedActivity=${data}`
          );
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle error state (e.g., show error message)
        });
    }

    setIsLoading(false);
  };

  const fileUploadHandler = (status: string) => {
    if (status === "Success") handleSubmit();
    else console.error("Failed to upload file to server");
  };

  return (
    <div className="relative bg-background dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full flex-grow flex-wrap h-screen">
      <div className="bg-black w-full h-[300px] rounded-b-3xl"></div>
      <div className="overflow-x-auto -top-64 relative dark:bg-card-dark dark:text-text-dark bg-[#F7F9FF] p-5 rounded-3xl shadow-lg w-11/12 mx-auto min-h-[600px] -mt-10">
        {newAction.taskId ? (
          <h1 className="text-xl inter-font text-text ">
            New Activity for {taskId}
          </h1>
        ) : (
          <h1 className="text-xl inter-font text-text ">New Activity</h1>
        )}
        <form
          onSubmit={handleSubmit}
          className="max-w-xl mx-auto mt-8 bg-card dark:text-text dark:bg-card-dark shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          {!newAction.taskId && (
            <div className="my-5">
              <div className="font-bold dark:text-text-dark text-sm mb-2">
                Task By Id
              </div>
              <div className="remove-input-txt-border">
                <Select
                  options={taskOptions}
                  value={taskOptions.find(
                    (option) => option.value === newAction.taskId
                  )}
                  onChange={handleTaskIdChange}
                  components={{ Option: CustomOption }}
                  classNamePrefix="react-select"
                  className="dark:text-text"
                />
              </div>
            </div>
          )}
          <div className="my-5">
            <div className="font-bold dark:text-text-dark text-sm mb-2">
              Action Type
            </div>
            <div className="remove-input-txt-border">
              <Select
                options={actionTypeOptions}
                value={actionTypeOptions.find(
                  (option) => option.value === newAction.subTypeId
                )}
                onChange={handleActionTypeChange}
                className="dark:text-text"
              />{" "}
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Description:
            </label>
            <textarea
              id="description"
              name="description"
              placeholder="A brief description of what the task entails..."
              value={newAction.description}
              onChange={handleChange}
              required
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-text dark:text-text leading-tight focus:outline-none focus:shadow-outline `}
              // ${
              //   descriptionImmutable ? "bg-gray-200" : ""
              // }
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="durationInMinutes"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Duration (in minutes):
            </label>
            <input
              type="number"
              id="durationInMinutes"
              name="durationInMinutes"
              value={newAction.durationInMinutes}
              onChange={handleDurationChange}
              min="0"
              step="1"
              // readOnly={recommendedTimeImmutable} // Read-only when recommendedTimeImmutable is true
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline `}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="completionDate"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Completion Date:
            </label>
            <input
              type="date"
              id="completionDate"
              name="completionDate"
              value={newAction.completionDate}
              onChange={handleChangeDate}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="completionTime"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Completion Time:
            </label>
            <input
              type="time"
              id="completionTime"
              name="completionTime"
              value={newAction.completionTime}
              onChange={handleChangeTime}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          {selectedAttachment && (
            <div className="">
              <div className="w-full">
                <FileUploadFromTemplate
                  taskId={newAction.taskId}
                  toggleFileUpload={fileUploadHandler}
                  intendedFilename={selectedAttachment.fileName}
                />
              </div>
              <div className="mt-5">
                Upload File{" "}
                <span className="bg-muted-dark p-1 text-sm text-text italic">
                  {selectedAttachment.fileName}
                </span>
              </div>
            </div>
          )}
          {selectedAttachment === undefined && (
            <div className="flex justify-end">
              {isLoading ? (
                <button
                  // onClick={(e) => handleSubmit()}
                  disabled={true}
                  className="bg-[#61ffc0] flex items-center align-middle justify-center gap-2 w-full rounded-xl text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
                >
                  <span className="text-2xl">+ </span> Create Activity
                </button>
              ) : (
                <button
                  // onClick={(e) => handleSubmit()}
                  disabled={selectedAttachment !== undefined}
                  type="submit"
                  className="bg-[#3dd598] flex items-center align-middle justify-center gap-2 w-full rounded-xl text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
                >
                  <span className="text-2xl">+ </span> Create Activity
                </button>
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateTaskActivityComponent;
