import React, { useState } from "react";
import { formatDateToWords } from "../../../utilities/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faMagnifyingGlass,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import AdministrativeTransactionRequest from "../../../interfaces/adminRequests/AdministrativeTransactionRequest";
import { useNavigate } from "react-router-dom";
import { toTitleCase } from "../../../utilities/stringUtils";

interface AdminRequestsTableProps {
  transactions: AdministrativeTransactionRequest[];
  organizationName: string;
  approveTask: (id: number) => void;
  rejectTask: (id: number) => void;
}

const AdminRequestsTable: React.FC<AdminRequestsTableProps> = ({
  transactions,
  organizationName,
  approveTask,
  rejectTask
}) => {
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = useState<number>();

  const toggleMenuTrigger = (id: number) => {
    if (id === toggleMenu) {
      setToggleMenu(undefined);
    } else { 
        setToggleMenu(id);
    }
  };

  return (
    <div className="overflow-x-auto rounded-md border min-h-[500px] relative text-xs flex-1 blue-scrollbar">
      <table className="min-w-full divide-y divide-gray-200 rounded-t-lg">
        {/* Table Header */}
        <thead className="bg-gray-50 rounded-t-lg">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
              Requested By
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
              Approver
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
              Type
            </th>

            <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
              Task
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
              Requested
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">Response</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
              Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"></th>
          </tr>
        </thead>

        {/* Table Body */}
        <tbody className="bg-white divide-y divide-gray-200">
          {transactions.map((tx, index) => (
            <tr
              key={tx.id}
              className={` ${index % 2 === 1 ? "" : ""} `}
            >
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {tx.requesterName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {tx.approverName ? tx.approverName : "--"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {toTitleCase(tx.transactionType)}
              </td>
              <td
                className={`px-6 py-4 whitespace-nowrap text-sm flex items-center align-middle ${
                  tx.taskId ? "text-blue-600 cursor-pointer" : "text-gray-900"
                }`}
                onClick={() => {
                  if (tx.taskId)
                    navigate(`/task/${organizationName}-${tx.taskId}`);
                }}
              >
                {tx.taskId ? organizationName + "-" + tx.taskId : "N/A"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {formatDateToWords(new Date(tx.requestTime))}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {tx.completionTime
                  ? formatDateToWords(new Date(tx.completionTime))
                  : "--"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                <div
                  className={`p-1 ${
                    tx.status.includes("pending")
                      ? "bg-yellow-100 text-yellow-700"
                      : tx.status.includes("approved")
                      ? "bg-green-100 text-green-700"
                      : tx.status.includes("rejected")
                      ? "bg-red-100 text-red-700"
                      : "bg-blue-300 text-blue-700"
                  } text-xs rounded inline`}
                >
                  {tx.status}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex justify-center items-center align-middle relative">
                <FontAwesomeIcon
                  icon={faEllipsis}
                  className="cursor-pointer"
                  onClick={() => toggleMenuTrigger(tx.id)}
                />
              {toggleMenu === tx.id && (
                <div className="absolute bg-white select-none shadow-lg z-10 text-sm rounded right-0 top-10">
                  <div className="p-2 hover:bg-gray-100 cursor-pointer flex align-middle items-center gap-2 bg-blue-50 text-blue-700"
                  onClick={() => approveTask(tx.id)}>
                    <FontAwesomeIcon icon={faThumbsUp} />
                    <div>Approve</div>
                </div>
                  <div className="p-2 hover:bg-gray-100 cursor-pointer text-red-600 flex gap-2 align-middle items-center bg-red-50"
                  onClick={() => rejectTask(tx.id)}>
                  <FontAwesomeIcon icon={faThumbsDown} />
                  <div>Reject</div>
                  </div>
                </div>
              )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {transactions.length === 0 && (
        <div className="absolute text-muted text-center -translate-y-1/2  translate-x-1/2 right-1/2 top-1/2 flex flex-col items-center align-middle justify-center">
          <FontAwesomeIcon className="text-8xl mb-5" icon={faMagnifyingGlass} />
          <div>No results found</div>
        </div>
      )}
    </div>
  );
};

export default AdminRequestsTable;
