// file: services/chatServices/ChatService.ts

import UserInfo from "../../interfaces/UserInfo";

// export interface Conversation {
//     id: number;
//     participantA: UserInfo;
//     participantB: UserInfo;
//     lastMessage: Message;
//   }

export interface Conversation {
  id: number;
  participants: UserInfo[];
  lastMessage?: Message;
  lastReadAt?: Date;
}

export interface Reaction { 
  userId: string; 
  reactionType: string // e.g. "like", "love", "haha", etc.
}

export interface Message {
  id: number;
  conversationId: number;
  author: UserInfo;
  messageContent: string;
  createDate: Date;
  reactions?: Reaction[]; 
}

export interface SendMessageRequest {
  conversationId: number;
  text: string;
}


interface CreateConversationRequest {
    participantIds: string[];
  }
  
/**
 * Gets all conversations for the authenticated user.
 * Auth token is a Firebase or JWT token
 */
export async function getMyConversations(
  authToken: string
): Promise<Conversation[]> {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/chat/my-conversations`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch conversations");
  }
  return response.json();
}

/**
 * Gets all messages for a particular conversation
 */
export async function getMessagesForConversation(
  authToken: string,
  conversationId: number
): Promise<Message[]> {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/chat/${conversationId}/messages`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch messages");
  }
  const message = await response.json()
  return message;
}

export async function getConversationById(
  authToken: string,
  conversationId: number
): Promise<Conversation | null> {
  try {

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/chat/conversation/${conversationId}`
    // Adjust the base URL if needed
    const response = await fetch(apiUrl,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      }
    );
    return await response.json();
  } catch (error) {
    console.error("Failed to fetch conversation by ID:", error);
    return null;
  }
}

/**
 * Sends a message to a user (this will create a conversation if not exists)
 */
export async function sendMessage(
  authToken: string,
  conversationId: number,
  text: string, 
  attachmentUrl?: string
): Promise<{ conversationId: number; messageId: number }> {
  console.log('Attachment: ', attachmentUrl)
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/chat/send`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ conversationId, text, attachmentUrl } as SendMessageRequest),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to send message");
  }
  const message = await response.json()
  return message;
}


// -------------- CREATE CONVERSATION --------------
export const createConversation = async (
    token: string,
    participantIds: string[]
  ): Promise<Conversation> => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/chat/create-conversation`;
  
    const response = await fetch(
      url,
      {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      body: JSON.stringify({ participantIds } as CreateConversationRequest )
      }
    );
  
    const data = await response.json();
    return data;
  };
