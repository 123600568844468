// This will fetch the permissions of the user logged in
export async function getPermissions(accessToken: string) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/permissions`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  
    if (!response.ok) {
      throw new Error(`Failed to fetch permissions: ${response.status}`);
    }
  
    // Expecting an array like: [ { resource: "...", action: "...", condition: "..." }, ... ]
    return response.json();
  }
  