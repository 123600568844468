import React, { useEffect, useState } from "react";
import Avatar from "../../../../interfaces/avatar/Avatar";
import { getAvatarsFromStorage } from "../../../../services/fileServices/FileService";
import { useAuth } from "../../../../firebase/AuthProvider";
import "./OwnerDashboard.css";
import { toTitleCase } from "../../../../utilities/stringUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faClipboard,
  faCog,
  faFileLines,
  faHome,
  faInbox,
  faMessage,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import OwnerDashboardElement from "./OwnerDashboardElement";
import AdminRequests from "../../adminRequests/AdminRequests";
import {
  approveAdministrativeTransactionRequest,
  getAdministrativeTransactionRequestsForUser,
  rejectAdministrativeTransactionRequest,
} from "../../../../services/AdministrativeRequestServices/AdministrativeRequestService";
import AdministrativeTransactionRequest from "../../../../interfaces/adminRequests/AdministrativeTransactionRequest";
import OwnerInboxElement from "./OwnerInboxElement";
import OwnerSettingsElement from "./OwnerSettingsElement";
import OwnerReportsElement from "./OwnerReportsElement";
import OwnerAnalyticsElement from "./OwnerAnalyticsElement";
import {
  Conversation,
  Message,
  getConversationById,
  getMyConversations,
} from "../../../../services/chatService/ChatService";
import {
  getLatestNotifications,
  getNotifications,
} from "../../../../services/notificationServices/NotificationService";
import EventNotification from "../../../../interfaces/eventNotification/EventNotification";
import { useChatConnection } from "../../../../contexts/ChatConnectionContext";
import { useNotifications } from "../../../../contexts/NotificationProvider";
import { ToastOptions } from "react-toastify";
import { renderMessageWithLinks } from "../../../../utilities/notifications/EventNotificationUtils";

const OwnerDashboard: React.FC = () => {
  const { currentUser, currentUserInfo, currentRoles } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const connection = useChatConnection();
  const { notify } = useNotifications();

  const [avatars, setAvatars] = useState<Avatar[] | null>([]);
  const [reloadTransactions, setReloadTransactions] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<
    AdministrativeTransactionRequest[] | null
  >(null);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [notifications, setNotifications] = useState<EventNotification[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true); // Track the initial load
  const readReceiptSubscribedRef = React.useRef(false);

  const isAuthorized = React.useMemo(() => {
    return (
      currentUser &&
      currentRoles?.some(
        (role) => role.name === "OWNER" || role.name === "ADMIN"
      )
    );
  }, [currentUser, currentRoles]);

  useEffect(() => {
    if (
      currentUser &&
      currentRoles?.some(
        (role) => role.name === "OWNER" || role.name === "ADMIN"
      )
    ) {
      const getNotificationsForUser = async () => {
        const authToken = await currentUser.getIdToken();
        const response = await getNotifications(authToken);
        if (response !== undefined && response !== null) {
          setNotifications(response);
        }
      };
      getNotificationsForUser();
    }
  }, [currentUser]);

  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars);
  }, []);

  const fetchTransactions = async () => {
    try {
      if (currentUser) {
        const authToken = await currentUser.getIdToken();
        const fetchedTransactions =
          await getAdministrativeTransactionRequestsForUser(
            authToken,
            currentUser.uid
          );

        if (fetchedTransactions) {
          setTransactions(fetchedTransactions);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  
  useEffect(() => {
    if (
      connection && 
      currentUser && isAuthorized
    ) {
      const handleReceiveMessage = async (msg: Message) => {
        if (msg.author.id !== currentUser.uid) {
          playNotificationSound("message_notification.mp3");
          const authToken: string = await currentUser.getIdToken();
  
          // Use functional state update to get the latest conversations without adding it to dependencies.
          setConversations((prevConversations) => {
            const conv = prevConversations.find(
              (conv) => conv.id === msg.conversationId
            );
  
            if (!conv) {
              console.warn("Conversation not loaded yet, fetching it:", msg.conversationId);

              // Kick off an async fetch to retrieve that single conversation
              getConversationById(authToken, msg.conversationId).then((newConversation: Conversation | null) => {
                // Insert into state with the new message
                if (newConversation)
                  setConversations((prev) => [...prev, { ...newConversation, lastMessage: msg }]);
              });
            
              return prevConversations; // For now, exit early
            }
            
  
            // Build the notification element using the found conversation
            const messageElement = (
              <div className="inter-font bg-white">
                <div className="flex align-middle items-center text-blue-500 gap-1 inter-font text-xs mb-2">
                  <FontAwesomeIcon icon={faMessage} />
                  <div>Messages</div>
                  <div className="text-text">
                    {conv.participants.length > 2 &&
                      conv.participants
                        .filter((part) => part.id !== currentUser.uid)
                        .map((part) => part.firstName)
                        .join(", ")}
                  </div>
                  <div className="text-muted-light">&#183;</div>
                  <div className="text-2xs text-muted-light">Now</div>
                </div>
                <div className="inter-font-bold text-sm">
                  {msg.author.firstName + " " + msg.author.lastName}
                </div>
                <div className="text-xs">{msg.messageContent}</div>
                <div className="bg-inactive text-blue-500 flex text-xs gap-4 mt-2">
                  <div
                    className="hover:text-blue-900"
                    onClick={() => navigate("/chat")}
                  >
                    REPLY
                  </div>
                  <div className="hover:text-blue-900">MARK READ</div>
                </div>
              </div>
            );
  
            notify(messageElement);
  
            return prevConversations.map((conv) => {
              if (conv.id === msg.conversationId) {
                return { ...conv, lastMessage: msg };
              }
              return conv;
            });
          });
        }
      };
  
      connection.on("ReceiveMessage", handleReceiveMessage);
      return () => {
        connection.off("ReceiveMessage", handleReceiveMessage);
      };
    }
  }, [connection, isAuthorized]);

  useEffect(() => {
    if (connection && isAuthorized && !readReceiptSubscribedRef.current) {
      const handleReadReceipt = (
        conversationId: number,
        userId: string,
        lastReadAt: Date
      ) => {
        setConversations((prevConversations) =>
          prevConversations.map((conv) =>
            conv.id === conversationId ? { ...conv, lastReadAt } : conv
          )
        );
      };
  
      connection.on("ReceiveReadReceipt", handleReadReceipt);
      readReceiptSubscribedRef.current = true;
  
      return () => {
        connection.off("ReceiveReadReceipt", handleReadReceipt);
        readReceiptSubscribedRef.current = false;
      };
    }
  }, [connection, isAuthorized]);

  useEffect(() => {
    fetchTransactions();
  }, [reloadTransactions]);
  const findAvatarById = (userId: string): string | null => {
    const avatarObj = avatars?.find((avatar) => avatar.id === userId);
    return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
  };

  useEffect(() => {
    if (!connection) return;
    conversations.forEach((conv) => {
      connection
        .invoke("JoinConversation", conv.id)
        .then(() => {
        })
        .catch((err) => {
          console.error(`Failed to join conversation ${conv.id}`, err);
        });
    });
  }, [connection, conversations]);

  const triggerTaskApproval = async (requestId: number) => {
    if (currentUser) {
      const authToken = await currentUser.getIdToken();
      const result = await approveAdministrativeTransactionRequest(
        authToken,
        requestId
      );
      if (result) {
        // Refresh the list after successful approval
        await fetchTransactions();
      } else {
        console.error("Failed to approve request.");
      }
    }
  };

  const triggerTaskRejection = async (requestId: number) => {
    if (currentUser) {
      const authToken = await currentUser.getIdToken();
      const result = await rejectAdministrativeTransactionRequest(
        authToken,
        requestId
      );
      if (result) {
        // Refresh the list after successful rejection
        await fetchTransactions();
      } else {
        console.error("Failed to reject request.");
      }
    }
  };

  useEffect(() => {
    if (
      currentUser &&
      currentRoles?.some(
        (role) => role.name === "OWNER" || role.name === "ADMIN"
      )
    ) {
      const getData = async () => {
        const authToken = await currentUser.getIdToken();
        const data = await getMyConversations(authToken);
        setConversations(data);
      };

      getData();
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      currentUser &&
      currentRoles?.some(
        (role) => role.name === "OWNER" || role.name === "ADMIN"
      )
    ) {
      const pollInterval = 30000; // 30 seconds

      // Our polling function that fetches new notifications
      const pollNotifications = async () => {
        if (currentUser) {
          const authToken = await currentUser.getIdToken();

          // const newNotificationsResponse = await getLatestNotifications(authToken);
          const latest = await getLatestNotifications(authToken);

          if (latest && latest.length > 0) {
            // Add new notifications to top of existing
            setNotifications((prev) => {
              const combined = [...latest, ...prev];
              return combined.slice(0, 5); // keep only top 5 if you’d like
            });
            if (!isInitialLoad) {
              playNotificationSound(); // Play sound for new notifications
              // For each new notification, let's toast it
            }
            latest.forEach((notification) => {
              // Optionally handle different statuses
              let toastOptions: ToastOptions = {};
              // Example style changes based on status:
              switch (notification.status) {
                case "error":
                  toastOptions = { type: "error" };
                  break;
                case "success":
                  toastOptions = { type: "success" };
                  break;
                case "info":
                default:
                  toastOptions = { type: "info" };
                  break;
              }
              const content = (
                <div className="text-xs inter-font">
                  {renderMessageWithLinks(notification.message, notification)}
                </div>
              );
              notify(content, toastOptions);
            });
          }
        }

        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
      };

      pollNotifications(); // Initial fetch when the component mounts

      const interval = setInterval(() => {
        pollNotifications();
      }, pollInterval);

      return () => clearInterval(interval); // Cleanup the interval on component unmount
    }
  }, [currentUser]);

  const playNotificationSound = (filename: string | null = null) => {
    let soundFileName;

    if (filename) {
      soundFileName = filename;
    } else {
      // Retrieve the user's preferred sound from local storage
      soundFileName =
        localStorage.getItem("notificationSound") || "notification-4.mp3";
    }

    const audio = new Audio(`/notificationTones/${soundFileName}`); // Assuming the files are in the public folder
    audio
      .play()
      .then(() => {})
      .catch((error) => {
        console.error("Error playing sound:", error);
      });
  };

  return (
    <div className="h-screen overflow-none w-full bg-[#f1f3f1] flex inter-font">
      <div className="max-h-full bg-[#131715] w-52 rounded-xl m-1">
        <div className="flex flex-col h-full justify-between">
          <div>
            {currentUser && currentUserInfo && (
              <div className="profile">
                <div className="profile-picture">
                  <div className="relative flex justify-center items-center align-middle mt-10">
                    {findAvatarById(currentUser.uid) ? (
                      <img
                        src={findAvatarById(currentUser.uid)!}
                        alt="profile_picture"
                        className="h-24 w-24 rounded-full me-2"
                      />
                    ) : (
                      <div className="w-24 h-24 font-bold flex items-center justify-center rounded-full bg-gray-300 dark:text-text">
                        <div className="2xl:text-sm">
                          {currentUserInfo.firstName[0] +
                            "" +
                            currentUserInfo.lastName[0]}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="name text-[#e6e7e6]">
                  {currentUserInfo.firstName + " " + currentUserInfo.lastName}
                </div>
                <div className="role">
                  {toTitleCase(
                    currentUserInfo.roles.reduce(
                      (max, obj) => (obj.id > max.id ? obj : max),
                      currentUserInfo.roles[0]
                    ).name
                  )}
                </div>
              </div>
            )}
            <div className="nav-items">
              <Link
                to=""
                className={`nav-item ${
                  location.pathname.endsWith("/dashboard/owner") ? "active" : ""
                }`}
              >
                <FontAwesomeIcon className="nav-item-icon" icon={faHome} />
                <div className="w-full flex justify-between">
                  <span className="nav-item-text">Dashboard</span>
                  <div
                    className={`${
                      location.pathname.endsWith("/dashboard/owner")
                        ? "active-border"
                        : ""
                    }`}
                  ></div>
                </div>
              </Link>
              <Link
                to="inbox"
                className={`nav-item ${
                  location.pathname.endsWith("/inbox") ? "active" : ""
                }`}
              >
                <FontAwesomeIcon className="nav-item-icon" icon={faInbox} />
                <div className="w-full flex justify-between">
                  <span className="nav-item-text">Inbox</span>
                  <div className="flex items-center align-middle justify-end w-full me-2">
                    {(notifications.filter((n) => n.read === false).length >
                      0 ||
                      conversations.filter(
                        (conv) =>
                          !conv.lastReadAt ||
                          (conv.lastMessage &&
                            new Date(conv.lastReadAt) <
                              new Date(conv.lastMessage.createDate))
                      ).length > 0) && (
                      <span className="text-xs bg-red-700 text-white rounded-full justify-center align-middle w-4 h-4 flex font-normal">
                        {(
                          notifications.filter((n) => n.read === false).length +
                          conversations.filter(
                            (conv) =>
                              !conv.lastReadAt ||
                              (conv.lastMessage &&
                                new Date(conv.lastReadAt) <
                                  new Date(conv.lastMessage.createDate))
                          ).length
                        ).toString()}
                      </span>
                    )}
                  </div>
                  <div
                    className={`${
                      location.pathname.endsWith("/inbox")
                        ? "active-border"
                        : ""
                    }`}
                  ></div>
                </div>
              </Link>
              <Link
                to="analytics"
                className={`nav-item ${
                  location.pathname.endsWith("/analytics") ? "active" : ""
                }`}
              >
                <FontAwesomeIcon className="nav-item-icon" icon={faChartLine} />
                <div className="w-full flex justify-between">
                  <span className="nav-item-text">Analytics</span>
                  <div
                    className={`${
                      location.pathname.endsWith("/analytics")
                        ? "active-border"
                        : ""
                    }`}
                  ></div>
                </div>
              </Link>
              <Link
                to="reports"
                className={`nav-item ${
                  location.pathname.endsWith("/reports") ? "active" : ""
                }`}
              >
                <FontAwesomeIcon className="nav-item-icon" icon={faClipboard} />
                <div className="w-full flex justify-between">
                  <span className="nav-item-text">Reports</span>
                  <div
                    className={`${
                      location.pathname.endsWith("/reports")
                        ? "active-border"
                        : ""
                    }`}
                  ></div>
                </div>
              </Link>
              <Link
                to="requests"
                className={`nav-item ${
                  location.pathname.endsWith("/requests") ? "active" : ""
                }`}
              >
                <FontAwesomeIcon className="nav-item-icon" icon={faFileLines} />
                <div className="w-full flex justify-between">
                  <span className="nav-item-text">Requests</span>
                  <div className="flex items-center align-middle justify-end w-full me-2">
                    {transactions &&
                      transactions.filter((tx) => tx.status === "pending")
                        .length > 0 && (
                        <span className="text-xs bg-red-700 text-white rounded-full items-center justify-center align-middle w-4 h-4 flex">
                          {
                            transactions.filter((tx) => tx.status === "pending")
                              .length
                          }
                        </span>
                      )}
                  </div>
                  <div
                    className={`${
                      location.pathname.endsWith("/requests")
                        ? "active-border"
                        : ""
                    }`}
                  ></div>
                </div>
              </Link>
            </div>
          </div>
          <div className="nav-items mb-5 text-sm">
            <Link
              to="settings"
              className={`nav-item ${
                location.pathname.endsWith("/settings") ? "active" : ""
              }`}
            >
              <FontAwesomeIcon className="nav-item-icon" icon={faCog} />
              <div className="w-full flex justify-between">
                <span className="nav-item-text">Settings</span>
                <div
                  className={`${
                    location.pathname.endsWith("/settings")
                      ? "active-border"
                      : ""
                  }`}
                ></div>
              </div>
            </Link>
            <Link className="nav-item" to={"/login"}>
              <div className="nav-item-icon text-sm">
                <FontAwesomeIcon icon={faRightFromBracket} />
              </div>
              <div className="w-full flex justify-between">
                <span className="nav-item-text">Logout</span>
                <div></div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="h-full w-full overflow-auto blue-scrollbar">
        {/* NAVIGATION OUTLET GOES HERE */}
        <Routes>
          <Route path="" element={<OwnerDashboardElement />} />
          <Route path="inbox" element={<OwnerInboxElement />} />
          <Route path="analytics" element={<OwnerAnalyticsElement />} />
          <Route path="reports" element={<OwnerReportsElement />} />
          <Route path="settings" element={<OwnerSettingsElement />} />
          <Route
            path="requests"
            element={
              <AdminRequests
                transactions={transactions ?? []}
                triggerTaskApproval={triggerTaskApproval}
                triggerTaskRejection={triggerTaskRejection}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default OwnerDashboard;
