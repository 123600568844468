import React from "react";
import { useAuth } from "../../../firebase/AuthProvider";

const Footer: React.FC = () => {
  const { currentUser } = useAuth();

  if (currentUser) {
    return (
      // <div className="h-44 max-h-[50px] text-sm absolute bottom-0 w-full bg-black flex justify-center items-end align-middle gap-10 text-gray-400">
        <div>
        {/* <div>
          Sprout - an HEAI, Inc. Product &copy;2025 | All Rights Reserved
        </div>
        <div>Sprout v0.11.0</div> */}
      </div>
    );
  } else {
    return <></>;
  }
};

export default Footer;
