import React, { useEffect } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import { useNavigate } from "react-router-dom";
import LandingPageNavbar from "./nav/LandingPageNavbar";
import { motion } from "framer-motion";
import {
  faAt,
  faCalendarDays,
  faComments,
  faGears,
  faHandHoldingDollar,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LandingPage: React.FC = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/home");
    }
  }, [currentUser]);

  return (
    <div className="relative flex flex-col items-center w-full overflow-x-hidden text-gray-200 bg-[#1a35aa]">
      {/* Navbar */}
      <LandingPageNavbar />

      {/* Hero Section */}
      <div className="relative w-full min-h-[50vh] px-6 sm:px-10">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between pt-20 pb-32">
          {/* Left Content */}
          <div className="flex-1 text-center md:text-left md:ms-10 md:-mt-32">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-tight">
              The All-in-One Workflow for Case Management Agencies
            </h2>
            <p className="mt-4 text-base sm:text-lg text-gray-300">
              Efficiency, compliance, training, and communication — everything you
              need to help disabled participants get the care they deserve.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 mt-6 items-center">
              <button className="w-full sm:w-32 bg-[#00c397] px-4 py-2 rounded-3xl shadow-lg text-white">
                Learn More
              </button>
              <button onClick={() => navigate('/login')} className="w-full sm:w-32 bg-[#00c397] px-4 py-2 rounded-3xl shadow-lg text-white">
                Log In
              </button>
            </div>
          </div>

          {/* Right Image */}
          <div className="flex-1 flex justify-center mt-10 md:mt-0">
            <img
              src="/site-images/watering_sprout.png"
              className="max-h-[500px] w-auto md:w-[395px]"
              alt="sprout"
            />
          </div>
        </div>
        <svg
          className="absolute bottom-0 left-0 w-full h-40"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#fff"
            d="M0,160 C480,0 960,320 1440,160 L1440,320 L0,320 Z"
          />
        </svg>
      </div>

      {/* Features Section */}
      <section
        id="features"
        className="relative w-full bg-white text-black p-6"
      >
        <h2 className="text-center inter-font-bold text-xl">WHAT WE OFFER</h2>
        <div className="w-24 mx-auto h-1 bg-[#00c397] mb-10 mt-2"></div>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-5">
          {[
            {
              title: "HIPAA Compliance",
              text: "Ensure your agency meets strict data security and privacy regulations.",
              icon: faShieldHalved,
            },
            {
              title: "Case Workflow Management",
              text: "Optimize case management processes with seamless automation.",
              icon: faGears,
            },
            {
              title: "Calendar Integration",
              text: "Synchronize schedules across your team effortlessly.",
              icon: faCalendarDays,
            },
            {
              title: "Real-time Chat Integration",
              text: "Facilitate smooth communication between employees and participants.",
              icon: faComments,
            },
            {
              title: "Email Integration",
              text: "Automate participant surveys with streamlined email workflows.",
              icon: faAt,
            },
            {
              title: "File Storage",
              text: "Securely store and access files with cloud-based solutions.",
              icon: faHandHoldingDollar,
            },
          ].map((feature, index) => (
            <div
              key={index}
              className="relative w-full bg-white text-black p-6 flex justify-start"
            >
              <div className="hand-drawn-circle w-24 h-24 relative">
                <img
                  className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 h-24 w-24 opacity-75"
                  src="/site-images/bg_blob.svg"
                  alt="icon"
                />
                <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 h-20 w-20">
                  <img
                    className=""
                    src="/site-images/pencil_circle.png"
                    alt="icon"
                  />
                  <FontAwesomeIcon
                    className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-xl z-10 text-black opacity-50"
                    icon={feature.icon}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-start align-middle gap-5 tracking-tight">
                <h3 className="text-xl font-semibold mt-8 text-[#00c397]">
                  {feature.title}
                </h3>
                <p className=" text-sm text-muted">{feature.text}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Testimonials */}
      {/* <section className="py-10 bg-gray-100 w-full">
        <h2 className="text-center inter-font-bold text-text text-xl">
          WHAT OTHERS ARE SAYING ABOUT US
        </h2>
        <div className="w-24 mx-auto h-1 bg-[#00c397] mb-10 mt-2"></div>
        <div className="">
          <div className="shadow-lg border w-3/5">
            <div className="flex justify-start gap-1 px-10 items-center align-middle text-text">
              <img src="/site-images/blossomsca.png" className="h-16"></img>
              <div className="name">
                <div className="personal-name">Sally Sue</div>
                <div className="business-name">Blossom Support Coordination</div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Call to Action */}
      <footer className="relative w-full max-w-6xl mt-5 text-center text-gray-100 p-6">
        <p className="text-lg">Ready to transform your agency?</p>
        <a
          href="#contact"
          className="mt-4 inline-block bg-[#00c397] text-white font-semibold px-3 py-2 rounded-full shadow-lg hover:bg-green-500"
        >
          Get Started
        </a>
      </footer>
    </div>
  );
};

export default LandingPage;
